<template>
  <div class="content-wrap">
    <div class="title">
        <span class="titlespan">{{isEditor?'编辑新闻':'新增新闻'}}</span>
        <el-button class="fr" size="small" @click="$router.push('/schoolNews')">返回</el-button>
    </div>
    <div class="new-form">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <p class="box-tit" v-if="schoolId=='null'">地区选择</p>
          <el-form-item prop="schoolId" v-if="schoolId=='null'">
            <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
          </el-form-item> 
          <p class="box-tit">新闻</p>
          <el-form-item label="新闻封面:">
            <el-button :class="active == 1?'active':''" @click="active = 1">无图</el-button>
            <el-button :class="active == 2?'active':''" @click="active = 2">单图</el-button>
            <el-button :class="active == 3?'active':''" @click="active = 3">三图</el-button>
          </el-form-item>
          <el-form-item >
            <div class="flex">
              <base-upload
                v-if="active == 2 || active == 3"
                @beforeUpload="handleBeforeUploadAvatar"
                :limit="1"
                fileType="single"
                v-model="pic1"
                :uploadData="uploadData"
                tips
                buttonTxt="上传封面"
                class="components-upload-up"
              ></base-upload>
              <base-upload
                v-if="active == 3"
                @beforeUpload="handleBeforeUploadAvatar"
                :limit="1"
                fileType="single"
                v-model="pic2"
                :uploadData="uploadData"
                tips
                buttonTxt="上传封面"
                class="components-upload-up"
              ></base-upload>
              <base-upload
                v-if="active == 3"
                @beforeUpload="handleBeforeUploadAvatar"
                :limit="1"
                fileType="single"
                v-model="pic3"
                :uploadData="uploadData"
                tips
                buttonTxt="上传封面"
                class="components-upload-up"
              ></base-upload>
            </div>
            <span class="txt">建议尺寸: 480x270</span>
          </el-form-item>
          <el-form-item label="新闻标题:" prop="title">
             <el-input placeholder="请输入新闻标题" clearable v-model="form.title" ></el-input>
          </el-form-item>
          <el-form-item label="新闻内容:" prop="content"> 
             <tinymce   v-model="form.content"></tinymce>
          </el-form-item>
          <div class="btn">
             <div class="con cfx">
                <el-button class="fl" @click="fetchSchoolNewsColse">取消</el-button>
                <el-button class="fr" type="success" @click="fetchSchoolNewsAdd">确定</el-button>
             </div>
          </div>
          
      </el-form>
    </div>
  </div>
</template>

<script>
import BaseUpload from "../common/BaseUpload.vue";
import Tinymce from "@/tinymce";
import AreaTree from "@/components/common/AreaTree";
import { mapActions } from "vuex";
  export default {
    components: {
    BaseUpload,
    Tinymce,
    AreaTree
    },
    data(){
      return{
        isEditor: false,
        schoolId:'',
        queryParams:{},
        id:'',
        active:1, // 图片状态  1 无图 2 单图  3 多图
        pic1:'',
        pic2:'',
        pic3:'',
        form:{
          zoneId:'',
          title:this.$route.query.title,
          content:this.$route.query.content,
          pics:this.$route.query.pics
        },
        rules:{
          zoneId:[ { required: true, message: '请选择发布区域', trigger: 'blur' }],
          title:[ { required: true, message: '请输入新闻标题', trigger: 'blur' }],
          content:[ { required: true, message: '请输入新闻内容', trigger: 'blur' }],
          pics:[ { required: true, message: '请上传新闻封面', trigger: 'blur' }],
        },
        uploadData: {
          bizType: "",
          bizId: "",
          needStore: "1",
        },
      }
    },

    methods: {
      ...mapActions("schoolNews", [
        "getSchoolNewsAdd",
        "getSchoolNewsEdit",
        "getSchoolNewsDelete",
        "getSchoolNewsLoad"

      ]),
      // 新增
      async fetchSchoolNewsAdd(){
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if(this.form.zoneId=="") {
              return  this.$message({
                message: '请选择发布区域',
                type: 'warning'
              });
            }
            let params = Object.assign({}, this.form)
            if (this.active == 1) {
                params.pics = ''
              } else if (this.active == 2) {
                params.pics = this.pic1
              } else {
                // 三张图
                if (this.pic1 != '' && this.pic2 != '' && this.pic3 != '') {
                  params.pics = this.pic1 + ',' + this.pic2 + ',' + this.pic3
                }else{
                  return this.$message({
                    message: '必须上传三张图片',
                    type: 'warning'
                  });
                }         
              }
            this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
              // 上传
              this.bLoading = true
              try {
                if(this.isEditor==false) {
                  let res = await this.getSchoolNewsAdd(params)
                }else{
                  let res = await this.getSchoolNewsEdit(params)
                }
                this.$message.success('数据保存成功！')
                this.$router.push('schoolNews')
              }catch(e){
                log(e)
              }finally {
                this.bLoading = false
              }
            })
          }
        })
        // window.history.go(-1)
      },
      //取消
      fetchSchoolNewsColse(){
        window.history.go(-1)
      },

      handleBeforeUploadAvatar(img, callback) {
        const isImg2M = img.size / 1024 / 1024 < 100;
        const isImg =
          img.name.includes("jpg") ||
          img.name.includes("png");
        if (!isImg) {
          this.$message.error("只能上传JPG/PNG!");
          return;
        }
        if (!isImg2M) {
          this.$message.error("上传文件大小不能超过 100MB!");
          return;
        }
        callback(isImg && isImg2M);
      },
      handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
          this.form.zoneId = ele
        });
        // console.log(this.queryParams,'lll');
      },
      clearArea(isRefresh){
        this.queryParams.province = null;
        this.queryParams.city = null;
        this.queryParams.area = null;
        this.queryParams.schoolId = null;
        if(isRefresh) this.refresh();
      }
    },
    async mounted() {
      this.schoolId = sessionStorage.getItem('schoolId')
      if( this.schoolId !=='null') {
        this.form.zoneId = this.schoolId
      }
      this.id = this.$route.query.id
      if(this.id) {
        this.isEditor = true
        let res = await this.getSchoolNewsLoad({id:this.id})
        this.form = {
          id: res.id,
          title: res.title,
          content: res.content,
          zoneId:''
        }
        let imgList = res.pics.split(',')
        if (imgList[0] == '') {
          this.active = 1
          this.form.pics = ''
        }
        if (imgList.length == 1 && imgList[0] != '') {
          this.active = 2
          this.pic1 = imgList[0]
        }else {
          this.active = 3
          this.pic1 = imgList[0]
          this.pic2 = imgList[1]
          this.pic3 = imgList[2]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.content-wrap{
  .title{ height: 30px; line-height: 30px; border-bottom:1px solid $c-border;
    .titlespan{position:relative;border-bottom:4px solid $c-success;padding-bottom:5px;font-size:16px; font-weight: 600;
    }
  }
  .new-form{ margin-top: 20px;
    .el-form-item__label{text-align: left; font-size: 18px; font-weight: 400;}
    .box-tit{font-size: 16px; font-weight: 600; margin-bottom: 20px;
    }
    .flex {display: flex;}
    .active {background-color: $c-success;color: $c-white;}
    .btn{
      .con{width: 200px; margin: 0px auto;
      }
    }
  }
  /deep/.components-upload .avatar-uploader-icon {width: 240px; height: 135px; color: $c-success; font-size: 30px; font-weight: bold;}
  /deep/.components-upload .avatar-uploader .el-upload{margin: 0 10px;}
  .txt{color: #AFAFAF;}
}

</style>