/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const advertPositionList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/list",
    method: "post",
    data: params
  });
};

export const advertPositionAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/add",
    method: "post",
    data: params
  });
};

export const advertPositionEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/edit",
    method: "post",
    data: params
  });
};

export const advertPositionDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/delete",
    method: "post",
    data: params
  });
};

export default {
  advertPositionList,
  advertPositionAdd,
  advertPositionEdit,
  advertPositionDelete,
};
