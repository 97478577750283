import api from "@/api/assess/divide";

const state = {
    
}

const actions = {

}

const mutations = {
   
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
