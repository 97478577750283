/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 门锁列表
export const getLockList = function(params) {
  return axios({
    url: BaseUrl + "operate/lock/list",
    method: "post",
    data: params
  });
};

// 详情
export const lockDetail = function(params) {
  return axios({
    url: BaseUrl + "operate/lock/load",
    method: "post",
    data: params
  });
};

// 编辑
export const lockEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/lock/edit",
    method: "post",
    data: params
  });
};

export default {
  getLockList,
  lockDetail,
  lockEdit
};
