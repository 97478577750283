/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/advPosition";

const state = {
  advList: [],
  advTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.advList = list;
    state.advTotal = total;
  },
};

// actions
const actions = {
  async advertPositionList({ commit, state }, params) {
    let result = await api.advertPositionList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async advertPositionAdd({ commit, state }, params) {
    return await api.advertPositionAdd(params);
  },
  async advertPositionEdit({ commit, state }, params) {
    return await api.advertPositionEdit(params);
  },
  async advertPositionDelete({ commit, state }, params) {
    return await api.advertPositionDelete(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
