<template>
<!-- 列表组件 -->
  <div>
    <el-table
      id="table"
      class="entity-table"
      :data="data"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
      :default-sort="{ prop: defaultSortProp, order: defaultSortOrder }"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
      ref="table"
    >
      <el-table-column
        v-if="isShowSelection"
        fixed="left"
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        fixed="left"
        prop="serialNumber"
        :label="defaultLabelName"
        :width="width"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <slot></slot>
    </el-table>
    <el-pagination
      background
      v-if="!hidePagination && total>0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNum"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
export default {
  name: "PagedTable",
  components: {},
  props: {
    data: Array,
    loading: {
      type: Boolean,
      default: false
    },
    defaultIdLabel: {
      type: String,
      default: "ID"
    },
    defaultLabelName: {
      type: String,
      default: "序号"
    },
    defaultSortProp: {
      type: String,
      default: "id"
    },
    defaultSortOrder: {
      type: String,
      default: "descending"
    },
    total: {
      type: Number,
      default: 0
    },
    value: null,
    hidePagination: {
      type: Boolean,
      default: false
    },
    isShowSelection: {
      type: Boolean,
      default: false
    },
    idSortable: {
      type: Boolean,
      default: true
    },
    width: {
      default: 70
    }
  },
  computed: {
    
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      }
    };
  },
  watch: {
    value() {
      this.onValueChange();
    },
    data(arr){
      arr.forEach((k,i)=>{
        k.serialNumber = (this.queryParams.pageNum - 1) * this.queryParams.pageSize + parseInt(i) + 1
      })
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("selectionChange", val);
    },
    handleSortChange(val) {
      if (!val.order) return;
      if (val.prop) {
        this.$emit("input", this.queryParams);
        this.$emit("refresh");
      }
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.$emit("input", this.queryParams);
      this.$emit("refresh");
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.$emit("input", this.queryParams);
      this.$emit("refresh");
    },
    onValueChange() {
      if (this.value !== null && this.value !== undefined)
        this.queryParams = Object.assign(this.value, this.queryParams);
    }
  },
  mounted() {
    this.data.forEach((k,i)=>{
      k.serialNumber = (this.queryParams.pageNum - 1) * this.queryParams.pageSize + parseInt(i) + 1
    })
    this.onValueChange()
    this.$emit("input", this.queryParams);
  }
};
</script>