import api from '@/api/market/distribution'

const state = {
    distributionList:[],      // 分销任务 列表
    distributionTotal:0,      // 分销任务 数量
    distInfo: {
        extend:{}
    }      // 分销任务详情
}

const actions = {
    // 分销列表
    async getDistributionList({commit,state},params) {
        let res = await api.getDistributionList(params)
        let {list,total} = res
        commit('saveDistribution',{list,total})
        return res
    },
    // 分销详情
    async getDistInfo({commit,state},params) {
        let res = await api.getDistInfo(params)
        commit('saveDistInfo',res)
        return res
    },
    // 分销成员
    async getDistMember({commit,state},params) {
        return await api.getDistMember(params)
    }
}

const mutations = {
    saveDistribution(state,{list,total}) {
        state.distributionList = list
        state.distributionTotal = total
    },
    saveDistInfo(state,data) {
        state.distInfo = data
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}