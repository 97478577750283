<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- 失物招领 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
        <el-form-item v-if="!url">
          <area-tree ref="cascader" @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="类型：" class="ml20">
          <el-select v-model="queryParams.type" @change="resetPageAndRefresh" clearable filterable placeholder="请选择类型">
            <el-option label="招领" :value="1" ></el-option>
            <el-option label="失物" :value="2"  ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" class="ml20">
          <el-select v-model="queryParams.status" placeholder="请选择状态" clearable filterable @change="resetPageAndRefresh">
            <el-option label="寻找中" :value="0" ></el-option>
            <el-option label="已找到" :value="1" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间：" class="ml20">
          <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParams'),refresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item label="内容：" class="ml20">
          <el-input v-model="queryParams.content" placeholder="请输入关键词"  clearable ></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" size="small" @click="resetPageAndRefresh">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="isEditor=!isEditor"  v-if="OA.includes('yygl:swzl:del')"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '删除'}}</el-button>
        </el-form-item>
        
      </el-form>
      <!-- 列表 -->
      <div v-if="goodTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="goodList" :total="goodTotal" :isEditor="isEditor" @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="list" @click="detail(scope.row.id)">
            <div class="con-head">
              <div class="area">
                <p class="con-txt">{{scope.row.type==1?'招领':'失物'}}</p>
                <p class="mission">类型</p>
              </div>
              <div class="area">
                <p class="con-txt txtwidth" :title="scope.row.title">{{scope.row.title?scope.row.title:'-'}}</p>
                <p class="mission">物品名称</p>
              </div>
              <div class="areas fr">
                <p class="con-txt tinctblue" v-if="scope.row.status==0">寻找中</p>
                <p class="con-txt tinctgray" v-if="scope.row.status==1">已找到</p>
                <p class="mission fr">状态</p>
              </div>
              <div class="area fr">
                <p class="con-txt">{{scope.row.extend.userName}}</p>
                <p class="mission">发布者</p>
              </div> 
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">发布时间</span>
                  <span class="condel">2020-02-27 16:21</span>
                </div>
                <div class="time" v-if="scope.row.type==2">
                  <span class="contit">丢失时间</span>
                  <span class="condel">{{scope.row.addTime?scope.row.addTime:"-"}}</span>
                </div>
                <div class="time" v-if="scope.row.type==2">
                  <span class="contit " >丢失地点</span>
                  <span class="condel twidth">{{scope.row.address?scope.row.address:"-"}}</span>
                </div>
                <div class="time" v-if="scope.row.type==1">
                  <span class="contit" >捡到时间</span>
                  <span class="condel ">{{scope.row.addTime?scope.row.addTime:"-"}}</span>
                </div>
                <div class="time" v-if="scope.row.type==1">
                  <span class="contit " >捡到地点</span>
                  <span class="condel twidth">{{scope.row.address?scope.row.address:"-"}}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time">
                  <span class="contit">学校名称</span>
                  <span class="condel">{{scope.row.extend.schoolName?scope.row.extend.schoolName:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">手机号码</span>
                  <span class="condel">{{scope.row.phone?scope.row.phone:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">内容状态</span>
                  <span class="condel" v-if="scope.row.del==0">上架中</span>
                  <span class="condel" v-if="scope.row.del==1">用户删除</span>
                  <span class="condel" v-if="scope.row.del==2">平台删除</span>
                </div>
              </div>
            </div>
          </div>
        </template> 
        <template v-slot:control="{row}">          
          <el-button v-if="OA.includes('yygl:swzl:del')" type="danger"  @click="loseDel(row.id)" icon="el-icon-delete" circle></el-button>
          <!-- <el-button type="danger"  @click="reportDelete(row.id)" icon="el-icon-delete" circle></el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      append-to-body
      title="详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
      <div class="allocationdetail" v-if="details.goods">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">{{details.goods.type==1?'招领':'失物'}}</p>
              <p class="mission">类型</p>
            </div>
            <div class="area">
              <p class="con-txt" >{{details.goods.addTime?details.goods.addTime:'-'}}</p>
              <p class="mission">发布时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt tinctblue" v-if="details.goods.status==0">寻找中</p>
              <p class="con-txt tinctgray" v-if="details.goods.status==1">已找到</p>
              <p class="mission">状态</p>
            </div>
          </div>
          <div class="headcon" v-if="details.user">
            <p class="contit">发布人</p>
            <img :src="details.user.headImg" class="pig" alt="">
            <div class="area">
              <p class="con-txt">
                {{details.user.extend.userName?details.user.extend.userName:'-'}}
                <img :src="details.user.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
              </p>
              <p class="mission">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}|{{details.user.fade?details.user.fade:'-'}}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.goods.phone?details.goods.phone:'-'}}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>
        <div class="d-condel" v-if="details.goods">
          <p class="headLine">物品详情</p>
          <div class="people">
            <p class="peo-head">{{details.goods.title?details.goods.title:'-'}}</p>
            <p class="peo-time" v-if="details.goods.type==2"><span class="time">丢失时间：</span><span class="time-con">{{details.goods.pickTime?details.goods.pickTime:'-'}}</span></p>
            <p class="peo-time" v-if="details.goods.type==2"><span class="time">丢失地点：</span><span class="time-con">{{details.goods.address?details.goods.address:'-'}}</span></p>
            <p class="peo-time" v-if="details.goods.type==1"><span class="time">捡到时间：</span><span class="time-con">{{details.goods.pickTime?details.goods.pickTime:'-'}}</span></p>
            <p class="peo-time" v-if="details.goods.type==1"><span class="time">捡到地点：</span><span class="time-con">{{details.goods.address?details.goods.address:'-'}}</span></p>
            <p class="peo-time"><span class="time">物品描述：</span><span class="time-con">{{details.goods.content?details.goods.content:'-'}}</span></p>
          </div>
        </div>
        <div class="d-conjob">
          <viewer :images="pics">
            <img v-for="(item,index) in pics" :key="index" :src="item" alt="">
          </viewer>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";
import { log, mergeObject } from '@/common/utils'

export default {
  name:'lostProperty',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      url:'',
      active: 1,
      drawer: false,
      drawerApply: false,
      statusOptions:[],
      queryParams: {
        pageNum:1,
        pageSize:10,
        s1:'',
        s2:'',
        content:'',
        status:''
      },
      pics:[{}],
      details:{},
      pageApi:'getPagesList',      
      isEditor: false
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('lostProperty',['goodList','goodTotal'])
  },
  methods: {
     ...mapActions('lostProperty',["getPagesList","goodsAdd","goodsEdit","goodsLoad","goodsDelete"]),
    add() {
      this.$router.push({
        path:'/newPart'
      })
    },
    edit() {
      this.status = 1
    },
    commit() {
      this.status = 0
    },
    // 失物招领删除，逻辑删除
    async loseDel(id) {
      await this.goodsEdit({id,del:2})
      this.isEditor = false
      this.refresh();
    },
    async detail(id) {
      const res = await this.goodsLoad({id})
      console.log(res)
      this.details = res
      console.log(this.details.goods.pics)
      if(this.details.goods.pics) {
        this.pics = this.details.goods.pics.split(',')
      }
      this.drawer = true
    },
    republish() {
      this.active = 1
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    // 打开报名列表
    applyList() {
      this.active = 2
      this.drawerApply = true
    }
  },
  async mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.ml20 {margin-left: 20px;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 300px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;}
        .time{ display: inline-block; font-size: 14px;padding-top:15px;
          .contit{color: $c-9;padding-right: 10px;}
          span { display: inline-block; vertical-align: top;}
          .twidth{ width: 200px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .condel{color: $c-2; width: 200px;}
        }
      }
    }
  }
}
/deep/#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;}
.allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 10px;
        .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%;}
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-condel{padding-top:20px;
    .headLine{ font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: bolder;color: $c-2;line-height: 16px; }
    .people{ overflow: hidden;
      .peo-head{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: $c-2;line-height: 20px; margin: 20px 0;}
      .peo-time{margin-top: 20px;
        .time{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px;}
        .time-con{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 14px;
        }
      }
    }
  }
  .d-conjob{ margin-top: 20px;padding-bottom:20px;
    img{ width: 150px;height: 150px; background: skyblue;margin-right: 10px; border-radius: 4px; margin-bottom: 10px;}
  }
}

</style>