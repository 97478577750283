import { BaseUrl } from "@/common/config";
import axios from "axios";

// 赛事列表
export const getMatchList = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/getList',
        method: 'post',
        data: params
    })
}

// 赛事详情
export const getMatchInfo = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/load',
        method: 'post',
        data: params
    })
}


// 赛事审核
export const matchCheck = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/audit',
        method: 'post',
        data: params
    })
}

// 取消资格
export const matchRecusal= function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/cancel',
        method: 'post',
        data: params
    })
}

// 数据统计
export const getDataDetail = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/getNum',
        method: 'post',
        data: params
    })
}

// 活动奖项发布状态
export const chargeStatus = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/status/getStatus',
        method: 'post',
        data: params
    })
}
// 修改状态
export const editStatus = function (params) {
    return axios({
        url: BaseUrl + 'match/web/god/status/editStatus',
        method: 'post',
        data: params
    })
}

export default {
    getMatchList,
    getMatchInfo,
    matchCheck,
    matchRecusal,
    getDataDetail,
    chargeStatus,
    editStatus
}