import { log } from "@/common/utils";
import api from "@/api/market/channelMarket";

const state = {
    marketList:[],  // 分销列表
    marketTotal:0,  // 分销列表数量
    marketInfo:{}   // 分销详情
}

const actions = {
    // 分销列表
    async getChannelMarketList({commit,state},params) {
        let res = await api.getChannelMarketList(params)
        let { list, total } = res
        commit("saveMarketList",{ list, total })
        return res
    },
    // 分销详情
    async getChannelInfo({commit,state},params) {
        let res = await api.getChannelInfo(params)
        commit('saveMarketInfo',res)
        return res
    },
    // 已加入分销 info
    async successTask({commit,state},params) {
        return await api.successTask(params)
    },
    // 不加入分销 info
    async failTask({commit,state},params) {
        return await api.failTask(params)
    },
    // 加入分销
    async joinTask({commit,state},params) {
        return await api.joinTask(params)
    },
    // 拒绝加入分销
    async refuseTask({commit,state},params) {
        return await api.refuseTask(params)
    }
}

const mutations = {
    saveMarketList(state,{list,total}) {
        state.marketList = list
        state.marketTotal = total
    },
    saveMarketInfo(state,data) {
        state.marketInfo = data
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}