/*
 * @Auhtor: 文锋
 * @Date: 2021-05-24 14:24:33
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-26 09:06:47
 * @FilePath: \operate\src\store\modules\operate\schoolFellow.js
 */
/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/schoolFellow";
import { recommendTopicAdd } from "../../../api/operate/schoolFellow";

const state = {
    schoolFellowList:[],
    schoolFellowTotal: 0,
    schooltopicList:[],  // 全部话题
    schooltopicTotal:0,  // 话题数量
    hotTopicList:[],     // 热门话题
    selectHotTopicList:[],  // 下拉热门话题列表
    indexTopicList:[],   // 推荐话题列表
    indexTopicTotal: 0,
};

// actions
const actions = {
  async getSchoolFellowList({ commit, state }, params) {
    let result = await api.getSchoolFellowList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async socialDetail({ commit, state }, params) {
    return await api.socialDetail(params);
  },
  // 全部话题
  async getSchooltopicList({commit,state},params) {
    let res = await api.getSchooltopicList(params)
    let {list,total} = res
    commit('saveSchooltopicList',{list,total})
    return res
  },
  // 查询话题重复
  async selectRepeat({commit,state},params) {
    return await api.selectRepeat(params)
  },
  // 新增话题
  async addSchooltopic({commit,state},params) {
    return await api.addSchooltopic(params)
  },
  // 话题详情
  async schooltopicInfo({commit,state},params) {
    return await api.schooltopicInfo(params)
  },
  // 更新话题
  async editSchooltopic({commit,state},params) {
    return await api.editSchooltopic(params)
  },
  // 开启关闭话题
  async upOrDowntopic({commit,state},params) {
    return await api.upOrDowntopic(params)
  },
  // 删除话题
  async deleteSchooltopic({commit,state},params) {
    return await api.deleteSchooltopic(params)
  },
  // 热门话题
  async getHotTopicList({commit,state},params) {
    let res = await api.getHotTopicList(params)
    let {list} = res
    commit('saveHotTopic',{list})
    return res
  },
  // 热门话题 下拉列表
  async getSelectAllTopic({commit,state},params) {
    let res = await api.getSelectAllTopic(params)
    commit('saveSelectTopicList',res)
    return res
  },
  // 更新热门话题
  async updateHotTopic({commit,state},params) {
    return await api.updateHotTopic(params)
  },
  // 推荐话题列表
  async recommendTopicList({commit,state},params) {
    let res = await api.recommendTopicList(params)
    let {list,total} = res
    commit('saveRecommendTopicList',{list,total})
    return res
  },
  // 查重 推荐话题
  async recommendTopicRepeat({commit,state},params) {
    return await api.recommendTopicRepeat(params)
  },
  // add 推荐话题
  async recommendTopicAdd({commit,state},params) {
    return await api.recommendTopicAdd(params)
  },
  // edit 推荐话题
  async recommendTopicEdit({commit,state},params) {
    return await api.recommendTopicEdit(params)
  },
  // info 推荐话题
  async recommendTopicInfo({commit,state},params) {
    return await api.recommendTopicInfo(params)
  },
  // delete 推荐话题
  async recommendTopicDelete({commit,state},params) {
    return await api.recommendTopicDelete(params)
  },
  // status 推荐话题
  async recommendTopicStatus({commit,state},params) {
    return await api.recommendTopicStatus(params)
  }
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.schoolFellowList = list;
    state.schoolFellowTotal = total;
  },
  saveSchooltopicList(state,{list, total}) {
    state.schooltopicList = list
    state.schooltopicTotal = total
  },
  saveHotTopic(state,{list}) {
    state.hotTopicList = list
  },
  saveSelectTopicList(state,data) {
    state.selectHotTopicList = data
  },
  saveRecommendTopicList(state,{list,total}) {
    state.indexTopicList = list
    state.indexTopicTotal = total
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
