/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 提现列表
export const settleWithdrawSelectAll = function(params) {
  return axios({
    url: BaseUrl + "settle/withdraw/selectAll",
    method: "post",
    data: params
  });
};

// 修改
export const settleWithdrawUpd = function(params) {
  return axios({
    url: BaseUrl + "settle/withdraw/upd",
    method: "post",
    data: params
  });
};

export default {
  settleWithdrawSelectAll,
  settleWithdrawUpd,
};
