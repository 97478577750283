/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/task/mytask/DirectorTask";

const state = {
  dirTaskList:[],
  dirTaskTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.dirTaskList = list;
    state.dirTaskTotal = total;
  },
};

// actions
const actions = {
  async directorList({ commit, state }, params) {
    let result = await api.directorList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async directorAdd({ commit, state }, params) {
    return await api.directorAdd(params);
  },
  async directorEdit({ commit, state }, params) {
    return await api.directorEdit(params);
  },
  async directorLoad({ commit, state }, params) {
    return await api.directorLoad(params);
  },
  async directorDelete({ commit, state }, params) {
    return await api.directorDelete(params);
  },
  async directorAll({ commit, state }, params) {
    return await api.directorAll(params);
  },
  async directorDis({ commit, state }, params) {
    return await api.directorDis(params);
  },
  async taskadminDirectorGetAllManagers({ commit, state }, params) {
    return await api.taskadminDirectorGetAllManagers(params);
  },
  async directorGetSurplus({ commit, state }, params) {
    return await api.directorGetSurplus(params);
  },
  async taskAdd({ commit, state }, params) {
    return await api.taskAdd(params);
  },
  async taskList({ commit, state }, params) {
    return await api.taskList(params);
  },
  async taskTree({ commit, state }, params) {
    return await api.taskTree(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
