<template>
  <div>
    <!-- 门锁管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.systemlockName" clearable placeholder="请输入协议名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.systemlockManager" clearable placeholder="请输入协议负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <!-- <div class="btn-wrap cfx">
        <el-button v-if="OA.includes('jcxx:xygl:add')" type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="lockTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div> -->

      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-download">
          <el-link href="https://h5.ahqmhl.cn/operate/lock/download" style="color:white">导出</el-link>
        </el-button>
      </div>
      <!--列表-->
      <paged-table :data="lockList" :total="lockTotal" :loading="tLoading" v-if="lockTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="schoolName" show-overflow-tooltip label="学校名称"></el-table-column>
        <el-table-column sortable="custom" prop="contact" show-overflow-tooltip label="联系人"></el-table-column>
        <el-table-column sortable="custom" prop="phone" show-overflow-tooltip label="联系方式"></el-table-column>
        <el-table-column sortable="custom" prop="schoolType" show-overflow-tooltip label="学校类型"></el-table-column>
        <el-table-column sortable="custom" prop="studentTotal" show-overflow-tooltip label="学生总数"></el-table-column>
        <el-table-column sortable="custom" prop="buildingTotal" show-overflow-tooltip label="宿舍楼总数"></el-table-column>
        <el-table-column sortable="custom" prop="roomTotal" show-overflow-tooltip label="宿舍房间总数"></el-table-column>
        <el-table-column sortable="custom" prop="lockYet" show-overflow-tooltip label="安装情况"></el-table-column>
        <el-table-column sortable="custom" prop="doorMaterial" show-overflow-tooltip label="宿舍门材质"></el-table-column>
        <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        <!-- <el-table-column sortable="custom" prop="type" show-overflow-tooltip label="处理状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1">未处理</span>
            <span v-if="scope.row.status==2">已处理</span>
          </template>
        </el-table-column>
        -->
        <el-table-column align="center" label="操作" width="120" fixed="right" >
          <template slot-scope="scope">
            <!-- <el-button v-if="OA.includes('jcxx:xygl:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:xygl:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="处理"></el-button> -->
            <el-button size="mini" @click="showDetail(scope.row.id)" title="详情">详情</el-button>
          </template>
        </el-table-column> 
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑' : '添加'" :visible.sync="aVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="协议名称：" prop="name" style="width: 80%">
          <el-input placeholder="请输入协议名称" v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="协议类型" prop="type" style="width: 80%" >
          <el-select v-model="form.type" clearable filterable placeholder="请选择协议类型">
            <el-option label="注册协议" :value="1"></el-option>
            <el-option label="隐私协议" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议内容：" prop="content">
          <tinymce placeholder="请输入协议内容" v-model="form.content" v-if="aVisible"></tinymce>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="详情" :visible.sync="bVisible" width="650px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" prop="name">
          <span v-text="details.schoolName"></span>
        </el-form-item>
        <el-form-item label="联系人：" prop="contact">
          <span v-text="details.contact"></span>
        </el-form-item>
        <el-form-item label="联系方式：" prop="phone">
          <span v-text="details.phone"></span>
        </el-form-item>
        <el-form-item label="学校类型：" prop="schoolType">
          <span v-text="details.schoolType"></span>
        </el-form-item>
        <el-form-item label="学生总数：" prop="studentTotal">
          <span v-text="details.studentTotal"></span>
        </el-form-item>
        <el-form-item label="宿舍楼总数：" prop="buildingTotal">
          <span v-text="details.buildingTotal"></span>
        </el-form-item>
        <el-form-item label="宿舍房间总数：" prop="roomTotal">
          <span v-text="details.roomTotal"></span>
        </el-form-item>
        <el-form-item label="是否安装：" prop="lockYet">
          <span v-text="details.lockYet"></span>
        </el-form-item>
        <el-form-item label="宿舍门材质：" prop="doorMaterial">
          <span v-text="details.doorMaterial"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
        <el-form-item label="添加时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
        <!-- <el-form-item label="处理状态：" prop="status"> 
          <span>{{ details.status==1?'未处理':'已处理' }}</span>
        </el-form-item> -->
        <el-form-item label="照片：" >
          <viewer :images="details.picList">
            <img v-for="(item,index) in details.picList" :key="index" :src="item" style="width:30%;border-radius:10px;margin-right:10px;margin-bottom:10px"/>
          </viewer>
        </el-form-item>
        <!-- <el-form-item label="协议添加人：" prop="extend.addUserName">
          <span v-text="details.extend.addUserName"></span>
        </el-form-item>
        <el-form-item label="协议添加时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
import Tinymce from "@/tinymce";
export default {
  name:'lock',
  components: {
    PagedTable,
    Tinymce,
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      queryParams: {
        systemlockName: null,
        systemlockManager: null,
      },

      form:{
        id:'',
        name: '',
        content: '',
        type: '',
      },
      details:{
      },
      formRules:{
        name: [{ required: true, message: '请输入协议名称', trigger: 'blur' }],
        content: [{ required: true, message: '请输入协议内容', trigger: 'blur' }],
        type: [{ required: true, message: '请选择协议类型', trigger: 'blur' }],
      },

      pageApi:'getLockList',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("lock", ["lockList", "lockTotal"]),
  },
  methods: {
    ...mapActions("lock",["getLockList", "lockEdit", "lockDetail"]),

    //展示详情
    async showDetail(id){
      console.log("lock-id",id)
      let res = await this.lockDetail({id});
      this.details = res
      this.bVisible = true
    }
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>
