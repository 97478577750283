<template>
  <div>
    <!-- 首页广告管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.systemPopName" clearable placeholder="请输入首页广告名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.systemPopManager" clearable placeholder="请输入首页广告负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button v-if="OA.includes('jcxx:sygggl:add')" type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="popTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="popList" :total="popTotal" :loading="tLoading" v-if="popTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh"> 
        <el-table-column sortable="custom" prop="title" show-overflow-tooltip label="标题"></el-table-column>
        <el-table-column sortable="custom" prop="photo" show-overflow-tooltip label="图片地址"></el-table-column>
        <el-table-column sortable="custom" prop="linkType" show-overflow-tooltip label="链接类型">
          <template slot-scope="scope">
            <span>{{ scope.row.linkType==1?'内部链接':'外部链接' }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="linkUrl" show-overflow-tooltip label="链接地址"></el-table-column>
        <el-table-column sortable="custom" prop="startDate" show-overflow-tooltip label="开始时间"></el-table-column>
        <el-table-column sortable="custom" prop="endDate" show-overflow-tooltip label="结束时间"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="OA.includes('jcxx:sygggl:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:sygggl:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="OA.includes('jcxx:sygggl:del')" size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑首页广告' : '添加首页广告'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="广告标题：" prop="title">
          <el-input placeholder="请输入广告标题" style="width:316px;" v-model="form.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="广告图片：" prop="photo">
          <base-upload v-model="form.photo" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片格式只支持：JPG、PNG</div>
        </el-form-item>
        <el-form-item label="广告类型：" prop="linkType">
          <el-select clearable filterable v-model="form.linkType" style="width:316px;" placeholder="请选择广告类型">
            <el-option label="内部链接" :value="1"></el-option>
            <el-option label="外部链接" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接地址：" prop="linkUrl">
          <el-input placeholder="请输入链接地址" style="width:316px;" v-model="form.linkUrl" clearable></el-input>
        </el-form-item>
        <el-form-item label="投放日期：" prop="timeRange">
          <el-date-picker style="width:316px;" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate')" type="datetimerange" value-format="yyyy-MM-dd HH:mm" :picker-options="recentOptions" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="首页广告详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="广告标题：" prop="title">
          <span v-text="details.title"></span>
        </el-form-item>
        <el-form-item label="广告图片：" prop="photo">
          <el-image style="width: 100px; height: 100px" :src="details.photo" v-viewer></el-image>
        </el-form-item>
        <el-form-item label="连接类型：" prop="linkType">
          <span>{{ details.linkType==1?'内部链接':'外部链接' }}</span>
        </el-form-item>
        <el-form-item label="链接地址：" prop="linkUrl">
          <span> {{ details.linkUrl }} </span>
        </el-form-item>
        <el-form-item label="开始时间：" prop="startDate">
          <span> {{ details.startDate }} </span>
        </el-form-item>
        <el-form-item label="结束时间：" prop="startDate">
          <span> {{ details.endDate }} </span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'popManage',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, datePicker],
  data() {
    let validateTime = (rule, value, callback) => {
      if (!this.form.startDate || !this.form.endDate) {
        callback(new Error('请选择投放日期'));
      }
      callback();
    };
    return {
      queryParams: {
      },

      form:{
        id:'',
        title:'',
        photo:'',
        linkType:'',
        linkUrl:'',
        startDate:'',
        endDate:'',
      },
      details:{
        title:'',
        photo:'',
        linkType:'',
        linkUrl:'',
        startDate:'',
        endDate:'',
      },
      formRules:{
        title: [{ required: true, message: '请输入广告标题', trigger: 'blur' }],
        photo: [{ required: true, message: '请上传广告图片', trigger: 'blur' }],
        linkType: [{ required: true, message: '请输入连接类型', trigger: 'blur' }],
        linkUrl: [{ required: true, message: '请输入链接地址', trigger: 'blur' }],
        timeRange: [{ required: true, validator: validateTime, trigger: 'blur' }],
      },

      pageApi:'systemPopList',
      insertApi:'systemPopAdd',
      editorApi:'systemPopEdit',
      deleteApi:'systemPopDelete',
      loadApi:'systemPopLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemPop", ["popList", "popTotal"]),
  },
  methods: {
    ...mapActions("systemPop",["systemPopList", "systemPopAdd", "systemPopEdit", "systemPopDelete", "systemPopLoad"]),
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png')
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isPicture) {
        this.$message.error('上传引导图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传引导图片大小不能超过 1MB!');
      }
      callback(isPicture && isLt2M)
    },
    timeRangeChange(val, index){
      this.gameTime[index].startDate = val ? val[0] : ''
      this.gameTime[index].endDate = val ? val[1] : ''
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>