import { BaseUrl } from "@/common/config";
import axios from "axios";

// 分销群列表
export const getGroupList = function (params) {
    return axios({
        url: BaseUrl + 'sell/group/list',
        method: 'post',
        data: params
    })
}

// 解散分销群
export const cancelGroupMarket = function (params) {
    return axios({
        url: BaseUrl + 'sell/group/dismiss',
        method: 'post',
        data: params
    })
}

export default {
    getGroupList,
    cancelGroupMarket
}