/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 新闻列表
export const postSchoolNewsList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/schoolNews/getPagesList",
    method: "post",
    data: params
  });
};
// 添加
export const postSchoolNewsAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/schoolNews/add",
    method: "post",
    data: params
  });
};
// 修改
export const postSchoolNewsEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/schoolNews/edit",
    method: "post",
    data: params
  });
};
// 详情
export const postSchoolNewsLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/schoolNews/load",
    method: "post",
    data: params
  });
};
// 物理删除
export const postSchoolNewsDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/schoolNews/delete",
    method: "post",
    data: params
  });
};

// 逻辑删除
export const postSchoolNewsDeleteLogic = function(params) {
  return axios({
    url: BaseUrl + "app/schoolNews/edit",
    method: "post",
    data: params
  });
};


export default {
  postSchoolNewsList,
  postSchoolNewsAdd,
  postSchoolNewsLoad,
  postSchoolNewsEdit,
  postSchoolNewsDelete,
  postSchoolNewsDeleteLogic
};
