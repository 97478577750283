<template>
<!-- 详情组件 @需要改仅为静态页 -->
  <div>
    <div class="c_9 tab_ti">{{ tab_ti }}</div>
    <div>
      <el-button :class="['tab-btn', active==1?'active': '']" @click="active = 1">直播课程（10）</el-button>
      <el-button :class="['tab-btn', active==2?'active': '']" @click="active = 2">录播课程（10）</el-button>
      <el-button :class="['tab-btn', active==3?'active': '']" @click="active = 3">文本阅读（10）</el-button>
    </div>
    <div class="tab_text" v-if="active == 1">
      <div class="video_box" v-for="item in 20" :key="item">
        <div class="video_ti"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3322974547,4080291942&fm=26&gp=0.jpg" alt=""></div>
        <div class="video_video">校友圈直播课程标题校友…</div>
      </div>
    </div>
    <div class="tab_text" v-if="active == 2">
      <div class="video_box" v-for="item in 20" :key="item">
        <div class="video_ti"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3322974547,4080291942&fm=26&gp=0.jpg" alt=""></div>
        <div class="video_video">校友圈直播课程标题校友…</div>
      </div>
    </div>
    <div class="tab_text" v-if="active == 3">
      <div class="txt_box" v-for="item in 20" :key="item">
        <div class="txt_ti">校友圈文本标题</div>
        <div class="txt_txt">校友圈直播课程标题校友圈直播课程标题校友…校友…</div>
      </div>
    </div>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";

export default {
  props: {
    doStatus: {
      type: Boolean,
      default: false
    },
    tab_ti: { type: String }
  },
  name: "StudyTab",
  mixins: [pageMixin],
  data() {
    return {
      active: 1,
     
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.c_9 { color: $c-9; }
.tab_ti { font-size: 16px; line-height: 16px; padding: 10px 0; }
.tab-btn { height: 34px; padding: 10px 15px; margin-right: 20px; }
.tab_text{ width: 100%; height: 250px; margin: 10px 0 20px; background: #F5F7F9; border-radius: 6px; padding: 10px; overflow-y: scroll;
  .txt_box { width: 30%; height: 86px; background: #FFFFFF; border-radius: 4px; border: 1px solid #EAEFF3; padding: 10px; display: inline-block; margin: 0 15px 15px 0;
    .txt_ti { font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: $c-2; line-height: 16px; padding-bottom: 10px; }
    .txt_txt { display:-webkit-box; width: 100%; font-size: 14px; line-height: 20px; text-overflow:ellipsis; -webkit-line-clamp:2; -webkit-box-orient:vertical; overflow: hidden; }
  }
  .video_box { width: 30%; height: 115px; background: #FFFFFF; border-radius: 4px; border: 1px solid #EAEFF3; display: inline-block; margin-right: 15px;
    .video_ti { width: 100%; height: 115px; 
      img { padding: 0; margin: 0; width: 100%; height: 115px; }
    }
    .video_video { text-overflow:ellipsis; font-size: 16px; overflow: hidden; white-space: nowrap; border-radius: 4px; }
  }
}
.active { color: #27a987; border-color: #27a987; outline: 0; background-color: #eaf8f4; }
</style>
