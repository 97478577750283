/*
 * @Auhtor: 文锋
 * @Date: 2021-05-18 11:23:28
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-18 11:23:36
 * @FilePath: \operate\src\mixins\validate.js
 */
export default {
  methods: {
    //手机格式校验
    validateTel(rule, value, callback) {
      if (value === "") {
        callback();
        return;
      }
      let regular = /^1[3|4|5|7|8]\d{9}$/;
      if (!regular.test(value)) {
        callback(new Error("请输入正确格式手机号"));
      } else {
        callback();
      }
    },
    //邮箱格式校验
    validateEmail(rule, value, callback) {
      if (value === "") {
        callback();
        return;
      }
      let regular = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if (!regular.test(value)) {
        callback(new Error("请输入正确格式邮箱"));
      } else {
        callback();
      }
    },
    //仅允许数字
    onlyNumber(rule, value, callback) {
      if (value === "") {
        callback();
        return;
      }
      let regular = /^[0-9]+$/;
      if (!regular.test(value)) {
        callback(new Error("只允许输入数字"));
      } else {
        callback();
      }
    },
    //仅允许英文字母数字
    onlyEnNumber(rule, value, callback) {
      if (value === "") {
        callback();
        return;
      }
      let regular = /^[a-zA-Z0-9]+$/;
      if (!regular.test(value)) {
        callback(new Error("只允许输入英文或数字"));
      } else {
        callback();
      }
    },
  },
};
