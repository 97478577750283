<template>
  <!-- 社团管理 -->
  <div class="wrap">
    <div class="box-con">
      <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="社团列表" name="first">
          <el-form class="params-wrap select" :inline="true" v-if="manage||chief" :model="queryParams">
            <el-form-item class="area_img">
              <el-cascader class="year" size="small" v-model="value" :options="dataTree" ref="cascader" placeholder="请选择区域" :props="{ checkStrictly: true, ...defaultProps }" @change="handleClick" clearable @clear="$emit('clear')"></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-select size="small" label-width="150px" clearable filterable placeholder="请选择审核状态" v-model="queryParams.status" @change="resetPageAndRefresh">
                <el-option v-for="item in checkOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间：" class="ml20">
              <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParams'),refresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input size="small" v-model="queryParams.clubName" clearable placeholder="请输入社团名称" @change="resetPageAndRefresh"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="success" icon="el-icon-search">查询</el-button>
            </el-form-item>
            <el-form-item class="fr">
              <el-button size="small" icon="el-icon-plus" @click="handleForm(false)">新增</el-button>
            </el-form-item>
          </el-form>

          <!-- 列表 -->
          <div v-if="storeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
          <paged-list v-model="queryParams" :data="storeList" :total="storeTotal" :span='12' @refresh="refresh">
            <template slot-scope="scope">
              <div class="list" @click="requireDetails(scope.row)">
                <div class="con-head">
                  <div class="area">
                    <el-image :src="scope.row.logo"></el-image>
                  </div>
                  <div class="area">
                    <p class="con-txt txtwidth" :title="scope.row.name">{{ scope.row.name?scope.row.name:'-' }}</p>
                    <p class="mission">社团名称</p>
                  </div>
                  <div class="area fr">
                    <p :class="['con-txt', scope.row.status==0?'grey':'success']">{{ scope.row.status==0?'已关闭':"已开启"}}</p>
                    <p class="mission fr">状态</p>
                  </div>
                  <div class="area" v-if="scope.row.extend">
                    <p class="con-txt" :title="scope.row.extend.userName">{{ scope.row.extend.userName?scope.row.extend.userName:'-' }}</p>
                    <p class="mission">社长</p>
                  </div>
                  <div class="area">
                    <p class="con-txt" :title="scope.row.number">{{ scope.row.number?scope.row.number:'0' }}</p>
                    <p class="mission">社团人数</p>
                  </div>
                  <div class="area" v-if="scope.row.extend">
                    <p class="con-txt txtwidth" :title="scope.row.extend.schoolName">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                    <p class="mission">学校名称</p>
                  </div>
                </div>
                <div class="con-con cfx">
                  <div class="time-fl fl">
                    <div class="time">
                      <span class="contit">创建时间</span>
                      <span class="condel">{{ scope.row.addTime }}</span>
                    </div>
                    <div class="time">
                      <span class="contit">社团概述</span>
                      <span class="condel">{{ scope.row.description }}</span>
                    </div>
                  </div>
                  <div class="time-fr fr">
                    <div class="time">
                      <span class="contit">社团ID</span>
                      <span class="condel">{{ scope.row.id }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="control">
              <el-button type="primary" icon="el-icon-edit" circle></el-button>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </paged-list>
        </el-tab-pane>
        
        <!-- 社团换届审核列表 -->
        <el-tab-pane label="社团换届审核" name="second">
          <el-form class="params-wrap select" :inline="true" v-if="manage||chief" :model="queryParamsB">
            <el-form-item class="area_img">
              <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
              <!-- <area-tree class="year" @setQueryParams="handleQueryB" @clear="clearAreaB(true)"></area-tree> -->
              <el-cascader class="year" size="small" v-model="value" :options="dataTree" ref="cascaderB" placeholder="请选择区域" :props="{ checkStrictly: true, ...defaultProps }" @change="handleClickB" clearable @clear="$emit('clear')"></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-select size="small" label-width="150px" clearable filterable placeholder="请选择审核状态" v-model="queryParamsB.status" @change="refreshB">
                <el-option v-for="item in checkOptionsB" :key="item.status" :label="item.lable" :value="item.status"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input size="small" v-model="queryParamsB.clubName" clearable placeholder="请输入社团名称" @change="refreshB"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="success" icon="el-icon-search" >查询</el-button>
            </el-form-item>
          </el-form>

          <!-- 列表 -->
          <div v-if="storeTotalB==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
          <paged-list v-model="queryParamsB" :data="storeListB" :isEditor="isEditor" :total="storeTotalB" :span='12' @refresh="refresh">
            <template slot-scope="scope">
              <div class="list"  @click="detail(scope.row)">
                <div class="con-head">
                  <div class="area">
                    <el-image :src="scope.row.logo"></el-image>
                  </div>
                  <div class="area">
                    <p class="con-txt limit-80" :title="scope.row.clubName">{{ scope.row.clubName?scope.row.clubName:'-' }}</p>
                    <p class="mission">社团名称</p>
                  </div>
                  <div class="area fr">
                    <p class="con-txt primary" v-if="scope.row.status==0">待审核</p>
                    <p class="con-txt success" v-else-if="scope.row.status==1">审核通过</p>
                    <p class="con-txt danger" v-else>审核不通过</p>
                    <p class="mission fr">状态</p>
                  </div>
                  <div class="area" v-if="scope.row.extend">
                    <p class="con-txt limit-80" :title="scope.row.extend.userName">{{ scope.row.extend.userName?scope.row.extend.userName:'-' }}</p>
                    <p class="mission">申请人</p>
                  </div>
                  <div class="area">
                    <p class="con-txt limit-80" :title="scope.row.extend.successionName">{{ scope.row.extend.successionName?scope.row.extend.successionName:'-' }}</p>
                    <p class="mission">接班人</p>
                  </div>
                  <div class="area">
                    <p class="con-txt w110" :title="scope.row.extend.schoolName">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                    <p class="mission">学校名称</p>
                  </div>
                </div>
                <div class="con-con cfx">
                  <div class="time-fl fl">
                    <div class="time">
                      <span class="contit">申请时间</span>
                      <span class="condel">{{ scope.row.addTime }}</span>
                    </div>
                  </div>
                  <div class="time-fr fr">
                    <div class="time">
                      <span class="contit">换届原因</span>
                      <span class="condel">{{ scope.row.reason }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="control">
              <el-button type="primary" icon="el-icon-edit" circle></el-button>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </paged-list>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 新增界面 -->
    <el-dialog :title="isEditor ? '编辑学期' : '新增社团'" :visible.sync="aVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="所属校区：" prop="distId">
          <el-select clearable filterable v-model="form.distId" class="w350">
            <el-option v-for="item in distOption" :label="item.distName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社团名称：" prop="name">
          <el-input placeholder="请输入社团名称" v-model="form.name" class="w350"></el-input>
        </el-form-item>
        <el-form-item label="社团图标：" prop="logo" class="logo">
          <base-upload v-model="form.logo" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload> <span class="size">建议尺寸120x120</span>
        </el-form-item>
        <el-form-item label="任命社长" prop="successionId">
          <el-select
            v-model="form.successionId"
            filterable
            remote
            @change="currentSel"
            reserve-keyword
            placeholder="请输入任命的社长"
            :remote-method="remoteMethod"
            :loading="loading" class="w350">
            <el-option
              v-for="item in memberType"
              :key="item.id"
              :label="item.extend.userName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联标签：" prop="sketch" class="sketch">
          <div v-for="(item,index) in sketchArr" :key="index">
            <el-input placeholder="请输入标签" v-model="sketchArr[index]" class="w350" @change="handleSketch"></el-input>
            <img
              src="@/assets/images/operate/add.svg"
              alt
              class="btn"
              v-if="index == 0"
              @click="addTeam"
            />
            <img
              src="@/assets/images/operate/delete.svg"
              alt
              class="btn"
              v-if="index > 0"
              @click="removeTeam(index)"
            />
          </div>
        </el-form-item>
        <el-form-item label="社团简介：" prop="description">
          <el-input placeholder="请输入社团简介" v-model="form.description" class="w350"></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 社团详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="36.5%"
      >
      <div class="details">
        <div class="d-head">
          <div class="headcon">
            <p class="contit">基础信息</p>
            <el-image :src="organizeDetails.logo+'?x-oss-process=image/resize,m_fill,h_150,w_150'" class="pig" alt=""></el-image>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.name?organizeDetails.name:'--' }}</p>
              <p class="mission">社团名称</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.id?organizeDetails.id:'--' }}</p>
              <p class="mission">社团ID</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.number?organizeDetails.number:'0' }}</p>
              <p class="mission">社团人数</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.extend.schoolName?organizeDetails.extend.schoolName:'--' }}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
          <div class="conitem">
            <div class="conlable">创建时间</div>
            <div class="contxt">{{ organizeDetails.addTime }}</div>
          </div>
          <div class="conitem">
            <div class="conlable">成立时间</div>
            <div class="contxt">{{ organizeDetails.createTime }}</div>
          </div>
          <div class="conitem1">
            <div class="conlable">社团简介</div>
            <div class="contxt1">{{ organizeDetails.description }}</div>
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="taskcon">
            <p class="contit">社长</p>
            <div>
              <div class="imgbg h118">
                <div class="imgitem">
                  <el-image :src="presidentNow.logo+'?x-oss-process=image/resize,m_fill,h_150,w_150'" class="pig" alt=""></el-image>
                  <div class="area">
                    <p class="con-txt">{{ presidentNow.extend.userName || presidentNow.name }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="presidentNow.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ presidentNow.extend.schoolName }}{{ presidentNow.fade?' | ' + presidentNow.fade: presidentNow.fade }}</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ presidentNow.phone }}</p>
                    <p class="mission">手机号码</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ presidentNow.dateNum }} 天</p>
                    <p class="mission">在任时间</p>
                  </div>
                </div>
                <div class="conitem">
                  <div class="conlable">任命时间</div>
                  <div class="contxt">{{ presidentNow.joinTime }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">任命人</div>
                  <div class="contxt">{{ presidentNow.extend.addUserName }}</div>
                </div>
              </div>
            </div>
            <p class="contit1" v-if="presidentOldList.length!=0">往届社长</p>
            <div class="presidentOldList" v-if="presidentOldList.length!=0">
              <div class="imgbg mar_bot20" v-for="item in presidentOldList" :key="item.id">
                <div class="imgitem">
                  <el-image :src="item.logo" class="pig" alt=""></el-image>
                  <div class="area" v-if="item.extend">
                    <p class="con-txt">{{ item.extend.userName || item.name }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="item.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ item.extend.schoolName }}｜{{ item.fade }}</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ item.phone }}</p>
                    <p class="mission">手机号码</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ item.dateNum }} 天</p>
                    <p class="mission">在任时间</p>
                  </div>
                </div>
                <div class="conitem0">
                  <div class="conlable">任命时间</div>
                  <div class="contxt">{{ item.joinTime }}</div>
                </div>
                <div class="conitem0">
                  <div class="conlable">离任时间</div>
                  <div class="contxt">{{ item.exitTime }}</div>
                </div>
                <div class="conitem0" v-if="item.extend">
                  <div class="conlable">任命人</div>
                  <div class="contxt">{{ item.extend.addUserName }}</div>
                </div>
              </div>
            </div>
            <p class="contit" v-if="memberList.length!=0">社团成员 <span class="mission">共 {{ memberNum }} 位</span></p>
            <div class="member" v-if="memberList.length!=0">
              <div class="imgbg" v-for="item in memberList" :key="item.id">
                <div class="imgitem">
                  <el-image :src="item.headImg" class="pig" alt=""></el-image>
                  <div class="area" v-if="item.extend">
                    <p class="con-txt">{{ item.extend.userName || item.userName }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="item.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ item.extend.schoolName }}｜{{ item.major }}</p>
                  </div>
                  <div class="area fr">
                    <p class="minister fr" v-if="item.position==1">部长</p>
                    <p class="minister fr" v-else-if="item.position==2">社长</p>
                    <p class="fr" v-else>&nbsp;&nbsp;</p>
                    <div class="cfx"></div>
                    <p class="mission">加入时间：{{ item.addTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 换届审核详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawerReach"
      size="36.5%"
      >
      <div class="details">
        <div class="headStatus">
          <div class="area">
            <p class="con-txt">{{ clubApply.addTime }}</p>
            <p class="mission">申请时间</p>
          </div>
          <div class="area fr">
            <p class="con-txt con-primary" v-if="clubApply.status==0">待审核</p>
            <p class="con-txt con-success"  v-else-if="clubApply.status==1">审核通过</p>
            <p class="con-txt con-danger"  v-else-if="clubApply.status==2">审核不通过</p>
            <p class="mission fr">状态</p>
          </div>
          <div class="area" v-if="!clubApply.status==0">
            <p class="con-txt">{{ clubApply.updTime }}</p>
            <p class="mission">处理时间</p>
          </div>
          <div v-if="clubApply.status==2">
            <p class="missionB">不通过原因</p>
            <p class="con-txtB">{{ clubApply.failedReason }}fgadfs</p>
          </div>
        </div>
        <div class="d-head">
          <div class="headcon">
            <p class="contit">基础信息</p>
            <el-image :src="organizeDetails.logo" class="pig" alt=""></el-image>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.name }}</p>
              <p class="mission">社团名称</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.id }}</p>
              <p class="mission">社团ID</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.number?organizeDetails.number:'0' }}</p>
              <p class="mission">社团人数</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ organizeDetails.extend.schoolName }}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
          <div class="conitem">
            <div class="conlable">创建时间</div>
            <div class="contxt">{{ organizeDetails.addTime }}</div>
          </div>
          <div class="conitem">
            <div class="conlable">成立时间</div>
            <div class="contxt">{{ organizeDetails.createTime }}</div>
          </div>
          <div class="conitem1">
            <div class="conlable">社团简介</div>
            <div class="contxt1">{{ organizeDetails.description }}</div>
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="taskcon">
            <p class="contit">申请人</p>
            <div>
              <div class="imgbg">
                <div class="imgitem">
                  <el-image :src="ClubPresident.logo" class="pig" alt=""></el-image>
                  <div class="area">
                    <p class="con-txt">{{ ClubPresident.extend.userName || ClubPresident.name }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="ClubPresident.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ ClubPresident.extend.schoolName }}｜{{ ClubPresident.fade }}</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ ClubPresident.phone }}</p>
                    <p class="mission">手机号码</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ ClubPresident.dateNum }} 天</p>
                    <p class="mission">在任时间</p>
                  </div>
                </div>
                <div class="conitem">
                  <div class="conlable">任命时间</div>
                  <div class="contxt">{{ ClubPresident.joinTime }}</div>
                </div>
                <div class="conitem">
                  <div class="conlable">任命人</div>
                  <div class="contxt">{{ ClubPresident.extend.addUserName }}</div>
                </div>
                <div class="conitem" style="width: 100%">
                  <div class="conlable">换届原因</div>
                  <div class="contxt">{{ clubApply.reason }}</div>
                </div>
              </div>
            </div>
            <p class="contit" v-if="SuccessioUser">接班人</p>
            <div v-if="SuccessioUser">
              <div class="imgbg h84">
                <div class="imgitem">
                  <el-image :src="SuccessioUser.headImg" class="pig" alt=""></el-image>
                  <div class="area">
                    <p class="con-txt">{{ SuccessioUser.extend.userName }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="SuccessioUser.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{SuccessioUser.extend.schoolName}}｜{{ SuccessioUser.fade }}</p>
                  </div>
                  <div class="area">
                    <p class="con-txt">{{ SuccessioUser.extend.mobile }}</p>
                    <p class="mission">手机号码</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="checkMaster" v-if="clubApply.status==0">
              <div class="checkTitle">审核</div>
              <el-form :inline="true" ref="formBFileds" :model="formB" :rules="formBRules" label-width="180px">
                <el-form-item label="审核状态：" prop="status">
                  <el-radio-group v-model="formB.status">
                    <el-radio :label="1">审核通过</el-radio>
                    <el-radio :label="2">审核不通过</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="不通过原因：" v-if="formB.status==2" prop="reason">
                  <el-input placeholder="请输入原因" v-model="formB.failedReason" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item style="display: block; text-align: center;">
                  <el-button style=" width:140px; margin-right: 40px;" @click="resetForm">取消</el-button>
                  <el-button style=" width:140px; " type="success" @click="checkPresident">确定</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import BaseUpload from "@/components/common/BaseUpload";
import AreaTree from "@/components/common/AreaTree";
import datePicker from "@/mixins/datePicker";
import { mapActions, mapState } from "vuex";
/* eslint-disable no-unused-vars */
export default {
  name:'organizeManage',
  components: {
    PagedList,
    BaseUpload,
    AreaTree
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      dataTree: [],
      value: null,
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
      },
      distOption: [],
      /** 角色权限 */
      member: false, ceo: false, manage: false, chief: true,
      activeName:'first',
      dialogFormVisible: false,
      drawer: false,
      /** 社团列表 */
      checkOptions: [
        { status: null, lable: "全部" },
        { status: "1", lable: "已开启" },
        { status: "0", lable: "已关闭" },
      ],
      organizeDetails: {
        extend: {},
      },
      presidentOldList: [],
      presidentNow: {
        extend: {},
      },
      memberList: [],
      memberNum: null,
      willName: '',
      loading: false,
      memberType: [],
      sketchArr: [null],
      form: {
        schoolId: null,
        distId: null,
        name: '',
        logo: '',
        successionId: '',
        sketch: '',
        description: '',
      },
      formRules:{
        distId: [{ required: true, message: '请选择学区', trigger: 'change' }],
        name: [{ required: true, message: '请输入社团名称', trigger: 'blur' }],
        successionId: [{ required: true, message: '请选择社长', trigger: 'blur' }],
        sketch: [{ required: true, message: '请输入标签', trigger: 'blur' }],
        logo: [{ required: true, message: '请上传logo', trigger: 'blur' }],
        description: [{ required: true, message: '请输入社团简介', trigger: 'blur' }],
      },
      uploadData:{
        bizType:'',
        bizId:'',
        needStore:'0',
      },
      queryParams: {
        s1:'',
        s2:'',
        province: null,
        city: null,
        schoolId: null,
        area: null,
        clubName: '',
        status: null,
        distId: null,
      },

      // 换届审核
      drawerReach: false,
      getDetail: {},
      // 接班人信息
      SuccessioUser: {
        extend: {},
      },
      // 审核人信息
      userInfo: {
        extend: {},
      },
      clubApply: {
        extend: {}
      },
      ClubPresident:{
        extend:{}
      },
      checkOptionsB: [
        { status: '', lable: "全部" },
        { status: '0', lable: "待审核" },
        { status: '1', lable: "审核通过" },
        { status: '2', lable: "审核不通过" },
      ],
      queryParamsB: {
        pageNum: 1,
        pageSize: 10,
        status: '',
        province: null,
        city: null,
        area: null,
        schoolId: null,
        clubName: '',
        distId: null,
      },
      formB: {
        status: null,
        failedReason: '',
      },
      formBRules:{
        failedReason: [{ required: true, message: '请输入原因', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
      },
      storeListB: [],
      storeTotalB: 0,
      statusOptions: [],
      formLabelWidth: '160px',

      pageApi: 'clubList',
      insertApi: 'clubAdd',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("organizeManage", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("organizeManage", ["clubList", "applyList", "presidentList", "applyResult", "applySuccess", "applyFail", "clubAdd", "clubEdit", "clubLoad", "clubDelete", "getUsers", "userList", "membersSchoolDistAppUserDists"]),
    ...mapActions("commonOptions", ["membersSchoolGetSchoolDistTree"]),
    // handleQuery(data) {
    //   this.clearArea(false)
    //   data.forEach(ele => {
    //     if ((ele + "").length === 9) { this.queryParams.province = ele }
    //     if ((ele + "").length === 12) { this.queryParams.city = ele }
    //     if ((ele + "").length === 15) { this.queryParams.area = ele }
    //     if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
    //   });
    //   this.refresh();
    // },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) {this.refresh() }
    },
    handleQueryB(data) {
      this.clearAreaB(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParamsB.province = ele }
        if ((ele + "").length === 12) { this.queryParamsB.city = ele }
        if ((ele + "").length === 15) { this.queryParamsB.area = ele }
        if ((ele + "").length < 9) { this.queryParamsB.schoolId = ele }
      });
      this.refreshB();
    },
    clearAreaB(isRefresh){
      this.queryParamsB.province = null;
      this.queryParamsB.city = null;
      this.queryParamsB.area = null;
      this.queryParamsB.schoolId = null;
      if(isRefresh) { this.refreshB() }
    },
    /** 社团列表 */
    async requireDetails(row) {
      try { let res = await this.clubLoad({id: row.id})
        this.organizeDetails = res
      } catch (error){
      }
      try { let resB = await this.presidentList({pageNum: "1",pageSize: "100", clubId: this.organizeDetails.id})
        this.presidentOldList = []
        resB.list.forEach(ele => {
          if(!ele.exitTime){
            this.presidentNow = ele
          }else{
            this.presidentOldList.unshift(ele)
          }
        });
        // console.log(this.presidentNow, this.presidentOldList)
      } catch (error){
      }
      try { let resC = await this.userList({clubId: this.organizeDetails.id,pageNum: "1",pageSize: "100"})
        this.memberNum = resC.total
        this.memberList = resC.list
        // console.log(this.memberList)
      } catch (error){
      }
      this.drawer = true
    },
    currentSel(selVal) {
      this.willName = selVal
    },
    async remoteMethod(query){
      this.getUsers({distId: this.form.distId, userName: query}).then(res=>{
        this.memberType = res
      })
    },
    // 添加关联标签
    handleSketch() {
      this.sketchArr.forEach(item => {
        this.form.sketch = this.form.sketch + item + ','
      })
      this.form.sketch = this.form.sketch.substring(0, this.form.sketch.lastIndexOf(','))
    },
    addTeam() {
      if(this.sketchArr.length<3) {
        this.form.sketch = ''
        this.sketchArr = this.sketchArr.filter(item=>item);
        this.sketchArr.push('');
      }else if(this.sketchArr.length>=3) {
        return this.$message({
          type: 'warning',
          message: '最多只能添加3个关联标签'
        });
      }
    },
    // 删除关联标签
    removeTeam(index) {
      this.sketchArr = this.sketchArr.filter((item, idx) => idx != index);
    },
    /** 图片格式 */
    handleBeforeUpload(file, callback){
      const isPicture = file.name.includes('jpg') || file.name.includes('png');
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isPicture) {
          this.$message.error('上传图片只能是 jpg/png 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
      }
      callback(isPicture && isLt2M)
    },

    // 社团审核
    async refreshB(){ //列表查询
      try {
        let res = await this.applyList({...this.queryParamsB})
        this.storeListB = res.list
        this.storeTotalB = res.total
      }catch(e){
      }finally {
      }
    },
    async detail(row) {
      try { 
        let res = await this.applyResult({id: row.id})
        console.log(res)
        // this.getDetail = res
        this.SuccessioUser = res.SuccessioUser
        // this.userInfo = res.list[1]
        this.clubApply = res.clubApply
        this.ClubPresident = res.ClubPresident
      } catch (error){
      }
      // try { let resB = await this.presidentList({pageNum: "1",pageSize: "100", clubId: row.clubId})
      //   this.presidentOldList = []
      //   resB.list.forEach(ele => {
      //     if(!ele.exitTime){
      //       this.presidentNow = ele
      //     }else{
      //       this.presidentOldList.unshift(ele)
      //     }
      //   });
      //   this.userInfo = resB.list[1]
      
      // } catch (error){
      // }
      try { 
        let res = await this.clubLoad({id: row.clubId})
        this.organizeDetails = res
      } catch (error){
      }
      this.drawerReach = true
    },
    checkPresident() {
      this.$refs.formBFileds.validate(async (valid) => {
        if (valid) {
          this.formB.id = this.clubApply.id
          if(this.formB.status == 1) {
            let res = await this.applySuccess({id:this.formB.id, status:this.formB.status})
            if(!!res){this.$message.success("审核成功")}
          }else if(this.formB.status == 2){
            let resB = await this.applyFail({...this.formB})
            if(!!resB){this.$message.success("审核成功")}
          }
          this.resetForm()
          this.drawerReach = false
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.drawerReach = false
      this.$refs.formBFileds.resetFields();
    },
    async areaTree(val){
      this.dataTree = [];
      if(!val) return;
      this.dataTree = await this.membersSchoolGetSchoolDistTree({})
    },
    // 树型节点查询
    handleClick(data, node) {
      this.clearArea(false)
      if(data.length==1){ this.queryParams.province = data[data.length-1] }
      if(data.length==2){ this.queryParams.city = data[data.length-1] }
      if(data.length==3){ this.queryParams.schoolId = data[data.length-1] }
      if(data.length==4){ this.queryParams.distId = data[data.length-1] }
      this.refresh();
      this.$refs.cascader.dropDownVisible = false;
    },
    handleClickB(data, node) {
      this.clearArea(false)
      if(data.length==1){ this.queryParamsB.province = data[data.length-1] }
      if(data.length==2){ this.queryParamsB.city = data[data.length-1] }
      if(data.length==3){ this.queryParamsB.schoolId = data[data.length-1] }
      if(data.length==4){ this.queryParamsB.distId = data[data.length-1] }
      this.refresh();
      this.$refs.cascaderB.dropDownVisible = false;
    },
    resetValue(){
      this.value = []
    }
  },
  async mounted() {
    this.form.schoolId = sessionStorage.getItem("schoolId")-0
    this.distOption = await this.membersSchoolDistAppUserDists({})
    this.areaTree("area_cn")
    this.refresh(),
    this.refreshB()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .tabs { padding: 0 20px; }
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px; background-position: 8px 7px; padding: 0 0 0 30px; box-sizing: border-box; font-size: 14px;
        }
      }
    }
    .params-wrap { padding: 0;
      /deep/.el-input__icon{ height: auto; }
      .el-form-item { margin: 0 20px 0 0; }
      .area_img {
        /deep/.el-input__inner { padding-left: 30px !important; }
        .area_select { position: absolute; z-index: 10; top: 13px; left: 8px; }
      }
    }
    .paged-page { padding: 0; }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px; vertical-align: text-top;
          .el-image{ width: 70px; height: 70px; border-radius: 4px; }
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC; }
          .txtwidth{ width: 100px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}

.logo { 
  /deep/.el-upload{ width: 100px; height: 100px;
    /deep/img { width: 100px !important; height: 100px !important; }
    /deep/.el-icon-plus.avatar-uploader-icon { width: 100px !important; height: 100px !important; line-height: 100px !important; }
  }
  .size { font-size: 12px; color: $c-9; line-height: 12px; }
  /deep/.el-form-item__content { line-height: 20px; }
}
.sketch {
  img { vertical-align: middle; margin-left: 20px; cursor: pointer; }
}
.dialog-footer { margin-right: 100px;
  .el-button { width: 140px; margin-right: 20px; }
}
.w110 { width: 110px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }

.limit-80 { width: 80px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
.w410 { width: 410px !important; } 
.success { color: $c-success !important; }
.primary { color: $c-primary !important; }
.danger { color: $c-danger !important; }
.grey { color: $c-9 !important; }
.textarea { width: 410px; height: 88px; resize:none; border: 1px solid #DCDFE6; outline: none; padding: 11px 20px; } 
.dia_btn { 
  .el-button { width: 140px; height: 44px; margin-right: 40px; }
}
.margin0 { margin: 0 !important; }

/deep/#el-drawer__title{ background:$c-title;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;padding: 20px; }
.details{ width: 100%; height: 100%; background: #fff; padding: 0 20px 20px 20px ;
  .headStatus { background: $c-border; padding: 20px; width: 100%; margin-top: 20px;
    .area { display: inline-block; margin-right: 20px;
      .con-txt { font-size: 20px; line-height: 20px; color: $c-2; font-weight: bold; }
      .con-primary{ color: $c-primary; }
      .con-success{ color: $c-success; }
      .con-danger{ color: $c-danger; }
      .mission { font-size: 14px; line-height: 20px; color: $c-9; margin-top: 14px; }
    }
    .con-txtB { font-size: 14px; line-height: 14px; color: $c-2; margin-left: 20px; display: inline-block; }
    .missionB { font-size: 14px; line-height: 14px; color: $c-9; display: inline-block; }
  }
  .d-head{ color: $c-9; line-height: 14px; font-size: 14px;
    .headcon{
      .contit{ color: $c-1; padding: 20px 0; line-height: 16px; font-size: 16px; font-weight: bold; }
      .pig{ width: 70px; height: 70px; display: inline-block;margin-right: 16px; }
      .area{ display: inline-block; margin-right: 16px;height:50px; margin-top: 10px; vertical-align: top;
        .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222; }
        .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px; }
      }
    }
    .conitem, .conitem1 { display: inline-block; width: 50%; line-height: 14px; margin-top: 14px;
      .conlable { display: inline-block; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; margin-right: 20px; }
      .contxt { line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC;color: #222; display: inline-block; }
    }
    .conitem1 { width: 100%;
      .contxt1 { width: 100%; display: block; line-height: 20px; font-size: 14px; margin-top: 14px; color: #222; }
    }
  }
  .d-bodyFirst{ 
    .taskcon { padding: 10px 0;
      .h118{ height: 118px !important; }
      .checkMaster { padding: 20px; background: $c-border; margin-top: 20px; width: 100%;
        .checkTitle { font-size: 16px; font-weight: bold; color: $c-2; }
      }
      .mar_bot20 { margin-bottom: 20px; }
      .contit{ color: $c-1; padding: 20px 0; line-height: 16px; font-size: 16px; font-weight: bold;
        .mission { color: $c-9; }
      }
      .contit1 { color: $c-9; line-height: 14px; font-size: 14px; padding: 10px 0; }
      .presidentOldList { min-height: 0; max-height: 310px; overflow-y: scroll; }
      .conitem, .conitem1, .conitem0 { display: inline-block; width: 50%;
        .conlable { display: inline-block; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; margin-right: 20px; }
        .contxt { line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC;color: #222; display: inline-block; }
      }
      .member { min-height: 0; max-height: 300px; width: 100%; overflow-y: scroll; border-radius: 4px; 
        .imgbg { border-radius: 0; } 
      }
      .conitem0 { margin-right: 20px; width: auto; }
      .conitem1 { width: 100%; 
      }
      .h84 { height: 84px !important; }
      .imgbg { width: 100%; background: $c-border; border-radius: 4px; padding: 20px;
        .imgitem { 
          .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 22px; }
          .area{ display: inline-block; margin-right: 16px;height:50px;
            .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222; }
            .minister { width: 44px; height: 22px; background: #28B892; border-radius: 4px;  font-size: 14px; line-height: 22px; text-align: center; color: $c-f;}
            .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px; }
          }
        }
      }
    }
  }
}
</style>