/*
 * @Auhtor: 文锋
 * @Date: 2021-05-06 15:37:11
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 16:49:11
 * @FilePath: \operate\src\api\finance\mallOrder.js
 */
import { BaseUrl } from "@/common/config";
import axios from 'axios'

// 获去商品订单列表
export const getMallOrderList = function (params) {
    return axios({
        url: BaseUrl + 'ecshop/order/getOrderList',
        method: "post",
        data: params
    })
}

// 总收入
export const getOrderSummary = function (params) {
    return axios({
        url: BaseUrl + 'ecshop/order/getSummary',
        method: 'post',
        data: params
    })
}

export default {
    getMallOrderList,
    getOrderSummary
}