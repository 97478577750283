<template>
  <!-- 分销任务 @需要写 -->
  <div  class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams" label-width="100px">
        <el-form-item v-if="areaManager == 'areaManager' || sysCOO == 'sysCOO'">
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item  label="商铺类型：">
          <el-button class="w110" :class="activeNum == 1?'all':''"  @click="activeNum =1,queryParams.shopType=null,queryParams.mallType='',resetPageAndRefresh()">全部</el-button>
          <el-button :class="activeNum == 2?'all':''" @click="activeNum =2,queryParams.shopType=107001001,queryParams.mallType='',resetPageAndRefresh()">通信大厅</el-button>
          <el-button :class="activeNum == 3?'all':''" @click="activeNum =3,queryParams.shopType=107001002,queryParams.mallType='',resetPageAndRefresh()">网络大厅</el-button>
          <el-select  v-model="queryParams.mallType"  @change="queryParams.shopType = queryParams.mallType,resetPageAndRefresh()" clearable filterable placeholder="商城" style="width:100px;margin-left:10px">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-input placeholder="请输入成员名称" size="small" clearable v-model="queryParams.companyName" style="width:200px;margin-left:20px" >
          </el-input>
          <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
        </el-form-item >
      </el-form>
      <!-- 列表 -->
      <div v-if="distributionTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-if="distributionTotal>0" v-model="queryParams" :data="distributionList" :total="distributionTotal"  @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="market" @click="aVisible = true,getDetail(scope.row.id,scope.row.schoolId,scope.row.shopType)">
            <div class="market-h">
              <div class="market-t w50">
                <span class="tip ellipsis">{{scope.row.goodsName}}</span>
                <span class="mt">商品名称</span>
              </div>
              <div class="market-t w20">
                <span class="tip">¥{{scope.row.goodsCommission|money}}</span>
                <span class="mt">佣金</span>
              </div>
              <div class="market-t w20">
                <span class="tip">{{scope.row.demand}}</span>
                <span class="mt">可分销数（笔）</span>
              </div>
              <div class="market-t">
                <span class="tip ">{{scope.row.conversionRate}}%</span>
                <span class="mt">转化率</span>
              </div>
            </div>
            <div class="cont">
              <div class="fl">
                <p><span>店铺名称：</span>{{scope.row.shopName}}</p>
                <p><span>商品金额：</span>{{scope.row.goodsPrice|money}}</p>
                <p><span>成交金额：</span>{{scope.row.amount|money}}</p>
                <p><span>区域：</span>{{scope.row.areaName}}</p>
                <p ><span>学校名称：</span>{{scope.row.extend.schoolName}}</p>
              </div>
              <div class="fr">
                <p><span>店铺类型：</span>{{scope.row.extend.shopTypeName}}</p>
                <p><span>成交量：</span>{{scope.row.goodsNum}}</p>
                <p><span>总产生佣金：</span>{{scope.row.commission|money}}</p>
                <p><span>分销排名：</span>{{scope.row.ranking}}</p>
                <p><span>访问次数：</span>{{scope.row.browseNum}}</p>
              </div>
            </div>
          </div>
        </template>
      </paged-list>
      <!-- 详情 -->
      <el-drawer class="detail" title="详情" :visible.sync="aVisible" direction="rtl" size="750px" :destroy-on-close="true" :show-close="true" :wrapperClosable="true">
        <div class="user">
          <div class="userInfo">
            <div class="info">
              <div class="info-t">{{distInfo.extend.userName}}</div>
              <span>申请人</span>
            </div>
            <div class="info">
              <div class="info-t">{{distInfo.extend.mobile}}</div>
              <span>手机号码</span>
            </div>
            <div class="info">
              <div class="info-t">{{distInfo.approveTime}}</div>
              <span>申请时间</span>
            </div>
          </div>
        </div>
        <div class="shopInfo">
          <p>店铺信息</p>
          <div class="shop">
            <img :src="distInfo.shopLogo" alt="">
            <div class="continfo">
              <div class="cont">
                <div class="info">
                  <div class="info-t">{{distInfo.shopName}}</div>
                  <span>店铺名称</span>
                </div>
                <div class="info">
                  <div class="info-t" >{{distInfo.extend.shopTypeName}}</div>
                  <span>店铺类型</span>
                </div>
              </div>
              <div class="address"><span>经营范围</span>{{distInfo.areaName}}</div>
            </div>
          </div>
        </div>
        <div class="goodsInfo">
          <p>商品详情</p>
          <div class="goodsName">{{distInfo.goodsName}}</div>
          <div class="money">
            <div class="money-info"><span>商品金额</span>￥{{distInfo.goodsPrice|money}}</div>
            <div class="money-info"><span>佣金</span>￥{{distInfo.goodsCommission|money}}</div>
            <div class="money-info"><span>可分销数</span>{{distInfo.demand}}</div>
          </div>
          <div class="money">
            <div class="money-info"><span>一级佣金</span>￥{{distInfo.goodsCommission1|money}}</div>
            <div class="money-info"><span>二级佣金</span>￥{{distInfo.goodsCommission2|money}}</div>
            <div class="money-info"><span>三级佣金</span>￥{{distInfo.goodsCommission3|money}}</div>
          </div>
          <div class="money-line"><span>商品链接</span><a :href="distInfo.goodsLink"  target="_blank" style="text-decoration-line: underline;" rel="noopener noreferrer">{{distInfo.goodsLink}}</a></div>
          <div class="imgItem">
            <viewer v-if="distInfo.notePic" :images="distInfo.notePic.map(item => item.url)">
              <span v-for="(item,index) in distInfo.notePic" :key="index">
                  <el-image style="width: 207px; height: 207px;border-radius:4px;margin:10px" :src="item.url"></el-image>
              </span>
            </viewer>
          </div>
        </div>
        <!-- 分销情况 -->
        <div class="distCont">
          <p>分销情况</p>
          <div class="money">
            <div class="money-info"><span>成交量</span>￥{{distInfo.goodsCommission1|money}}</div>
            <div class="money-info"><span>成交金额</span>￥{{distInfo.goodsCommission2|money}}</div>
            <div class="money-info"><span>转化率</span>￥{{distInfo.goodsCommission3|money}}</div>
          </div>
          <div class="money">
            <div class="money-info"><span>可分销数</span>￥{{distInfo.goodsCommission1|money}}</div>
            <div class="money-info"><span>分销排名</span>￥{{distInfo.goodsCommission2|money}}</div>
            <div class="money-info"><span>访问次数</span>￥{{distInfo.goodsCommission3|money}}</div>
          </div>
          <div class="money-line"><span>总产生佣金</span>￥{{distInfo.goodsCommission|money}}</div>
        </div>
        <!-- 分销成员 -->
        <div class="distMember">
          <p>分销成员<span>共{{memberList.length}}位</span></p>
          <div class="member" v-if="memberList.length > 0">
            <div class="member-item" v-for="item in memberList" :key="item.id">
              <div class="user-h">
                <img :src="item.extend.headImg+'?x-oss-process=image/resize,m_fill,h_150,w_150'" alt="">
                <div class="user-t">
                  <p>{{item.extend.username}}<img :src="item.extend.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-top:6px;margin-left:10px"></p>
                  <span>{{item.extend.schoolName}}|{{item.extend.fadeName}}</span>
                </div>
              </div>
              <p class="member-m">佣金：￥{{item.commission}}</p>
            </div>
          </div>
        </div>

      </el-drawer>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";

export default {
  name:'distribution',
  mixins:[pageMixin],
  components:{
    AreaTree,
    PagedList
  },
  data() {
    return {
      activeNum:1,
       // 角色
      ceo:'',
      areaManager:'',
      sysCOO:'',
      role:'',
      // 成员列表
      memberList:[],
      queryParams: {
        status:null,
        companyName:'',
        shopType:null,
        pageNum:1,
        pageSize:10,

      },
      positonList:[],

      pageApi:'getDistributionList'
    }
  },
  computed:{
    ...mapState(["OA"]),
    ...mapState('distribution',['distributionList','distributionTotal','distInfo'])
  },
  filters:{
    money(val) {
      return (val/100).toFixed(2)
    }
  },
  methods: {
    ...mapActions('enterAduit',['dictGetDictFPid','getLoginUser']),
    ...mapActions('distribution',['getDistributionList','getDistInfo','getDistMember']),
    // 查看详情
    async getDetail(id,schoolId,shopType) {
      await this.getDistInfo({id})
      this.memberList = await this.getDistMember({schoolId,shopType})
    },
    // 地区查询
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
  },
  async created() {
    const res = await this.getLoginUser()
    const arr = res.roles
    arr.forEach(item=>{
      if( item == 'xtgly') {
        this.role = item
      }
      if(item == 'ceo') {
        this.ceo = item
      }
      if(item == 'areaManager') {
        this.areaManager = item
      }
      if(item == 'sysCOO') {
        this.sysCOO = item
      }
    })  
  },
  async mounted() {
    let res = await this.dictGetDictFPid({pid:104003003})
    this.positonList = res.filter(item=>item.id != 107001001 || item.id != 107001002)
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .market {
      .market-h {
        display: flex;
        .market-t {
          display: flex;
          flex-direction: column;
          span {
            color: #889098;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            &.tip{
              font-size: 20px;
              font-weight: 600;
              color: #222222;
              line-height: 20px;
            }
            &.mt {
              margin: 14px 0 40px;
            }
          }
          
        }
        .w50 {width: 50%;}
        .w20 {width: 20%;}
      }
      .cont {
        display: flex;
        .fl,.fr {
          width: 50%;
          p {
            font-size: 14px;color:#222222;
            span {font-size: 14px;color: #889098;}
          }
        }
      }
    }
    .detail {
      .user{
        display: flex;
        flex-direction: column;
        margin: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 4px;
        background-color: #F5F7F9;
        .userInfo{
          display: flex;
          justify-content: space-between;
          .info {
            display: flex;
            flex-direction: column;
            .info-t {
              font-size: 20px;
              font-weight: 600;
              color: #222222;
              line-height: 36px;
            }
            span{font-size: 14px;color: #979797;}
          }
        }
        .task-money {
          >p{
            color: $c-2;
            span{font-size: 14px;color: #979797;margin-right: 10px;}
          }
          .money {
            margin-left: 20px;
            display: flex;
            .money-info {
              width: 30%;
              color: $c-2;
              span {font-size: 14px;color: #979797;margin-right: 10px;}
            }
          }
        }
        .reason {
          color: #222222;
          span{font-size: 14px;color: #979797;margin-right: 20px;}
        }
      }
      .shopInfo {
        margin: 20px;
        >p{font-size: 16px;color: #222222;font-weight: 600;}
        .shop {
          margin-top: 20px;
          border-bottom: 1px solid #EAEFF3;
          display: flex;
          >img{width: 44px;height: 44px;border-radius: 4px;}
          .continfo {
            margin-left: 20px;
            flex: 1;
            display: flex;
            flex-direction: column;
            .cont {
              display: flex;
              .info {
                width: 50%;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                .info-t {
                  font-size: 20px;
                  font-weight: 600;
                  color: #222222;
                  line-height: 20px;
                }
                span{font-size: 14px;color: #979797;}
              }
            }
            .address {
              margin-bottom: 20px;
              span {font-size: 14px;color: #979797;margin-right: 10px;}
            }
          }
        }
      }
      .goodsInfo {
        margin: 20px;
        >p{margin-bottom: 10px;}
        >p,.goodsName{font-size: 16px;color: #222222;font-weight: 600;}
        .money {
          margin: 10px auto;
          display: flex;
          .money-info {
            width: 30%;
            color: $c-danger;
            span {font-size: 14px;color: #979797;margin-right: 10px;}
          }
        }
        .money-line {
          span {font-size: 14px;color: #979797;margin-right: 10px;}
        }
      }
      .task {
        margin: 40px 20px 20px 20px;
        padding: 10px;
        background: #F5F7F9;
        border-radius: 6px;
        >p{font-size: 16px;color: #222222;font-weight: 600;}
        .edit {
          .btn {
            width: 140px;
            margin-right: 20px;
            &.submit {
              color:$c-white;
              background-color:$c-success ;
            }
          }
        }
        /deep/.el-form-item__label{font-size: 14px;color: #979797;}
      }
      .distCont{
         margin: 20px;
        >p{font-size: 16px;color: #222222;font-weight: 600;margin-bottom: 10px;}
        .money {
          margin: 10px auto;
          display: flex;
          .money-info {
            width: 30%;
            color: $c-danger;
            span {font-size: 14px;color: #979797;margin-right: 10px;}
          }
        }
        .money-line {
          color: $c-danger;
          span {font-size: 14px;color: #979797;margin-right: 10px;}
        }
      }
      .distMember{
        margin: 40px 20px 20px 20px;
        >p{font-size: 16px;color: #222222;font-weight: 600;
          span{ font-size: 14px;color: #979797;margin-left: 20px;}
        }
        .member{
          margin-top: 20px;
          padding: 20px;
          background: #F5F7F9;
          border-radius: 6px;
          height: 300px;
          overflow: scroll;
          .member-item{
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .user-h {
              display: flex;
              align-items: center;
              >img {
                margin-right: 20px;
                width: 44px;
                height: 44px;
                border-radius: 22px;
              }
              .user-t {
                p{font-size: 16px;color: #222222;font-weight: 600;}
                span{font-size: 14px;color: #979797;}
              }
            }
            .member-m {font-size: 16px;color: $c-success;}
          }
        }
      }
    }
  }
}
.w110 {
  width: 110px;
}
.all {
  color: $c-success;
  background-color: rgba(40, 184, 146, 0.1);
  border: 1px solid $c-success;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.primary {
  color: #4A90E2!important;
}
.success {
  color: #28B892!important;
}
.danger {
  color: #979797!important;
}
/deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/ .el-drawer__header{ margin-bottom: 0px;}
</style>