/*
 * @Auhtor: 文锋
 * @Date: 2021-04-30 08:55:13
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-04-30 08:55:13
 * @FilePath: \operate\src\router\assess.js
 */
/* eslint-disable no-unused-vars */
const AssessCenter = () => import ('@/components/assess/AssessCenter')
const Divide = () => import ('@/components/assess/Divide')
const addAssess = () => import ('@/components/assess/addAssess')
const addDivide = () => import ('@/components/assess/addDivide')


export default {
  route:[
    {
      path: "AssessCenter",
      name: "考核权重",
      component: AssessCenter
    },
    {
      path: "divide",
      name: "分成管理",
      component: Divide
    },
    {
      path: "addAssess",
      name: "新增考核管理",
      component: addAssess
    },
    {
      path: "addDivide",
      name: "新增分成管理",
      component: addDivide
    },
  ]
};
