/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询(半年 全年) */
export const directorList = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/list",
    method: "post",
    data: params
  });
};

/** 查询所有 */
export const directorAll = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/all",
    method: "post",
    data: params
  });
};

/** 详情 */
export const directorLoad = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/load",
    method: "post",
    data: params
  });
};

/** 添加 */
export const directorAdd = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/add",
    method: "post",
    data: params
  });
};

/** 修改 */
export const directorEdit = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/edit",
    method: "post",
    data: params
  });
};

/** 删除 */
export const directorDelete = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/delete",
    method: "post",
    data: params
  });
};

/** 任务分配 */
export const directorDis = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/dis",
    method: "post",
    data: params
  });
};

/** 获取所有的区域经理 */
export const taskadminDirectorGetAllManagers = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/taskadmin/director/getAllManagers",
    method: "post",
    data: params
  });
};

/** 运营总监分配任务剩余 */
export const directorGetSurplus = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/director/getSurplus",
    method: "post",
    data: params
  });
};

/** 学习任务添加 */
export const taskAdd = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/task/add",
    method: "post",
    data: params
  });
};

/** 学习任务我的任务 */
export const taskList = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/task/list",
    method: "post",
    data: params
  });
};

/** 获取所有任务及子集 */
export const taskTree = function(params) {
  return axios({
    url: BaseUrl + "taskadmin/task/tree",
    method: "post",
    data: params
  });
};

export default {
  directorList,
  directorAll,
  directorLoad,
  directorAdd,
  directorEdit,
  directorDelete,
  directorDis,
  taskadminDirectorGetAllManagers,
  directorGetSurplus,
  taskAdd,
  taskList,
  taskTree,
};
