<!--
 * @Auhtor: 文锋
 * @Date: 2021-05-06 14:53:45
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 17:43:40
 * @FilePath: \operate\src\components\finance\MallOrder.vue
-->
<template>
  <div>
    <!-- 商城大厅 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-select v-model="queryParams.type" placeholder="请选择商品类型" clearable filterable @change="mallResetPageAndRefresh">
          <el-option label="商城类型1" value="1"></el-option>
          <el-option label="商城类型2" value="2"></el-option>
          <el-option label="商城类型3" value="3"></el-option>
          <el-option label="商城类型4" value="4"></el-option>
          <el-option label="商城类型5" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="timestamp" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.content" clearable placeholder="请输入商户名称/商品名称/订单号" @change="mallResetPageAndRefresh" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="mallResetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div class="flex">
        <div class="orderSummary">
          <span>总营业额（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.sales' :duration='3000' prefix="￥"></countTo>
        </div>
        <div class="orderSummary">
          <span>总提点（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.commission' :duration='3000' prefix="￥"></countTo>
        </div>
        <div class="orderSummary">
          <span>冻结金额（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.frozen' :duration='3000' prefix="￥"></countTo>
        </div>
        <div class="orderSummary">
          <span>总收入（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.frozen' :duration='3000' prefix="￥"></countTo>
        </div>
      </div>
    </div>
    <div class="content-wrap" style="50%">
      <div class="btn-wrap cfx">
      </div>
      <div v-if="mallOrderTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table v-if="mallOrderTotal>0" :data="mallOrderList"
        :total="mallOrderTotal" :loading="tLoading" :isShowSelection="false"
        :defaultSortProp="mallOrderList.id" v-model="queryParams" @refresh="refresh"
      >
        <el-table-column width="200"  label="店铺名称" prop="distributorName" ></el-table-column>
        <el-table-column width="200" prop="title" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="200" prop="distributorName" label="商品分类" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="200" prop="orderId" label="订单号" :show-overflow-tooltip="true" align="center"> </el-table-column>
        <el-table-column width="200" prop="mobile" label="手机号" :show-overflow-tooltip="true" align="center"> </el-table-column>
        <el-table-column width="200" label="实付款（单位：元）" prop="totalFee2" align="center">
          <template slot-scope="scope">
            <div>
              {{scope.row.totalFee2}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" label="商城提点(元)" prop="halfRate" align="center">
           <template slot-scope="scope">
            <span>{{scope.row.halfRate}}%</span>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="payType" label="支付方式" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.payType == 'wxpay'">微信支付</span>
            <span v-if="scope.row.payType == 'alipayh5'">支付宝</span>
            <span v-if="scope.row.payType == 'wxpayh5'">微信H5支付</span>
            <span v-if="scope.row.payType == 'deposit'">其他支付</span>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="payStatus" label="支付状态" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.payStatus == 'NOTPAY'">未支付</el-tag>
            <el-tag type="success" v-if="scope.row.payStatus == 'PAYED'">已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200" label="收入（单位：元）" prop="halfCommission,payStatus">
           <template slot-scope="scope">
              <span v-if="scope.row.payStatus =='PAYED'">{{scope.row.halfCommission|money}}</span>
           </template>
        </el-table-column>
        <el-table-column width="200" fixed="right" prop="createTime" label="订单生成时间" :show-overflow-tooltip="true" align="center" >
          <template slot-scope="scope">
            <div>
              {{scope.row.createTime|datexx}}
            </div>
          </template>
        </el-table-column>
      </paged-table>
    </div>

  
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import datePicker from "@/mixins/datePicker";
import countTo from 'vue-count-to'
import { log, mergeObject,dateFormat,moneyType} from '@/common/utils'

export default {
  name:'mallOrder',
  components: {
    PagedTable,
    countTo
  },
  mixins: [pageMixin, provinces,datePicker],
  data() {
    return {
      url:'',
      queryParams: {
        distributorName: null,
        startDate: '',
        endDate: '',
        content:'',
        hallType:2,
      },
      orderSummary:{},
      userRoleIds:[],
      roleOptions:[],
      teamRoles:[],
      
      pageApi:'getMallOrderList',
     
    };
  },
  filters:{
    datexx(val){
      return dateFormat(val*1000,"yyyy-MM-dd hh:mm:ss")
    },
    money(val) {
      return moneyType(val)
    }
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("mallOrder", ["mallOrderList","mallOrderTotal",]),
    income(money,rate){
      return money * rate
    }
  },
  methods: {
    ...mapActions("mallOrder",[ "getMallOrderList","getOrderSummary"]),
  },
  async mounted() {
    this.orderSummary = await this.getOrderSummary(this.queryParams)

    this.refresh()
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.orderSummary {
  display: flex;
  margin: 0 40px;
  flex-direction: column;
  span{
    font-size: 20px;
    font-family: DIN-Black, DIN;
    &:last-child {
      color: #FF5353;
      font-size: 40px;
      font-weight: 900;
    }
  }
}
</style>