import { BaseUrl } from "@/common/config";
import axios from "axios";


// 分销列表
export const getChannelMarketList = function (params) {
    return axios({
        url: BaseUrl + 'sell/pool/list',
        method: 'post',
        data: params
    })
}
 
// 分销详情
export const getChannelInfo = function (params) {
    return axios({
        url: BaseUrl + 'sell/pool/getInfo',
        method: 'post',
        data: params
    })
}

// 已加入分销
export const successTask = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/getInfo',
        method: 'post',
        data: params
    })
}

// 不加入分销
export const failTask = function (params) {
    return axios({
        url: BaseUrl + 'sell/refuse/getInfo',
        method: 'post',
        data: params
    })
}

// 加入分销
export const joinTask = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/approve',
        method: 'post',
        data: params
    })
}

// 不加入分销
export const refuseTask = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/refuse',
        method: 'post',
        data: params
    })
}

export default {
    getChannelMarketList,
    getChannelInfo,
    joinTask,
    refuseTask,
    successTask,
    failTask
}