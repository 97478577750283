/*
 * @Auhtor: 文锋
 * @Date: 2021-04-30 10:10:57
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-04-30 10:45:40
 * @FilePath: \operate\src\api\task\CurrentTask.js
 */
import { BaseUrl } from "@/common/config";
import axios from "axios";

// 当前任务
export const selectCueerentTask = function (params) {
    return axios({
        url: BaseUrl + 'taskadmin/task/tree',
        method: 'post',
        data: params
    })
}

export default {
    selectCueerentTask
}