<template>
<!-- 登录 -->
  <div class="login-page page">
    <div
      class="point"
      v-for="(item, index) in point"
      :key="index"
      :style="item"
    ></div>
    <div
      class="balloon"
      v-for="(item, index) in balloon"
      :key="'ball' + index"
      :style="item"
    ></div>
    <div
      class="bubble"
      v-for="(item, index) in bubble"
      :key="'bubb' + index"
      :style="item"
    ></div>
    <div class="login-form">
      <img class="loginHead" :src="url?require('@/assets/images/login.png'):require('@/assets/images/login1.png')" alt="">
      <div class="loginCon">
        <el-form ref="form" :model="form" :rules="rules">
          <h6>账号登录</h6>
          <el-form-item prop="userName">
            <el-input
              class="input-userName"
              prefix-icon="el-icon-user"
              v-model="form.username"
              placeholder="请输入用户登录名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="input-password"
              prefix-icon="el-icon-key"
              placeholder="请输入密码"
              v-model="form.password"
              show-password
              @keyup.enter.native="onLogin"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              :loading="bLoading"
              class="login-btn"
              type="primary"
              @click="onLogin"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { log, saveAccount } from "@/common/utils.js";
import { mapActions, mapMutations } from "vuex";
import pageMixin from "@/mixins/pageMixin";
export default {
  name: "login",
  mixins: [pageMixin],
  data() {
    return {
      url:'',
      form: {
        username: null,
        password: null,
      },
      rules: {
        username: [{ required: true, message: "用户登录名不能为空" }],
        password: [
          { required: true, message: "密码不能为空" },
          { min: 6, max: 30, message: "密码长度提示", trigger: "blur" },
        ],
      },
      point: [],
      balloon: [],
      bubble: []
    };
  },
  computed:{
    loginApi(){
      return window.location.href.includes('ceo.') || window.location.href.includes('20030') ? 'ceoLogin' : 'login'
    }
  },
  mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030') 
    // console.log(this.url)
  },
  methods: {
    ...mapActions("account", ["login","ceoLogin", "logout"]),
    async doLogin() {
      try {
        this.bLoading = true;
        let res = await this[this.loginApi]({
          username: this.form.username,
          password: this.form.password,
        });
        let accountInfo = { 
          username: this.form.username,
          password: this.form.password,
          token: res.tokenHead + res.token
        }
        saveAccount(accountInfo)
        // this.$message.success('登录成功')
        this.toPage('/schoolManagement')
      } catch (e) {
        this.$message.error(e)
        log(e);
      } finally {
        this.bLoading = false;
      }
    },
    onLogin() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.doLogin();
        } else {
          return false;
        }
      });
    },
    setPoint() {
      for (let i = 0; i < 100; i++) {
        let random = Math.random().toFixed(2);
        this.point.push({
          background: "rgba(0, 193, 224, " + random + ")",
          boxShadow: "0 0 2px rgba(0, 193, 224, " + random + ")",
          width: 6 * random + "px",
          height: 6 * random + "px",
          left: 1920 * Math.random().toFixed(4) + "px",
          top: 50 + 49 * Math.random().toFixed(2) + "%"
        });
        if (i % 2 == 0) {
          this.balloon.push({
            left: 1920 * Math.random().toFixed(4) + "px",
            top: 100 + 49 * Math.random().toFixed(2) + "%"
          });
        }
        if (i % 4 == 0) {
          let left = 1800 * Math.random().toFixed(4);
          let top = 40 + 49 * Math.random().toFixed(2);
          if (
            !this.bubble.filter(
              (item) => Math.abs(parseInt(item.left) - left) < 30
            ).length
          ) {
            this.bubble.push({
              width: 70 * random + "px",
              height: 70 * random + "px",
              left: left + "px",
              top: top + "%"
            });
          }
        }
      }
    }
  },
  created() {
    this.setPoint();
  }
};
</script>
<style scoped lang="scss">
$size: 100px;
.login-page {
  background: linear-gradient(180deg, #104839, #28b892);
  overflow: hidden;
  .login-form {
    position: relative;
    width: 600px;
    min-height: 452px;
    margin: 200px auto;
    border-radius: 4px;
    text-align: center;
    border-radius: 10px;
    background: #fff;
    z-index: 99;
    .loginHead{
      width: 100%;
      height: 140px;
    }
    .loginCon{
      width: 400px;
      display: inline-block;
    }
    h6 {
      line-height: 50px;
      font-size: 20px;
      color: #000;
      font-weight: bold;
    }
    .el-button {
      width: 100%;
      margin-top: 20px;
    }
    .forget-password {
      color: #04a099;
      font-size: 13px;
      cursor: pointer;
      text-align: right;
    }
  }
  .point {
    position: absolute;
    border-radius: 50%;
  }
  .bubble {
    position: absolute;
    border-radius: 50%;
    background: rgba(48, 201, 221, 0.2);
    box-shadow: 0 0 10px rgba(27, 187, 236, 0.2);
  }
  .balloon {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #2fe9da;
    box-shadow: 0 0 2px #2fe9da;
    border-radius: 50%;
    transform: translateY(-500px);
    animation: balloon 5s ease-in-out;
    &:after {
      position: absolute;
      left: 50%;
      top: 100%;
      display: block;
      content: "";
      width: 2px;
      height: 320px;
      margin-left: -1px;
      background: linear-gradient(
        180deg,
        rgba(47, 233, 218, 0.8),
        rgba(40, 184, 146, 0.01)
      );
    }
  }
  @for $i from 0 to 100 {
    .point:nth-child(#{$i}) {
      animation: (point + $i) 20s ease-in-out infinite;
    }
    @keyframes #{point +$i} {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(
          if(random() >0.5, $size * random(), -($size * random())),
          if(random() >0.5, $size * random(), -($size * random()))
        );
      }
      100% {
        transform: translate(0, 0);
      }
    }
  }
  @keyframes balloon {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-500px);
    }
  }
}
</style>