/* eslint-disable no-unused-vars */
const SchoolManagement = () => import ('@/components/basic/SchoolManagement')
const NoticeManagement = () => import ('@/components/basic/NoticeManagement')
const CampusManagement = () => import ('@/components/basic/CampusManagement')
const UserManagement = () => import ('@/components/basic/UserManagement')
const AppUser = () => import ('@/components/basic/AppUser')
const VersionManage = () => import ('@/components/basic/VersionManage')
const TreatyManage = () => import ('@/components/basic/TreatyManage')
const ApplyManage = () => import ('@/components/basic/ApplyManage')
const GuideManage = () => import ('@/components/basic/GuideManage')
const PopManage = () => import ('@/components/basic/PopManage')
const AboutUs = () => import ('@/components/basic/AboutUs')
const MemberManagement = () => import ('@/components/basic/MemberManagement')
const CollegeManege = () => import ('@/components/basic/CollegeManege')
const ApartmentManage = () => import ('@/components/basic/ApartmentManage')
const Creative = () => import ('@/components/basic/Creative')
const Suggestions = () => import ('@/components/basic/Suggestions')
const CeoCheck = () => import ('@/components/basic/CeoCheck')

export default {
  route:[
    {
      path: "schoolManagement",
      name: "学校管理",
      component: SchoolManagement
    },
    {
      path: "noticeManagement",
      name: "通知公告",
      component: NoticeManagement
    },
    {
      path: "campusManagement",
      name: "校区管理",
      component: CampusManagement
    },
    {
      path: "userManagement",
      name: "用户管理",
      component: UserManagement
    },
    {
      path: "appUser",
      name: "App用户管理",
      component: AppUser
    },
    {
      path: "aboutUs",
      name: "关于我们",
      component: AboutUs
    },
    {
      path: "versionManage",
      name: "版本管理",
      component: VersionManage
    },
    {
      path: "applyManage",
      name: "应用管理",
      component: ApplyManage
    },
    {
      path: "treatyManage",
      name: "协议管理",
      component: TreatyManage
    },
    {
      path: "guideManage",
      name: "引导页管理",
      component: GuideManage
    },
    {
      path: "popManage",
      name: "首页广告管理",
      component: PopManage
    },
    {
      path: "memberManagement",
      name: "成员管理",
      component: MemberManagement
    },
    {
      path: "collegeManege",
      name: "院系管理",
      component: CollegeManege
    },
    {
      path: "apartmentManage",
      name: "宿舍管理",
      component: ApartmentManage
    },
    {
      path: "ceoCheck",
      name: "CEO审核",
      component: CeoCheck
    },
    {
      path: "creative",
      name: "创意征集",
      component: Creative
    },
    {
      path: "suggestions",
      name: "投诉与建议",
      component: Suggestions
    },
  ]
};
