<template>
  <!-- 我的任务 @需要改未进行数据渲染 -->
  <div>
    <director-task v-if="true"></director-task>
    <manager-task v-if="false"></manager-task>
    <ceo-task v-if="false"></ceo-task>
    <member-task v-if="false"></member-task>
  </div>
</template>

<script>
import DirectorTask from "@/components/task/myTask/DirectorTask";
import ManagerTask from "@/components/task/myTask/ManagerTask";
import CeoTask from "@/components/task/myTask/CeoTask";
import MemberTask from "@/components/task/myTask/MemberTask";
export default {
  name:'myTask',
  components: {
    DirectorTask,
    ManagerTask,
    CeoTask,
    MemberTask,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  async mounted() {
    
  }
};
</script>
<style lang="scss" scoped>
</style>
