<template>
  <div class="wrap">
    <!-- 考核权重 @需要改 -->
    <div class="box-con">
      <el-form
        class="select params-wrap"
        size="small"
        :inline="true"
        :model="queryParams"
        label-width="100px"
      >
        <div class="box-head">
          <p>
            <span class="head_title">通用分成管理</span
            ><span class="headSpan"
              >通用分成管理为未单独设置分成管理的分成管</span
            >
          </p>
          <div class="head_con">
            <div class="head_line">
              <span>0分~3分</span>
              <p>分成比例为0%，总分成比例为2%</p>
            </div>
            <div class="head_line">
              <span>0分~3分</span>
              <p>分成比例为0%，总分成比例为2%</p>
            </div>
            <div class="head_line">
              <span>0分~3分</span>
              <p>分成比例为0%，总分成比例为2%</p>
            </div>
            <div class="head_line">
              <span>0分~3分</span>
              <p>分成比例为0%，总分成比例为2%</p>
            </div>
          </div>
          <el-button class="fc_upload" size="mini">编辑</el-button>
        </div>
        <!-- edit -->
        <div>
          <el-form-item>
          <area-tree
            @setQueryParams="handleQuery"
            class="year"
            @clear="clearArea(true)"
          ></area-tree>
        </el-form-item>
        <el-form-item class="fr">
          <el-button >编辑</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button><i class="el-icon-plus"></i>新增</el-button>
        </el-form-item>
        </div>
      </el-form>
      <!-- 列表 -->
       <!-- <paged-list v-model="queryParams" :data="schoolNewsList" @refresh='refresh' :total="schoolNewsTotal"  :span="12">
         <template slot-scope="scope">
        <div>
          
        </div>
        </template>
      </paged-list> -->
      <div style="display: flex;flex-wrap:wrap;justify-content: center;">
        <el-card class="box-item" v-for="(item,index) in 6" :key="index">
            <div slot="header" class="box-title">
              <span>安徽省-合肥市-蜀山区</span>
            </div>
            <div class="box-cont">
              <p>综合评分分成</p>
              <div class="box-fc">
                <p><span>0分-3分</span>分成比例为0% ，总分成比例为2%</p>
                <p><span>4分-6分</span>分成比例为50%</p>
                <p><span>7分-8分</span>分成比例为20%</p>
                <p><span>9分-10分</span>分成比例为30%</p>
              </div>
              <i class="el-icon-more"></i>
            </div>
          </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
export default {
  data() {
    return {};
  },
  components: {
    PagedList,
    AreaTree,
  },
  methods: {
    handleQuery(data) {
      this.clearArea(false);
      data.forEach((ele) => {
        if ((ele + "").length === 9) {
          this.queryParams.province = ele;
        }
        if ((ele + "").length === 12) {
          this.queryParams.city = ele;
        }
        if ((ele + "").length === 15) {
          this.queryParams.area = ele;
        }
        if ((ele + "").length < 9) {
          this.queryParams.schoolId = ele;
        }
      });
      this.refresh();
    },
    clearArea(isRefresh) {
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if (isRefresh) this.refresh();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
* {
  font-family: PingFangSC-Regular, PingFang SC;
}
.wrap {
  width: 100%;
  height: 100%;
  .box-con {
    width: 100%;
    min-height: 100%;
    background: #fff;
    .box-head {
      margin-top: 20px;
      position: relative;
      padding: 20px;
      height: 162px;
      background: #f5f7f9;
      overflow: hidden;
      margin-bottom: 20px;
      .head_title {
        display: inline-block;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 20px;
        margin-bottom: 20px;
        margin-right: 30px;
      }
      .headSpan {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #889098;
        line-height: 14px;
      }
      .head_con {
        display: flex;
        flex-wrap: wrap;
        .head_line {
          box-sizing: border-box;
          display: flex;
          margin-bottom: 20px;
          span {
            color: #889098;
            font-size: 14px;
            margin-right: 12px;
          }
          p {
            width: 714px;
            margin-left: 20px;
          }
        }
      }
      .fc_upload {
        position: absolute;
        height: 40px;
        width: 90px;
        top: 20px;
        right: 20px;
      }
    }

    .select {
      .year {
        /deep/.el-input__inner {
          background: url("../../assets/images/he/operate/area.svg") no-repeat;
          background-size: 12px 14px;
          background-position: 8px 7px;
          padding: 0 0 0 30px;
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
  }
  .box-item {
    width: 800px;
    height: 218px;
    margin: 20px;
    .box-title {
      span {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 20px;
      }
    }
    .box-cont {
    position: relative;
      >p{
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        line-height: 16px;
        margin-bottom: 20px;
      } 
      .box-fc {
        display: flex;
        flex-wrap: wrap;
        >p {
          color: #222;
          width: 50%;
          margin-bottom: 20px;
          span {
            color: #889098;
            font-size: 14px;
            margin-right: 14px;
          }
        }
      }
      i {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  // 详情
  /deep/ #el-drawer__title {
    background: $c-title;
    padding: 10px;
    color: $c-white;
  }
  /deep/ .el-drawer__header {
    margin-bottom: 0px;
  }
  .drawer {
    padding: 0px 20px;
    .title {
      height: 84px;
      background: #f5f7f9;
      margin-top: 20px;
      padding: 10px 20px 1px 20px;
      .new-title {
        width: 220px;
        p {
          font-size: 20px;
          color: $c-2;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .txt {
          font-size: 14px;
          color: $c-9;
        }
      }
      .name {
        width: 120px;
        p {
          font-size: 20px;
          color: $c-2;
          font-weight: bold;
        }
        .txt {
          font-size: 14px;
          color: $c-9;
        }
      }
      .status {
        p {
          font-size: 20px;
          color: $c-primary;
          font-weight: bold;
        }
        .gray {
          color: $c-9;
        }
        .txt {
          font-size: 14px;
          color: $c-9;
        }
      }
    }
    .content {
      margin: 20px 0px;
      .puplish {
        .puplish-name {
          color: $c-2;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .puplish-con {
          .img {
            width: 44px;
            height: 44px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 10px;
            img {
              width: 44px;
              height: 44px;
            }
          }
          .name {
            width: 140px;
            p {
              font-size: 16px;
              color: $c-2;
              font-weight: bold;
              img {
                margin: 6px 0px 0px 5px;
              }
            }
            .txt {
              font-size: 12px;
              color: $c-9;
              .line {
                margin: 0px 5px;
              }
            }
          }
        }
        .status-con {
          margin-top: 20px;
          p {
            color: &c-2;
            font-weight: bold;
            margin-bottom: 10px;
            font-weight: bold;
          }
          .title-img {
            margin-bottom: 20px;
            .new-img {
              width: 150px;
              height: 100px;
              margin-right: 20px;
              img {
                width: 150px;
                height: 100px;
              }
            }
            .new-title {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 10px;
              width: 250px;
            }
          }
          .text {
            font-size: 14px;
            font-weight: 400;
          }
          .btn {
            margin: 40px auto;
            width: 100px;
            .el-button {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
</style>