/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/partTime";

const state = {
    partList:[],
    partTotal: 0,
};

// actions
const actions = {
  async getPagesList({ commit, state }, params) {
    let result = await api.getPagesList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async jobGetDetail({ commit, state }, params) {
    return await api.jobGetDetail(params);
  },
  async jobAddJob({ commit, state }, params) {
    return await api.jobAddJob(params);
  },
  async jobEdit({ commit, state }, params) {
    return await api.jobEdit(params);
  },
  async jobDelete({ commit, state }, params) {
    return await api.jobDelete(params);
  },
  async jobApply({ commit, state }, params) {
    return await api.jobApply(params);
  },
  async getSearchList({ commit, state }, params) {
    return await api.getSearchList(params);
  },
  async jobComment({ commit, state }, params) {
    return await api.jobComment(params);
  },
  async jobLoad({ commit, state }, params) {
    return await api.jobLoad(params);
  },
  async jobEditJob({ commit, state }, params) {
    return await api.jobEditJob(params);
  },
  
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.partList = list;
    state.partTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
