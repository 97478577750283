<template>
<!-- 上传文件/照片例子 -->
	<div class="box-wrap wrap">
		<el-form :model="form" ref="ruleForm" label-width="160px" class="demo-ruleForm">
			<el-form-item label="身份证（正面）" prop="idcardPosimg">
				<!-- fileType: single/file/multiple; default:multiple -->
				<base-upload v-model="form.idcardPosimg" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload>
			</el-form-item>
			<el-form-item label="身份证（反面）" prop="idcardSideimg">
				<base-upload v-model="form.idcardSideimg" :limit="2" :uploadData="uploadData" @beforeUpload="handleBeforeUpload2"></base-upload>
			</el-form-item>
			<el-form-item label="学生证" prop="stucardPosimg">
				<base-upload v-model="form.stucardPosimg" fileType="file" :uploadData="uploadData" :limit="1" @beforeUpload="handleBeforeUpload3"></base-upload>
			</el-form-item>
      <el-form-item label="视屏" prop="stucardPosimg">
				<base-upload v-model="form.videoUp" fileType="video" :uploadData="uploadDataB" :limit="1" @beforeUpload="handleBeforeUpload4"></base-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm()">提交</el-button>
			</el-form-item>
		</el-form>
		<el-button v-if="form.idcardPosimg" @click="downloadPath(form.idcardPosimg)">下载</el-button>
	</div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import BaseUpload from "@/components/common/BaseUploadVideo";
import download from "@/mixins/download";
export default {
  name: "Main",
  components:{
	  BaseUpload
  },
  mixins: [download],
  data() {
    return {
      form:{
				idcardPosimg:'',
				idcardSideimg:[],
				stucardPosimg:[],
				videoUp:[],
			},
			uploadData:{
				bizType:'',
				bizId:'',
				needStore:'0',
			},
      uploadDataB:{
				bizType:'',
				bizId:'',
				needStore:'0',
        FoldPath:'上传目录',
        SecretKey:'安全验证'
			},
    };
  },
  methods: {
	handleBeforeUpload(file, callback){
		const isPicture = file.name.includes('jpg') || file.name.includes('png');
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isPicture) {
			this.$message.error('上传图片只能是 JPG/PNG 格式!');
		}
		if (!isLt2M) {
			this.$message.error('上传图片大小不能超过 2MB!');
		}
		callback(isPicture && isLt2M)
	},
	handleBeforeUpload2(file, callback){
		callback(true)
	},
	handleBeforeUpload3(file, callback){
		callback(true)
	},
    submitForm(){
		log(this.form)
	},
  handleBeforeUpload4(file, callback){
		callback(true)
	},
  }
};
</script>