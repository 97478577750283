<template>
<!-- 文件上传 -->
  <div class="components-upload">
    <el-upload
      v-if="fileType == 'single'"
      class="avatar-uploader"
      :data="uploadData"
      :disabled="disabled"
      :action="BaseUrl +　uploadUrl"
      :headers="{ 'Authorization' : getAccessToken() }"
      :show-file-list="false"
			:on-success="handleSuccess"
			:before-remove="beforeRemove"
      :before-upload="beforeUpload"
			:on-remove="handleRemove">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-upload
      v-if="fileType == 'multiple'"
			list-type="picture-card"
			:action="BaseUrl +　uploadUrl"
			:headers="{ 'Authorization' : getAccessToken() }"
			:limit="limit"
      :data="uploadData"
			:on-success="handleSuccess"
			:before-remove="beforeRemove"
      :before-upload="beforeUpload"
			:on-remove="handleRemove">
			<i class="el-icon-plus"></i>
		</el-upload>
    <el-upload
      v-if="fileType == 'file'"
			:action="BaseUrl +　uploadUrl"
			:headers="{ 'Authorization' : getAccessToken() }"
			:limit="limit"
      :data="uploadData"
			:on-success="handleSuccess"
			:before-remove="beforeRemove"
      :before-upload="beforeUpload"
			:on-remove="handleRemove"
      :file-list="fileList"
      :show-file-list="showList">
        <el-button type="success" size="small">{{ buttonTxt }}</el-button>
        <div slot="tip" class="el-upload__tip">{{ tips }}</div>
		</el-upload>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log, getAccessToken } from "@/common/utils";
import { BaseUrl } from "@/common/config";
import fileUpload from "@/mixins/fileUpload";
export default {
  name: "upload",
  mixins: [fileUpload],
  props:{
    limit:{
      type: Number,
      default: 5
    },
    fileType:{
      type: String,
      default: 'multiple'
    },
    value: '',
    tips:{
      type: String,
      default: '只能上传excel/word/ppt文件'
    },
    buttonTxt:{
      type: String,
      default: '点击上传'
    },
    showList:{
      type: Boolean,
      default: true
    },
    uploadData:{
      type:Object,
      default: null
    },
    disabled:{
      type:Boolean,
      default: null
    }
  },
  data() {
    return {
      BaseUrl,
      imageUrl:'',
    };
  },
  watch: {
    value(n,o) {
      this.onValueChange(n,o);
    },
  },
  methods: {
    getAccessToken,
    
    onValueChange(val,o){
      // log('val',val)
      if(val){
        if(this.fileType == 'file'){
          let arr = []
          if(this.limit == 1 && val){
            arr.push({
              name: val,
              path: val
            })
          }
          if(this.limit > 1 && val.length){
            this.list = val
            val.forEach(k=>{
              arr.push({
                name: k.url,
                path: k.url
              })
            })
          }
          this.fileList = arr
        }
      }else{
        this.fileList = []
        this.list = []
      }
      if(this.fileType == 'single'){
        this.imageUrl = val
      }
    },
    beforeUpload(file){
      const promise = new Promise((resolve, reject) => {
        this.$emit('beforeUpload', file, (val)=>{
          val ? resolve(val) : reject(val);
        })
      })
      return promise
    },
  },
  mounted(){
    this.onValueChange(this.value)
  }
};
</script>
<style lang="scss">
.components-upload {
  .avatar-uploader .el-upload { border: 1px dashed #c0ccda; background:#fbfdff; border-radius: 6px; cursor: pointer; position: relative; overflow: hidden; }
  .avatar-uploader .el-upload:hover { border-color: #409EFF }
  .avatar-uploader-icon { font-size: 28px; color: #8c939d; width: 148px; height: 148px; line-height: 148px; text-align: center }
  .avatar { width: 148px; height: 148px; display: block }
}
</style>