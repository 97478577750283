/*
 * @Auhtor: 文锋
 * @Date: 2021-05-24 13:57:24
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-26 09:06:34
 * @FilePath: \operate\src\api\operate\schoolFellow.js
 */
/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 校友圈列表
export const getSchoolFellowList = function(params) {
  return axios({
    url: BaseUrl + "social/my/getPagesList",
    method: "post",
    data: params
  });
};
// 详情
export const socialDetail = function(params) {
  return axios({
    url: BaseUrl + "social/my/detail",
    method: "post",
    data: params
  });
};

// 全部话题
export const getSchooltopicList = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/list',
    method: 'post',
    data: params
  })
}

// 查询话题重复
export const selectRepeat = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/has',
    method: 'post',
    data: params
  })
}

// 新增话题
export const addSchooltopic = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/insert',
    method: 'post',
    data: params
  })
}

// 更新话题
export const editSchooltopic =function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/edit',
    method: 'post',
    data: params
  })
}
// 开启关闭话题
export const upOrDowntopic = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/status',
    method: 'post',
    data: params
  })
}

// 话题详情
export const schooltopicInfo = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/load',
    method: 'post',
    data: params
  })
}

// 删除话题
export const deleteSchooltopic = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/delete',
    method:'post',
    data: params
  })
}

// 热门话题
export const getHotTopicList = function (params) {
  return axios({
    url:  BaseUrl + 'social/admin/topic-hot/list',
    method: 'post',
    data: params
  })
}

// 所有话题
export const getSelectAllTopic = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic/all',
    method: 'post',
    data: params
  })
}

// 更新热门话题
export const updateHotTopic = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-hot/insert',
    method: 'post',
    data: params
  })
}

// 首页推荐话题列表
export const recommendTopicList = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/list',
    method: 'post',
    data: params
  })
}

// 推荐话题查重
export const recommendTopicRepeat = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/has',
    method: 'post',
    data: params
  })
}

// 推荐话题  新增
export const recommendTopicAdd = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/insert',
    method: 'post',
    data: params
  })
}

// 推荐话题  编辑
export const recommendTopicEdit = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/update',
    method: 'post',
    data: params
  })
}

// 推荐话题  详情
export const recommendTopicInfo = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/detail',
    method: 'post',
    data: params
  })
}

// 推荐话题 状态
export const recommendTopicStatus = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/status',
    method: 'post',
    data: params
  })
}

// 推荐话题  删除
export const recommendTopicDelete = function (params) {
  return axios({
    url: BaseUrl + 'social/admin/topic-commend/delete',
    method: 'post',
    data: params
  })
}


export default {
  getSchoolFellowList,
  socialDetail,
  getSchooltopicList,
  addSchooltopic,
  editSchooltopic,
  deleteSchooltopic,
  schooltopicInfo,
  upOrDowntopic,
  getHotTopicList,
  getSelectAllTopic,
  updateHotTopic,
  recommendTopicList,
  recommendTopicAdd,
  recommendTopicEdit,
  recommendTopicInfo,
  recommendTopicDelete,
  recommendTopicStatus,
  selectRepeat,
  recommendTopicRepeat
};
