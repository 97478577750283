<template>
  <!-- 运营总监-新增学习任务 @需要改仅为静态页 -->
  <div class="wrap">
    <div class="box-con">
      <el-tabs v-model="active" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="新增任务" name="1"></el-tab-pane>
      </el-tabs>

      <!-- 常规任务 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="form">
        <el-form-item>
          <el-select class="btn-task" label-width="150px" v-model="form.status" placeholder="选择年份">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select class="btn-task" v-model="form.month">
            <el-option label="上半年" :value="1"></el-option>
            <el-option label="下半年" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :class="[btnActive==1?'btn-active':'','btn-task']" @click="btnActive=1">学习任务</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :class="[btnActive==2?'btn-active':'','btn-task']" @click="btnActive=2">考试任务</el-button>
        </el-form-item>
        <el-form-item>
          <el-select class="btn-task" label-width="150px" v-model="form.status" placeholder="选择学校">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form class="form-task" size="small" :model="form" v-if="btnActive==1">
        <el-form-item>
          <div class="form-title">功能学习</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="网络大厅" :value="1"></el-option>
              <el-option label="校友圈" :value="1"></el-option>
              <el-option label="小赚一笔" :value="1"></el-option>
              <el-option label="爆款清单" :value="1"></el-option>
              <el-option label="社团组织" :value="1"></el-option>
              <el-option label="教学服务" :value="1"></el-option>
              <el-option label="失物招领" :value="1"></el-option>
              <el-option label="易货空间" :value="1"></el-option>
              <el-option label="校园报修" :value="1"></el-option>
              <el-option label="校园大赛" :value="1"></el-option>
              <el-option label="校园新闻" :value="2"></el-option>
              <el-option label="校园兼职" :value="3"></el-option>
            </el-select>
            <el-button class="class-btn" @click="dialogVisible = true">选择课程</el-button>&nbsp;
            <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 节</div>&nbsp;
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addCurrent" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteCurrent(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">运营策划</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="自定义名称" :value="1"></el-option>
            </el-select>
            <el-button class="class-btn" @click="dialogVisible = true">选择课程</el-button>&nbsp;
            <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 节</div>&nbsp;
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addCurrent" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteCurrent(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">考核策略</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="自定义名称" :value="1"></el-option>
            </el-select>
            <el-button class="class-btn" @click="dialogVisible = true">选择课程</el-button>&nbsp;
            <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 节</div>&nbsp;
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addCurrent" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteCurrent(index)" />
          </div>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button size="small" class="sub-btn" @click="$router.push({path: '/myTask'})">取消</el-button>
          <el-button size="small" class="sub-btn" type="primary" @click="$router.push({path: '/myTask'})">确定</el-button>
        </el-form-item>
      </el-form>

      <el-form class="form-task" size="small" :model="form" v-if="btnActive==2">
        <el-form-item>
          <div class="form-title">任务内容</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="网络大厅" :value="1"></el-option>
              <el-option label="校友圈" :value="1"></el-option>
              <el-option label="小赚一笔" :value="1"></el-option>
              <el-option label="爆款清单" :value="1"></el-option>
              <el-option label="社团组织" :value="1"></el-option>
              <el-option label="教学服务" :value="1"></el-option>
              <el-option label="失物招领" :value="1"></el-option>
              <el-option label="易货空间" :value="1"></el-option>
              <el-option label="校园报修" :value="1"></el-option>
              <el-option label="校园大赛" :value="1"></el-option>
              <el-option label="校园新闻" :value="2"></el-option>
              <el-option label="校园兼职" :value="3"></el-option>
            </el-select>
            <el-button class="class-btn" @click="testVisible = true">选择试卷</el-button>&nbsp;
            <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 节</div>&nbsp;
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addCurrent" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteCurrent(index)" />
          </div>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button size="small" class="sub-btn" @click="$router.push({path: '/myTask'})">取消</el-button>
          <el-button size="small" class="sub-btn" type="primary" @click="$router.push({path: '/myTask'})">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 选择课程 -->
    <el-dialog title="选择课程" center :visible.sync="dialogVisible" width="61%"> 
      <div class="cfx">
        <div class="dialog-title">校友圈课程</div>
        <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 节</div>
        <div class="fr">
          <el-input style="width: 250px;" placeholder="请输入课程名称">
          </el-input><el-button slot="append" type="primary">查询</el-button>
        </div>
      </div>
      <div class="class-box">
        <el-checkbox v-for="item in 20" :key="item" class="image-box">
          <div class="image">
            <el-image style="width: 100%; height: 100%; border-radius: 5px;" src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3036316726,676055399&fm=26&gp=0.jpg"></el-image>
          </div>
          <div class="image-lable">校友圈直播课程标题校友圈直播课程…</div>
        </el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 140px;margin-right:20px;" @click="dialogVisible = false">取 消</el-button>
        <el-button style="width: 140px;" type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择试卷 -->
    <el-dialog title="选择试卷" center :visible.sync="testVisible" width="61%"> 
      <div class="cfx">
        <div class="dialog-title">校友圈试卷</div>
        <div class="font-gray">已选择 <span class="tinctgreen" v-if="1">10</span><span class="tinctred" v-if="0">0</span> 篇</div>
        <div class="fr">
          <el-input style="width: 250px;" placeholder="请输入课程名称">
          </el-input><el-button slot="append" type="primary">查询</el-button>
        </div>
      </div>
      <div class="test-box">
        <el-checkbox v-for="item in 20" :key="item" class="text-box">
          <div class="text">
            <div style="margin: 5px 0px;">校友圈试卷标题</div>
            <div style="margin: 5px 0px;">难度：简单</div>
            <div style="margin: 5px 0px;">题目总数：20题</div>
          </div>
        </el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 140px;margin-right:20px;" @click="testVisible = false">取 消</el-button>
        <el-button style="width: 140px;" type="primary" @click="testVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MemberTask from "@/components/task/myTask/MemberTask";
import DirectorTask from "@/components/task/myTask/DirectorTask";
export default {
  name:'directorAddStudyTask',
  components: {
    MemberTask,
    DirectorTask
  },
  data() {
    return {
      dialogVisible: false,
      testVisible: false,
      form: {
        nameRoom: '',
      },
      statusOptions: [],
      active:'1',
      btnActive: 1,
      nameRoomArr: [''],
    };
  },
  computed: {
  },
  methods: {
    async handleClick(tab, event) {
      console.log(tab)
    },
    addCurrent(){
      if(this.nameRoomArr.length<3) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteCurrent(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addSpread(){
      if(this.nameRoomArr.length<5) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteSpread(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addButt(){
      if(this.nameRoomArr.length<6) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteButt(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addService(){
      if(this.nameRoomArr.length<3) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteService(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addBusiness(){
      if(this.nameRoomArr.length<6) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteBusiness(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addAwardTaskOne(){
      if(this.nameRoomArr.length<2) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteAwardTaskOne(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
  },
  async mounted() {
    
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; background: #fff; width: 100%; padding: 10px 20px 20px;
  .box-con{ width: 100%; min-height: 100%; background:#fff;
    .tabs{ width: 100%; background:#fff;padding-top: 0;
      /deep/.el-tabs__item { font-size: 18px; font-weight: bold; }
    }
    .params-wrap { padding: 15px 15px 0px 0px; 
      .btn-task { width: 150px; height: 32px; font-size: 14px; }
      .btn-active { color: #28B892; border-color: #28B892; outline: 0; background-color: rgba(40, 184, 146, 0.1); }
    }
    .form-task { margin-bottom: 30px;
      .form-title { font-size: 18px; font-weight: bold; height: 18px; line-height: 18px; margin-top: 20px; }
      .class-btn { width: 100px; margin-left: 10px; color:#28B892; }
      .font-gray { display: inline-block; color: $c-9; }
      .for-task { display: inline-block; margin: 20px 20px 0 0;
        .form-select { width: 150px; }
        .form-input { width: 150px; margin-left: 10px; }
        .add-icon { vertical-align: middle; cursor: pointer; }
      }
      .sub-btn { width: 160px; height: 40px; margin: 30px 50px 0 0; } 
    }
  }
}

.dialog-title { font-size: 20px; color: $c-2; font-weight: bold; display: inline-block; margin-right: 60px; }
.font-gray { display: inline-block; color: $c-9; }
.class-box { margin-top: 20px; max-height: 500px; overflow-y: scroll;
  /deep/.el-checkbox + .el-checkbox { margin-left: 0px !important; }
  .image-box { display: inline-block; margin-right: 20px; position: relative;
    .image { width: 260px; height: 145px; border: solid 1px #000; border-radius: 5px; }
    /deep/.el-checkbox__inner { position: absolute; left: 16px; top: -154px; }
    .image-lable { margin: 10px 0 15px; }
  }
  .image-box.is-checked{ 
    .image { width: 260px; height: 145px; border: solid 1px #28B892; border-radius: 5px; }
  }
}
.test-box { margin-top: 20px; max-height: 500px; overflow-y: scroll;
  /deep/.el-checkbox + .el-checkbox { margin-left: 0px !important; }
  /deep/.text-box { display: inline-block; margin-right: 20px; position: relative;
    .text { padding: 10px 20px; width: 260px; height: 100px; border: solid 2px #ddd; border-radius: 5px; margin-bottom: 20px; }
  }
  /deep/.el-checkbox__inner { display: none; }
  .text-box.is-checked{ 
    .text { padding: 10px 20px; width: 260px; height: 100px; border: solid 2px #28B892; border-radius: 5px; margin-bottom: 20px; }
  }
}
</style>
