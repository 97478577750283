import api from "@/api/assess/assessCenter";

const state = {
    regionInfo:{} // 通用考核权重 
}

const actions = {
    // 通用考核权重 详情    
    async getAllByRegionInfo({commit,state},params) {
        let res = await api.getAllByRegionInfo(params)
        commit('saveRegionInfo',res)
        return res
    },
    // 通用考核权重 编辑
    async editAllByRegionInfo({commit,state},params) {
        return await api.editAllByRegionInfo(params)
    }
}

const mutations = {
    saveRegionInfo(state,data) {
        state.regionInfo = data
    }
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
