import api from "@/api/operate/applyMatch.js";

export const state = {
    matchList:[],
    matchTotal:0,
    matchInfo:{},
    dataDetail:{}
}

export const actions = {
    // 数据统计
    async getDataDetail({commit,state},params) {
        let res = await api.getDataDetail(params)
        commit('saveData',res)
        return res
    },
    // 赛事列标
    async getMatchList({commit,state},params) {
        let res = await api.getMatchList(params)
        let {list,total} = res
        commit('saveMatchList',{list,total})
        return res
    },
    // 赛事详情
    async getMatchInfo({commit,state},params) {
        let res = await api.getMatchInfo(params)
        commit('saveMatchInfo',res)
    },
    // 赛事审核
    async matchCheck({commit,state},params) {
        return await api.matchCheck(params)
    },
    // 取消资格
    async matchRecusal({commit,state},params) {
        return await api.matchRecusal(params)
    },
    // 查看改变状态
    async chargeStatus({commit,state},params) {
        return await api.chargeStatus(params)
    },
    // 改变状态
    async editStatus({commit,state},params) {
        return await api.editStatus(params)
    }
}

export const mutations = {
    saveData(state,res) {
        state.dataDetail = res
    },
    saveMatchList(state,{list,total}) {
        state.matchList = list
        state.matchTotal = total
    },
    saveMatchInfo(state,res) {
        state.matchInfo = res
    }
}

export const getters = {}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
};
  