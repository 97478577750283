/*
 * @Auhtor: 文锋
 * @Date: 2021-05-12 17:19:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 11:17:21
 * @FilePath: \operate\src\store\modules\basic\ceocheck.js
 */
import api from "@/api/basic/ceocheck";

const state = {
    checkList:[],
    checkTotal:0
}

const actions = {
    async getPagesList({commit,state},params) {
        let res = await api.getPagesList(params)
        let {list,total} = res
       commit('savePageList',{list,total})
    },
    async getCheckInfoById({commit,state},params) {
        return await api.getCheckInfoById(params)
    },
    async editCheckInfo({commit,state},params) {
        return await api.editCeoCheck(params)
    }
}

const mutations = {
    savePageList(state,{list,total}) {
        state.checkList = list
        state.checkTotal = total
    } 
}

const getters = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}