import { BaseUrl } from "@/common/config";
import axios from "axios";

// 分销任务 列表
export const getDistributionList = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/list',
        method: 'post',
        data: params
    })
}

// 分销任务 详情
export const getDistInfo  = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/getTaskInfo',
        method: 'post',
        data: params
    })
}

// 分销成员
export const getDistMember = function (params) {
    return axios({
        url: BaseUrl + 'sell/task/members',
        method: 'post',
        data: params
    })
}

export default {
    getDistributionList,
    getDistInfo,
    getDistMember
}