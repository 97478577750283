<template>
<!-- 当前任务 @需要改未进行数据渲染 -->
  <div class="wrap">
    <el-tabs v-model="activeName" class="tabs">
      <!-- 常规任务 -->
      <el-tab-pane label="常规任务" name="first">
        <div class="big-box">
          <div class="box-item" v-for="(item,index) in currentList" :key="index">
            <div class="header">{{item.name}}</div>
            <div class="select-btn">
              <el-button class="btn" type="success" plain>本月</el-button>
              <el-button class="btn"  plain>上月</el-button>
              <span>其他月份</span>
              <el-date-picker style="width:230px; margin-left: 20px;" size="small" v-model="timeRange" @change="getTimeRange($event,'start','end','queryParams'),refresh(),requireCashAll()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
              <el-button class="btn2">查询</el-button>
            </div>
            <keep-alive>
             <div v-if="index === 0">
               <el-row :gutter="20">
                  <el-col :span="8" v-for="(item1) in item.children" :key="item1.id">
                    <div class="item-cont" >
                      <div class="item-children">
                        <div class="t">
                          <span>{{item1.name}}</span>
                          <span>未完成</span>
                        </div>
                        <div class="number">50,000,00</div>
                        <div >
                          <div><span class="">距离任务：</span>
                          <span>-3000.00</span></div>
                          <div><span>任务金额：</span>
                          <span>53,000.00</span></div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
             </div>
             <div v-else-if="index === 1">
               <el-row :gutter="20">
                  <el-col :span="4" v-for="(item1) in item.children" :key="item1.id">
                    <div class="item-cont">
                      <div class="item-children">
                        <div class="t">
                          <span>{{item1.name}}</span>
                          <span>未完成</span>
                        </div>
                        <div class="number">6</div>
                        <div>
                          <div><span class="">距离任务：</span>
                          <span>+30</span></div>
                          <div><span>任务金额：</span>
                          <span>3</span></div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
             </div>
             <div v-else-if="index === 2">
               <el-row :gutter="20">
                    <el-col :span="8" v-for="(item1) in item.children" :key="item1.id">
                    <div class="item-cont">
                      <div class="item-children">
                        <div class="t">
                          <span>{{item1.name}}</span>
                          <span>未完成</span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
             </div>
             <div v-else-if="index === 3">
               <el-row :gutter="20">
                  <el-col :span="8" v-for="(item1) in item.children" :key="item1.id">
                    <div class="item-cont">
                      <div class="item-children">
                        <div class="t">
                          <span>{{item1.name}}</span>
                          <span>未完成</span>
                        </div>
                        <div style="display:flex;justify-content: space-between;">
                          <span>客户满意度</span>
                          <div>
                            <span><i class="el-icon-star-on"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
             </div>
             <div v-else-if="index === 4">
               <el-row :gutter="20">
                  <el-col :span="6" v-for="(item1) in item.children" :key="item1.id">
                    <div class="item-cont">
                      <div class="item-children">
                        <div class="t">
                          <span>{{item1.name}}</span>
                          <span>未完成</span>
                        </div>
                        <div style="display:flex;flex-wrap:wrap;">
                          <div>发布： <span>3/30</span></div>
                          <div>点赞： <span>3/300</span></div>
                          <div>浏览： <span>3/300</span></div>
                          <div>评论： <span>3/300</span></div>
                          <div>分享： <span>3/3000</span></div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
             </div>
            </keep-alive>
          </div>
        </div>
      </el-tab-pane>
      <!-- 奖励任务 -->
      <el-tab-pane label="奖励任务" name="second">
        <div class="big-box">
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-经开区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
               <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
          </div>  
          </div>
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
                <!-- <span class="state1 fr">待审核</span> -->
                <!-- <span class="state2 fr">未通过</span> -->
                <!-- <span class="state3 fr">已通过</span> -->
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-瑶海区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
          </div>  
          </div>
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
                <span class="state3 fr">已通过</span>
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-蜀山区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
          </div>  
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 分解详情 -->
    <el-drawer
      append-to-body
      title="分配详情"
      :visible.sync="drawer"
      size="35%"
      >
      <div class="allocationdetail">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">2021-3月份</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt">已分配</p>
              <p class="mission">分配状态</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">分配人</p>
            <img src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2640900762,1357209539&fm=26&gp=0.jpg" class="pig" alt="">
            <div class="area">
              <p class="con-txt">王小二</p>
              <p class="mission">区域经理</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
          </div>
        </div>
        <div class="d-con">
          <p class="contit">已分配详情</p>
          <el-collapse v-model="unfold" accordion>
            <el-collapse-item name="1">
              <div slot="title" class="colltit">
                <span class="college">中国科技大学</span>
                <div class="people">
                  <span>CEO：</span>
                  <span>李晓（17855664235）</span>
                </div>
              </div>
              <div class="collcon">
                <div class="conhead">
                  <span>分配时间：</span>
                  <span>2019-08-12 14:34</span>
                </div>
                <div class="content">
                  <div class="taskcon">
                    <p class="contit">通用任务</p>
                    <div class="conitem">
                      <div class="conlab">移动号卡（张）</div>
                      <div class="connum">800 </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">营业额（元）</div>
                      <div class="connum">3000 </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">订单数（笔）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">访客数（人）</div>
                      <div class="connum">500</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">商户推广</p>
                    <div class="conitem">
                      <div class="conlab">运营商（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">考证培训（家）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">餐饮商家（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">大型超市（家）</div>
                      <div class="connum">30</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">广告招商（家）</div>
                      <div class="connum">50</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">对接应用</p>
                    <div class="conitem">
                      <div class="conlab">对接一卡通（个）</div>
                      <div class="connum">800</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接教务系统（个）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接共享服务（个）</div>
                      <div class="connum">400</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接其他应用（个）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">线下举办活动（个）</div>
                      <div class="connum">30</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-button class="taskdetails" @click="apptask">任务详情</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 任务详情 -->
    <el-drawer
      append-to-body
      :visible.sync="innerDrawer"
      size="35%"
      >
      <div slot="title"> 
        <i @click="goback" v-if="drawer==true"> &lt; </i>
        任务详情
      </div>
      <div class="taskdetail">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">常规任务</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area">
              <p class="con-txt">2021-3月份</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt">2021-04-18 16:39</p>
              <p class="mission">分配时间</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">接收人</p>
            <img  src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2640900762,1357209539&fm=26&gp=0.jpg" class="pig" alt="">
            <div class="area">
              <p class="con-txt">王小二</p>
              <p class="mission">区域经理</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">通用任务</p>
          <div class="conitem">
            <div class="conlab">移动号卡（张）</div>
            <div class="connum">800 </div>
          </div>
          <div class="conitem">
            <div class="conlab">营业额（元）</div>
            <div class="connum">3000 </div>
          </div>
          <div class="conitem">
            <div class="conlab">订单数（笔）</div>
            <div class="connum">200</div>
          </div>
          <div class="conitem">
            <div class="conlab">访客数（人）</div>
            <div class="connum">5000</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">商户推广</p>
          <div class="conitem">
            <div class="conlab">运营商（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">考证培训（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">餐饮商家（家）</div>
            <div class="connum">300</div>
          </div>
          <div class="conitem">
            <div class="conlab">大型超市（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">广告招商（家）</div>
            <div class="connum">20</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">对接应用</p>
          <div class="conitem">
            <div class="conlab">对接一卡通（个）</div>
            <div class="connum">800 </div>
          </div>
          <div class="conitem">
            <div class="conlab">对接教务系统（个）</div>
            <div class="connum">40</div>
          </div>
          <div class="conitem">
            <div class="conlab">对接共享服务（个）</div>
            <div class="connum">400</div>
          </div>
          <div class="conitem">
            <div class="conlab">对接其他应用（个）</div>
            <div class="connum">200</div>
          </div>
          <div class="conitem">
            <div class="conlab">线下举办活动（个）</div>
            <div class="connum">30</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">用户服务</p>
          <div class="conitem">
            <div class="conlab">客户服务（个）</div>
            <div class="connum">10</div>
          </div>
          <div class="conitem">
            <div class="conlab">上门服务（个）</div>
            <div class="connum">10</div>
          </div>
          <div class="conitem">
            <div class="chang">商务合作联系方式维护（个）</div>
            <div class="duan">5</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">用户运营</p>
          <div class="conitem">
            <div class="conlab">客户服务（个）</div>
            <div class="connum">20</div>
          </div>
          <div class="conitem">
            <div class="conlab">上门服务（个）</div>
            <div class="connum">20</div>
          </div>
          <div class="conitem">
            <div class="chang">商务合作联系方式维护（个）</div>
            <div class="duan">3</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'current',
  components: {
    PagedList
  },
  mixins: [pageMixin],
  data() {
    return{
      activeName:'first',
      queryParams: {

      },
      statusOptions:[],
      drawer: false,
      innerDrawer: false,
      unfold:'first',
    }
  },
  computed:{
...mapState("currentTask", ["currentList"]),
  },
  created() {
    this.selectCurrentList({code:'task_common'})
  },
  methods: {
    ...mapActions("currentTask", [
      "selectCurrentList"
    ]),
    handleClose(done) {
      this.$confirm('还有未保存的工作哦确定关闭吗？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 任务分配
    taskapp() {
      this.$router.push({
        path: '/taskAllocation'
      })
    },
    // 分配详情
    apportiondel() {
      this.drawer = true
    }, 
    // 任务详情
    taskdel() {
      this.innerDrawer = true
    },
    // 分配详情中的任务详情按钮
    apptask() {
      this.innerDrawer = true
    },
    // 任务详情返回
    goback() {
      this.innerDrawer = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .tabs{ width: 100%; min-height: 100%; background:#fff;padding: 20px;padding-top: 0;
  }
  .big-box{ width: 100%; height: 100%; 
    .box-item {
      box-sizing: border-box;
    margin: 20px 5px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #cecccc;
      .header { font-size: 20px;color: #222;font-weight: 600; }
      .select-btn {
        margin: 20px 0;
        .btn {margin-right: 20px;width: 68px;height: 40px;border: 1px solid #f5f5f5;}
        .btn2 { width: 100px;height: 40px;background-color:#28B892 ;color: #fff;}
      }
      .item-cont { border: 1px solid #f5f5f5;padding: 20px;
         .t {display: flex;justify-content: space-between;
            span {font-size: 16px;font-weight: 600;}
         }
         .number { font-size:40px;}
      }
    }
  }
}

/deep/#el-drawer__title{ background:$c-title;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;padding: 20px; }
.allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 18px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bolder;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%;}
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-con{
    .contit{ color: $c-9; padding: 20px 0; font-size: 16px;}
    .el-collapse{ border: 0; }
    .el-collapse-item{ margin-bottom: 20px; }
    .el-collapse-item__content{padding: 0;}
    .el-collapse-item .is-active{ background:$c-success; border: 1px solid #28B892; color:#fff; }
    .el-collapse-item__header{ background: #e2e7eb; height: 44px; font-size: 16px; color: $c-2; border-radius: 4px; border: 1px solid #e2e7eb;}
    .el-collapse-item__header .is-active{color: #fff;}
    .el-collapse-item__arrow .el-icon-arrow-right{ color: $c-2; }
    .el-collapse-item__arrow .el-icon-arrow-right .is-active{ color: #fff; }
    .colltit{ padding:0 20px;
      .people{ display: inline-block; margin-left:50px;}
    }
    .collcon{ padding: 20px; background: $c-border;min-height: 300px;padding-right: 0;
      .conhead{ color: $c-2; font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC;line-height: 20px;
        span{  font-weight: bold; }
      }
      .content{
        .taskcon {
          .contit{ color: $c-1; padding: 10px 0; font-size: 14px; font-weight: bold; }
          .conitem{ display: inline-block; 
            .conlab, .chang{ color: $c-9; display: inline-block; min-width: 130px;}
            .connum{ display: inline-block; width: 100px; }
            .duan { display: inline-block; }
          }
        }
      }
    }
    .taskdetails{ width:120px; margin-left: 50%; transform: translateX(-50%); margin-top: 20px;}
  }
}
.taskdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 18px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold; }
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .taskcon { padding: 10px 0;
    .contit{ color: $c-1; padding: 10px 0; font-size: 14px; font-weight: bold; }
    .conitem{ display: inline-block; width: 240px;
      .conlab, .chang{ color: $c-9; display: inline-block; width: 130px; }
      .chang { width: 200px; }
      .connum{ display: inline-block; width: 100px; }
      .duan { display: inline-block; }
    }
  }
}

</style>
