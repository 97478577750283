/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 提现审核列表
export const settleShopWithdrawGetVerifyList = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/getVerifyList",
    method: "post",
    data: params
  });
};
 
// 提现汇总
export const settleShopWithdrawGetSum = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/getSum",
    method: "post",
    data: params
  });
};

// 提现详情
export const settleShopWithdrawGetDetail = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/getDetail",
    method: "post",
    data: params
  });
};

// 提现审核
export const settleShopWithdrawVerify = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/verify",
    method: "post",
    data: params
  });
};

// 非商城商家，按提现id导出订单明细
export const netAdminCashNoLoginExportWithSWId = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cash/noLogin/exportWithSWId",
    method: "get",
    params: params
  });
};


// 用户模块
// 金额统计
export const getCashMoneyDetail = function (params) {
  return axios({
    url: BaseUrl + 'operate/withdraw/getSta',
    method: 'post',
    data: params
  })
} 

// 提现列表
export const getUserCashCheckList = function (params) {
  return axios({
    url: BaseUrl + 'operate/withdraw/getPageList',
    method: 'post',
    data: params
  })
}

// 提现详情
export const getUserCashInfo = function (params) {
  return axios({
    url: BaseUrl + 'operate/withdraw/load',
    method: 'post',
    data: params
  })
}

// 提现审核
export const checkUserCash = function (params) {
  return axios({
    url: BaseUrl + 'operate/withdraw/audit',
    method:  'post',
    data: params
  })
}

export default {
  settleShopWithdrawGetVerifyList,
  settleShopWithdrawGetSum,
  settleShopWithdrawGetDetail,
  settleShopWithdrawVerify,
  netAdminCashNoLoginExportWithSWId,
  getCashMoneyDetail,
  getUserCashCheckList,
  getUserCashInfo,
  checkUserCash
};
