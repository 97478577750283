/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/lock";

const state = {
  lockList:[],
  lockTotal: 0,
};

// actions
const actions = {
  async getLockList({ commit, state }, params) {
    let result = await api.getLockList(params);
    commit("saveLockList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async lockDetail({ commit, state }, params) {
    return await api.lockDetail(params);
  },
  async lockEdit({ commit, state }, params) {
    return await api.lockEdit(params);
  },  
};

//getters
const getters = {};

const mutations = {
  saveLockList(state, { list, total }) {
    state.lockList = list;
    state.lockTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
