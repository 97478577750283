<template>
<!-- 侧边栏 -->
  <div class="plat-slider" :class="{ on: isSidebarCollapsed }">
    <div class="plat-menu-box page">
      <div class="plat-menu page">
        <el-menu :collapse="isSidebarCollapsed" :default-openeds="openeds" :default-active="currentTabPath" background-color="#fff">
					<component
              v-for="(menu, index) in menus"
              :key="index"
              :index="(index + 1).toString()"
              :is="
                menu.children && menu.children.length ? 'el-submenu' : 'el-menu-item'
              "
              :class="{ 'is-active': checkActive('/' + menu.path) }"
              @click="toPageValite('/' + menu.path)"
            >
						<template slot="title" v-if="menu.children && menu.children.length">
              <i v-if="menu.icon" :class="menu.icon" />
              <span slot="title">{{ menu.title }}</span>
            </template>
            <template v-else>
              <i v-if="menu.icon" :class="menu.icon" />
              <span slot="title">{{ menu.title }}</span>
            </template>
            <template v-if="menu.children && menu.children.length">
              <el-menu-item
                v-for="(submenu, index2) in menu.children"
                :key="index2"
                :index="index + 1 + '-' + (index2 + 1)"
                @click="toPageValite('/' + submenu.path)"
                :class="{ 'is-active': checkActive('/' + submenu.path) }"

              >
                {{ submenu.title }}
              </el-menu-item>
            </template>
					</component>
				</el-menu>
      </div>
    </div>
    <div class="navbar-inner" @click="collapseChange()">
      <i :class="isSidebarCollapsed ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState, mapMutations } from "vuex";
import { log } from "@/common/utils";
export default {
  name: "SideBar",
  data() {
    return {
      menus: [
        // { title:'基础管理', icon:'', children:[
        //   { title:'学校管理', path:'schoolManagement' }
        // ]},
        // { title:'任务中心', icon:'', children:[
        //   {title:'分解信息', path: 'resolveMessage'},
        //   { title:'我的任务', path: 'myTask'},
        // ]},
        // { title:'基础信息', icon:'', children:[
        //   { title:'学校管理', path:'schoolManage' },
        //   { title:'学院管理', path:'collegeManageCeo' },
        //   { title:'成员管理', path:'memberManageCeo' },
        //   { title:'任务分配', path: 'taskAllocation'},
        // ]},
        { title:'运营管理', icon:'', children:[
          { title:'兼职管理', path:'partTime' },
          { title:'校友圈', path:'schoolfellow' },
          { title:'校园新闻', path:'schoolNews' },
          { title:'小赚一笔', path:'littleMoney' },
          { title:'跳蚤市场', path:'fleaMarket' },
          { title:'举报管理', path:'report' },
          { title:'广告管理', path:'advertising' },
          { title:'失物招领', path:'lostProperty' },
          { title:'宽带报修', path:'netRepair' },
          { title:'校园报修', path:'campusRepair' },
          
        ]},
        { title:'课表管理', icon:'', children:[
          { title:'课程表', path:'timeTable' },
          { title:'节次管理', path:'classManage' },
          { title:'学期管理', path:'termManage' },
        ]},
        { title:'社团组织', icon:'', children:[
          { title:'社团动态', path:'organizeTrends' },
          { title:'社团活动', path:'organizeActivity' },
          { title:'社团管理', path:'organizeManage' },
        ]},
        { title:'基础管理', icon:'', children:[
          { title:'用户管理', path:'userManagement' },
          { title:'校区管理', path:'campusManagement' },
          { title:'学校管理', path:'schoolManagement' },
          { title:'App用户管理', path:'appUser' },
          { title:'版本管理', path:'versionManage' },
          { title:'应用管理', path:'applyManage' },
          { title:'协议管理', path:'treatyManage' },
          { title:'引导页管理', path:'guideManage' },
          { title:'首页广告管理', path:'popManage' },
          { title:'关于我们', path:'aboutUs' },
        ]},
        { title:'系统管理', icon:'', children:[
          { title:'字典管理', path:'dictManagement' },
          { title:'菜单管理', path:'menuManagement' },
          { title:'角色管理', path:'roleManagement' },
          { title:'部门管理', path:'deptManagement' },
        ]}
      ],
      curMenu: "",
      menus:[],
      openeds:[]
    };
  },
  props:{
    subNav:{
      type: Array,
      default: [],
    },
    defaultOpened:{
      type: String,
      default: '',
    }
  },
  watch:{
    subNav(children){
      this.menus = children
    },
    defaultOpened(val){
      this.openeds = val ? [val] : []
      log('openeds',this.openeds)
    }
  },
  computed: {
    ...mapState(["isSidebarCollapsed", "currentTabPath"])
  },
  methods: {
    ...mapMutations(["setSidebarCollpased"]),
    ...mapActions("account", ["login","getUnloginCode", "userAjaxSsLogin"]),
    
    async toPageValite(path) {
      console.log(11111111111111111)
      console.log('path', path)
      if(path === '/xxpt'){
        let token = await this.getUnloginCode({})
        if(token){
          window.open('http://study.ahjxxy.cn/sso.jsp?token=' + token, '_blank')
        }else{
          this.$message.warning('未查询到token')
        }
        return;
      }
      if (!path || this.$route.path == path) return;
      this.$router.push(path);
    },
    collapseChange() {
      this.setSidebarCollpased(!this.isSidebarCollapsed);
    },
    checkActive(path) {
			return this.currentTabPath == path;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
$w180: 180px;
$w200: 200px;
$w84: 84px;
$w64: 64px;
.plat-slider {
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 0;
  width: $w180;
  background: #fff;
  font-size: 14px;
  transition: width 0.3s ease-in-out;
  z-index: 2000; //要比局部loading高，但是比弹出对话框低
  .plat-menu-box { overflow: hidden;
    .plat-menu {
      width:$w200;
      position: relative;
      height: 100%;
       padding-right:20px;
      overflow:auto;
      ul { width: $w180; }
			.el-menu { background:#fff; padding-top:2px; }
			.el-submenu__icon-arrow { width: 12px; }
			.el-submenu { margin-bottom:2px; 
				.el-menu-item{ min-width:auto; height:50px; line-height:50px;padding-left: 30px!important; }
				span { line-height:50px;}
			}
			.el-submenu__title { height: 50px; display: flex; align-items: center; color: #222; padding-left: 10px !important; background:#fff !important; }
      .el-icon { display:inline-block; width:40px; color:#222; text-align:center; line-height:50px; font-size:1.2em; }
      .el-menu-item { margin:2px 0; padding-left:10px !important; background: #fff; border-right:transparent 5px solid; }
			.el-menu-item:hover,.el-menu-item.is-active,.el-menu-item.is-active:hover { background: #dff5ef !important; border-right-color:#28b892; color:#28b892; }
			.el-submenu__title i { color: #222; }
    }
  }
  &.on { width:64px;
    .plat-menu-box {
      .plat-menu {
        width:$w84;
        ul { width: $w64; }
      }
    }
    .el-submenu__title { padding:0 !important; overflow:hidden; justify-content:center;
      .menu-name,.el-icon-arrow-right { display:none !important; }
    }
  }
  .navbar-inner {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 12px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    margin-top: -32px;
    cursor: pointer;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: block;
      content: "";
      border-bottom: 8px solid transparent;
      border-right: none;
      border-left: 12px solid #ccc;
      border-top: 8px solid transparent;
      transition: all 0.3s ease-in-out;
    }
    i {
      position: relative;
      z-index: 2;
      color: #222;
      transition: all 0.3s ease-in-out;
    }
    &:hover:before {
      border-left-color: #aaa;
    }
    &:hover i {
      color: #222;
      text-shadow: 0 0 2px #fff;
    }
  }
}
.el-menu--vertical .el-menu-item { height: 40px; line-height:50px;
  &:hover { background: #dff5ef !important; }
}
</style>
