/*
 * @Auhtor: 文锋
 * @Date: 2021-05-06 15:01:19
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-06 15:01:19
 * @FilePath: \operate\src\router\finance.js
 */
/* eslint-disable no-unused-vars */
const DealOrder = () => import ('@/components/finance/DealOrder')
const CommunOrder = () => import ('@/components/finance/CommunOrder')
const MallOrder = () => import('@/components/finance/MallOrder')
const Deposit = () => import ('@/components/finance/Deposit')
const Allocations = () => import('@/components/finance/Allocations')
const AllocationsEdit = () => import('@/components/finance/AllocationsEdit')
const CashCheck = () => import('@/components/finance/CashCheck')

export default {
  route:[
    {
      path: "dealOrder",
      name: "网络大厅订单",
      component: DealOrder
    },
    {
      path: "communOrder",
      name: "通信大厅订单",
      component: CommunOrder
    },
    {
      path: "mallOrder",
      name: "商城大厅订单",
      component: MallOrder
    },
    {
      path: "deposit",
      name: "提现管理",
      component: Deposit
    },
    {
      path: "allocations",
      name: "提现配置",
      component: Allocations
    },
    {
      path: "allocationsEdit",
      name: "提现配置 > 编辑提现配置",
      component: AllocationsEdit
    },
    {
      path: "cashCheck",
      name: "提现审核",
      component: CashCheck
    },
  ]
};
