/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 跳蚤市场列表
export const postFleaMarketList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/market/getPagesList",
    method: "post",
    data: params
  });
};
// 详情
export const postFleaMarketLoad = function(params) {
    return axios({
      url: BaseUrl + "app/market/loadDetail",
      method: "post",
      data: params
    });
  };

// 详情
export const postFleaMarketEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/market/edit",
    method: "post",
    data: params
  });
};
export default {
    postFleaMarketList,
    postFleaMarketLoad,
    postFleaMarketEdit
};
