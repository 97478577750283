<template>
  <!-- 广告位管理 -->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" :model="queryParams">
        <el-form-item class="area_img" v-if="!url">
          <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item style="marginLeft:50px" label="广告类型：">
          <el-select v-model="queryParams.type" @change="resetPageAndRefresh" clearable filterable placeholder="请选择广告类型">
            <el-option v-for="item in advStatusOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button size="small" icon="el-icon-plus" @click="handleForm(false)"  v-if="OA.includes('yygl:ggwgl:add')">新增</el-button>
          <el-button size="small"  v-if="OA.includes('yygl:ggwgl:editor')" @click="isEditor=!isEditor" :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
      </el-form>
      
      <!-- 列表 -->
      <div v-if="advTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="advList" :isEditor="isEditor" :total="advTotal" :span='12' @refresh="refresh">
        <template slot-scope="scope">
          <div class="con-head">
            <div class="area">
              <p class="con-txt">{{ scope.row.name?scope.row.name:'-' }}</p>
              <p class="mission">广告位</p>
            </div>
            <div class="area fr">
              <p class="con-txt" v-if="scope.row.type==1">图片</p>
              <p class="con-txt" v-if="scope.row.type==2">视频</p>
              <p class="con-txt" v-if="scope.row.type==3">红包</p>
              <p class="con-txt" v-if="scope.row.type==4">动态植入</p>
              <p class="con-txt" v-if="scope.row.type==5">代码引用</p>
              <p class="mission fr">广告类型</p>
            </div> 
          </div>
        </template>

        <template slot="control" slot-scope="scope">
          <el-button type="primary" @click="handleForm(true, scope.row,afterHandleForm)" icon="el-icon-edit" title="编辑"  v-if="OA.includes('yygl:ggwgl:editor')" circle></el-button>
          <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
          <el-button type="danger" v-if="OA.includes('yygl:ggwgl:del')" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" circle></el-button>
        </template>
      </paged-list>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑广告位' : '添加广告位'" :visible.sync="aVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="广告类型：" prop="type">
          <el-select class="w350" v-model="form.type" clearable filterable placeholder="请选择广告类型">
            <el-option v-for="item in advStatusOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告位：" prop="name">
          <el-input placeholder="请输入广告位名称" v-model="form.name" class="w350"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import datePicker from "@/mixins/datePicker";
import { mapState, mapActions } from "vuex";
import { log, mergeObject } from '@/common/utils'
/* eslint-disable no-unused-vars */
export default {
  name:'advPosition',
  components: {
    PagedList,
    AreaTree,
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      url:true,
      schoolType: '',
      /** 角色权限 */
      form: {
        id: null,
        type: null,
        name: "",
      },
      advStatusOptions: [
        { status: 1, lable: "图片" },
        { status: 2, lable: "视频" },
        { status: 3, lable: "红包" },
        { status: 4, lable: "动态植入" },
        { status: 5, lable: "代码引用" },
      ],
      formRules:{
        name: [{ required: true, message: '请输入广告位', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      },
      queryParams: {
        schoolId: null,
        type: null,
      },
      statusOptions: [],

      pageApi:'advertPositionList',
      insertApi: 'advertPositionAdd',
      editorApi: 'advertPositionEdit',
      deleteApi: 'advertPositionDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("advPosition", ["advList", "advTotal"]),
  },
  methods: {
    ...mapActions("commonOptions", ["getSchoolCombo"]),
    ...mapActions("advPosition", ["advertPositionList", "advertPositionAdd", "advertPositionEdit", "advertPositionDelete" ]),
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    async afterHandleForm(row){
      this.timeRange = [ row.beginOn, row.endOn ]
    },
  },
  async mounted() {
    this.schoolType = await this.getSchoolCombo({schoolName:''})
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
    this.refresh ()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    // .params-wrap { padding: 0;
    //   /deep/.el-input__icon{ height: auto; }
    //   .el-form-item { margin: 0 20px 0 0; }
    //   .area_img {
    //     /deep/.el-input__inner { padding-left: 30px !important; }
    //     .area_select { position: absolute; z-index: 10; top: 13px; left: 8px; }
    //   }
    // }
    .con-head{ 
      .area,.areas{display: inline-block; padding-right: 16px;
        .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
        .txtwidth{ width: 240px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
      }
      .areas{margin-right:0;}
    }
  }
}
.w350{ width: 350px !important; }
.w175{ width: 175px !important; }

.dialog-footer { margin-right: 100px;
  .el-button { width: 140px; margin-right: 20px; }
}
</style>