<template>
  <div>
    <!-- 成员管理 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.userName" clearable placeholder="请输入成员名称进行查询" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-col :span="4">
        <el-card class="box-card" style="margin-top:15px;margin-right:20px;min-height:700px">
          <div slot="header" class="clearfix">
            <span>区域名称</span>
          </div>
          <div class="area-tree">
            <el-tree v-if="dataTreeB" style="border-radius:4px" :data="dataTreeB" :props="defaultPropsB" @node-click="handleClick" default-expand-all ref="tree"></el-tree>
            <img v-else :src="require('@/assets/images/empty.jpg')" alt="" style="display:block;margin: 0 auto;width:100%">
          </div>
        </el-card>
      </el-col>
      <el-col :span="20">
        <div class="content-wrap">
          <div class="btn-wrap cfx">
            <el-button type="success" v-if="OA.includes('jcxx:cygl:add')" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
          </div>
          <div v-if="userTotal==0" class="totalNull"><img src="@/assets/images/bg.png" alt=""></div>
          <!--列表-->
          <paged-table
            v-if="userTotal>0"
            :data="userList"
            :total="userTotal"
            :loading="tLoading"
            :isShowSelection="false"
            defaultSortProp="id"
            v-model="queryParams"
            @refresh="refresh"
          > 
            <el-table-column sortable="custom" prop="extend.userName" show-overflow-tooltip label="成员名称"></el-table-column>
            <el-table-column sortable="custom" prop="extend.mobile" show-overflow-tooltip label="手机号"></el-table-column>
            <el-table-column sortable="custom" prop="extend.passport" show-overflow-tooltip label="账号"></el-table-column>
            <el-table-column sortable="custom" prop="extend.roleName" show-overflow-tooltip label="团队角色"></el-table-column>
            <el-table-column sortable="custom" prop="schoolName" show-overflow-tooltip label="学校名称" width="250"></el-table-column>
            <el-table-column sortable="custom" prop="fade" show-overflow-tooltip label="学院" width="200"></el-table-column>
            <el-table-column sortable="custom" prop="major" show-overflow-tooltip label="专业" width="200"></el-table-column>
            <el-table-column align="right" label="操作" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="OA.includes('jcxx:cygl:editor')"  size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
                <el-button  v-if="OA.includes('jcxx:cygl:del')"  size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
              </template>
            </el-table-column>
          </paged-table>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑用户' : '添加用户'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="form.mobile" @change="contant" clearable :maxlength="11" :disabled="isEditor"></el-input>
        </el-form-item>
        <el-form-item label="成员名称：" prop="userName">
          <el-input v-model="form.userName" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="团队角色：" prop="teamUserType">
          <el-select clearable filterable v-model="form.teamUserType">
            <el-option v-for="item in teamRoles" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属角色：" prop="userRoleIds">
          <el-select clearable filterable v-model="userRoleIds" multiple @change="form.userRoleIds = userRoleIds.toString()">
            <el-option v-for="item in roleOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'memberManagement',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, provinces],
  data() {
    return {
      queryParams: {
        name: null,
        province:'',
        city:'',
        schoolId:'',
        distId:''
      },

      dataTreeB: [],
      defaultPropsB: {
        children: "children",
        label: "name",
        value: "id",
      },
      userRoleIds:[],
      roleOptions:[],
      teamRoles:[],
      form:{
        id:'',
        mobile:'',
        userName:'',
        teamUserType:'',
        userRoleIds:'',
      },
      formRules:{
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        teamUserType: [{ required: true, message: '请选择团队角色', trigger: 'blur' }],
      },
      pageApi:'getTeamUserList',
      insertApi:'addTeamUser',
      editorApi:'editTeamUser',
      deleteApi:'deleteTeamUser',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicMember", ["userList", "userTotal"]),
  },
  methods: {
    ...mapActions("systemRole",["commonRoleAll"]),
    ...mapActions("systemDict",["commonDictGetDictFCode"]),
    ...mapActions("basicMember",["getTeamUserList", "addTeamUser", "deleteTeamUser", "editTeamUser","getSchoolUser"]),
    ...mapActions("basicApartment", [
      "membersSchoolGetSchoolDistTree",
    ]),
    handleClick(data){
      // 侧边栏
      console.log(data)
      if((data.id+'').length === 9){ this.queryParams.province = data.id}
      if((data.id+'').length === 12){ this.queryParams.city = data.id}
      // if((data.id+'').length === 15){ this.queryParams.area = data.id 
      // console.log("area",data.id)}
      if((data.id+'').length < 9){
        if(data.type == 5){ 
          this.queryParams.distId = data.id
        } else{
          this.queryParams.schoolId =data.id
        }
      }
      this.refresh()
    },
    // 获取侧边栏区域树
    async areaTree(){
      this.dataTreeB = await this.membersSchoolGetSchoolDistTree({code: "area_cn"})
      console.log('====', this.dataTreeB)
      this.membersDormitoryGetDormitoryTree({
        appId: 3,
        distId: this.dataTreeB[0].children[0].children[0].children[0].id,
      })
     // this.queryParams.schoolId = this.dataTreeB[0].children[0].children[0].children[0].id
    },
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png')
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleForm(isEditor = false, row, afterFun = null) {
      this.aVisible = true;
      this.isEditor = isEditor
      this.$nextTick(async () => {
        this.$refs.formFileds.resetFields();
        if (isEditor) {
          mergeObject(this.form,row)
          console.log(row)
          this.form.userName = row.extend.userName
          this.form.mobile = row.extend.mobile
          this.form.id = row.id
          this.userRoleIds = row.userRoleIds ? row.userRoleIds.split(',').map(item=>parseInt(item)) : ''
          log(this.userRoleIds)
        }
      })
    },
    async submitForm(api) {
      let params = Object.assign({}, this.form)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              await this[api](params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    async contant(val) {
      this.form.userName = ''
      try{
        const res = await this.getSchoolUser({mobile:val})
        this.form.userName = res.username
        this.form.id = res.id
      }catch(e){
      }
    },
    async teamRole() {
      const res = await this.commonDictGetDictFCode({code:'ceo_team_role'})

      this.teamRoles = res.filter(item=> item.name != 'CEO')
    }
  },
  async mounted() {
    // tree
    this.areaTree()
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    const saas = sessionStorage.getItem('schoolId')
    if(sessionStorage.getItem('appId') == 4) {
      this.roleOptions = await this.commonRoleAll({saas})
    }else {
      this.roleOptions = await this.commonRoleAll({})
    }
    this.cityChange(101001340010, this.form.area)
    // this.roleOptions = await this.commonRoleAll({})
    this.teamRole()
    log(this.userList)
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 0px 0px ;
}
</style>