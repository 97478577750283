<template>
  <div  class="wrap">
    <!-- 提现配置 -->
    <div class="box-con">
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryList">
        <el-form-item>
          <el-select v-model="queryList.type" @change="requireList()" filterable placeholder="请选择配置角色">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="CEO提现审核配置" :value="1"></el-option>
            <el-option label="商家提现审核配置" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button  style="margin-left: 20px;" type="success" @click="requireList()">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="select params-wrap">
        <!-- 列表 -->
        <div v-if="listAll.length==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
        <div class="box-head" v-for="(item1,index1) in listAll" :key="index1">
          <p>
            <span class="head_title">{{item1.type==1?"CEO":'商家'}}提现审核配置</span>
            <span class="headSpan">{{item1.type==1?"CEO":'商家'}}提现审核配置为未单独设置提现审核配置的提现审核配置</span> 
            <el-button class="fr" size="middle" @click="$router.push({path: '/allocationsEdit'})">编辑</el-button>
          </p>
          <div class="head_con">
            <div v-for="item2 in item1.list" :key="item2.id">
              <div class="conTit" v-if="item2.flag == 0">
                <div class="conTitMoney"><span>￥{{ item2.minMoney }}</span>~<span>￥{{ item2.maxMoney }}</span></div>
                <div class="conTitUser"><span class="duty">最终审核</span><span class="user">{{ item2.lastName }}</span></div>
              </div>
              <div class="conTit" v-if="item2.flag == 1">
                <div class="conTitMoney"><span>￥{{ item2.minMoney }}</span>~<span>￥{{ item2.maxMoney }}</span></div>
                <div class="conTitUser">
                  <span class="duty">一级审核</span>
                  <span class="user" v-if="item2.first == 102002001">CEO</span>
                  <span class="user" v-if="item2.first == 12">区域经理</span>
                  <span class="user" v-if="item2.first == 13">运营总监</span>
                </div>
                <div class="conTitUser">
                  <span class="duty">最终审核</span><span class="user">{{ item2.lastName }}</span>
                </div>
              </div>
              <div class="conTit" v-if="item2.flag == 2">
                <div class="conTitMoney"><span>￥{{ item2.minMoney }}</span>~<span>￥{{ item2.maxMoney }}</span></div>
                <div class="conTitUser">
                  <span class="duty">一级审核</span>
                  <span class="user" v-if="item2.first == 102002001">CEO</span>
                  <span class="user" v-if="item2.first == 12">区域经理</span>
                  <span class="user" v-if="item2.first == 13">运营总监</span>
                </div>
                <div class="conTitUser">
                  <span class="duty">二级审核</span>
                  <span class="user" v-if="item2.second == 102002001">CEO</span>
                  <span class="user" v-if="item2.second == 12">区域经理</span>
                  <span class="user" v-if="item2.second == 13">运营总监</span>
                </div>
                <div class="conTitUser"><span class="duty">最终审核</span><span class="user">{{ item2.lastName }}</span></div>
              </div>
              <div class="conTit" v-if="item2.flag == 3">
                <div class="conTitMoney"><span>￥{{ item2.minMoney }}</span>~<span>￥{{ item2.maxMoney }}</span></div>
                <div class="conTitUser">
                  <span class="duty">一级审核</span>
                  <span class="user" v-if="item2.first == 102002001">CEO</span>
                  <span class="user" v-if="item2.first == 12">区域经理</span>
                  <span class="user" v-if="item2.first == 13">运营总监</span>
                </div>
                <div class="conTitUser">
                  <span class="duty">二级审核</span>
                  <span class="user" v-if="item2.second == 102002001">CEO</span>
                  <span class="user" v-if="item2.second == 12">区域经理</span>
                  <span class="user" v-if="item2.second == 13">运营总监</span>
                </div>
                <div class="conTitUser">
                  <span class="duty">三级审核</span>
                  <span class="user" v-if="item2.third == 102002001">CEO</span>
                  <span class="user" v-if="item2.third == 12">区域经理</span>
                  <span class="user" v-if="item2.third == 13">运营总监</span>
                </div>
                <div class="conTitUser"><span class="duty">最终审核</span><span class="user">{{ item2.lastName }}</span></div>
              </div>
            </div>
            <!-- <div class="conTit">
              <div class="conTitUser"><span style="color: #889098;">配置描述</span><span class="user">配置描述配置描述配置描述</span></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
export default {
  name:'allocations',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      listAll: [],
      queryList: {
        type: null
      },
    };
  },
  computed: {
    ...mapState(["OA"]),
  },
  
  methods: {
    ...mapActions("allocations", [
      "settleWithdrawSelectAll",
      "settleWithdrawUpd",
    ]),
    async requireList(){
      let res = await this.settleWithdrawSelectAll(this.queryList)
      this.listAll = []
      let obj = { type: null, list: [] }
      res.forEach(ele => {
        if(ele.type == 1){
          obj.type = 1
          obj.list.push(ele)
        }
      });
      this.listAll.push(obj)
      obj = { type: null, list: [] }
      res.forEach(ele => {
        if(ele.type == 2){
          obj.type = 2
          obj.list.push(ele)
        }
      });
      this.listAll.push(obj)
      console.log(this.listAll)
    }
  },
  async mounted() {
    this.requireList()
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{ width: 100%; height: 100%;
  .box-con{ min-width: 50%; min-height: 100%; background:#fff;
  .box-head{ padding: 20px; background: #F5F7F9;overflow: hidden; margin-bottom: 20px;
    .head_title{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;margin-right: 30px; }
    .headSpan{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #889098;line-height: 14px; }
    .head_con{ margin-top: 30px;
      .conTit{ width: 100%; display: inline-block; line-height: 30px;
        .conTitMoney{ display: inline-block; width: 200px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
        .conTitUser{ display: inline-block;overflow:hidden;
          .duty{ color: #889098; margin: 0 0 0 20px;}
          .user{ margin: 0 20px; }
        }
      }
    }
  }

  }
}
</style>