<template>
  <div  class="wrap">
    <!-- 跳蚤市场 -->
    <div class="box-con">
      <el-form class="select btns params-wrap" size="small" :inline="true" :model="queryParams" label-width="100px">
            <el-form-item v-if="!url">
               <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
            </el-form-item> 
            <el-form-item label="商品状态:" >
              <el-select v-model="queryParams.flag" @change="refresh" clearable filterable placeholder="区域选择" style="width:260px;margin-right:20px">
                <el-option label="上架中" :value="1"  ></el-option>
                <el-option label="已卖出" :value="2" ></el-option>
                <el-option label="已下架" :value="3" ></el-option>
                <!-- <el-option label="已删除" :value="4" ></el-option>
                <el-option label="被删除" :value="5" ></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="时间：" class="ml20">
              <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParams'),refresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
            </el-form-item>
            <el-form-item label="内容：" class="ml20">
              <el-input v-model="queryParams.content" placeholder="请输入关键词"  clearable ></el-input>
            </el-form-item>
            <el-form-item >
              <el-button type="primary" size="small" @click="resetPageAndRefresh">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="isEditor=!isEditor"  v-if="OA.includes('yygl:tssc:del')"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '删除'}}</el-button>
            </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="fleaMarketTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" @refresh="refresh" :data="fleaMarketList" :total="fleaMarketTotal"  :span="12" :isEditor="isEditor">
         <template slot-scope="scope">
          <div class="list" @click="fetchFleaMarketLoad(scope.row.id)">
            <div class="top cfx">
              <div class="new-title fl">
                <p>{{ scope.row.title ? scope.row.title : '-'}}</p>
                <div class="txt">商品标题</div>
              </div>
              <div class="name fl">
                <p v-text=" scope.row.number? scope.row.number : 0  "> </p>
                <div class="txt">感兴趣(人)</div>
              </div>
              <div class="name fl cfx">
                <p>￥{{ scope.row.getPrice ? scope.row.getPrice : 0 }}</p>
                <div class="txt ">价格</div>
              </div>
              <div class="status fr cfx">
                <p v-if="scope.row.status == 0 && scope.row.tradeStatus==0">上架中</p>
                <p v-if="scope.row.status == 0 && scope.row.tradeStatus==1">已卖出</p>
                <p v-if="scope.row.status == 1" class="tinctgray" >已下架</p>
                <p v-if="scope.row.status == 2" class="tinctred">已删除</p>
                <p v-if="scope.row.status == 3" class="tinctred">被删除</p>
                <div class="txt fr">状态</div>
              </div>
            </div>
            <div class="content cfx ">
              <el-row :gutter="20">
                  <el-col :span="12" >
                        <div class="time"><span>发布时间</span><span class="con">{{ scope.row.addTime ? scope.row.addTime:'-' }}</span></div>
                  </el-col>
                  <el-col :span="12"  >
                        <div class="time"><span>发布者</span><span class="con">{{ scope.row.extend.userName ? scope.row.extend.userName : '-' }}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;">
                        <div class="time"><span>手机号码</span><span class="con">{{ scope.row.phone ? scope.row.phone:'-' }}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;">
                        <div class="time"><span>学校名称</span><span class="con">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;">
                        <div class="time">
                          <span>内容状态</span>
                          <span class="con" v-if="scope.row.status==0">上架中</span>
                          <span class="con" v-else-if="scope.row.status==1">已下架</span>
                          <span class="con" v-else-if="scope.row.status==2">用户删除</span>
                          <span class="con" v-else-if="scope.row.status==3">平台删除</span>
                          <span class="con" v-else>-</span>
                        </div>
                  </el-col>
              </el-row>
            </div>
          </div>
        </template>
        <template v-slot:control="{row}">          
          <el-button v-if="OA.includes('yygl:tssc:del')" type="danger"  @click="fleaDel(row.id)" icon="el-icon-delete" circle></el-button>
          <!-- <el-button type="danger"  @click="reportDelete(row.id)" icon="el-icon-delete" circle></el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="35%"
      >
        <div class="drawer ">
          <div class="title cfx">
              <div class="new-title fl">
                <p>{{details.addTime ?details.addTime:'-'}}</p>
                <div class="txt">发布时间</div>
              </div>
              <div class="new-title fl" v-if="details.status == 2">
                <p>{{details.updTime?details.updTime:'-'}}</p>
                <div class="txt">卖出时间</div>
              </div>
              <div class="status fr cfx">
                <p v-if="details.status == 0 && details.tradeStatus == 0" >上架中</p>
                <p v-if="details.status == 0 && details.tradeStatus == 1" >已卖出</p>
                <p v-if="details.status == 1" class="tinctgray" >已下架</p>
                <p v-if="details.status == 2" class="tinctred" >已删除</p>
                <p v-if="details.status == 3" class="tinctred" >已删除</p>
                <div class="txt fr">状态</div>
              </div>
          </div>
          <div class="content cfx ">
            <div class="puplish">
              <p class="puplish-name">发布人</p>
              <div class="puplish-con cfx">
                <div class="img fl">
                  <img :src="user.headImg" alt="">
                </div>
                <div class="name fl" v-if="user.extend">
                  <p>{{user.extend.userName?user.extend.userName:'-'}} <img :src="details.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></p>
                  <div class="txt" v-if="user.extend">{{user.extend.schoolName?user.extend.schoolName:'-'}}<span class="line">|</span>{{user.fade?user.fade:'-'}}</div>
                </div>
                <div class="name fl" v-if="user.extend">
                  <p>{{user.extend.mobile?user.extend.mobile:'-'}}</p>
                  <div class="txt">手机号码</div>
                </div>
                <div class="name fl" v-if="user.extend">
                  <p>{{user.extend.schoolName?user.extend.schoolName:'-'}}</p>
                  <div class="txt">学校名称</div>
                </div>
              </div>
              <div class="goods-con">
                <p>商品信息</p>
                <div class="goods-title">{{details.title?details.title:'-'}}</div>
                <div class="price-box cfx">
                  <div class="price-left fl">
                      <span class="price">￥{{details.getPrice ?details.getPrice:0}}</span><span class="price-old">￥{{details.sellingPrice ?details.sellingPrice:0}}</span>
                  </div>
                  <div class="people-right fr">
                    <span class="people fr">{{details.interestNumber ?details.interestNumber:0}}人感兴趣</span>
                  </div>
                </div>
                <div class="goods-text">{{details.content ?details.content:'-'}}</div>
                <el-row :gutter="20">
                  <viewer :images="detailImg">
                    <el-col :span="8" 
                      v-for="(item,index) in detailImg"
                      :key="index"
                    >
                          <div class="img">
                            <img :src="item" alt="">
                          </div>
                    </el-col>
                  </viewer>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";

export default {
  name:'fleaMarket',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      user:{},
      drawer:false,
      queryParams:{
        pageNum:1,
        pageSize:10,
        s1:'',
        s2:'',
        content:'',
        flag:''
      },
      details:{},
      detailImg:[],
      pageApi: "getFleaMarketList",
      loadApi: "getFleaMarketLoad",
      isEditor: false
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("fleaMarket", ["fleaMarketList", "fleaMarketTotal"]),
  },
  methods: {
    ...mapActions("fleaMarket", [
      "getFleaMarketList",
      "getFleaMarketLoad",
      "getFleaMarketEdit",
    ]),
    // 详情
    async fetchFleaMarketLoad(id){
      this.drawer = true
      const res = await this.getFleaMarketLoad({id})
      this.details = res.market
      this.user = res.user
      this.detailImg = res.market.pics.split(',')
      console.log( this.details);
    },
    // 逻辑删除
    async fleaDel(id){
      await this.getFleaMarketEdit({id, status: 3})
      this.isEditor = false
      this.refresh()
    },
    gotoAdd(){
      this.$router.push('schoolNewsAdd')
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
     this.refresh();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year {
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    // .btns{
    //   .el-button{width: 110px; margin-right: 20px;}
    // }
  }
  .list{ 
    .top{ border-bottom: 1px solid $c-border; padding-bottom: 20px;
      .new-title{width: 250px; height: 48px; 
        p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{ margin-left: 20px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .status{
         p{font-size: 20px;  color: $c-primary; font-weight: bold;}
         .gray{color: $c-9;}
        .txt{font-size: 14px; color: $c-9;}
      }
    }
    .content{ margin-top: 20px; 
        .time{
          span{font-size: 14px; color: $c-9; }
          .con{font-size: 14px; color: $c-2; margin-left: 10px;}
        }
    }
  }
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/ .el-drawer__header{ margin-bottom: 0px;}
  .drawer{ padding: 0px 20px;
    .title{height: 84px;  background: #F5F7F9; margin-top: 20px; padding: 10px 20px 1px 20px;
      .new-title{ width: 190px;
        p{font-size: 16px; height: 38px; line-height: 38px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{  width: 120px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .status{ 
        p{font-size: 20px;  color:$c-primary; font-weight: bold;}
        .gray{color: $c-9;}
        .txt{font-size: 14px; color: $c-9;}}
    }
    .content{ margin: 20px 0px;
      .puplish{
        .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
        .puplish-con{ 
          .img{width:44px; height: 44px; overflow: hidden;  border-radius: 50%; margin-right: 10px;
            img{width:44px; height: 44px;}
          }
          .name{   width: 140px;
            p{font-size: 16px;  color: $c-2; font-weight: bold;
              img{margin: 6px 0px 0px 5px;}
            }
            .txt{font-size: 12px; color: $c-9; font-weight: 400;
              .line{margin: 0px 5px;}
            }
          }
        }
        .goods-con{ margin-top: 20px;
          p{color: &c-2; font-weight:bold; margin-bottom: 10px;}
          .goods-title{font-size: 18px; font-weight:bold; margin-bottom: 10px;}
          .text{font-size: 14px; font-weight:400;}
          .price-box{
            .price-left{height: 40px; width: 250px; 
              .price{color: #FF5353; font-size: 20px; margin-right: 20px;}
              .price-old{color: $c-9; font-size: 14px; text-decoration:line-through;}
            }
            .people-right{height: 40px;width: 200px;  line-height: 40px;
              .people{color: $c-9; font-size: 14px;}
            }
          }
          .goods-text{font-size: 14px; font-weight: 400px;}
          .img{height: 150px; margin-top: 10px; 
            img{width: 150px; height: 150px;}
          }
           
        }
      }
    }  
  } 
}
</style>