<template>
  <div>
    <!-- 地区级联选择 -->
    <el-cascader
      v-model="value"
      :options="dataTree"
      ref="cascader"
      :placeholder="placeholder"
      :props="{ checkStrictly: true, ...defaultProps }"
      @change="handleClick"
      clearable
      :size="size"
      :disabled="disabled"
      @clear="$emit('clear')"
      ></el-cascader>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { commonDictGetAllDictTreeFCode } from "@/api/system/dict";
import { log, mergeObject } from '@/common/utils'
import pageMixin from "@/mixins/pageMixin";
import { mapState, mapActions } from "vuex";
export default {
  name: "AreaTree",
  props: {
    size: {
      default: 'small',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '请选择区域',
      type: String,
    }
  },
  mixins: [pageMixin],
  data() {
    return {
      dataTree: [],
      value: null,
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
      },
    };
  },
  created() {
    this.areaTree("area_cn")
  },
  methods: {
    // ...mapActions("systemDict",[ "commonDictGetAllDictTreeFCode" ]),
    ...mapActions("commonOptions",[ "getSchoolTree" ]),
    // 获取侧边栏区域树
    async areaTree(val){
      this.dataTree = [];
      if(!val) return;
      this.dataTree = await this.getSchoolTree({})
    },
    // 树型节点查询
    handleClick(data, node) {
      this.$emit('setQueryParams', data)
      console.log("areaTree",data)
      this.$refs.cascader.dropDownVisible = false;
    },
    resetValue(){
      this.value = []
    }
  }
};
</script>
<style scoped lang="scss">
</style>