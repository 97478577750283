<template>
  <div>
    <!-- 通信大厅 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
          <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
      <el-form-item>
        <el-select v-model="queryParams.operator" @change="resetPageAndRefresh" filterable placeholder="请选择运营商">
          <el-option key="1" value="">全部</el-option>
          <el-option key="2" value="中国电信">中国电信</el-option>
          <el-option key="3" value="中国移动">中国移动</el-option>
          <el-option key="4" value="中国联通">中国联通</el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.name" clearable placeholder="请输入商户名称/商品名称/订单号" @change="resetPageAndRefresh" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
        <div class="content-wrap">
      <div class="flex">
        <div class="orderSummary">
          <span>总营业额（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.total' :duration='3000' prefix="￥"></countTo>
        </div>
        <div class="orderSummary">
          <span>总提点（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.divide' :duration='3000' prefix="￥"></countTo>
        </div>
        <div class="orderSummary">
          <span>总收入（元）</span>
          <countTo :startVal='0' :decimals='2' :endVal='orderSummary.income' :duration='3000' prefix="￥"></countTo>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="btn-wrap cfx">
      </div>
      <div v-if="dealOrderTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
     <paged-table
      v-if="dealOrderTotal>0"
      :data="dealOrderList"
      :total="dealOrderTotal"
      :loading="tLoading"
      :isShowSelection="false"
      defaultSortProp="id"
      v-model="queryParams"
      @refresh="refresh"
    >
        <el-table-column width="200" prop="extend.userName" label="用户" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column width="200" prop="account" label="学号" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column width="200" prop="extend.schoolName" label="学校" :show-overflow-tooltip="true" > </el-table-column>
        <el-table-column width="200" prop="shopName" label="店铺名称" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column width="200" prop="operator" label="运营商" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column width="200" prop="orderType" label="商品分类" :show-overflow-tooltip="true">          
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">套餐充值</span>
            <span v-if="scope.row.orderType == 2">套餐续订</span>
            <span v-if="scope.row.orderType == 3">套餐升级</span>
            <span v-if="scope.row.orderType == 4">套餐报停</span>
            <span v-if="scope.row.orderType == 5">套餐复通</span>
            <span v-if="scope.row.orderType == 6">套餐变更</span>
            <span v-if="scope.row.orderType == 7">手机号购买</span>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="content" label="商品" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="200" prop="orderStatus" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">等待支付</span>
          <span v-if="scope.row.orderStatus == 1">交易成功</span>
          <span v-if="scope.row.orderStatus == 2">已退款</span>
          <span v-if="scope.row.orderStatus == 4">交易关闭</span>
        </template>
        </el-table-column>
        <el-table-column width="200" prop="orderNum" label="订单号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="200" prop="oriPrice" label="价格（元）" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.oriPrice| money}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="payPrice" label="实付" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{scope.row.payPrice| money}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="oriPrice" label="商家提点（元）" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.businessTips && (scope.row.businessTips/100).toFixed(2)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="payPrice" label="收入（元）" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <div>
                {{scope.row.income && (scope.row.income/100).toFixed(2)}}
              </div>
            </template>
        </el-table-column>
        <el-table-column width="200" prop="payType" label="支付方式" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.payType == 1">微信支付</span>
          <span v-if="scope.row.payType == 2">支付宝</span>
          <span v-if="scope.row.payType == 3">余额支付</span>
          <span v-if="scope.row.payType == 4">线下支付</span>
        </template>
        </el-table-column>
        <el-table-column width="200" fixed="right" prop="payTime" label="支付时间" :show-overflow-tooltip="true"></el-table-column>
      </paged-table>
    </div>

  
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import datePicker from "@/mixins/datePicker";
import countTo from 'vue-count-to'
import { log, mergeObject,moneyType } from '@/common/utils'
export default {
  name:'commOrder',
  components: {
    PagedTable,
    countTo
  },
  mixins: [pageMixin, provinces,datePicker],
  data() {
    return {
      queryParams: {
        name: null,
        startDate: '',
        endDate: '',
        content:'',
        operator:'',
        hallType:1,
      },
      userRoleIds:[],
      roleOptions:[],
      teamRoles:[],
      orderSummary:{},
      pageApi:'getDealOrderList',
     
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("communOrder", ["dealOrderList", "dealOrderTotal"]),
  },
  filters:{
money(val){
  return moneyType(val)
}
  },
  methods: {
    ...mapActions("communOrder",[ "getDealOrderList", "payAlipayRefund", "payWxpayRefund", "payYepayRefund", "getOrderSummary"]),

 
  },
  async mounted() {
    this.orderSummary = await this.getOrderSummary(this.queryParams)
    this.refresh()
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.orderSummary {
  display: flex;
  margin: 0 40px;
  flex-direction: column;
  span{
    font-size: 20px;
    font-family: DIN-Black, DIN;
    &:last-child {
      color: #FF5353;
      font-size: 40px;
      font-weight: 900;
    }
  }
}
</style>