<template>
  <!-- CEO-任务分解 @需要改仅为静态页 -->
  <div class="wrap">
    <div class="box-con">
      <el-tabs v-model="active" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="任务分解" name="1"></el-tab-pane>
      </el-tabs>

      <!-- 常规任务 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="form">
        <el-form-item>
          <el-checkbox class="check-use" v-model="checked">通用设置</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-select class="btn-task" label-width="150px" v-model="form.status" placeholder="请选择分配人">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form class="form-task" size="small" :model="form" v-if="0">
        <el-form-item>
          <div class="form-title">通用任务</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="营业额" :value="1"></el-option>
              <el-option label="订单数" :value="2"></el-option>
              <el-option label="访客数" :value="3"></el-option>
            </el-select>
            <el-input  class="form-input" placeholder="请输入数值">
              <div slot="append">剩余：6100616</div>
            </el-input> 元
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addCurrent" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteCurrent(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">推广任务</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="运营商" :value="1"></el-option>
              <el-option label="考证培训" :value="2"></el-option>
              <el-option label="餐饮培训" :value="3"></el-option>
              <el-option label="大型超市" :value="3"></el-option>
              <el-option label="广告招商" :value="3"></el-option>
            </el-select>
            <el-input  class="form-input" placeholder="请输入数值">
              <div slot="append">剩余：6100616</div>  
            </el-input> 家
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addSpread" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteSpread(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">对接应用</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="对接一卡通" :value="1"></el-option>
              <el-option label="对接教务系统" :value="2"></el-option>
              <el-option label="对接共享服务" :value="3"></el-option>
              <el-option label="社团招募" :value="3"></el-option>
              <el-option label="对接其他应用" :value="3"></el-option>
              <el-option label="线下举办活动" :value="3"></el-option>
            </el-select>
            <el-input  class="form-input" placeholder="请输入数值">
              <div slot="append">剩余：6100616</div>  
            </el-input> 个
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addButt" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteButt(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">用户服务</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="客户服务数" :value="1"></el-option>
              <el-option label="上门服务数" :value="2"></el-option>
              <el-option label="商务合作维护" :value="3"></el-option>
            </el-select>
            <el-input  class="form-input" placeholder="请输入数值">
              <div slot="append">剩余：6100616</div>  
            </el-input> 个
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addSpread" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteSpread(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">用户服务</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            <el-select class="form-select" v-model="form.month">
              <el-option label="校园动态" :value="1"></el-option>
              <el-option label="校园新闻" :value="2"></el-option>
              <el-option label="易货空间" :value="3"></el-option>
              <el-option label="失物招领" :value="3"></el-option>
              <el-option label="校园兼职" :value="3"></el-option>
              <el-option label="小赚一笔" :value="3"></el-option>
            </el-select>
             发布数<el-input class="form-input" placeholder="请输入数值"><div slot="append">剩余：6100</div></el-input>
             浏览数<el-input class="form-input" placeholder="请输入数值"><div slot="append">剩余：6100</div></el-input>
             点赞数<el-input class="form-input" placeholder="请输入数值"><div slot="append">剩余：6100</div></el-input>
             评论数<el-input class="form-input" placeholder="请输入数值"><div slot="append">剩余：6100</div></el-input>
             分享数<el-input class="form-input" placeholder="请输入数值"><div slot="append">剩余：6100</div></el-input>&nbsp;
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addBusiness" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteBusiness(index)" />
          </div>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button size="small" class="sub-btn" @click="$router.push({path: '/myTask'})">取消</el-button>
          <el-button size="small" class="sub-btn" type="primary" @click="$router.push({path: '/myTask'})">确定</el-button>
        </el-form-item>
      </el-form>

      <el-form class="form-task" size="small" :model="form" v-if="1">
        <el-form-item>
          <div class="form-title">任务一</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            当 <el-select class="form-select" v-model="form.month">
              <el-option label="营业额" :value="1"></el-option>
              <el-option label="访客数" :value="2"></el-option>
            </el-select>
            每超出 <el-input  class="form-input" placeholder="请输入数值"></el-input> 元 
            <el-select class="form-select" v-model="form.month">
              <el-option label="现金奖励" :value="1"></el-option>
              <el-option label="提高分成比例" :value="2"></el-option>
            </el-select>
            每超出 <el-input  class="form-input" placeholder="请输入数值"></el-input> 元%
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addAwardTaskOne" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteAwardTaskOne(index)" />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-title">任务二</div>
          <div v-for="(item,index) in nameRoomArr" :key="index" class="for-task">
            当 <el-select class="form-select" v-model="form.month">
              <el-option label="运营商" :value="1"></el-option>
              <el-option label="考证培训" :value="2"></el-option>
            </el-select>
            每超出 <el-input  class="form-input" placeholder="请输入数值"></el-input> 家 
            <el-select class="form-select" v-model="form.month">
              <el-option label="现金奖励" :value="1"></el-option>
              <el-option label="提高分成比例" :value="2"></el-option>
            </el-select>
            每超出 <el-input  class="form-input" placeholder="请输入数值"><div slot="append">剩余：610</div></el-input> 元%
            <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index == 0" @click="addAwardTaskOne" />
            <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index > 0" @click="deleteAwardTaskOne(index)" />
          </div>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button size="small" class="sub-btn" @click="$router.push({path: '/myTask'})">取消</el-button>
          <el-button size="small" class="sub-btn" @click="$router.push({path: '/myTask'})">暂存</el-button>
          <el-button size="small" class="sub-btn" type="primary" @click="$router.push({path: '/myTask'})">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MemberTask from "@/components/task/myTask/MemberTask";
import DirectorTask from "@/components/task/myTask/DirectorTask";
export default {
  name:'directorDistributeTask',
  components: {
    MemberTask,
    DirectorTask
  },
  data() {
    return {
      activeQuery: 0,
      form: {
        nameRoom: '',
      },
      checked: true,
      statusOptions: [],
      active:'1',
      btnActive: 1,
      nameRoomArr: [''],
    };
  },
  computed: {
  },
  methods: {
    async handleClick(tab, event) {
      console.log(tab)
    },
    addCurrent(){
      if(this.nameRoomArr.length<3) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteCurrent(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addSpread(){
      if(this.nameRoomArr.length<5) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteSpread(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addButt(){
      if(this.nameRoomArr.length<6) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteButt(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addService(){
      if(this.nameRoomArr.length<3) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteService(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addBusiness(){
      if(this.nameRoomArr.length<6) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteBusiness(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    addAwardTaskOne(){
      if(this.nameRoomArr.length<6) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteAwardTaskOne(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
  },
  async mounted() {
    
  }
};
</script>
<style lang="scss" scoped>
.wrap{ width: 100%; background: #fff; width: 100%; padding: 10px 20px 20px;
  .box-con{ width: 100%; min-height: 100%; background:#fff;
    .tabs{ width: 100%; background:#fff;padding-top: 0;
      /deep/.el-tabs__item { font-size: 18px; font-weight: bold; }
    }
    .params-wrap { padding: 15px 15px 0px 0px; 
      .check-use { border: 1px solid #DCDFE6; border-radius: 3px; padding: 0px 15px;     width: 150px; height: 32px;
        /deep/.el-checkbox__label { margin-left: 30px; }
      }
      .btn-task { width: 150px; height: 32px; font-size: 14px; }
      .btn-active { color: #28B892; border-color: #28B892; outline: 0; background-color: rgba(40, 184, 146, 0.1); }
    }
    .form-task { margin-bottom: 30px;
      .form-title { font-size: 18px; font-weight: bold; height: 18px; line-height: 18px; margin-top: 20px; }
      .for-task { display: inline-block; margin: 20px 20px 0 0;
        .form-select { width: 125px; }
        .form-input { width: 160px; margin-left: 10px; }
        /deep/.el-input-group__append { padding: 0 10px; }
        .add-icon { vertical-align: middle; cursor: pointer; }
      }
      .sub-btn { width: 160px; height: 40px; margin-top: 30px; margin-right: 20px; } 
    }
  }
}
</style>
