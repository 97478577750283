/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 学校列表 */
export const membersSchoolSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/school/selectAll",
    method: "post",
    data: params
  });
};

/** 专业列表 */
export const taskGetSubjects = function(params) {
  return axios({
    url: BaseUrl + "task/getSubjects",
    method: "post",
    data: params
  });
};

/** 学习任务列表 */
export const taskGetCourses = function(params) {
  return axios({
    url: BaseUrl + "task/getCourses",
    method: "post",
    data: params
  });
};

/** 考试任务列表 */
export const taskGetExams = function(params) {
  return axios({
    url: BaseUrl + "task/getExams",
    method: "post",
    data: params
  });
};

/** 详情 */
export const taskLoad = function(params) {
  return axios({
    url: BaseUrl + "task/load",
    method: "post",
    data: params
  });
};

/** 我的课程 */
export const taskGetMyCourse = function(params) {
  return axios({
    url: BaseUrl + "task/getMyCourse",
    method: "post",
    data: params
  });
};

/** 我的考试 */
export const taskGetMyExam = function(params) {
  return axios({
    url: BaseUrl + "task/getMyExam",
    method: "post",
    data: params
  });
};

/** 学习中心统计 */
export const taskGetMPNum = function(params) {
  return axios({
    url: BaseUrl + "task/getMPNum",
    method: "post",
    data: params
  });
};

export default {
  membersSchoolSelectAll,
  taskGetSubjects,
  taskGetCourses,
  taskGetExams,
  taskLoad,
  taskGetMyCourse,
  taskGetMyExam,
  taskGetMPNum,
};
