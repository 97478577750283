<!--
 * @Auhtor: 文锋
 * @Date: 2021-05-24 11:34:10
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-26 10:59:59
 * @FilePath: \operate\src\components\operate\Schooltopic.vue
-->
<template>
  <div class="wrap">
    <div class="box-con">
      <el-form
        class="select params-wrap"
        size="small"
        :inline="true"
        :model="queryParams"
      >
        <el-form-item v-if="!url">
          <area-tree
            @setQueryParams="handleQuery"
            class="year"
            @clear="clearArea(true)"
          ></area-tree>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryParams.status"
            placeholder="请选择状态"
            clearable
            @clear="clearArea(true)"
            @change="refresh()"
          >
            <el-option :value="1" label="开启"></el-option>
            <el-option :value="0" label="关闭"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入话题名称"
            clearable
            v-model="queryParams.name"
            style="width: 300px"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="refresh">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
            <el-button @click="aVisible = true,formData = {},isEditor = false,topicBackStatus = 0" size="small" icon="el-icon-plus" style="color:#28B892; border-color:#28B892;" v-if="OA.includes('xyq:qbht:add')">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div class="list">
           <div v-if="schooltopicTotal == 0" class="totalNull" >
            <img src="../../assets/images/bg.png" alt="" />
          </div>
          <paged-table
            v-if="schooltopicTotal>0"
            :data="schooltopicList"
            :total="schooltopicTotal"
            :loading="tLoading"
            :isShowSelection="false"
            defaultSortProp="id"
            v-model="queryParams"
            @refresh="refresh"
          >
            <el-table-column sortable="custom" prop="name" show-overflow-tooltip label="话题名称"></el-table-column>
            <el-table-column sortable="custom" prop="updTime" show-overflow-tooltip label="创建时间"></el-table-column>
            <el-table-column sortable="custom" prop="digest" show-overflow-tooltip label="内容"></el-table-column>
            <el-table-column sortable="custom" prop="viewNum" show-overflow-tooltip label="浏览量"></el-table-column>
            <el-table-column sortable="custom" prop="issueNum" show-overflow-tooltip label="讨论数"></el-table-column>
            <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" align="center">
              <template slot-scope="scope">
                <div>
                  <el-tag type="success" v-if="scope.row.status == 1" @click.native="changeStatus(scope.row.id,scope.row.status)">开启</el-tag>
                  <el-tag type="danger" v-else @click="changeStatus(scope.row.id,scope.row.status)">关闭</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" show-overflow-tooltip label="操作" align="center">
               <template slot-scope="scope">
                 <div>
                    <el-button class="operate" type="text" v-if="OA.includes('xyq:qbht:edit')" @click="topicInfo(scope.row.id,scope.row.status)">编辑</el-button>
                    <el-button class="operate" type="text" @click="bVisible = true,topicInfo2(scope.row.id)">详情</el-button>
                    <el-button class="operate" type="text" v-if="OA.includes('xyq:qbht:delete')" @click="deletetopic(scope.row.status,scope.row.id)">删除</el-button>
                 </div>
               </template>
            </el-table-column>
          </paged-table>
      </div>
      <!-- 新建or编辑 -->
      <el-dialog :title="isEditor?'编辑话题':'创建话题'" center :visible.sync="aVisible" width="700px">
        <el-form :model="formData" label-width="160px" :rules="formRules" ref="ruleForm">
          <el-form-item label="话题名称：" prop="name">
            <el-input class="w410" v-model="formData.name" placeholder="给话题去一个好听的名称" maxlength="15" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="话题描述：" prop="digest">
            <el-input class="w410" v-model="formData.digest" type="textarea" placeholder="给话题加一段描述，吸引更多用户参加"  maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="话题背景：">
            <el-select class="w410" v-model="topicBackStatus"  placeholder="请选择背景类型" clearable >
              <el-option v-for="(item,index) in topicBack" :value="item.value" :label="item.name" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="topicBackStatus == 1">
            <base-upload v-model="formData.background" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
            <div class="txt">建议尺寸：750X464,支持 JPG/PNG/JPEG 格式</div>
          </el-form-item>
          <el-form-item label="话题状态：" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item >
            <el-button class="submit" @click="isEditor?edittopic():addtopic('ruleForm')">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 详情 -->
      <el-dialog title="详情" center :visible.sync="bVisible" width="700px">
        <el-form :model="formData" label-width="160px">
          <el-form-item label="话题名称：">
            <el-input class="w410" disabled v-model="formData.name" placeholder="给话题去一个好听的名称" maxlength="15" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="话题描述：">
            <el-input class="w410" disabled v-model="formData.digest" type="textarea" placeholder="给话题加一段描述，吸引更多用户参加"  maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="话题背景：">
            <img :src="formData.background" alt="" style="width:375px;height:232px">
          </el-form-item>
          <el-form-item label="话题状态：">
            <el-tag type="success" v-if="formData.status == 1">开启</el-tag>
            <el-tag type="danger" v-else>关闭</el-tag>
          </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 提示 -->
      <el-dialog title="提示" center :visible.sync="cVisible" width="500px">
        <p class="tips">当前话题正在开启中 请关闭后在进行相关操作</p>
        <div class="edit">

          <el-button class="btn submit" @click="cVisible = false">确定</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>
import { mapState,mapActions } from 'vuex'
import AreaTree from "@/components/common/AreaTree";
import PagedTable from "@/components/common/PagedTable"
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";

export default {
  name: "schooltopic",
  mixins: [pageMixin],
  components: {
    AreaTree,
    PagedTable,
    BaseUpload
  },
  data() {
    return {
      url:'',
      isEditor:false,
      aVisible:false,
      bVisible:false,
      cVisible:false,
      queryParams: {},
      formData:{},
      topicBackStatus:0,
      topicBack:[
        {
          value:0,
          name:'默认背景'
        },
        {
          value:1,
          name:'自定义背景'
        },
      ],
      formRules:{
        name:[{ required: true, message: '请输入话题名称', trigger: 'blur' }],
        digest:[{ required: true, message: '请输入话题描述', trigger: 'blur' }],
        status:[{ required: true, message: '请选择话题状态', trigger: 'change' }],
      },
      pageApi:'getSchooltopicList'
    };
  },
  computed:{
    ...mapState(["OA"]),
    ...mapState('schoolFellow',['schooltopicList','schooltopicTotal'])
  },
  methods: {
    ...mapActions('schoolFellow',['getSchooltopicList','addSchooltopic','schooltopicInfo','editSchooltopic','upOrDowntopic','deleteSchooltopic','selectRepeat']),
    // 新增话题
    addtopic(formName) {

       this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // 查询
            let topic = await this.selectRepeat({name:this.formData.name})
            
            if (topic != 'OK') {
              this.$message.wraning('创建话题名称重复!')
              this.formData.topicId = null
            }else{
              if (this.topicBackStatus == 0) {
                this.formData.background = 'https://file.ahqmhl.cn/image/20210524/202105241707558505.png'
              }
              let res = await this.addSchooltopic(this.formData)
              if (res != null) {
                this.aVisible = false
                this.$message.success('创建话题成功')
                this.refresh()
              }
            }
          } else {
            return false
          }
        });
    },
    // 话题详情
    async topicInfo(id,status) {
      if (status == 1) {
        this.cVisible = true
      }else{
        this.aVisible = true,
        this.isEditor = true,
        this.formData = await this.schooltopicInfo({id})
        if (this.formData.background != 'https://file.ahqmhl.cn/image/20210524/202105241707558505.png') {
          this.topicBackStatus =1
        }
      }
    },
    //
    async topicInfo2(id) {
        this.formData = await this.schooltopicInfo({id})
        if (this.formData.background != 'https://file.ahqmhl.cn/image/20210524/202105241707558505.png') {
          this.topicBackStatus =1
        }
    },
    // 修改话题
    async edittopic() {
      let params = {
        id:this.formData.id,
        name: this.formData.name,
        digest: this.formData.digest,
        background: this.formData.background,
        status: this.formData.status
      }
      let res = await this.editSchooltopic(params)
      if (res == null) {
        this.$message.success('编辑话题成功')
        this.aVisible = false
      }
    },
    // 开启关闭
    async changeStatus(id,status) {
      let res
      if (status == 1) {
         res = await this.upOrDowntopic({id,status:0})
      }else{
         res = await this.upOrDowntopic({id,status:1})
      }
      if (res != null) {
        this.$message.success('状态更新成功')
        this.refresh()
      }
    },
    // 删除话题
    deletetopic(status,id) {
      if (status == 1) {
        this.cVisible = true
      }else{
        this.$confirm('此操作将永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          let res = await this.deleteSchooltopic({id})
          if (res == null) {
            this.$message.success('删除话题成功')
            this.refresh()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
    },
    // 上传
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('jpeg')
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG/JPEG 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      callback(isPicture && isLt10M)
    },
    // 区域查询
     handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
      },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      this.queryParams.status = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.refresh()
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
  },
};
</script>
<style lang="scss" >
@import "@/assets/scss/theme.scss";
* {
  font-family: PingFangSC-Regular, PingFang SC;
}
.wrap {
  width: 100%;
  height: 100%;
  .box-con {
    width: 100%;
    min-height: 100%;
    background: #fff;
    padding: 20px;
    .select {
      .year {
        /deep/.el-input__inner {
          background: url("../../assets/images/he/operate/area.svg") no-repeat;
          background-size: 12px 14px;
          background-position: 8px 7px;
          padding: 0 0 0 30px;
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list {
      margin:0 20px;
      .operate{
        color: $c-primary;
        margin: 0 20px;
        border-bottom: 1px solid $c-primary;
        text-decoration-line: underline;
      }
    }
    .w410 {width: 410px;}
    .submit{color: #fff;background-color: $c-success;width: 300px;}
    /deep/.components-upload .avatar-uploader-icon {width: 240px; height: 135px; color: $c-success; font-size: 30px; font-weight: bold;}
    .txt{color: #AFAFAF;}
    .tips {text-align: center;font-size:16px ;margin: 20px 0 40px;}
    .edit {
      display: flex;
      justify-content: center;
      .btn {
        width: 140px;
        background-color: #fff;
        &.submit {
          color: #fff;
          background-color: $c-success;
        }
      }
    }
  }
}
</style>