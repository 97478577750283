<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- 举报管理 -->
      <el-form class="params-wrap select" size="small" :inline="true"  :model="queryParams" label-width="100px">
        <el-row>
          <el-form-item v-if="!url" label='区域:'>
            <area-tree ref="cascader" @setQueryParams="handleQuery" class="year" @clear="clearArea(true)" style="width:230px"></area-tree>
          </el-form-item>
          <el-form-item label="处理状态:">
            <el-select v-model="queryParams.status" @change="resetPageAndRefresh" clearable filterable placeholder="请选择处理状态" style="width:230px">
              <el-option label="全部" value="" ></el-option>
              <el-option label="待处理" :value="0" ></el-option>
              <el-option label="核实不实" :value="1" ></el-option>
              <el-option label="核实属实" :value="2"  ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属功能:">
            <el-select v-model="queryParams.type" @change="resetPageAndRefresh" clearable filterable placeholder="请选择所属功能" style="width:230px">
              <el-option label="全部" value="" ></el-option>
              <el-option label="失物招领" :value="1" ></el-option>
              <el-option label="校园兼职" :value="2"  ></el-option>
              <el-option label="跳蚤市场" :value="3" ></el-option>
              <el-option label="小赚一笔" :value="4" ></el-option>
              <el-option label="校友圈" :value="5" ></el-option>
              <el-option label="校园新闻" :value="6" ></el-option>
              <el-option label="社团动态" :value="7" ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="时间:" class="ml20">
            <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParams'),refresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
          </el-form-item>
          <el-form-item label="内容:" class="ml20">
            <el-input style="width:230px" v-model="queryParams.content" placeholder="请输入关键词"  clearable ></el-input>
          </el-form-item>
          <el-form-item style="margin-left:40px">
            <el-button type="primary" size="small" @click="resetPageAndRefresh">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="isEditor=!isEditor"  v-if="OA.includes('yygl:jbgl:del')"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '删除'}}</el-button>
            <!-- <el-button @click="isEditor=!isEditor"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '删除'}}</el-button> -->
          </el-form-item>
        </el-row>
      </el-form>
      <!-- 列表 -->
      <div v-if="reportTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="reportList" :isEditor="isEditor" :total="reportTotal" @refresh="refresh" :span="12">
        <template slot-scope="scope">
          <div class="list">
            <div class="list" @click="detail(scope.row.id)">
              <div class="con-head">
                <div class="area">
                  <p class="con-txt" v-if="scope.row.type==1">失物招领</p>
                  <p class="con-txt" v-if="scope.row.type==2">校园兼职</p>
                  <p class="con-txt" v-if="scope.row.type==3">跳蚤市场</p>
                  <p class="con-txt" v-if="scope.row.type==4">小赚一笔</p>
                  <p class="con-txt" v-if="scope.row.type==5">校友圈</p>
                  <p class="con-txt" v-if="scope.row.type==6">校园新闻</p>
                  <p class="con-txt" v-if="scope.row.type==7">社团动态</p>
                  <p class="mission">举报功能</p>
                </div>
                <div class="area">
                  <p class="con-txt txtwidth" :title="scope.row.title">{{scope.row.title?scope.row.title:'-'}}</p>
                  <p class="mission">举报标题</p>
                </div>
                <div class="areas fr">
                  <p class="con-txt tinctblue" v-if="scope.row.status==0" >待处理</p>
                  <p class="con-txt tinctred" v-if="scope.row.status==1" >核实不实</p>
                  <p class="con-txt tinctgreen" v-if="scope.row.status==2" >核实属实</p>
                  <p class="mission fr">处理结果</p>
                </div>
                <div class="area fr">
                  <p class="con-txt twidth" :title="scope.row.reason">{{scope.row.reason?scope.row.reason:'-'}}</p>
                  <p class="mission">举报原因</p>
                </div> 
              </div>
              <div class="con-con cfx">
                <div class="time-fl fl">
                  <div class="time">
                    <span class="contit">内容状态</span>
                    <span class="condel">{{scope.row.condition?scope.row.condition:'-'}}</span>
                  </div>
                  <div class="time">
                    <span class="contit">举报人</span>
                    <span class="condel">{{scope.row.extend.addUserName?scope.row.extend.addUserName:'-'}}</span>
                  </div>
                  <div class="time">
                    <span class="contit">被举报人</span>
                    <span class="condel">{{scope.row.extend.userName?scope.row.extend.userName:'-'}}</span>
                  </div>
                </div>
                <div class="time-fr fr">
                  <div class="time">
                    <span class="contit">学校名称</span>
                    <span class="condel">{{scope.row.extend.schoolName?scope.row.extend.schoolName:'-'}}</span>
                  </div>
                  <div class="time">
                    <span class="contit">举报时间</span>
                    <span class="condel">{{scope.row.addTime?scope.row.addTime:'-'}}</span>
                  </div>
                  <div class="time">
                    <span class="contit">处理时间</span>
                    <span class="condel">{{scope.row.updTime?scope.row.updTime:'-'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:control="{row}">
          <!-- <el-button v-if="row.status == 1 && OA.includes('yygl:jzgl:editor')" type="primary" @click="republish(row.id)" icon="el-icon-edit" circle></el-button> -->
          <el-button v-if="OA.includes('yygl:jbgl:del')" type="danger"  @click="reportDelete(row.id)" icon="el-icon-delete" circle></el-button>
          <!-- <el-button type="danger"  @click="reportDelete(row.id)" icon="el-icon-delete" circle></el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
      <div class="allocationdetail" v-if="details.report">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt" v-if="details.report.type==1">失物招领</p>
              <p class="con-txt" v-if="details.report.type==2">校园兼职</p>
              <p class="con-txt" v-if="details.report.type==3">跳蚤市场</p>
              <p class="con-txt" v-if="details.report.type==4">小赚一笔</p>
              <p class="con-txt" v-if="details.report.type==5">校友圈</p>
              <p class="con-txt" v-if="details.report.type==6">校园新闻</p>
              <p class="con-txt" v-if="details.report.type==7">社团动态</p>
              <p class="mission">举报功能</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.report.addTime?details.report.addTime:'-' }}</p>
              <p class="mission">举报时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt tinctblue" v-if="details.report.status==0">待处理</p>
              <p class="con-txt tinctred" v-if="details.report.status==1">核实不实</p>
              <p class="con-txt tinctgreen" v-if="details.report.status==2">核实属实</p>
              <p class="mission">处理结果</p>
            </div>
            <div class="nodeny" v-if="details.report.status==1">
              <p><span class="no">不实原因</span> <span class="cause">{{details.report.failReason?details.report.failReason:'-'}}</span></p>
            </div>
          </div>
          <div class="headcon" v-if="details.user">
            <p class="contit">举报人</p>
            <img :src="details.user.headImg" class="pig" alt="">
            <div class="area">
              <p class="con-txt"  v-if="details.user.extend">
                {{details.user.extend.userName?details.user.extend.userName:'-'}}
                <img :src="details.user.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
              </p>
              <p class="mission">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}|{{details.user.fade?details.user.fade:'-'}}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.user.extend.mobile?details.user.extend.mobile:'-'}}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>
        <div class="d-condel">
          <div class="headline">
            <p class="report">举报原因：</p>
            <p class="reportcon">{{details.report.reason?details.report.reason:'-'}}</p>
          </div>
          <div class="conreport">
            <p class="crtit"><span class="recon">举报内容</span><span class="constatus">内容状态</span> <span class="status">{{details.report.condition?details.report.condition:'-'}}</span></p>
            <!-- 失物招领 -->
            <div class="goodsdel reportdel" v-if="details.goods">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.goods">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.goods.addTime?details.goods.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.goods.phone?details.goods.phone:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="people">
                <p class="peo-head"> 
                  <span class="tago" v-if="details.goods.type==2">失物</span>
                  <span class="tagt" v-if="details.goods.type==1">招领</span>
                  {{details.goods.title?details.goods.title:'-'}}
                </p>
                <p class="peo-time" v-if="details.goods.type==2"><span class="time">丢失时间：</span><span class="time-con">{{details.goods.pickTime?details.goods.pickTime:'-'}}</span></p>
                <p class="peo-time" v-if="details.goods.type==2"><span class="time">丢失地点：</span><span class="time-con">{{details.goods.address?details.goods.address:'-'}}</span></p>
                <p class="peo-time" v-if="details.goods.type==1"><span class="time">捡到时间：</span><span class="time-con">{{details.goods.pickTime?details.goods.pickTime:'-'}}</span></p>
                <p class="peo-time" v-if="details.goods.type==1"><span class="time">捡到地点：</span><span class="time-con">{{details.goods.address?details.goods.address:'-'}}</span></p>
                <p class="peo-time"><span class="time">物品描述：</span><span class="time-con">{{details.goods.content?details.goods.content:'-'}}</span></p>
                <viewer :images="goodsPics" >
                  <img v-for="(item,index) in goodsPics" :key="index" :src="item" alt="">
                </viewer>
                  <!-- <img v-for="(item,index) in goodsPics" :key="index" :src="item" alt=""> -->
              </div>
            
            </div>
            <!-- 兼职管理 -->
            <div class="jobdel reportdel" v-if="details.job">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.job">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.job.addTime?details.job.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.job.phone?details.job.phone:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="d-conjob" v-if="details.job">
                <div class="people">
                  <p class="peo-head">{{details.job.title}}</p>
                  <p class="peo-con"><span class="conMoney">{{details.job.salary}}元</span><span class="con">{{details.job.payType}}</span></p>
                  <div class="welfare"><span v-for="(item,index) in welfare" :key="index">{{item}}</span></div>
                  <p class="peo-time"><span class="time">工作日期：</span><span class="time-con">{{details.job.startTime}} ～ {{details.job.endTime}}</span></p>
                  <p class="peo-time"><span class="time">工作时间：</span><span class="time-con">{{details.job.workStartTime}}-{{details.job.workEndTime}}</span></p>
                  <p class="peo-time"><span class="time">公司名称：</span><span class="time-con">{{details.job.orporateName}}</span></p>
                  <p class="peo-time"><span class="time">工作地址：</span><span class="time-con">{{details.job.address}}</span> <el-button class="fr"  @click="cVisible = true" >查看地址</el-button></p>
                </div>
                <div class="d-conjob">
                  <p class="jobdel">岗位详情</p>
                  <p class="jobstate" v-if="details.job">{{details.job.content?details.job.content:'-'}}</p>
                  <img v-for="(item,index) in jobPics" :key="index" :src="item" alt="">
                </div>
              </div>
            </div>
            <!-- 跳蚤市场 -->
            <div class="marketdel reportdel" v-if="details.market">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.market">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.market.addTime?details.market.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.market.phone?details.market.phone:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="goods-con">
                <p>商品信息</p>
                <div class="goods-title">{{details.market.title?details.market.title:'-'}}</div>
                <div class="price-box cfx">
                  <div class="price-left fl">
                      <span class="price">￥{{details.market.getPrice ?details.market.getPrice:0}}</span><span class="price-old">￥{{details.market.sellingPrice ?details.market.sellingPrice:0}}</span>
                  </div>
                  <div class="people-right fr">
                    <span class="people fr">{{details.market.interestNumber ?details.market.interestNumber:0}}人感兴趣</span>
                  </div>
                </div>
                <div class="goods-text">{{details.market.content ?details.market.content:'-'}}</div>
                <el-row :gutter="20">
                  <el-col :span="8" 
                    v-for="(item,index) in marketPics"
                    :key="index"
                  >
                        <div class="img">
                          <img :src="item" alt="">
                        </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 小赚一笔 -->
            <div class="taskdel reportdel" v-if="details.task">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.task">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.task.time?details.task.time:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.task.phone?details.task.phone:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="d-conjob" v-if="details.task">
                <div class="tasktop">
                  <span v-if="details.task.taskType == 2" class="takeout">外卖</span>
                  <span v-if="details.task.taskType == 1" class="express">快递</span>
                  <p class="bonus">任务奖金：<span>￥{{details.task.money?details.task.money:'-'}}</span></p>
                </div>
                <div class="status-con">
                  <p>任务详情</p>
                  <div class="text">{{details.task.content}}</div>
                </div>
                <div class="path-box">
                  <p>配送信息</p>
                  <div class="path-con cfx">
                    <div class="icon-left fl">
                      <div class="green"></div>
                      <div class="dian"><img class="dianImg" src="../../assets/images/he/operate/peisong_icon.svg" alt=""></div>
                      <div class="red"></div>
                    </div>
                    <div class="path-right fl">
                      <div class="start">{{details.task.startAddress}}</div>
                      <div class="finish">{{details.task.endAddress}}</div>
                    </div>
                  </div>
                </div>
                <div class="order-load" v-if="details.status != 0 ">
                  <p class="puplish-name">接单信息</p>
                  <div class="person">
                    <img class="head" :src="details.sendUser.headImg" alt="">
                    <div class="nameinfo">
                      <span class="name">{{details.sendUser.extend.userName?details.sendUser.extend.userName:'-'}} 
                        <img :src="details.sendUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                      <p class="perCom">{{details.sendUser.extend.schoolName?details.sendUser.extend.schoolName:'-'}}|{{details.sendUser.fade?details.sendUser.fade:'-'}}</p>
                    </div>
                    <div class="time fr" v-if="details.sendUser">
                      <p class="perTime fr"><span>接单时间：</span>
                        <span class="apptime">{{details.task.receiveTime?details.task.receiveTime:'-'}}</span>
                      </p>
                      <p class="perTime fr"><span>手机号码：</span>
                        <span class="apptime">{{details.sendUser.extend.mobile?details.sendUser.extend.mobile:'-'}}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- 校友圈 -->
            <div class="newsdel reportdel" v-if="details.news">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.news">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.news.addTime?details.news.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.ByUser.extend.mobile?details.ByUser.extend.mobile:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="d-conjob" v-if="details.news">
                <p class="jobstate">{{details.news.digest?details.news.digest:'-'}}</p>
                <img v-for="(item,index) in newsPics" :key="index" :src="item" alt="">
              </div>
            </div>
            <!-- 校园新闻 -->
            <div class="schoolNewsdel reportdel" v-if="details.schoolNews">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.schoolNews">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.schoolNews.addTime?details.schoolNews.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.schoolNews.phone?details.schoolNews.phone:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="d-conjob" v-if="details.schoolNews">
                <p class="jobdel">{{details.schoolNews.title?details.schoolNews.title:'-'}}</p>
                <p class="jobstate" v-html="details.schoolNews.content?details.schoolNews.content:'-'"></p>
                <img v-for="(item,index) in schoolNewsPics" :key="index" :src="item" alt="">
              </div>
            </div>
            <!-- 社团动态 -->
            <div class="clubNewsdel reportdel" v-if="details.clubNews">
              <div class="person">
                <img class="head" :src="details.ByUser.headImg" alt="">
                <div class="nameinfo">
                  <span class="name">{{details.ByUser.extend.userName?details.ByUser.extend.userName:'-'}} 
                    <img :src="details.ByUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px"></span>
                  <p class="perCom">{{details.ByUser.extend.schoolName?details.ByUser.extend.schoolName:'-'}}|{{details.ByUser.fade?details.ByUser.fade:'-'}}</p>
                </div>
                <div class="time fr" v-if="details.clubNews">
                  <p class="perTime fr"><span>发布时间：</span>
                    <span class="apptime">{{details.clubNews.addTime?details.clubNews.addTime:'-'}}</span>
                  </p>
                  <p class="perTime fr"><span>手机号码：</span>
                    <span class="apptime">{{details.ByUser.extend.mobile?details.ByUser.extend.mobile:'-'}}</span>
                  </p>
                </div>
              </div>
              <div class="d-conjob" v-if="details.clubNews">
                <p class="sheTuan"><span>社团名称：</span> <span>{{details.clubName}}</span></p>
                <p class="jobstate">{{details.clubNews.content?details.clubNews.content:'-'}}</p>
                <img v-for="(item,index) in details.clubNews.imgList" :key="index" :src="item" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="d-confoot" v-if="details.report.status==0">
          <p class="refooth">举报处理</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef"  label-width="100px">
            <el-form-item prop="status" label="举报结果：">
              <el-radio-group v-model="ruleForm.status">
                <el-radio label="2">举报属实</el-radio>
                <el-radio label="1">举报不实</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="failReason" label="不实原因：" >
              <el-input :disabled="ruleForm.status==2" type="text" placeholder="请输入原因"  v-model="ruleForm.failReason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="candispose">取消</el-button>
              <el-button type="success" class="btn_true" @click="dispose">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>  
    </el-drawer>

    <!-- 地图 -->
    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
      <div class="map-container" v-if="cVisible">
        <maps :lng="parseFloat(details.job.longitude)" :lat="parseFloat(details.job.latitude)" @finish="setLngLat"></maps>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import { mapState,mapActions} from 'vuex';
import AreaTree from "@/components/common/AreaTree";
import Maps from '@/components/common/Maps';
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";

export default {
  name:'report',
  components: {
    PagedList,
    AreaTree,
    Maps
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      url: '',
      drawer:false,
      queryParams:{
        s1:'',
        s2:'',
        content:'',
        status:'',
      },
      ruleForm: {
        status:'',
      },
      details:{},
      rules:{},
      goodsPics:[],
      marketPics: [],
      welfare:[],
      jobPics:[],
      schoolNewsPics:[],
      newsPics:[],
      pageApi:'getPagesList',
      isEditor: false
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('report',["reportList","reportTotal"])
  },
  methods: {
    ...mapActions('report',["getPagesList","reportGetDetail","reportEdit","reportDel","getNumber"]),
    // 举报删除
    async reportDelete(id){
      await this.reportDel({id})
      this.isEditor = false
      this.refresh()
    },
    gotoAdd(){
      this.$router.push('schoolNewsAdd')
    },
    setLngLat(val){
      this.$message({
        message: '无法改变位置，只能进行查看',
        type: 'warning'
      });
      this.cVisible = false
    },
    async detail(id) {
      this.ruleForm.id = id
      const res = await this.reportGetDetail({id})
      console.log(res)
      this.details = res
      if(this.details.goods) {
        this.goodsPics = this.details.goods.pics.split(',')
      }
      if(this.details.market) {
        this.marketPics = this.details.market.pics.split(',')
      }
      if(this.details.job) {
        if(this.details.job.welfare) {
          this.welfare = this.details.job.welfare.split(',')
        }
      }
      if(this.details.job) {
        this.jobPics = this.details.job.pics.split(',')
      }
      if(this.details.schoolNews) {
        this.schoolNewsPics = this.details.schoolNews.pics.split(',')
      }
      if(this.details.news) {
        this.newsPics = this.details.news.imgList
      }
      this.drawer = true
    },
    // 举报处理按钮
    async dispose() {
      const res = await this.reportEdit(this.ruleForm)
      this.$message.success('处理成功')
      this.ruleForm = {}      
      console.log(res)
      this.drawer = false
      this.refresh()
      this.$store.commit('setReportRandom', Math.random())
    },
    candispose() {
      this.$refs.ruleFormRef.resetFields()
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
  },
  async mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
    this.refresh()
    await this.getNumber()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;}
  .select{
    .year { 
      /deep/.el-input__inner {  
        background: url('../../assets/images/he/operate/area.svg') no-repeat; 
        background-size: 12px 14px;  
        background-position: 8px 7px; 
        padding: 0 0 0 30px; 
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
  .list{ 
    .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
      .area,.areas{display: inline-block; margin-right: 10px;
        .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: $c-2;line-height: 20px; }
        .txtwidth{ width: 200px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        .twidth{ width: 150px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: $c-9;line-height: 14px;padding-top: 15px;}
      }
      .areas{margin-right:0;}
    }
    .con-con{
      .time-fl,.time-fr{width: 50%;display: inline-block;}
      .time{ display: inline-block; font-size: 14px;margin-top:15px;
        .contit{color: $c-9;padding-right: 10px;}
        .condel{color: $c-2; width: 200px; display: inline-block;}
      }
    }
  }
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/.el-drawer__header{ margin-bottom: 0px;}
  .allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
    .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
      .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
        .area{display: inline-block; margin-right: 16px;
          .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
          .mission { font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
        }
        .nodeny{ margin-top: 20px;
          .no, .cause{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px; }
          .cause{ color: $c-2; margin-left: 10px;}
        }
      }
      .headcon{
        .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold;}
        .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px;border-radius: 50%; }
        .area{display: inline-block; margin-right: 16px;height:50px;
          .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
          .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
        }
      }
    }
    .d-condel{padding-top: 20px;
      .headline{
        .report, .report span  { font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: bold;color: $c-2;line-height: 16px; }
        .reportcon{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;color: $c-2;line-height: 20px;padding-top: 10px;margin-bottom: 20px;}
      }
      .conreport{ background: #F5F7F9; margin-bottom:20px;border-radius: 4px;padding:20px;
        .crtit{ margin-bottom: 20px;
          .recon{ font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: bold;color: $c-2 ;line-height: 16px;margin-right: 20px; }
          .constatus,.status{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;color: $c-9;line-height: 14px; }
          .status{ color: $c-success; font-weight: bold;margin-left: 10px;}
         }
        .reportdel{ background: $c-white; padding: 20px;border-radius: 4px;
          .person{ margin-bottom:15px;border-bottom:1px solid $c-border;padding-bottom:20px;
            .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
            .nameinfo{ width: 160px;display: inline-block;margin-left:10px;;
              .name{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: $c-2;line-height: 14px; margin-bottom: 15px;display: inline-block;}
              .perCom{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400; color: #8E8E8E;line-height: 12px; }
            }
            .time{width: 170px;
              .perTime{color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 18px; width: 180px;height:20px;margin-bottom:10px;display:inline-block;
                .apptime{color: $c-2;}
              }
            }
          }
          .d-conjob{
            .jobdel{ font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; }
            .jobstate{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; margin-top: 20px;margin-bottom: 20px;}
            .img{ width: 122px;height: 124px; background: skyblue;margin-right: 10px; border-radius: 4px;}
          }
        }
        .jobdel{
          .d-conjob{
            .people{ overflow: hidden;
              .peo-head{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: $c-2;line-height: 20px; margin: 20px 0;}
              .peo-con{ margin-bottom: 20px;
                .conMoney{ font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-danger;line-height: 20px;margin-right: 20px; }
                .con{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
              }
              .welfare{
                span{ border-radius: 4px; border: 1px solid $c-border;padding: 6px 10px; margin-right: 10px;}
              }
              .peo-time{margin-top: 20px;
                .time{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px;}
                .time-con{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 14px;
                }
              }
            }
            .d-conjob{margin-top: 20px;
              .jobdel{ font-size: 20px; padding-top:20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; }
              .jobstate{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; margin-top: 10px;margin-bottom: 20px;}
              img{ width: 120px;height: 120px; background: skyblue;margin-right: 10px; border-radius: 4px;}
            }
          }
        }
        .goodsdel{
          .people{ overflow: hidden;
            .peo-head{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: $c-2;line-height: 20px; margin: 20px 0;
              .tago , .tagt{ border-radius: 5px; border: 1px solid#FFEDED; background: #FFEDED; margin: 0 10px;font-size: 14px;color: #FF6758; padding: 3px 8px; margin-left: 0;}
              .tagt{ color: $c-success; border: 1px solid #E4F2EF; background: #E4F2EF; }
            }
            .peo-time{margin-top: 20px;
              .time{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px;}
              .time-con{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 14px;
              }
            }
            img{ width: 120px;height: 120px; background: skyblue; border-radius: 4px; margin: 10px 0; margin-right: 10px; }

          }
        }
        .taskdel {
          .d-conjob{
            .tasktop{
              .takeout,.express{ border-radius: 5px; border: 1px solid#FFEDED; background: #FFEDED; margin: 0 10px;font-size: 14px;color: #FF6758; padding: 3px 8px; margin-left: 0; }
              .takeout{  color: $c-success; border: 1px solid #E4F2EF; background: #E4F2EF; }
              .bonus{color: &c-2; font-weight:bold; margin: 20px  0px; font-weight: bold;
                span{color: #FF6758;}
              }
            }
             .path-box{ margin: 20px 0px;
              p{color: &c-2; font-weight:bold; margin: 20px  0px; font-weight: bold;}
              .path-con{
                .icon-left{width: 10px; height: 90px;  margin: 6px 20px 0px 0px;
                  .green{width: 10px; height: 10px; background: $c-success; border-radius: 50%;}
                  .dian{margin: 12px 0px;
                    .dianImg{margin: 3px;}
                  }
                  .red{width: 10px; height: 10px; background: red; border-radius: 50%;}
                }
                .path-right{ width:370px;
                  .start{ font-size: 14px; margin-bottom: 10px; height: 50px;}
                  .finish{ font-size: 14px;}
                }
              }
            }
            .status-con{ margin-top: 10px;
              p{color: $c-2; font-weight:bold; margin: 20px  0px;}
              .text{font-size: 14px; font-weight:400;}
            }
            .order-load{ margin-top: 20px;
              .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
              .person{ margin-bottom:15px;border-bottom:1px solid $c-border;padding-bottom:20px;
              .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
                .nameinfo{ width: 160px;display: inline-block;margin-left:10px;;
                  .name{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: $c-2;line-height: 14px; margin-bottom: 15px;display: inline-block;}
                  .perCom{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400; color: #8E8E8E;line-height: 12px; }
                }
                .time{width: 170px;
                  .perTime{color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 18px; width: 180px;height:20px;margin-bottom:10px;display:inline-block;
                    .apptime{color: $c-2;}
                  }
                }
              }
            }
          }
        }
        .marketdel{
          .goods-con{ margin-top: 20px;
            p{color: &c-2; font-weight:bold; margin-bottom: 10px;}
            .goods-title{font-size: 18px; font-weight:bold; margin-bottom: 10px;}
            .text{font-size: 14px; font-weight:400;}
            .price-box{
              .price-left{height: 40px; width: 250px; 
                .price{color: #FF5353; font-size: 20px; margin-right: 20px;}
                .price-old{color: $c-9; font-size: 14px; text-decoration:line-through;}
              }
              .people-right{height: 40px;width: 100px;  line-height: 40px;
                .people{color: $c-9; font-size: 14px;}
              }
            }
            .goods-text{font-size: 14px; font-weight: 400px;}
            .img{height: 150px; margin-top: 10px; 
              img{width: 150px; height: 150px;}
            }
          }
        }
        .newsdel{
          .d-conjob{
            img{width: 120px; height: 120px; margin-right: 10px; border-radius: 4px; }
          }
        }
        .schoolNewsdel{
          .d-conjob{
            img{width: 120px; height: 120px; margin-right: 10px; border-radius: 4px;}
          }
        } 
        .clubNewsdel{
          .d-conjob{
            .sheTuan{
              font-size: 18px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: bolder;
            }
          }
        }
      }
    }
    .d-confoot{ height: 272px;background: #F5F7F9;padding:20px; border-radius: 4px;
      .refooth{font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: bold;color: $c-2;line-height: 18px;margin-bottom: 20px;}
      .btn_true{ width: 140px; height: 44px;}
    }
  }
  /deep/ .el-cascader{ width: 230px;}

}
</style>