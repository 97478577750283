/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const login = function(params) {
  return axios({
    url: BaseUrl + "members/sys/login",
    method: "post",
    data: params
  });
};
export const ceoLogin = function(params) {
  return axios({
    url: BaseUrl + "members/sys/ceo/login",
    method: "post",
    data: params
  });
};

export const membersUserPassword = function(params) {
  return axios({
    url: BaseUrl + "members/user/passwd",
    method: "post",
    data: params
  });
};

export const getUserInfo = function(params) {
  return axios({
    url: BaseUrl + "members/sys/getUserInfo",
    method: "post",
    data: params
  });
};

export const memberAppGetUserInfo = function(params) {
  return axios({
    url: BaseUrl + "members/app/getUserInfo",
    method: "post",
    data: params
  });
};

export const membersAppEdit = function(params) {
  return axios({
    url: BaseUrl + "members/app/edit",
    method: "post",
    data: params
  });
};


export const getUnloginCode = function(params) {
  return axios({
    url: BaseUrl + "members/app/getUnloginCode",
    method: "post",
    data: params
  });
};


export const userAjaxSsLogin = function(params) {
  return axios({
    url: "http://study.ahjxxy.cn/user/ajax/ssoLogin",
    method: "get",
    data: params
  });
};

export default {
  login,
  ceoLogin,
  membersUserPassword,
  getUserInfo,
  memberAppGetUserInfo,
  membersAppEdit,
  getUnloginCode,
  userAjaxSsLogin,
};
