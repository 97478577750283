<template>
    <!-- 赛事申请 -->
    <div class="wrap">
        <div class="box-con">
            <!-- 数据统计 -->
            <div class="count">
                <div class="statistics">
                    <span class="title">总人数</span>
                    <div>
                        <countTo class="num" :startVal='0' :endVal='dataDetail.peoSum' :duration='3000' ></countTo>
                    </div>
                    <div class="cont">
                        <span>报名人数：{{dataDetail.matNum}}</span>
                        <span>投票人数：{{dataDetail.votNum}}</span>
                    </div>
                </div>
                <div class="statistics">
                    <span class="title">总票数</span>
                    <div>
                        <countTo class="num" :startVal='0' :endVal='dataDetail.voteSum' :duration='3000' ></countTo>
                    </div>
                    <div class="cont">
                        <span>分享票数：{{dataDetail.share}}</span>
                        <span>投票票数：{{dataDetail.vote}}</span>
                    </div>
                </div>
                <div class="statistics">
                    <span class="title">总下载</span>
                    <div>
                        <countTo class="num" :startVal='0' :endVal='dataDetail.dowSum' :duration='3000' ></countTo>
                    </div>
                    <div class="cont">
                        <span>海报下载：{{dataDetail.pos}}</span>
                        <span>活动下载：{{dataDetail.act}}</span>
                    </div>
                </div>
                <div class="statistics">
                    <span class="title">总访问量（UV）</span>
                    <div>
                        <countTo class="num" :startVal='0' :endVal='dataDetail.uvSum' :duration='3000' ></countTo>
                    </div>
                    <div class="cont">
                        <span>APP外：{{dataDetail.uvw}}</span>
                        <span>APP内：{{dataDetail.uvn}}</span>
                    </div>
                </div>
                <div class="statistics">
                    <span class="title">总访问量（PV）</span>
                    <div>
                        <countTo class="num" :startVal='0' :endVal='dataDetail.pvSum' :duration='3000' ></countTo>
                    </div>
                    <div class="cont">
                        <span>APP外：{{dataDetail.pvw}}</span>
                        <span>APP内：{{dataDetail.pvn}}</span>
                    </div>
                </div>
            </div>
            <!-- 查询 -->
            <el-form class="params-wrap select" size="small" :inline="true"  :model="queryParams" label-width="100px">
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.status" value-key="" placeholder="请选择状态" clearable filterable @change="refresh()">
                        <el-option v-for="item in status"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="queryParams.name" placeholder="请输入名称，手机号"  clearable ></el-input>
                </el-form-item> 
                <el-form-item >
                    <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
                </el-form-item>  
                <el-form-item >
                    <el-link :href="BaseUrl + 'match/web/god/noLogin/downLoad'" class="button_download">
                        <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
                    </el-link>
                </el-form-item>  
                <el-form-item v-if="isShow">
                    <el-button type="success" size="small" icon="el-icon-message-solid" @click="actionStatus" :disabled='btnStatus'>发布活动奖项</el-button>
                </el-form-item>     
            </el-form>
            <!-- 列表 -->
            <div>
                <div v-if="matchTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
                <paged-table
                    v-if="matchTotal>0"
                    :total="matchTotal"
                    :data="matchList"
                    :loading="tLoading"
                    :isShowSelection="false"
                    defaultSortProp="id"
                    v-model="queryParams"
                    @refresh="refresh"
                >
                    
                    <el-table-column sortable="custom" prop="name" show-overflow-tooltip label="姓名"></el-table-column>
                    <el-table-column sortable="custom" prop="no" show-overflow-tooltip label="选手编号"></el-table-column>
                    <el-table-column sortable="custom" prop="sex" show-overflow-tooltip label="性别">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.sex == 1">男</span>
                                <span v-else>女</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="mobile" show-overflow-tooltip label="联系方式"></el-table-column>
                    <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校名称"></el-table-column>
                    <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="提交时间"></el-table-column>
                    <el-table-column sortable="custom" prop="intro" show-overflow-tooltip label="拉票描述"></el-table-column>
                    <el-table-column sortable="custom" prop="votes" show-overflow-tooltip label="票数"></el-table-column>
                    <el-table-column sortable="custom" prop="rank" show-overflow-tooltip label="排名"></el-table-column>
                    <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" align="center">
                        <template slot-scope="scope">
                            <div>
                                <el-tag size="mini" class="primary" v-if="scope.row.status == 1">待审核</el-tag>
                                <el-tag size="mini" type="success"  v-if="scope.row.status == 2">审核通过</el-tag>
                                <el-tag size="mini" type="danger" v-if="scope.row.status == 3">审核不通过</el-tag>
                                <el-tag size="mini" type="danger" v-if="scope.row.status == 4">取消资格</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom"  show-overflow-tooltip label="操作" align="center">
                        <template slot-scope="scope">
                            <div>
                                <el-button v-if="scope.row.status == 1" type="warning" size="mini" @click="isInfo = false,matchCheckInfo(scope.row.id)">审核</el-button>
                                <el-button v-else type="text" size="mini" class="info" @click="isInfo = true,matchCheckInfo(scope.row.id)">详情</el-button>
                            </div>
                        </template>
                    </el-table-column>

                </paged-table>
            </div>
            <!-- 审核 -->
            <el-dialog :title="isInfo?'详情':'审核'" :visible.sync="aVisible" width="1200px">
                <el-form label-width="120px" >
                    <el-form-item label="学校名称：" class="inlineForm w500">
                        <span v-if="matchInfo.extend != null">{{matchInfo.extend.schoolName}}</span>
                    </el-form-item>
                    <el-form-item label="提交审核时间：" class="inlineForm w500">
                        <span>{{matchInfo.addTime}}</span>
                    </el-form-item>
                    <el-form-item label="联系人：" class="inlineForm w500">
                        <span>{{matchInfo.name}}</span>
                    </el-form-item>
                    <el-form-item label="联系方式：" class="inlineForm w500">
                        <span>{{matchInfo.mobile}}</span>
                    </el-form-item>
                    <el-form-item label="学号/职工号：" class="inlineForm w500">
                        <span>{{matchInfo.account}}</span>
                    </el-form-item>
                    <el-form-item label="选手编号：" class="inlineForm w500">
                        <span>{{matchInfo.no}}</span>
                    </el-form-item>
                    <el-form-item label="审核状态：" class="inlineForm w500">
                        <el-tag type="primary" v-if="matchInfo.status == 1">待审核</el-tag>
                        <el-tag type="success" v-if="matchInfo.status == 2">审核通过</el-tag>
                        <el-tag type="danger" v-if="matchInfo.status == 3">审核不通过</el-tag>
                        <el-tag type="danger" v-if="matchInfo.status == 4">取消资格</el-tag>
                    </el-form-item>
                    <el-form-item label="拉票描述：" class="inlineForm" style="width:100%">
                        <span>{{matchInfo.intro}}</span>
                    </el-form-item>
                    <el-form-item label="照片：" >
                        <div class="checkImgList">
                            <viewer :img="matchInfo.files">
                                <img :src="item.url" alt="" v-for="item in matchInfo.files" :key="item.id" class="checkImg">
                            </viewer>
                        </div>
                    </el-form-item>
                    <!-- 通过 -->
                     <el-form-item label="当前排名："  v-if="isInfo && matchInfo.status ==2">
                        <span>{{matchInfo.rank}}</span>
                    </el-form-item>
                     <el-form-item label="当前票数："  v-if="isInfo && matchInfo.status ==2">
                        <span>{{matchInfo.votes}}</span>
                    </el-form-item>
                     <el-form-item label="距离上一名："  v-if="isInfo && matchInfo.status ==2">
                        <span>{{matchInfo.differ}}</span>
                    </el-form-item>
                     <el-form-item v-if="isInfo && matchInfo.status ==2">
                         <div class="edit">
                            <el-button class="btn submit" @click="bVisible = true">取消资格</el-button>
                            <el-button class="btn"  @click="aVisible =false">取消</el-button>
                        </div>
                    </el-form-item>
                    <!-- 不通过 -->
                    <el-form-item label="操作时间："  v-if="(isInfo && matchInfo.status == 3)||(isInfo && matchInfo.status == 4)">
                        <span>{{matchInfo.auditTime}}</span>
                    </el-form-item>
                     <el-form-item label="不通过原因："  v-if="isInfo && matchInfo.status == 3">
                        <span>{{matchInfo.reason}}</span>
                    </el-form-item>
                    <!-- 取消资格 -->
                     <el-form-item label="原因："  v-if="isInfo && matchInfo.status == 4">
                        <span>{{matchInfo.reason}}</span>
                    </el-form-item>
                </el-form>
                <!-- 审核表单 -->
                <el-form :model="formData" ref="formData"  :rules="formRules" label-width="120px" v-if="!isInfo">
                    <el-form-item label="审核：" prop="status">
                        <el-radio-group v-model="formData.status"  @change="formData.reason = ''">
                            <el-radio :label="2">通过</el-radio>
                            <el-radio :label="3">不通过</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="不通过原因：" prop="reason"  class="inlineForm w500" v-if="formData.status == 3">
                        <el-input v-model="formData.reason" placeholder="请输入不通过原因"  clearable ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="edit">
                            <el-button class="btn" @click="aVisible = false">取消</el-button>
                            <el-button class="btn submit" type="primary" @click="submitCheck('formData')">提交</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <!-- 通过表单 -->
                <el-dialog title="取消原因" :visible.sync="bVisible" width="400px" append-to-body>
                    <el-form :model="form" ref="form" >
                        <el-form-item >
                            <el-radio-group v-model="cancelStatus" >
                                <el-radio label='1'>使用网络图片</el-radio>
                                <el-radio label='2'>存在刷票行为</el-radio>
                                <el-radio label='3'>其他</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="cancelStatus == '3'" >
                            <el-input v-model="reason" placeholder="请输入其他原因"  clearable ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div class="edit">
                                <el-button class="btn" @click="bVisible = false">取消</el-button>
                                <el-button class="btn submit" type="primary" @click="cancelMatch">确定</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapState,mapActions } from "vuex";
import PagedTable from '../common/PagedTable.vue'
import countTo from 'vue-count-to'
import pageMixin from "@/mixins/pageMixin";
import { BaseUrl } from "@/common/config";

export default {
    name:'applymatch',
    components: { 
        PagedTable,
        countTo
    },
    mixins: [pageMixin],
    data() {
        return {
            isShow:false,
            btnStatus:false,
            aVisible:false,
            BaseUrl:BaseUrl,
            queryParams:{},
            formData:{
                id:null,
                status:null,
                reason:''
            },
            form:{
                id:null,
                reason:''
            },
            isInfo:false,
            status:[
                {label:'全部',value:''},
                {label:'待审核',value:'1'},
                {label:'审核通过',value:'2'},
                {label:'审核不通过',value:'3'},
            ],
            cancelStatus:null,
            reason:'',
            formRules:{
                status:[{ required: true, message: '请选择审核状态', trigger: 'change' }],
                reason:[{ required: true, message: '请输入审核不通过原因', trigger: 'blur' }],
            },
            pageApi:'getMatchList'
        }
    },
    computed:{
        ...mapState('applyMatch',['matchList','matchTotal','dataDetail','matchInfo'])
    },
    methods:{
        ...mapActions('applyMatch',['getDataDetail','getMatchList','getMatchInfo','matchCheck','matchRecusal','chargeStatus','editStatus']),
        // 审核详情
         async matchCheckInfo(id) {
             await this.getMatchInfo({id})
             this.aVisible = true
         },
        // 审核
        submitCheck(formName) {

            this.$refs[formName].validate(async(valid) => {
                if (valid) {
                    this.formData.id = this.matchInfo.id
                    let res = await this.matchCheck(this.formData)
                    if (res == null) {
                        this.aVisible = false
                        this.$message.success('审核状态成功')
                        this.refresh()
                    }
                } else {
                    return false;
                }
            });
        },
        // 取消资格
        async cancelMatch() {
            this.form.id = this.matchInfo.id
            if (this.cancelStatus == '1') this.form.reason = '使用网络图片'
            if(this.cancelStatus == '2') this.form.reason = '存在刷票行为'
            if(this.cancelStatus == '3') this.form.reason = this.reason
            let res = await this.matchRecusal(this.form)
            if (res == null) {
                this.$message.success('取消资格成功')
                this.bVisible = false
                this.aVisible = false
                this.refresh()
            }
        },
        // 发布活动状态
        async actionStatus() {
           let res = await this.editStatus()
           if (res) {
               this.$message.success('状态修改成功')
               this.refresh()
           }
        }
    },
    created() {
        let now = new Date().getTime()
        let end = new Date('2021.06.20 20:00:00').getTime()
        if (end-now <= 0) {
            this.isShow = true
        }
    },
    async mounted() {
        await this.getDataDetail()
        this.refresh()
        let res = await this.chargeStatus()
        if (res.status == 1) this.btnStatus = true

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.primary{color: $c-primary;}
.info{
color: $c-primary;
}
.w500 {width:500px}
.edit {
    .btn {
        margin-right: 20px;
        width: 140px;
        color: #222222;
        background-color: #ffffff;
        &.submit {color: #ffffff;background-color: $c-success;}
    }
}
.wrap {width: 100%;margin: 0 10px;padding: 20px 10px;background-color: $c-white;color:#222222;
    .box-con {width: 100%;height: 100%;padding: 50px;
        .count{display: flex;justify-content: space-around;margin-bottom:10px;
            .statistics{width: 316px;height: 122px;padding: 20px;box-sizing: border-box;border-radius: 4px;box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
                .title{font-size: 14px;}
                .num{font-size: 24px;font-weight: 600;}
                .cont{display: flex;
                    span{width: 50%;font-size: 12px;color: #AFAFAF;}
                }
            }
        }
    }
}
.checkImgList{
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    .checkImg {
        width: 150px;
        height: 150px;
        margin: 10px 20px;
    }
}
</style>