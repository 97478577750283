<template>
  <!-- 我的任务-CEO @需要改仅为静态页 -->
  <div class="wrap">
    <div class="box-con">
      <el-tabs v-model="active" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="常规任务" name="1"></el-tab-pane>
        <el-tab-pane label="奖励任务" name="2"></el-tab-pane>
        <el-tab-pane label="学习任务" name="3"></el-tab-pane>
      </el-tabs>
      <!-- 常规任务 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <el-select style="width:150px;" label-width="150px" v-model="queryParams.year" placeholder="选择年份" @change="resetPageAndRefresh">
            <el-option v-for="item in yearOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="active!=3">
          <el-select style="width:150px;" v-model="queryParams.month" @change="resetPageAndRefresh">
            <el-option label="上半年" value="上半年"></el-option>
            <el-option label="下半年" value="下半年"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="active==3">
          <el-select style="width:150px;" v-model="queryParams.month" @change="resetPageAndRefresh">
            <el-option label="1" :value="1"></el-option>
            <el-option label="2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="active==3">
          <el-select style="width:150px;" v-model="queryParams.status" placeholder="选择任务类型" @change="resetPageAndRefresh">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="active==3">
          <el-select style="width:150px;" v-model="queryParams.status" placeholder="选择学校" @change="resetPageAndRefresh">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select style="width:150px;" v-model="queryParams.status" placeholder="执行状态" @change="resetPageAndRefresh">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button size="small" icon="el-icon-plus" v-if="active==2" @click="$router.push({path: '/ceoAddTask'})">新增</el-button>
          <el-button size="small" icon="el-icon-plus" v-if="active==1" @click="$router.push({path: '/ceoAddTask'})">新增</el-button>
          <el-button size="small" icon="el-icon-plus" v-if="active==3" @click="$router.push({path: '/ceoAddStudyTask'})">新增</el-button>
          <el-button size="small" @click="isEditor=!isEditor" :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
      </el-form>

      <!-- 列表 -->
      <paged-list v-if="active==1||active==2" v-model="queryParams" :data="storeList" :isEditor="isEditor" :total="storeTotal" :span='8' @refresh="refresh">
        <template>
          <div class="list">
            <div class="head-top cfx">
              <!-- 任务 -->
              <div class="rule bgSuccess" v-if="active==1">常规任务</div>
              <div class="rule bgDanger" v-if="active==2">奖励任务</div>
              <!-- 状态 -->
              <div class="state tinctblue fr" v-if="0">待分配</div>
              <div class="state tinctgray fr" v-if="1">已分配</div>
              <div class="state tinctgreen fr" v-if="0">分配中</div>
            </div>
            <div class="area">
              <p class="con-txt">2020-2月份</p>
              <p class="mission tinctgray">任务时间</p>
            </div>
            <!-- <div class="area">
              <p class="con-txt">安徽省-合肥市-蜀山区</p>
              <p class="mission tinctgray">任务区域</p>
            </div> -->
            <div class="area" v-if="active==2">
              <p class="con-txt">+5%分成</p>
              <p class="mission tinctgray">奖励金额</p>
            </div>
            <div class="content"  v-if="active==1">
              <p class="content-tit">任务内容</p>
              <div>
                <div class="content-con"><div class="color_9">通用任务</div> <div class="content-p">营业额营业额营业额营业额营业额营业额营业额营业额营业额营业额营业额营业额营业额营业额(元)：53000、</div></div>
                <div class="content-con"><div class="color_9">商户推广</div> <div class="content-p">营业额(元)：53000、</div></div>
                <div class="content-con"><div class="color_9">对接应用</div> <div class="content-p">营业额(元)：53000、</div></div>
                <p>...</p>
              </div>
            </div>
            <div class="content"  v-if="active==2">
              <p class="content-tit">任务内容</p>
              <div>
                <div class="content-con"><div class="color_9">任务一</div> <div class="content-p">营业额（元）：53000.00、订单数（</div></div>
                <div class="content-con"><div class="color_9">任务二</div> <div class="content-p">营业额(元)：53000、</div></div>
                <div class="content-con"><div class="color_9">任务三</div> <div class="content-p">营业额(元)：53000、</div></div>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="task_deta1" @click="drawer = true, taskBtn = 1" v-if="0">任务详情</el-button>

              <el-button class="footer-btn" @click="drawer = true, taskBtn = 1" v-if="0">任务详情</el-button>
              <el-button class="footer-btn" @click="$router.push({path: '/ceoDistributeTask'})" v-if="1">任务分配</el-button>
              <el-button class="footer-btn" @click="drawer = true, taskBtn = 2" v-if="0">分配详情</el-button>
              <el-button class="footer-btn" @click="drawer = true" v-if="1">继续分配</el-button>
              <el-button class="footer-btn" v-if="0" disabled>已分配</el-button>
              <!-- <el-button class="footer-btn" @click="drawer = true" v-if="0">重新填配</el-button> -->
            </div>
          </div>
        </template>
        <template slot="control" slot-scope="scope">
          <el-button type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" circle></el-button>
        </template>
      </paged-list>

      <!-- <paged-list v-if="active==3" v-model="queryParams" :data="dirTaskList" :isEditor="isEditor" :total="dirTaskTotal" :span='8' @refresh="refresh"> -->
      <paged-list v-if="active==3" v-model="queryParams" :data="storeList" :isEditor="isEditor" :total="storeTotal" :span='8' @refresh="refresh">
        <template>
          <div class="list">
            <div class="head-top cfx">
              <!-- 任务 -->
              <div class="rule bgPrimary" v-if="1">学习任务</div>
              <div class="rule bgPrimary" v-if="0">考试任务</div>
              <!-- 状态 -->
              <div class="state tinctred fr" v-if="0">未完成（3/5）</div>
              <div class="state tinctgreen fr" v-if="1">已完成（3/5）</div>
            </div>
            <div>
              <div class="area">
                <p class="con-txt">2020-4月份</p>
                <p class="mission tinctgray">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">中国科学技术大学</p>
                <p class="mission tinctgray">学校名称</p>
              </div>
              <div class="area">
                <p class="con-txt">陈强</p>
                <p class="mission tinctgray">CEO</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <div class="content-con">
                  <div class="color_9">考试内容 </div>&nbsp;
                  <div class="content-p"> 营业额（元）：53000.00、订单营业额（元）：53000.00、订单数（营业额（元）：53000.00、订单数（营业额（元）：53000.00、订单数（数（</div>
                </div>
                <p>...</p>
              </div>
            </div>
            <div class="footer" v-if="active==3">
              <el-button class="task_deta1" @click="studyDrawer = true">任务详情</el-button>
            </div>
          </div>
        </template>
        <template slot="control" slot-scope="scope">
          <el-button type="primary" @click="handleForm(true, scope.row,afterHandleForm)" icon="el-icon-edit" title="编辑" circle></el-button>
          <el-button type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" circle></el-button>
        </template>
      </paged-list>
    </div>

    <!-- 分配任务详情 -->
    <el-drawer :title="taskBtn==1?'任务详情':'分配详情'" :visible.sync="drawer" size="36.5%">
      <div class="details">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">{{1?'常规任务':'奖励任务'}}</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area fr">
              <p class="con-statu1" v-if="0">待分配</p>
              <p class="con-statu2" v-if="1">分配中</p>
              <p class="con-statu4" v-if="0">已分配</p>
              <p class="mission">分配状态</p>
            </div>
            <div class="area">
              <p class="con-txt">2021-1月份</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area">
              <p class="con-txt">2019-08-12 14:34</p>
              <p class="mission">接收时间</p>
            </div>
            <div class="area1" v-if="taskBtn==1">
              <span class="con-txt1">任务学校</span>
              <span class="mission1">中国科学技术大学</span>
            </div>
            <!-- <div class="area1" v-if="taskBtn==2">
              <span class="con-txt1">审核时间</span>
              <span class="mission1">2019-08-12 14:34</span>
            </div> -->
            <div class="area1" v-if="taskBtn==2">
              <span class="con-txt1">原因</span>
              <span class="mission1">2019-08-12 原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因14:34</span>
            </div>
          </div>
          
          <div class="headcon" v-if="taskBtn==2">
            <p class="contit">分配人</p>
            <el-avatar src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2640900762,1357209539&fm=26&gp=0.jpg"></el-avatar> &nbsp;
            <div class="area">
              <p class="con-txt">王小二</p>
              <p class="mission">校园CEO</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
            <div class="area1">
              <p class="con-txt1">提交时间</p>
              <p class="mission1">2019-08-12 14:34</p>
            </div>
          </div>
        </div>


        <div class="d-bodyFirst" v-if="taskBtn==1 && active==1">
          <div class="taskcon">
            <p class="contit">通用任务</p>
            <div class="conitem">
              <div class="conlab">营业额（元）</div>
              <div class="connum">5300.00</div>
            </div>
            <div class="conitem">
              <div class="conlab">订单数（笔）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">访客数（人）</div>
              <div class="connum">5300</div>
            </div>
          </div>
          <div class="taskcon">
            <p class="contit">商户推广</p>
            <div class="conitem">
              <div class="conlab">运营商（家）</div>
              <div class="connum">5300.00</div>
            </div>
            <div class="conitem">
              <div class="conlab">考证培训（家）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">餐饮商家（家）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">大型超市（家）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">广告招商（家）</div>
              <div class="connum">5300</div>
            </div>
          </div>

          <div class="taskcon">
            <p class="contit">对接应用</p>
            <div class="conitem">
              <div class="conlab">对接一卡通（个）</div>
              <div class="connum">5300.00</div>
            </div>
            <div class="conitem">
              <div class="conlab">对接教务系统（个）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">对接共享服务（个）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">对接其他应用（个）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="conlab">线下举办活动（个）</div>
              <div class="connum">5300</div>
            </div>
          </div>

          <div class="taskcon">
            <p class="contit">用户服务</p>
            <div class="conitem">
              <div class="conlab">客户服务（个）</div>
              <div class="connum">5300.00</div>
            </div>
            <div class="conitem">
              <div class="conlab">上门服务（个）</div>
              <div class="connum">5300</div>
            </div>
            <div class="conitem">
              <div class="chang">商务合作联系方式维护（个）</div>
              <div class="duan">5300</div>
            </div>
          </div>

          <div class="taskcon">
            <p class="contit">用户运营</p>
            <div class="conitem_last" v-for="(item, index) in conitem_last" :key='index'>
              <div class="conlab_last">校园动态</div>
              <div class="connum_last">发布：<span>30</span></div>
              <div class="connum_last">点赞：<span>30</span></div>
              <div class="connum_last">浏览：<span>30</span></div>
              <div class="connum_last">评论：<span>300</span></div>
              <div class="connum_last">分享：<span>30</span></div>
            </div>
          </div>
          <div style="text-align: center; width: 100%;" v-if="0">
            <el-button style="margin-right: 20px;" size="small" type="primary">前往分配</el-button>
            <el-button style="margin-right: 20px;" size="small">编辑</el-button>
            <el-button style="margin-right: 20px;" size="small">删除</el-button>
          </div>
        </div>

        <div class="d-con" v-if="taskBtn==2 || 1">
          <p class="contit">已分配详情</p>
          <el-collapse v-model="unfold" accordion>
            <el-collapse-item v-for="item in 2" :key="item">
              <div slot="title" class="colltit">
                <div class="people">
                  <span>成员姓名：</span>
                  <span>李晓（CTO）</span>
                </div>
              </div>
              <div class="collcon">
                <div class="conhead">
                  <span>分配时间：</span>
                  <span>2019-08-12 14:34</span>
                </div>
                <div class="content">
                  <div class="taskcon">
                    <p class="contit">任务一</p>
                    <div class="conitem">
                      <div class="conlab">移动号卡（张）</div>
                      <div class="connum">800 </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">营业额（元）</div>
                      <div class="connum">3000 </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">订单数（笔）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">访客数（人）</div>
                      <div class="connum">500</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">任务二</p>
                    <div class="conitem">
                      <div class="conlab">运营商（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">考证培训（家）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">餐饮商家（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">大型超市（家）</div>
                      <div class="connum">30</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">广告招商（家）</div>
                      <div class="connum">50</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">任务三</p>
                    <div class="conitem">
                      <div class="conlab">对接一卡通（个）</div>
                      <div class="connum">800</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接教务系统（个）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接共享服务（个）</div>
                      <div class="connum">400</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接其他应用（个）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">线下举办活动（个）</div>
                      <div class="connum">30</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div style="text-align: center;">
            <el-button class="taskdetails" @click="drawer = true, taskBtn = 1">任务详情</el-button>
            <el-button class="taskdetails" type="primary" @click="taskDetail" v-if="0">继续分配</el-button>
            <el-button class="taskdetails" type="primary" @click="taskDetail" v-if="0">重新分配</el-button>
          </div>
        </div>

        <div class="d-bodyFirst" v-if="active==2">
          <div class="taskcon">
            <p class="contit">任务一</p>
            <div class="conitem">
              <div class="conlabAward">当</div>
              <div class="connumAward">营业额</div>
              <div class="conlabAward">每超出</div>&nbsp;
              <div class="connumAward">5300.00</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">现金奖励</div>&nbsp;
              <div class="connumAward">5300</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">当</div>
              <div class="connumAward">营业额</div>
              <div class="conlabAward">每超出</div>&nbsp;
              <div class="connumAward">5300.00</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">提高分成比例</div>&nbsp;
              <div class="connumAward">+5</div>
              <div class="conlabAward">%</div>
            </div>
          </div>
          <div class="taskcon">
            <p class="contit">任务二</p>
            <div class="conitem">
              <div class="conlabAward">当</div>
              <div class="connumAward">营业额</div>
              <div class="conlabAward">每超出</div>&nbsp;
              <div class="connumAward">5300.00</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">现金奖励</div>&nbsp;
              <div class="connumAward">5300</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">当</div>
              <div class="connumAward">营业额</div>
              <div class="conlabAward">每超出</div>&nbsp;
              <div class="connumAward">5300.00</div>
              <div class="conlabAward">元</div>
            </div>
            <div class="conitem">
              <div class="conlabAward">提高分成比例</div>&nbsp;
              <div class="connumAward">+5</div>
              <div class="conlabAward">%</div>
            </div>
          </div>

          <div style="text-align: center; width: 100%;" v-if="0">
            <el-button style="margin-right: 20px;" size="small" type="primary">前往分配</el-button>
            <el-button style="margin-right: 20px;" size="small">编辑</el-button>
            <el-button style="margin-right: 20px;" size="small">删除</el-button>
          </div>
        </div>

      </div>
    </el-drawer>

    <el-drawer title="任务详情" :visible.sync="studyDrawer" size="36.5%">
      <div class="details">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">{{1?'学习任务':'考试任务'}}</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area fr">
              <p class="con-statu3">未完成<span>（0/5）</span></p>
              <p class="con-statu3" v-if="0">已完成</p>
              <p class="mission fr" >执行状态</p>
            </div>
            <div class="area">
              <p class="con-txt">2021-1月份</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area" v-if="ceo==true || member==true">
              <p class="con-txt">2021-02-18 16:39</p>
              <p class="mission">发布时间</p>
            </div>
          </div>
          <div class="headcon" >
            <p class="contit">接收人</p>
            <img src="" class="pig" alt="">
            <div class="area">
              <p class="con-txt">王小二<i>图片</i></p>
              <p class="mission">校园CEO</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
          </div>
        </div>

        <div class="d-bodyExam" v-if="1">
          <p class="contit">任务详情</p>
          <p class="contit_stu">平台功能学习</p>
          <div class="taskcon" v-for="it in 5" :key="it">
            <p class="contit_net">网络大厅</p>&nbsp;
            <p class="contit_num">共<span>10</span>张</p>
            <div class="contit_box">
              <div class="contit_item" v-for="item in 10" :key="item">
                <div class="item_ti">校友圈试卷标题</div>
                <div class="item_txt">难度：简单 题目总数：20题</div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-bodyExam" v-if="0">
          <p class="contit">任务详情</p>
          <p class="contit_stu">平台功能学习</p>
          <div class="taskcon" v-for="it in 2" :key="it">
            <p class="contit_num">网络大厅</p>
            <p class="contit_num">共<span>10</span>节</p>
            <div class="contit_box">
              <div class="video_box" v-for="item in 20" :key="item">
                <div class="video_ti"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3322974547,4080291942&fm=26&gp=0.jpg" alt=""></div>
                <div class="video_video">校友圈直播课程标题校友…</div>
              </div>
            </div>
          </div>

          <p class="contit_stu">运营策划</p>
          <div class="taskcon" v-for="it in 1" :key="it">
            <p class="contit_num">自定义名称</p>
            <p class="contit_num">共<span>10</span>节</p>
            <div class="contit_box">
              <div class="video_box" v-for="item in 20" :key="item">
                <div class="video_ti"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3322974547,4080291942&fm=26&gp=0.jpg" alt=""></div>
                <div class="video_video">校友圈直播课程标题校友…</div>
              </div>
            </div>
          </div>

          <p class="contit_stu">考核政策</p>
          <div class="taskcon" v-for="it in 1" :key="it">
            <p class="contit_num">自定义名称</p>
            <p class="contit_num">共<span>10</span>节</p>
            <div class="contit_box">
              <div class="video_box" v-for="item in 20" :key="item">
                <div class="video_ti"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3322974547,4080291942&fm=26&gp=0.jpg" alt=""></div>
                <div class="video_video">校友圈直播课程标题校友…</div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </el-drawer>
  </div>
</template>

<script>
import StudyTab from "@/components/task/myTask/StudyTab";
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import { mapState, mapActions } from "vuex";
export default {
  name:'ceoTask',
  components: {
    StudyTab,
    PagedList,
  },
  mixins: [ pageMixin ],
  data() {
    return{
      taskBtn: 0,
      queryParams: {
        // schoolName:'',
        status: 0,
        // year: null,
        // month: '上半年',
      },
      storeTotal: 20,
      storeList: [
        {},
        {},
        {},
        {},
      ],
      yearOptions: [],
      statusOptions: [],
      pageData:[
        { name:'中国科技大学' }
      ],
      active:'1',
      drawer: false,
      studyDrawer: false,
      member: false, ceo: true, manage: false, chief: false,
      stuDetail: 2,
      innerDrawer: false,
      unfold:'first',
      conitem_last: [ "校园动态", "校园新闻", "易货空间", "失物招领", "校园招聘", "小赚一笔" ],
      taskOrder: [ 
        { task: "任务一", get1: "5300", get2: "+100%", get3: "300"},
        { task: "任务二", get: "+5%"}, 
        { task: "任务三", get: "5300"}, 
      ],
      taskResult: [ "5300", "+100%", "300" ],

      pageApi:'ceoList',
      insertApi: 'ceoAdd',
      editorApi: 'ceoEdit',
      deleteApi: 'ceoDelete',
    }
  },
  computed: {
    // ...mapState(["OA"]),
    // ...mapState("ceoTask", ["dirTaskList", "dirTaskTotal"]),
  },
  methods: {
    // ...mapActions("ceoTask", ["ceoList", "ceoAdd", "ceoEdit", "ceoLoad", "ceoDelete", "ceoAll", "ceoDis", "taskadminDirectorGetAllManagers", "ceoGetSurplus", "taskAdd", "taskList"]),
    async handleClick(tab, event) {
      console.log(tab)
    },
    handleClose(done) {
      this.$confirm('还有未保存的工作哦确定关闭吗？')
      .then(_ => {
        done();
      })
      .catch(_ => {});
    },
    taskDetail(){},
    getyear(){
      this.yearOptions = []
      var date = new Date();
      let year = date.getFullYear()
      console.log(year)
      for(let i=year-2;i<year+3;i++){
        this.yearOptions.push({ name: i+'年', value: i })
      }
      console.log(this.yearOptions)
    }
  },
  mounted() {
    this.getyear()
    // this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.color_9 { color: $c-9; display: inline-block; width: 56px; overflow: hidden; }
.wrap{ width: 100%; background: #fff; width: 100%; padding: 10px 20px 20px;
  .box-con{ width: 100%; min-height: 100%; background:#fff;
    .tabs{ width: 100%; background:#fff;padding-top: 0; }
    .params-wrap.select{ padding: 0;
      /deep/.el-form-item--small.el-form-item { margin-bottom: 0px; }
      /deep/.el-input__icon{ height: auto; }
    }
  }
  /deep/.paged-page{ padding: 0; }
  .list {
    .head-top{
      .rule { width: 80px; height: 30px; border-radius: 4px; display: inline-block; text-align: center; color: $c-f; line-height: 30px; }
      .bgDanger { background: $c-danger; }
      .bgSuccess { background: $c-success; }
      .bgPrimary { background: $c-primary; }
      .state { font-size: 18px; font-weight: bold; }
    }
    .area { display: inline-block;
      .con-txt { font-size: 18px; font-weight: bold; padding-right: 16px; height: 16px; line-height: 16px; margin: 20px 0 14px; }
    }
    .content { border-top: 1px solid $c-border; margin-top: 15px; padding-top: 15px;
      .content-tit { font-size: 16px; font-weight: bold; }
      .content-con { width: 100%; overflow: hidden; height: 24px;
        .content-p { display: inline-block; width: 80%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
      }
    }
    .footer { text-align: center;
      .task_deta1 { width: 100%; }
      .footer-btn { width: 48%; }
    }
  }
}


/deep/#el-drawer__title{ background:$c-title;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;padding: 20px; }
.details{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; line-height: 14px; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; line-height: 18px; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin: 10px 16px 10px 0;
        .con-txt, .con-statu1, .con-statu2, .con-statu3, .con-statu4 { line-height: 20px; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
        .con-statu1 { color: $c-primary; }
        .con-statu2 { color: $c-success; }
        .con-statu3 { color: $c-danger; }
        .con-statu4 { color: $c-9; }
        .mission{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
      .area1{ margin-top: 10px; 
        .con-txt1 { text-align: right; display: inline-block; width: 60px; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; }
        .mission1{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; margin-left: 20px; line-height: 14px;}
      }
    }
    .headcon{
      .contit{ color: $c-1; padding: 20px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
      .area1{ margin: 10px 0; 
        .con-txt1 { text-align: right; display: inline-block; width: 60px; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; }
        .mission1{ line-height: 14px; display: inline-block; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; margin-left: 20px; line-height: 14px; color: $c-2; }
      }
    }
  }
  .d-bodyFirst{
    .taskcon { padding: 10px 0;
      .contit{ color: $c-1; padding: 10px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .conitem { display: inline-block; width: 240px;
        .conlab, .chang{ color: $c-9; display: inline-block; width: 130px; }
        .chang { width: 200px; }
        .connum{ display: inline-block; width: 100px; }
        .duan { display: inline-block; }
        .conlabAward{ color: $c-9; display: inline-block; }
        .connumAward{ display: inline-block; width: 70px; }
      }
      .conitem_last {  
        display: inline-block; width: 100%;
        .conlab_last { color: $c-9; display: inline-block; width: 13%; }
        .connum_last { display: inline-block; width: 17%; }
      }
    }
  }
  .d-bodySecond{
    .taskcon { padding: 10px 0;
      .contit{ color: $c-1; padding: 10px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .conitem { 
        .conlab{ color: $c-9; display: inline-block; margin-right: 10px; }
        .connum{ display: inline-block; width: 20%; }
      }
    }
  }
  .d-bodyThird{
    .taskcon { padding: 10px 0;
      .contit{ color: $c-1; padding: 10px 0; font-size: 16px; font-weight: bold; line-height: 16px;}
      .contit_stu { font-weight: bold; color: $c-1; padding: 10px 0; font-size: 18px; line-height: 18px; }
      .conitem { 
        .conlab{ color: $c-9; display: inline-block; margin-right: 10px; }
        .connum{ display: inline-block; width: 20%; }
      }
    }
  }
  .d-bodyExam {
    .contit { color: $c-1; padding: 10px 0; font-size: 16px; font-weight: bold; line-height: 16px;}
    .contit_stu { font-weight: bold; color: $c-1; padding: 10px 0; font-size: 18px; line-height: 18px; }
    .taskcon { padding: 10px 0;
      .contit_net { display: inline-block; padding: 10px 0; font-size: 18px; font-weight: bold; line-height: 18px; }
      .contit_num { font-size: 16px; line-height: 16px; display: inline-block; margin-right: 20px; color: $c-9; }
      .contit_box { margin: 10px 0; height: 250px; width: 100%; background: $c-f; border-radius: 6px; padding: 10px; overflow-y: scroll;
        .contit_item { padding: 20px; background: #fff; width: 47%; display: inline-block;margin: 0 14px 14px 0;
          .item_ti { font-size: 16px; margin-bottom: 10px; font-weight: bold; line-height: 16px; }
          .item_txt { color: $c-9; line-height: 14px; }
        }
        .video_box { width: 30%; height: 115px; background: #FFFFFF; border-radius: 4px; border: 1px solid #EAEFF3; display: inline-block; margin-right: 15px;
          .video_ti { width: 100%; height: 115px; 
            img { padding: 0; margin: 0; width: 100%; height: 115px; }
          }
          .video_video { text-overflow:ellipsis; font-size: 16px; overflow: hidden; white-space: nowrap; border-radius: 4px; }
        }
      }
    }
  }
}
.d-con{
  // .contit{ color: $c-9; padding: 20px 0; font-size: 16px;}
  .contit{ color: $c-2; padding: 20px 0; font-size: 16px;}
  .el-collapse{ border: 0; }
  .el-collapse-item{ margin-bottom: 20px; }
  .el-collapse-item__content{padding: 0;}
  .el-collapse-item .is-active{ background:$c-success; border: 1px solid #28B892; color:#fff; }
  /deep/.el-collapse-item__header{ background: #F5F7F9 !important; height: 44px; font-size: 16px; color: $c-2; border-radius: 4px; border: 1px solid #e2e7eb; }
  /deep/.el-collapse-item__header.is-active{ color: #fff; background: #28B892!important; }
  .el-collapse-item__arrow .el-icon-arrow-right{ color: $c-2; }
  .el-collapse-item__arrow .el-icon-arrow-right .is-active{ color: #fff; }
  .colltit{ padding:0 20px;
    .people{ display: inline-block; margin-left:20px;}
  }
  .collcon{ padding: 20px; background: $c-border;min-height: 300px;padding-right: 0;
    .conhead{ color: $c-2; font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC;line-height: 20px;
      span{  font-weight: bold; }
    }
    .content{
      .taskcon {
        .contit{ color: $c-1; padding: 10px 0; font-size: 14px; font-weight: bold; }
        .conitem{ display: inline-block; 
          .conlab, .chang{ color: $c-9; display: inline-block; min-width: 130px;}
          .connum{ display: inline-block; width: 100px; }
          .duan { display: inline-block; }
        }
      }
    }
  }
  .taskdetails{ width:120px; margin-top: 20px; margin-right: 20px;}
}
</style>
