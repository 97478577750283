/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/finance/cashCheck";

const state = {
  cashCheckList:[],
  cashCheckTotal: 0,
  userCashList:[],    // 用户提现列表
  userCashTotal:0,    // 用户提现数量
  userCashInfo:{},    // 用户提现详情
  userCashMoneyInfo:{}, // 用户提现金额统计
};

// actions
const actions = {
 // 列表
  async settleShopWithdrawGetVerifyList({ commit, state }, params) {
    let res = await api.settleShopWithdrawGetVerifyList(params);
    console.log(res)
      res.list.forEach(k => {
        if(k.level == 3){
        let a = k.reviewList[0] ? k.reviewList[0].status : null
        let b = k.reviewList[1] ? k.reviewList[1].status : null
        let c = k.reviewList[2] ? k.reviewList[2].status : null
        k.setps = [
          {
            dept: '区域经理', reviewStatus:a, status: [
              { name: '待审核', value: 2, class:'on' },
              { name: '审核通过', value: 3, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
          {
            dept: '运营总监', reviewStatus:b, status: [
              { name: '待审核', value: 3, class:'on' },
              { name: '审核通过', value: 4, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
          {
            dept: '财务', reviewStatus:c, status: [
              { name: '待审核', value: 4, class:'on' },
              { name: '审核通过', value: 5, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
        ]
      }else if(k.level==2){
          let a = k.reviewList[0] ? k.reviewList[0].status : null
          let b = k.reviewList[1] ? k.reviewList[1].status : null
          k.setps = [
            {
              dept: '区域经理', reviewStatus:a, status: [
                { name: '待审核', value: 2, class:'on' },
                { name: '审核通过', value: 3, class:'finish' },
                { name: '未通过', value: -1, class:'fail' },
              ]
            },
            {
              dept: '财务', reviewStatus:b, status: [
                { name: '待审核', value: 4, class:'on' },
                { name: '审核通过', value: 5, class:'finish' },
                { name: '未通过', value: -1, class:'fail' },
              ]
            },
          ]
      }
      })
    

    commit("saveList", {
      list: res.list,
      total: res.total,
    });
    
    return res;
    
  },
  async settleShopWithdrawGetSum({ commit, state }, params) {
    return await api.settleShopWithdrawGetSum(params);
  },
  async settleShopWithdrawGetDetail({ commit, state }, params) {
    return await api.settleShopWithdrawGetDetail(params);
  },
  async settleShopWithdrawVerify({ commit, state }, params) {
    return await api.settleShopWithdrawVerify(params);
  },
  async netAdminCashNoLoginExportWithSWId({ commit, state }, params) {
    return await api.netAdminCashNoLoginExportWithSWId(params);
  },
  // 用户提现
  // 金额详情
  async getCashMoneyDetail({commit,state},params) {
    let res = await api.getCashMoneyDetail(params)
    commit('saveCashMoney',res)
    return res
  },
  // 提现列表
  async getUserCashCheckList({commit,state},params) {
    let res = await api.getUserCashCheckList(params)
    let {list,total} = res
    commit('saveCashList',{list,total})
    return res 
  },
  // 提现详情
  async getUserCashInfo({commit,state},params) {
    let res = await api.getUserCashInfo(params)
    commit('saveCashInfo',res)
    return res
  },
  // 提现审核
  async checkUserCash({commit,state},params) {
    return await api.checkUserCash(params)
  }
};

//getters
const getters = {};

const mutations = {
  saveList(state, { list, total }) {
    state.cashCheckList = list;
    state.cashCheckTotal = total;
  },
  // 用户统计提现金额
  saveCashMoney(state,data) {
    state.userCashMoneyInfo = data
  },
  // 提现列表
  saveCashList(state,{list,total}) {
    state.userCashList = list
    state.userCashTotal = total
  },
  // 提现详情
  saveCashInfo(state,data) {
    state.userCashInfo = data
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
