/*
 * @Auhtor: 文锋
 * @Date: 2021-05-06 15:38:55
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 16:46:45
 * @FilePath: \operate\src\store\modules\finance\mallOrder.js
 */
import api from "@/api/finance/mallOrder";
import { getOrderSummary } from "../../../api/finance/mallOrder";

const state = {
    mallOrderList:[],
    mallOrderTotal:0
}

const actions = {
    // 获取商城订单列表
    async getMallOrderList({commit,state},params) {
        let res = await api.getMallOrderList(params);
        commit('saveMallOrderList',{
            list: res.list,
            total: res.total
        })
        return res;
    }, 
    async getOrderSummary({commit,state},params) {
        return api.getOrderSummary(params)
    }
}

const getters = {}

const mutations = {
    saveMallOrderList(state,{ list, total }) {
        let lists = list.map(item => {
             item.income = item.halfRate*item.totalFee/10000
             return item
        })
        console.log("===",lists)
        state.mallOrderList = lists
        state.mallOrderTotal = total
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}