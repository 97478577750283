<!--
 * @Auhtor: 文锋
 * @Date: 2021-05-24 11:34:10
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-26 11:50:08
 * @FilePath: \operate\src\components\operate\Schoolhottopic.vue
-->
<template>
  <div class="wrap">
    <div class="box-con">
      <el-form
        class="select params-wrap"
        size="small"
        :inline="true"
        :model="queryParams"
      >
        <el-form-item v-if="!url">
          <area-tree
            @setQueryParams="handleQuery"
            class="year"
            @clear="clearArea(true)"
          ></area-tree>
        </el-form-item>
        <el-form-item >
            <span class="tips">*配置热门话题必须内容数量大于1 且最多只能设置7个</span>
        </el-form-item>
        <el-form-item class="fr">
            <el-button @click="aVisible = true,edit()" size="small" icon="el-icon-plus" style="color:#28B892; border-color:#28B892;" v-if="OA.includes('xyq:rmht:edit')">编辑</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div class="list">
          <paged-table
            :data="hotTopicList"
            :loading="tLoading"
            :isShowSelection="false"
            defaultSortProp="id"
            defaultLabelName="排序"
            v-model="queryParams"
            @refresh="refresh"
          >
            <el-table-column sortable="custom" prop="topicName" show-overflow-tooltip label="话题名称">
              <template slot-scope="scope">
                <div>{{scope.row.topicName}}</div>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="添加时间"></el-table-column>
            <el-table-column sortable="custom" prop="issueNum" show-overflow-tooltip label="讨论"></el-table-column>
            <el-table-column sortable="custom" prop="viewNum" show-overflow-tooltip label="浏览量"></el-table-column>
            <el-table-column sortable="custom" prop="clickNum" show-overflow-tooltip label="点击量"></el-table-column>
          </paged-table>
      </div>
      <!-- 编辑 -->
      <el-dialog title="编辑热门话题" center :visible.sync="aVisible" width="700px">
        <el-form  label-width="80px">
          <el-form-item :label="'话题'+ (index + 1)" v-for="(item,index) in list" :key="index">
            <el-select ref='selectForm' :value="form[index].topicId" placeholder="请选择话题" filterable @change="listChange($event, index)" clearable @clear="clearArr(index)">
              <el-option v-for="item in selectHotTopicList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item >
            <el-button class="submit" @click="saveHotTopic">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 详情 --> 
     
      <!-- 提示 -->
    </div>
  </div>
</template>
<script>
import { mapState,mapActions } from 'vuex'
import AreaTree from "@/components/common/AreaTree";
import PagedTable from "@/components/common/PagedTable"
import pageMixin from "@/mixins/pageMixin";

export default {
  name: "schoolhottopic",
  mixins: [pageMixin],
  components: {
    AreaTree,
    PagedTable
  },
  data() {
    return {
      aVisible:false,
      queryParams: {},
      list: new Array(7),
      select0:{},
      form:[
          {topicId:null,sort:0},
          {topicId:null,sort:1},
          {topicId:null,sort:2},
          {topicId:null,sort:3},
          {topicId:null,sort:4},
          {topicId:null,sort:5},
          {topicId:null,sort:6},
      ],
      pageApi:'getHotTopicList'
    };
  },
  computed:{
    ...mapState(["OA"]),
    ...mapState('schoolFellow',['hotTopicList','selectHotTopicList'])
  },
  methods: {
    ...mapActions('schoolFellow',['getHotTopicList','getSelectAllTopic','updateHotTopic']),
   
    // 修改话题
    async saveHotTopic() {
        // 数组查重
        let isRepeat = true
        this.form.forEach(k=>{
            if(!isRepeat) return;
            if(this.form.filter(item=>item.topicId == k.topicId && item.topicId != null).length > 1){
                this.$message.warning('话题不得重复')
                isRepeat = false
            }
        })
        if (isRepeat) {
          let params = this.form.filter(item => item.topicId != null)
          params = params.map(item=>{
            return {
              topicId:item.topicId,
              sort: item.sort
            }
          })
          console.log('sdfsdf',params)
          let res = await this.updateHotTopic(params)
          if (res == '更新成功') {
            this.$message.success('编辑话题成功')
            this.aVisible = false
            this.refresh()
          }
        }
    },
    async edit(){
      await this.getSelectAllTopic({issueNum:1})
      this.hotTopicList.map((item,index)=>{
        this.form[index].topicId = item.topicId
        //  this.form[index].sort = item.sort
      })
    },
    listChange(val, idx){
        // console.log(val)
        // console.log(idx)
        this.$refs['selectForm']
        if(val){
            let row = this.selectHotTopicList.find(item=>item.id == val)
           // this.form[idx].sort = idx
            this.form[idx].topicId = row.id
        }     
        console.log('sdffsd',this.form)  
    },
    clearArr(index){
      this.form[index].topicId = null
    },
    // 区域查询
     handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
      },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.refresh()
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
  },
};
</script>
<style lang="scss" >
@import "@/assets/scss/theme.scss";
* {
  font-family: PingFangSC-Regular, PingFang SC;
}
.wrap {
  width: 100%;
  height: 100%;
  .box-con {
    width: 100%;
    min-height: 100%;
    background: #fff;
    padding: 20px;
    .select {
      .year {
        /deep/.el-input__inner {
          background: url("../../assets/images/he/operate/area.svg") no-repeat;
          background-size: 12px 14px;
          background-position: 8px 7px;
          padding: 0 0 0 30px;
          box-sizing: border-box;
          font-size: 14px;
        }
      }
      .tips {text-align: center;font-size:12px ; color: $c-danger;line-height: 28px;}
    }
    .list {
      margin:0 20px;
      .operate{
        color: $c-primary;
        margin: 0 20px;
        border-bottom: 1px solid $c-primary;
        text-decoration-line: underline;
      }
    }
    .w410 {width: 410px;}
    .submit{color: #fff;background-color: $c-success;width: 300px;}
    /deep/.components-upload .avatar-uploader-icon {width: 240px; height: 135px; color: $c-success; font-size: 30px; font-weight: bold;}
    .txt{color: #AFAFAF;}
    .edit {
      display: flex;
      justify-content: center;
      .btn {
        width: 140px;
        background-color: #fff;
        &.submit {
          color: #fff;
          background-color: $c-success;
        }
      }
    }
  }
}
</style>