/* eslint-disable no-unused-vars */
const ResolveMessage = () => import ('@/components/task/ResolveMessage')
const MyTask = () => import ('@/components/task/MyTask')
const DirectorAddTask = () => import ('@/components/task/myTask/DirectorAddTask')
const DirectorDistributeTask = () => import ('@/components/task/myTask/DirectorDistributeTask')
const DirectorAddStudyTask = () => import ('@/components/task/myTask/DirectorAddStudyTask')

const ManagerDistributeTask = () => import ('@/components/task/myTask/ManagerDistributeTask')
const CeoDistributeTask = () => import ('@/components/task/myTask/CeoDistributeTask')
const CeoAddStudyTask = () => import ('@/components/task/myTask/CeoAddStudyTask')

const Allow = () => import ('@/components/task/Allow')
const Current = () => import ('@/components/task/Current')
const TaskAllocation = () => import ('@/components/task/TaskAllocation')
// // const MyTask = () => import ('@/components/task/myTask/MyTask')
// const Dist = () => import ('@/components/task/myTask/Dist')

export default {
  route:[
    {
      path: "resolveMessage",
      name: "分解信息",
      component: ResolveMessage,
    },
    {
      path: "myTask",
      name: "我的任务",
      component: MyTask,
    },
    {
      path: "directorAddTask",
      name: "新增常规任务", 
      component: DirectorAddTask 
    },
    {
      path: "directorDistributeTask",
      name: "常规任务 / 任务分配", 
      component: DirectorDistributeTask 
    },
    {
      path: "directorAddStudyTask",
      name: "新增学习任务", 
      component: DirectorAddStudyTask 
    },

    {
      path: "managerDistributeTask",
      name: "常规任务 / 任务分解", 
      component: ManagerDistributeTask 
    },

    {
      path: "ceoDistributeTask",
      name: "常规任务 / 任务分解", 
      component: CeoDistributeTask 
    },
    {
      path: "ceoAddStudyTask",
      name: "新增学习任务", 
      component: CeoAddStudyTask 
    },
    {
      path: "taskAllocation",
      name: "任务分配",
      component: TaskAllocation
    },
    {
      path: "allow",
      name: "执行任务",
      component: Allow
    },
    {
      path: "current",
      name: "当前任务",
      component: Current
    },
    
    // {
    //   path: "/school",
    //   component: null,
    //   name:'教师中心 ',
    //   redirect: '/school/schinfo',
    //   children: [
    //     { 
    //       path: "schinfo",
    //       name: "个人信息 ", 
    //       component: SchInfo 
    //     },
    //   ]
    // }
  ]
};
