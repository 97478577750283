<template>
  <div>
    <!-- 提现页面 老版不显示 -->
    <!-- 搜索栏 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" label-width="120px">
      <el-form-item>
        <div style="display:inline-block;">余额： <div class="lable-num">{{ '500.00' }}</div> 元</div> &nbsp;
        <el-button type="primary" @click="expendVisible = true">提现</el-button>
      </el-form-item>
      <el-form-item>
        <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search" @click="refresh">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-link :href="BaseUrl + 'net//admin/report/noLogin/export?mergeFlag=0'" class="button_download">
          <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
        </el-link>
      </el-form-item>
      <br>
      <el-form-item>
        <div style="display:inline-block;">总营业额： <div class="lable-num">{{ '500.00' }}</div> 元 </div>
      </el-form-item>
      <el-form-item>
        <div style="display:inline-block;">平台分成： <div class="lable-num">{{ '500.00' }}</div> 元 </div>
      </el-form-item>
      <el-form-item>
        <div style="display:inline-block;">冻结金额： <div class="lable-num">{{ '500.00' }}</div> 元 </div>
      </el-form-item>
      <el-form-item>
        <div style="display:inline-block;">总收入： <div class="lable-num">{{ '500.00' }}</div> 元 </div>
      </el-form-item>
    </el-form>

    <div class="content-wrap">
      <el-tabs type="border-card">
        <!-- 收入明细 -->
        <el-tab-pane label="收入明细">

          <paged-table :data="depositList" :total="depositTotal"
            :loading="tLoading" :isShowSelection="false"
            defaultSortProp="id" v-model="queryParams"
            @refresh="refresh">
            <el-table-column prop="cardNumber" label="交易时间" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="predictValue" label="交易号" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="addTime" label="交易事项" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="extend.addUserName" label="交易方式" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="商品说明" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="120" prop="extend.addUserName" label="订单金额（元）" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="120" prop="extend.addUserName" label="运营分成（元）" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="商品名" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="订单号" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="120" prop="extend.addUserName" label="实际收入（元）" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100">
              <template>
                <span v-if="0">冻结中</span>
                <span v-if="0">已到账</span>
              </template>
            </el-table-column>
          </paged-table>
        </el-tab-pane>

        <!-- 支出明细 -->
        <el-tab-pane label="支出明细">
          <paged-table :data="expendList" :total="expendTotal"
            :loading="tLoading" :isShowSelection="false"
            defaultSortProp="id" v-model="queryParamsB"
            @refresh="expendRequire">
            <el-table-column prop="cardNumber" label="交易时间" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="predictValue" label="交易号" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="addTime" label="交易事项" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="extend.addUserName" label="交易方式" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="金额（元）" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="打款方" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="extend.addUserName" label="余额（元）" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100">
              <template>
                <span v-if="0">待审核</span>
                <span v-if="0">已打款</span>
                <span v-if="0">审核不通过</span>
                <span v-if="0">待打款</span>
                <span v-if="1"></span>
              </template>
            </el-table-column>
          </paged-table>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'deposit',
  components: {
    PagedTable,
    BaseImport
  },
  mixins: [pageMixin,provinces,download,datePicker],
  data() {
    return {
      BaseUrl,
      expendVisible: false,
      queryParams: {
        cardNumber:''
      },
      queryParamsB: {
        cardNumber:''
      },
      form:{
        id:'',
        schoolName:'',
        department:'',
        province:'',
        city:'',
        area:'',
        level: '',
        remark: ''
      },
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      detailsB:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      expendList: [],
      expendTotal: 0,

      pageApi:'getNumber',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("deposit", ["depositList", "depositTotal"]),
  },
  methods: {
    ...mapActions("number",["getNumber", "getNumberImport", "adminCardInfoEdit", "adminCardInfoDelete",'numberGetNumbers']),
    async expendRequire(){
      // let res = await this.aaaaa({...this.queryParamsB})
      // this.expendList = res.list
      // this.expendTotal = res.total
    },
  },
  async mounted(){
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
.lable-num { width: 100px; text-align: center; background: #28B892; color: #fff; border-radius: 5px; display: inline-block; }
</style>
