import Vue from 'vue'
import App from "./App";
import router from "./router";
import store from "./store/index";
import ElementUI from 'element-ui';
import '@/assets/scss/theme/index.css';
import apiCommon from "@/api/common";
import Viewer from 'v-viewer'
import AMap from 'vue-amap'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer,{
                  defaultOptions: {
                      zIndex: 9999
                  }
                }
        )
Viewer.setDefaults({
  Options: {
    inline: true,
    button: false,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})

Vue.use(AMap)

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 申请的高德key
  key: '5d314d065ccc92721a60df351a2f2400',
  // 插件集合
  plugin: ['AMap.Geolocation','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geocoder']
})

Vue.config.productionTip = false
Vue.use(ElementUI);
apiCommon.init();

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
