<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- 入驻管理 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams" label-width="100px">
        <el-form-item v-if="!url">
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="执行状态：">
          <el-select v-model="queryParams.status" @change="resetPageAndRefresh()" clearable filterable placeholder="请选择执行状态">
            <el-option v-for="item in advStatusOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="商铺类型：">
          <el-button  @click="queryParams.shopType=104003002,queryParams.mallType='',resetPageAndRefresh()">通信大厅</el-button>
          <el-button  @click="queryParams.shopType=104003001,queryParams.mallType='',resetPageAndRefresh()">网络大厅</el-button>
          <el-select  v-model="queryParams.mallType"  @change="queryParams.shopType = queryParams.mallType,resetPageAndRefresh()" clearable filterable placeholder="商城" style="width:100px;margin-left:10px">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-input placeholder="请输入公司名称" size="small" clearable v-model="queryParams.companyName" style="width:200px;margin-left:20px" >
          </el-input>
          <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
        </el-form-item >
      </el-form>
      <div class="btnBox">
        <el-button class="fr" size="small" @click="aVisible = true" v-if="OA.includes('rzgl:rzsh:add')" >新增</el-button>
      </div>
      <div v-if="settleTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="settlList" :isEditor="isEditor" :total="settleTotal" @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="list" @click="load(scope.row.id)">
            <div class="list-top">
              <div class="top_head">
                <span>{{scope.row.company.companyName}}</span>
                <div v-if="ceo == 'ceo'">
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==1">待审核</span>
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==2">审核通过</span>
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==3">区域经理审核通过</span>
                  <span class="aduitStatus tinctgreen fr" v-if="scope.row.status==4">运营总监审核通过</span>
                  <span class="aduitStatus tinctred fr" v-if="scope.row.status==-1">未通过</span>
                </div>
                <div v-if="areaManager == 'areaManager'">
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==2">待审核</span>
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==3">审核通过</span>
                  <span class="aduitStatus tinctgreen fr" v-if="scope.row.status==4">运营总监审核通过</span>
                  <span class="aduitStatus tinctred fr" v-if="scope.row.status==-1">未通过</span>
                </div>
                <div v-if="sysCOO == 'sysCOO'">
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==3">待审核</span>
                  <span class="aduitStatus tinctgreen fr" v-if="scope.row.status==4">审核通过</span>
                  <span class="aduitStatus tinctred fr" v-if="scope.row.status==-1">未通过</span>
                </div>
                <div v-if="role == 'xtgly'">
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==1">等待校园ceo审核</span>
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==2">等待区域经理审核</span>
                  <span class="aduitStatus tinctblue fr" v-if="scope.row.status==3">等待运营总监审核</span>
                  <span class="aduitStatus tinctgreen fr" v-if="scope.row.status==4">审核通过</span>
                  <span class="aduitStatus tinctred fr" v-if="scope.row.status==-1">审核未通过</span>
                </div>
              </div>
              <div class="top_con">
                <span :class="scope.row.company.idStatus?'yesCon':'noCon'">法人证件 
                  <img :src="scope.row.company.idStatus?require('@/assets/images/operate/yes.svg'):require('@/assets/images/operate/no.svg')" alt=""></span>
                <span :class="scope.row.company.saStatus?'yesCon':'noCon'">入驻协议 
                  <img :src="scope.row.company.saStatus?require('@/assets/images/operate/yes.svg'):require('@/assets/images/operate/no.svg')" alt="">
                </span>
                <span :class="scope.row.company.blStatus?'yesCon':'noCon'">营业执照 
                  <img :src="scope.row.company.blStatus?require('@/assets/images/operate/yes.svg'):require('@/assets/images/operate/no.svg')" alt="">
                </span>
                <span :class="scope.row.company.baStatus?'yesCon':'noCon'">品牌授权 
                  <img :src="scope.row.company.baStatus?require('@/assets/images/operate/yes.svg'):require('@/assets/images/operate/no.svg')" alt="">
                </span>
              </div>
              <div class="top_foot">
                <div class="area">
                  <p> <span class="time">申请时间</span><span class="con">{{scope.row.applicationTime?scope.row.applicationTime:'-'}}</span> </p>
                  <p> <span class="time">企业类型</span><span class="con">{{scope.row.company.extend.enterpriseTyeName?scope.row.company.extend.enterpriseTyeName:'-'}}</span> </p>
                </div>
                <div class="area">
                  <p> <span class="time">申请人</span><span class="con">{{scope.row.extend.applyName?scope.row.extend.applyName:'-'}}</span> </p>
                  <p> <span class="time">商铺类型</span><span class="con" v-if="scope.row.company.extend">{{scope.row.company.extend.shopTypeName?scope.row.company.extend.shopTypeName:'-'}}</span> </p>
                </div>
                <div class="area">
                  <p> <span class="time">手机号码</span><span class="con">{{scope.row.phone?scope.row.phone:'-'}}</span> </p>
                  <p>
                    <span class="time">经营范围</span><span class="con textWidth" :title="scope.row.company.scopeName"> <span v-for="(item,index) in scope.row.company.scopeName" :key="index">{{item}}</span>
                    </span> 
                  </p>
                </div>  
              </div>
            </div>
            <div class="list-bottom" >
              <div class="bottom_head">
                <span>审核情况</span>
                <el-button type="success" size="small" class="fr" @click="drawer=true" v-if="scope.row.status==1">前往审核</el-button>
              </div>
              <div class="step" v-if="scope.row.reviewers">
                <div class="steps_item" v-for="(item,index) in scope.row.setps" :key="index"
                  :style="{ left: 100 / (scope.row.setps.length-1) * index +  '%' }"
                  :class="[
                    { fail: item.status.find(f=>f.class=='fail').value === item.reviewStatus },
                    { on: item.status.find(f=>f.class=='on').value === item.reviewStatus },
                    { finish: item.status.find(f=>f.class=='finish').value === item.reviewStatus },
                    { first: index == 0  },
                    { last: index ==scope.row.setps.length-1},
                  ]"
                  > 
                  <div class="status statusName" v-if="item.status.find(f=>f.value==item.reviewStatus)">
                    {{ item.status.find(f=>f.value==item.reviewStatus).name }}
                  </div>
                  <!-- <div class="status statusName" v-if="item.status.find(f=>f.value==item.reviewStatus)">
                    {{ item.status.find(f=>f.value==item.reviewStatus).name }}
                  </div> -->
                  <div class="dept deptName" v-if="item.dept" >{{ item.dept }}</div>
                  <div class="name doubleName" v-if="item.name">{{ item.name }}</div>
                </div>
                <base-steps :steps="scope.row.setps.length" :active='setActive(scope.row.setps, scope.row.reviewers)'>
                </base-steps>
              </div>
            </div>
          </div>
        </template>
        <template  slot="control" slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="editSettle(scope)" v-if="OA.includes('yygl:gggl:editor')" circle></el-button>
          <!-- <el-button type="danger" @click="handleDelete({id: scope.row.id})" v-if="OA.includes('yygl:gggl:del')" icon="el-icon-delete" title="删除" circle></el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 新增 -->
    <el-dialog :title="isEditor ? '编辑公司' : '新增公司'" :visible.sync="aVisible" width="700px" :close-on-click-modal="false" :before-close="closeDialog">
      <el-form label-width="130px" ref="formFileds" :model="form" :rules="formRules" size="small" >
        <el-form-item label="手机号：" prop="phone">
          <el-input placeholder="请输入手机号" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="法人名称：" prop="company.corporateName">
          <el-input placeholder="请输入法人名称" v-model="form.company.corporateName"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="company.companyName">
          <el-input placeholder="请输入公司名称" v-model="form.company.companyName"></el-input>
        </el-form-item>
        <el-form-item label="企业类型：" prop="company.enterpriseType">
          <el-select clearable filterable v-model="form.company.enterpriseType" placeholder="请选择企业类型">
            <el-option v-for="item in enterType" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商铺类型：" prop="company.shopType">
          <el-cascader placeholder="请选择商铺类型" v-model="valueB" :options="dataTreeC" accordion :props="{ checkStrictly: true, ...defaultPropsC }" @change="handleNode" clearable ref="cascader" style="width:530px"></el-cascader>
        </el-form-item>
        <el-form-item label="经营范围：" prop="company.companyScopeList">
          <el-cascader style="width:530px" v-model="valueA" :options="dataTreeB" accordion :props="{ multiple: true,...defaultPropsB }" @change="handleNodeClick" clearable ref="cascader" ></el-cascader>
        </el-form-item>
        <el-form-item label="经纬度：" prop="company.longitude">
          <el-input v-model="form.company.longitude" placeholder="经度" readonly style="width:200px"></el-input>
          <el-input v-model="form.company.latitude" placeholder="纬度" readonly style="width:200px; margin-left:30px"></el-input>
          <i class="el-icon-location" @click="cVisible = true" style="margin-left:30px; font-size:30px; color:#28B892; vertical-align:middle;"></i>
        </el-form-item>
        <el-form-item label="公司地址：" prop="company.companyAddress">
          <el-input v-model="form.company.companyAddress" clearable placeholder="请输入公司地址"></el-input>
        </el-form-item>
        <el-form-item label="营业执照：" prop="company.businessLicense" class="logo">
          <base-upload v-model="form.company.businessLicense" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload> <span class="size"></span>
        </el-form-item>
        <el-form-item label="品牌授权：" prop="company.brandAuthorized" class="logo">
          <base-upload v-model="form.company.brandAuthorized" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload> <span class="size"></span>
        </el-form-item>
        <el-form-item label="法人身份证正面：" prop="company.corporateIdFront" class="logo">
          <base-upload v-model="form.company.corporateIdFront" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload> <span class="size"></span>
        </el-form-item>
        <el-form-item label="法人身份证反面：" prop="company.corporateIdBack" class="logo">
          <base-upload v-model="form.company.corporateIdBack" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload> <span class="size"></span>
        </el-form-item>
        <el-form-item label="入驻协议：" prop="company.annexIds">
          <base-upload v-model="form.company.annexIds" fileType="file" :limit="4" tips @beforeUpload="handleBeforeUpload" :uploadData="uploadData"></base-upload>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>
    <!-- 详情+审核 -->
    <el-drawer
      append-to-body
      title="公司详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
      <div class="company">
        <div class="company-info">
         <p class="companyTitle">基础信息</p>
         <div class="top_head">
            <span v-if="details.company">{{details.company.companyName}}</span>
            <span class="aduitStatus tinctblue fr" v-if="details.status==1">等待校园ceo审核</span>
            <span class="aduitStatus tinctblue fr" v-if="details.status==2">等待区域经理审核</span>
            <span class="aduitStatus tinctblue fr" v-if="details.status==3">等待运营总监审核</span>
            <span class="aduitStatus tinctgreen fr" v-if="details.status==4">审核通过</span>
            <span class="aduitStatus tinctred fr" v-if="details.status== -1">审核未通过</span>
          </div>
          <div class="top_foot" v-if="details.company">
            <div class="area">
              <p> <span class="time">申请时间</span><span class="con">{{details.applicationTime?details.applicationTime:'-'}}</span> </p>
              <p> <span class="time">法人姓名</span><span class="con">{{details.company.corporateName?details.company.corporateName:'-'}}</span> </p>
              <p> <span class="time">企业类型</span><span class="con">{{details.company.extend.enterpriseTyeName?details.company.extend.enterpriseTyeName:'-'}}</span> </p>
            </div>
            <div class="area" v-if="details.extend">
              <p> <span class="time">申请人</span><span class="con">{{details.extend.applyName?details.extend.applyName:'-'}}</span> </p>
              <p> <span class="time">手机号码</span><span class="con">{{details.phone?details.phone:'-'}}</span> </p>
              <p> <span class="time">商铺类型</span><span class="con">{{details.company.extend.shopTypeName?details.company.extend.shopTypeName:'-'}}</span> </p>
            </div>
            <div class="area" >
              <p> <span class="time">经营范围</span><span class="detail-area" :title="details.company.scopeName"><span v-for="(item,index) in details.company.scopeName" :key="index">{{item+'、'}}</span></span> </p>
              <p class="address"> <span class="time">公司地址</span><span class="con">{{details.company.companyAddress?details.company.companyAddress:'-'}}</span>
               <!-- <a class="fr" >查看地址</a> -->
               <el-button type="text" size="default" @click="cVisible = true" class="fr">查看地址</el-button>
               </p>
            </div>
          </div>
        </div>
        <div class="company-id" v-if="details.company">
          <p class="companyTitle">证件信息</p>
          <div class="uploadBigBox">
            <div class="uploadBox">
              <div class="imgBox"  v-viewer>
                <img :src="details.company.businessLicense" alt="">
              </div>
              <p>营业执照</p>
            </div>
            <div class="uploadBox" v-viewer>
              <div class="imgBox">
                <img :src="details.company.brandAuthorized" alt="">
              </div>
              <p>品牌授权</p>
            </div>
            <div class="uploadBox" v-viewer>
              <div class="imgBox">
                <img :src="details.company.corporateIdFront" alt="">
              </div>
              <p>法人身份证（人像面）</p>
            </div>
            <div class="uploadBox" v-viewer>
              <div class="imgBox">
                <img :src="details.company.corporateIdBack" alt="">
              </div>
              <p>法人身份证（国徽面）</p>
            </div>
          </div>
        </div>
        <div class="company-enter" v-if="details.company">
          <p class="companyTitle">入驻协议信息</p>
          <div class="uploadAtt">
            <el-button style="margin-right:10px;margin-bottom:20px;" v-for="(item,index) in details.company.fileList" @click="downloadPath(item.url)" :key="index">{{item.name}}</el-button>
          </div>
        </div>
        <div class="company-aduit">
          <p class="companyTitle">审核情况</p>
          <div class="step" v-if="details.setps">
            <div class="steps_item" v-for="(item,index) in details.setps" :key="index"
              :style="{ left: 100 / (details.setps.length-1) * index +  '%' }"
              :class="[
                { fail: item.status.find(f=>f.class=='fail').value === item.reviewStatus },
                { on: item.status.find(f=>f.class=='on').value === item.reviewStatus },
                { finish: item.status.find(f=>f.class=='finish').value === item.reviewStatus },
                { first: index == 0  },
                { last: index == details.setps.length-1},
              ]"
              >
              <div class="status statusName" v-if="item.status.find(f=>f.value==item.reviewStatus)">
                {{ item.status.find(f=>f.value==item.reviewStatus).name }}
              </div>
              <div class="dept deptName" v-if="item.dept" >{{ item.dept }}</div>
              <div class="name doubleName" v-if="item.name">{{ item.name }}</div>
              <div class="time" v-if="item.time">
                <div>审核时间</div>
                {{ item.time }}
              </div>
              <div class="time" v-if="item.reason">
                <div>未通过原因</div>
                {{ item.reason }}
              </div>
            </div>
            <base-steps :steps="details.setps.length" :active='setActive(details.setps, details.reviewers)'></base-steps>
          </div>
        </div>
        <div class="aduitBox" v-if="details.status==1 && ceo=='ceo'">
          <p class="companyTitle" >ceo审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef"  label-width="100px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status">
                <el-radio :label="2" v-if="details.status==1">审核通过</el-radio>
                <el-radio :label="3" v-if="details.status==2">审核通过</el-radio>
                <el-radio :label="4" v-if="details.status==3">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" >
              <el-input :disabled="ruleForm.status==2 || ruleForm.status==3 || ruleForm.status==4" type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="aduitBox" v-if="details.status==2 && areaManager=='areaManager'">
          <p class="companyTitle">区域经理审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef"  label-width="100px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status">
                <el-radio :label="2" v-if="details.status==1">审核通过</el-radio>
                <el-radio :label="3" v-if="details.status==2">审核通过</el-radio>
                <el-radio :label="4" v-if="details.status==3">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" >
              <el-input :disabled="ruleForm.status==2 || ruleForm.status==3 || ruleForm.status==4" type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="aduitBox" v-if="details.status==3 && sysCOO=='sysCOO'">
          <p class="companyTitle">运营总监审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef"  label-width="100px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status">
                <el-radio :label="2" v-if="details.status==1">审核通过</el-radio>
                <el-radio :label="3" v-if="details.status==2">审核通过</el-radio>
                <el-radio :label="4" v-if="details.status==3">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" >
              <el-input :disabled="ruleForm.status==2 || ruleForm.status==3 || ruleForm.status==4" type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>

    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
      <div class="map-container" v-if="cVisible && details.company">
        <maps :lng="parseFloat(details.company.longitude)" :lat="parseFloat(details.company.latitude)" @finish="setLngLat1"></maps>
      </div>
    </el-dialog>


  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import BaseSteps from "@/components/common/BaseSteps";
import pageMixin from "@/mixins/pageMixin";
import download from "@/mixins/download";
import BaseUpload from "../common/BaseUpload.vue";
import Maps from '@/components/common/Maps';
import { log, mergeObject } from '@/common/utils'
export default {
  name:'enterAduit',
  components: {
    PagedList,
    AreaTree,
    BaseUpload,
    BaseSteps,
    Maps
  },
  mixins: [pageMixin,download],
  data() {
    return {
      cVisible:false,
      dataTreeC:[],
      defaultPropsC:{
        children: "children",
        label: "name",
        value: "id",
      },
      dataTreeB: [],
      defaultPropsB: {
        children: "children",
        label: "name",
        value: "id",
      },
      valueA: null,
      valueB:null,
      ceo:'',
      areaManager:'',
      sysCOO:'',
      role:'',
      enterType:[],
      storeType:[],
      form:{
        phone:'',
        company:{
          longitude:'',
          latitude:'',
          companyScopeList:[],
          corporateName:'',
          companyName:'',
          enterpriseType:'',
          shopType:'',
          scopeType:'',
          scopeSchool:'',
          scopeArea:'',
          companyAddress:'',
          businessLicense:'',
          brandAuthorized:'',
          corporateIdFront:'',
          corporateIdBack:'',
          annexIds:[],
        }
      },
      province:'',
      city:'',
      area:'',
      schoolId:'',
      distId:'',
      formRules:{
        phone:[{ required: true, message: "请输入手机号", trigger: "blur" }],
        company:{
          corporateName: [{ required: true, message: "请输入法人名称", trigger: "blur" }],
          companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
          enterpriseType: [{ required: true, message: "请选择企业类型", trigger: "blur" }],
          shopType: [{ required: true, message: "请选择商铺类型", trigger: "blur" }],
          scopeType: [{ required: true, message: "请选择经营范围类型", trigger: "blur" }],
          companyAddress: [{ required: true, message: "请输入公司地址", trigger: "blur" }],
          annexIds:[{ required: true, message: "请上传协议文件", trigger: "blur" }],
          companyScopeList: [{ required: true, message: "请选择经营范围", trigger: "blur" }],
          businessLicense:[{ required: true, message: "请上传图片", trigger: "blur" }],
          // brandAuthorized:[{ required: true, message: "请上传图片", trigger: "blur" }],
          corporateIdFront:[{ required: true, message: "请上传图片", trigger: "blur" }],
          corporateIdBack:[{ required: true, message: "请上传图片", trigger: "blur" }],
        }
      },
      aVisible:false,
      ruleForm: {
        status:'',
        reason:''
      },
      rules:{},
      annexId:'',
      picsGroup1:'',
      reviewers:[],
      active: 1,
      details:{
      },
      drawer: false,
      statusOptions:[],
      positonList: [],
      queryParams: {
        pageNum:10,
        pageSize: 1,
      },
      advStatusOptions: [
       { status: '', lable: "全部" },
       { status: '待审核', lable: "待审核" },
       { status: '已审核', lable: "已审核" },
       { status: '已通过', lable: "已通过" },
       { status: '未通过', lable: "未通过" },
      ],
      uploadData: {
        bizType: "",
        bizId: "",
        needStore: "1",
      },
      matchStatus:'',



      pageApi: 'settleList',
      insertApi: 'settleAdd',
      // deleteApi: 'advertDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('enterAduit',["settlList","settleTotal"]),
    
  },
  methods: {
    ...mapActions('enterAduit',["settleList","settleAdd","settleReview","settleLoad","dictGetDictFPid","getSchoolListByCity","getLoginUser","getAllDictTreeFPid"]),
    ...mapActions("basicApartment", [ "membersSchoolGetSchoolDistTree",]),
    closeDialog() {
      this.aVisible = false
      this.valueA = ''
      this.valueB = ''
      this.form.company.latitude = ''
      this.$refs.formFileds.resetFields()
    },
      // 添加表单提交
    async submitForm() {
      let beforeValid = true;
      let params = Object.assign({}, this.form)
      params.company.annexIds = params.company.annexIds.map(v=>v.id)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            try {
              let res = await this.settleAdd(params)
              this.$message.success('数据保存成功！')
              this.closeDialog()
            }catch(e){
              log(e)
            }finally{
              this.refresh()
            }
          })
        }
      })
    },
    setLngLat1(val){
      this.form.company.longitude = val[0]
      this.form.company.latitude = val[1]
      this.form.company.companyAddress = val[2]
      this.cVisible = false
    },
    async areaTree(){
      this.dataTreeB = await this.membersSchoolGetSchoolDistTree({code: "area_cn"})
    },
    handleNode(data) {
      this.form.company.shopType = data[data.length-1]
    },
    handleNodeClick(data) {
      console.log(data)
      // this.form.company.companyScopeList[]
      let obj = {}
      let Arr = []
      data.forEach(ele=>{
          ele.forEach(item=>{
            if ((item + "").length === 9) { this.province = item }
            if ((item + "").length === 12) { this.city = item  }
            if(ele.length===4){ this.schoolId = ele[ele.length-2] }
            if(ele.length===4){ this.distId = ele[ele.length-1] }
            if(ele.length===3){ this.schoolId = ele[ele.length-1] }
            obj = {
              province:this.province,
              city:this.city,
              schoolId:this.schoolId,
              distId:this.distId,
            }
          })
          Arr.push(obj)
          this.form.company.companyScopeList = Arr
      })
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    editSettle (row) {
      console.log(row)
    },
    async load(id) {
      this.details = await this.settleLoad({id})
      console.log('sdfasdfasf',this.details)
      this.drawer = true

      let a = this.details.reviewers[0] ? this.details.reviewers[0].status : null
      let b = this.details.reviewers[1] ? this.details.reviewers[1].status : null
      let c = this.details.reviewers[2] ? this.details.reviewers[2].status : null
      let timeAduit1 = this.details.reviewers[0] ? this.details.reviewers[0].reviewerTime : null
      let timeAduit2 = this.details.reviewers[1] ? this.details.reviewers[1].reviewerTime : null
      let timeAduit3 = this.details.reviewers[2] ? this.details.reviewers[2].reviewerTime : null
      let reasonA = this.details.reviewers[0] ? this.details.reviewers[0].reason : null
      let reasonB = this.details.reviewers[1] ? this.details.reviewers[1].reason : null
      let reasonC = this.details.reviewers[2] ? this.details.reviewers[2].reason : null
      this.details.setps = [
        {
          name: this.details.ceo, dept: 'CEO', reviewStatus:a,time:timeAduit1,reason:reasonA, status: [
            { name: '待审核', value: 1, class:'on' },
            { name: '审核通过', value: 2, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
        {
          name: this.details.qyjl, dept: '区域经理', reviewStatus:b,time:timeAduit2,reason:reasonB,status: [
            { name: '待审核', value: 2, class:'on' },
            { name: '审核通过', value: 3, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
        {
          name: this.details.yyzj, dept: '运营总监', reviewStatus:c,time:timeAduit3,reason:reasonC,status: [
            { name: '待审核', value: 3, class:'on' },
            { name: '审核通过', value: 4, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
      ]
    },
    handleBeforeUploadAvatar(img, callback) {
      const isImg2M = img.size / 1024 / 1024 < 100;
      const isImg =
        img.name.includes("jpg") ||
        img.name.includes("png");
      if (!isImg) {
        this.$message.error("只能上传JPG/PNG!");
        return;
      }
      if (!isImg2M) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return;
      }
      callback(isImg && isImg2M);
    },
     // 文件上传
    handleBeforeUpload(file, callback) {
      const isPicture = file.name.includes('pdf') || file.name.includes('jpg') || file.name.includes('png');
			if (!isPicture) {
          this.$message.error('允许上传pdf、jpg、png格式，请上传正确的文件格式!');
      }
      // log(isPicture)
		  callback(isPicture)
    },
    // 取消按钮
    cancel() {
    },
    // 审核按钮
    async aduitResult(id) {
      // console.log(res)
      this.ruleForm.id = id
      const res = await this.settleReview(this.ruleForm)
      this.drawer = false
      this.ruleForm = {}
      this.refresh()

    },
    setActive(setps,view){
      if(setps[view.length-1]) {
        let active = view.length
        if(setps[view.length-1].status.find(f=>f.class=='finish').value === setps[view.length-1].reviewStatus){
          if(active==3) {
            active ++
            active=3
          }
        }
        return active
      }
    }
  },
  async mounted() {
    this.refresh()
    this.dataTreeC = await this.getAllDictTreeFPid({pid:104003})
    this.enterType = await this.dictGetDictFPid({pid:104002})
    this.storeType = await this.dictGetDictFPid({pid:104003})
    this.statusOptions = await this.dictGetDictFPid({pid:104003})
    this.positonList = await this.dictGetDictFPid({pid:104003003})
    const res = await this.getLoginUser()
    console.log('asdasd',res)
    const arr = res.roles
    arr.forEach(item=>{
      if( item == 'xtgly') {
        this.role = item
      }
      if(item == 'ceo') {
        this.ceo = item
      }
      if(item == 'areaManager') {
        this.areaManager = item
      }
      if(item == 'sysCOO') {
        this.sysCOO = item
      }
    })  
      
  },
  activated(){
    this.refresh()
  },
   created(){
    this.areaTree()
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .btnBox{display: inline-block; margin-right: 10px; width: 100%;padding: 0 20px;}
    .list{ height: 348px; background: #FFFFFF; border-radius: 4px; 
      .list-top{ border-bottom: 1px solid $c-border;
        .top_head{ margin-bottom: 20px;
          span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;}
        }
        .top_con{margin-bottom: 20px;
          span{ display: inline-block; width: 100px; height: 30px; padding-left:10px;  margin-right: 20px;
            img{vertical-align: middle;margin-left: 3px;}
          }
          .yesCon{ background: #FFFFFF;border-radius: 4px;border: 1px solid #28B892; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400;color: #28B892; line-height: 30px; }
          .noCon{ background: #FFEDED; border-radius: 4px; border: 1px solid #FF5353; line-height: 30px;
          font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #FF5353;}
        }
        .top_foot{ margin-bottom:20px;
          .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:20px ;
            .time{ display: inline-block; color: #889098; margin-bottom:20px;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
            .textWidth{width: 100px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
            .con{ display: inline-block; }
          }
        }

      }
      .list-bottom{ 
        .bottom_head{ padding-top: 12px; margin-bottom: 20px;
          span{ color: #889098;line-height: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC; }
        }
        .base-steps {
          position: absolute;
          top: 40px;
        }
      }
    }
  }
}
.step{ position: relative; height:100px; text-align:center;
  .steps_item{ position: absolute; top:10px; padding-top:60px; min-width:60px; transform: translateX(-50%);
    .statusName{ position:absolute; left:0; top:0; min-width: 50px; }
    .deptName{ height:40px; }
    &.first {
      left: 0 !important;
      text-align: left;
        transform: translateX(0);
    }
    &.last {
      left: auto !important;
      right: 0;
      transform: translateX(0);
    }
    &.fail {
      color: $c-danger;
    }
    &.finish {
      color: $c-success;
    }
    &.on{
      color: $c-primary!important;
    }
  }
}
/deep/#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;}
.company{ padding: 20px;
  .companyTitle{ height: 18px; font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: $c-2; line-height: 18px; margin-bottom: 20px;}
  .company-info{ border-bottom:1px solid $c-border ; margin-bottom: 20px;
    .top_head{ 
      span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;}
    }
    .top_foot{ margin-bottom:20px;
      .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:40px ; margin-top: 20px;
        p{ margin-bottom: 20px; }
        .time{ display: inline-block; color: #889098;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
        .detail-area { display: inline-block; width: 330px; line-height: 25px; }
        .con{ display: inline-block;}
        
        .textWidth{width: 360px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
        .address{ width:480px; }
      }
    }
  }
  .company-id{ margin-bottom: 20px; border-bottom:1px solid $c-border;
    .uploadBigBox{ padding-bottom: 10px;
      .uploadBox{ display: inline-block; margin:10px 25px;text-align:center;
        .imgBox{ width: 180px; height: 200px; border-radius: 10px; 
          img{ width:100%;height: 100%;  border-radius: 4px;cursor:pointer;}
        }
        p{color: $c-9;}
      }
    }
  }
  .company-enter{border-bottom:1px solid $c-border; margin-bottom: 20px;
    .uploadAtt{ margin-bottom: 20px;

    }
  }
  .company-aduit{height: 235px;
    .step{ position: relative; height:100px; text-align:center;
      .steps_item{ position: absolute; top:10px; padding-top:60px; min-width:60px; transform: translateX(-50%);
        .statusName{ position:absolute; left:0; top:0; min-width: 120px; }
        .deptName{ height:40px; }
        &.first {
          left: 0 !important;
          text-align: left;
            transform: translateX(0);
        }
        &.last {
          left: auto !important;
          right: 0;
          transform: translateX(0);
        }
        &.fail {
          color: $c-danger;
        }
        &.finish {
          color: $c-success;
        }
        &.on{
          color: $c-primary!important;
        }
      }
    }
    .base-steps {
      position: absolute;
      top: 40px;
    }
  }
  .aduitBox{ width: 100%; height: 272px; background: #F5F7F9; border-radius: 6px; padding: 20px;

  }
}

.totalNull{ width: 100%; height: 400px;
}
</style>
