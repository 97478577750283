<template>
  <!-- 分销群管理 -->
  <div  class="wrap">
    <div class="box-con">
      <!-- 入驻管理 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams" label-width="100px">
        <el-form-item  v-if="areaManager == 'areaManager' || sysCOO == 'sysCOO'">
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item  label="群聊类型：">
          <el-button class="w110" :class="activeNum == 1?'all':''"  @click="activeNum =1,queryParams.shopType=null,queryParams.mallType='',resetPageAndRefresh()">全部</el-button>
          <el-button :class="activeNum == 2?'all':''" @click="activeNum =2,queryParams.shopType=107001001,queryParams.mallType='',resetPageAndRefresh()">通信大厅</el-button>
          <el-button :class="activeNum == 3?'all':''" @click="activeNum =3,queryParams.shopType=107001002,queryParams.mallType='',resetPageAndRefresh()">网络大厅</el-button>
          <el-select  v-model="queryParams.mallType"  @change="queryParams.shopType = queryParams.mallType,resetPageAndRefresh()" clearable filterable placeholder="商城" style="width:100px;margin-left:10px">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
        </el-form-item >
      </el-form>
      <!-- 列表 -->
      <div v-if="groupTotal == 0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-if="groupTotal > 0" v-model="queryParams" :data="groupList" :total="groupTotal"  @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="market">
            <div class="market-h">
              <div class="market-t">
                <img :src="scope.row.avatar+'?x-oss-process=image/resize,m_fill,h_150,w_150'" alt="">
              </div>
              <div class="market-t w50">
                <span class="tip ellipsis">{{scope.row.name}}</span>
                <span class="mt">群名称</span>
              </div>
              <div class="market-t w15">
                <span class="tip">{{scope.row.userNum}}</span>
                <span class="mt">群人数</span>
              </div>
              <div class="market-t w15">
                <span class="tip">{{scope.row.orderNum / scope.row.browseNum}}</span>
                <span class="mt">转化率</span>
              </div>
              <div class="market-t w20">
                <span class="tip">{{scope.row.commission|money}}</span>
                <span class="mt">总产生佣金</span>
              </div>
            </div>
            <div class="cont">
              <div class="fl">
                <p><span>创建时间：</span>{{scope.row.createTime}}</p>
                <p><span>群聊类型：</span>{{scope.row.extend.shopTypeName}}</p>
                <p><span>分销排名：</span>{{scope.row.idx}}</p>
                <p><span>访问次数：</span>{{scope.row.browseNum}}</p>
              </div>
              <div class="fr">
                <p><span>所属学校：</span>{{scope.row.extend.schoolName}}</p>
                <p><span>成交量：</span>¥{{scope.row.goodsNum}}</p>
                <p><span>成交金额：</span>¥{{scope.row.amount|money}}</p>
              </div>
            </div>
            <div class="btn">
              <el-button class="cancel" @click="cancel(scope.row.id)">解散群聊</el-button>
            </div>
          </div>
        </template>
      </paged-list>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";

export default {
  name:'channelmarket',
  mixins:[pageMixin],
  components:{
    AreaTree,
    PagedList
  },
  data() {
    return {
      activeNum:1,
       // 角色
      ceo:'',
      areaManager:'',
      sysCOO:'',
      role:'',

      queryParams: {
        status:null,
        companyName:'',
        shopType:null,
        pageNum:1,
        pageSize:10,

      },
      positonList:[],

      pageApi:'getGroupList'
    }
  },
  computed:{
    ...mapState(["OA"]),
    ...mapState('groupMarket',['groupList','groupTotal'])
  },
  filters:{
    money(val) {
      return (val/100).toFixed(2)
    }
  },
  methods: {
    ...mapActions('enterAduit',['dictGetDictFPid','getLoginUser']),
    ...mapActions('groupMarket',['getGroupList','cancelGroupMarket']),
    // 解散群聊
    cancel(id) {
      this.$confirm('确认解散该分销群, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await this.cancelGroupMarket({id})
        this.$message({
          type: 'success',
          message: '解散成功!'
        });
        this.refresh()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解散'
        });          
      });
    },
    // 地区查询
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
  },
  async created() {
    const res = await this.getLoginUser()
    const arr = res.roles
    arr.forEach(item=>{
      if( item == 'xtgly') {
        this.role = item
      }
      if(item == 'ceo') {
        this.ceo = item
      }
      if(item == 'areaManager') {
        this.areaManager = item
      }
      if(item == 'sysCOO') {
        this.sysCOO = item
      }
    })  
  },
  async mounted() {
    let res = await this.dictGetDictFPid({pid:104003003})
    this.positonList = res.filter(item=>item.id != 107001001 || item.id != 107001002)
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .market {
      .market-h {
        display: flex;
        border-bottom: 1px solid #EAEFF3;
        .market-t {
          display: flex;
          flex-direction: column;
          img{
            margin-right: 20px;
            width: 44px;
            height: 44px;
            border-radius: 4px;
          }
          span {
            color: #889098;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            &.tip{
              font-size: 20px;
              font-weight: 600;
              color: #222222;
              line-height: 20px;
            }
            &.mt {
              margin: 14px 0 20px;
            }
          }
          
        }
        .w50 {width: 50%;}
        .w20 {width: 20%;}
        .w15 {width: 15%;}
      }
      .cont {
        margin-top: 20px;
        display: flex;
        .fl,.fr {
          width: 50%;
          p {
            font-size: 14px;color:#222222;
            span {font-size: 14px;color: #889098;}
          }
        }
      }
      .btn {
        margin-top: 20px;
        .cancel {
          width: 100%;
        }
      }
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w110 {
  width: 110px;
}
.all {
  color: $c-success;
  background-color: rgba(40, 184, 146, 0.1);
  border: 1px solid $c-success;
}
.primary {
  color: #4A90E2!important;
}
.success {
  color: #28B892!important;
}
.danger {
  color: #979797!important;
}
</style>