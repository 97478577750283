/*
 * @Auhtor: 文锋
 * @Date: 2021-04-30 10:11:19
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-04-30 11:06:29
 * @FilePath: \operate\src\store\modules\task\CurrentTask.js
 */
import api from "@/api/task/CurrentTask.js";

const state = {
  currentList: [],
};

const mutations = {
    saveCurrentList(state,result) {
        state.currentList = result.children
    }
};

const actions = {
  async selectCurrentList({ commit, state },params) {
    const result = await api.selectCueerentTask(params)

    commit('saveCurrentList',result)
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
