import api from "@/api/market/groupMarket";

const state = {
    groupList:[],   // 分销群列表
    groupTotal:0,   // 分销群数量
}

const actions = {
    // 分校群列表
    async getGroupList({commit,state},params) {
        let res = await api.getGroupList(params)
        let {list,total} = res
        commit('saveGroupList',{list,total})
        return res
    },
    // 解散分校群
    async cancelGroupMarket({commit,state},params) {
        return await api.cancelGroupMarket(params)
    }
}

const mutations = {
    saveGroupList(state,{list,total}) {
        state.groupList = list
        state.groupTotal = total
    }
}

const getters = {}


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}