<template>
  <div  class="wrap">
    <div class="box-con">
      <el-tabs v-model="activeTab" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="工资提现审核" name="1">
          <!-- 提现审核 @需要改 数据不全-->
          <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
            <el-form-item>
              <el-select v-model="queryParams.status" @change="resetPageAndRefresh" filterable placeholder="请选择执行状态" clearable>
                <el-option v-for="(item,index) in advStatusOptions" :key="index" :label="item.label" :value="item.status"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select style="width:230px; margin-left: 20px;" v-model="queryParams.userType" placeholder="请选择用户类型" clearable filterable @change="resetPageAndRefresh">
                <el-option v-for="(item,index) in userOptions" :key="index"  :label="item.label" :value="item.userType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker style="width:230px; margin-left: 20px;" size="small" v-model="timeRange" @change="getTimeRange($event,'start','end','queryParams'),refresh(),requireCashAll()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input  style="width:230px; margin-left: 20px;" v-model="queryParams.userName" placeholder="请输入用户昵称"  clearable ></el-input>
            </el-form-item>
            
            <el-form-item>
              <el-button  style="margin-left: 20px;" type="success" @click="refresh(),requireCashAll()">查询</el-button>
            </el-form-item>
          </el-form>

          <div class="money-box">
            <div class="money-info">
              <div class="money-num">¥{{(cashAll.succ).toFixed(2)}}</div>
              <div class="money-from">累计成功提现金额</div>
            </div>
            <div class="money-info">
              <div class="money-num">¥{{(cashAll.wait).toFixed(2)}}</div>
              <div class="money-from">提现待审核金额</div>
            </div>
          </div>

          <div v-if="cashCheckTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
          <paged-list v-model="queryParams" :data="cashCheckList" :total="cashCheckTotal" @refresh="refresh" :span='12'>
            <template slot-scope="scope">
              <div class="list" @click="load(scope.row.id)">
                <div class="list-top">
                  <div class="top_head cfx">
                    <div class="top-name" v-if="scope.row.extend">
                      <span>{{scope.row.extend.username}}</span>
                      <div class="top-lable">提现人员</div>
                    </div>
                    <div class="fr">
                      <div>
                        <span class="aduitStatus tinctblue" v-if="scope.row.status==2">等待区域经理审核</span>
                        <span class="aduitStatus tinctblue" v-else-if="scope.row.status==3">等待运营总监审核</span>
                        <span class="aduitStatus tinctblue" v-else-if="scope.row.status==4">待财务审核</span>
                        <span class="aduitStatus tinctgreen" v-else-if="scope.row.status==5">已打款</span>
                        <span class="aduitStatus tinctred" v-else-if="scope.row.status==-1">审核未通过</span>
                        <span class="aduitStatus tinctred" v-else>状态出错</span>
                      </div>
                      <div class="top-lable fr">提现状态</div>
                    </div>
                    <div class="top-name" v-if="scope.row.amount">
                      <span>¥{{scope.row.amount.toFixed(2)/100}}</span>
                      <div class="top-lable">提现金额</div>
                    </div>
                  </div>
                  <div class="top_foot">
                    <div class="area">
                      <p> <span class="time">提现编号</span><span class="con">{{scope.row.tradeNo}}</span> </p>
                      <p v-if="scope.row.balance!=null"><span class="time">账户余额</span><span class="con">¥ {{scope.row.balance.toFixed(2)}}</span> </p>
                      <p v-else><span class="time">账户余额</span><span class="con">¥ 0.00</span> </p>
                      <p> <span class="time">提现账户类型</span>
                        <span class="con" v-if="scope.row.withdrawType==1">微信</span>
                        <span class="con" v-else-if="scope.row.withdrawType==2">支付宝</span>
                        <span class="con" v-else>银行卡</span>
                      </p>
                    </div>
                    <div class="area">
                      <p> <span class="time">申请时间</span><span class="con">{{scope.row.submitTime?scope.row.submitTime:'-'}}</span> </p>
                      <p> <span class="time">店铺名称</span><span class="con">{{scope.row.shopName?scope.row.shopName:'-'}}</span> </p>
                    </div>
                  </div>
                </div>
                <div class="list-bottom" >
                  <div class="bottom_head">
                    <span>审核情况</span>
                    <el-button type="success" size="small" class="fr" @click="drawer=true" v-if="scope.row.status==1">前往审核</el-button>
                  </div>
                  <div class="step" v-if="scope.row.reviewList">
                    <div class="steps_item" v-for="(item,index) in scope.row.setps" :key="index"
                      :style="{ left: 100 / (scope.row.setps.length-1) * index +  '%' }"
                      :class="[
                        { fail: item.status.find(f=>f.class=='fail').value === item.reviewStatus },
                        { on: item.status.find(f=>f.class=='on').value === item.reviewStatus },
                        { finish: item.status.find(f=>f.class=='finish').value === item.reviewStatus },
                        { first: index == 0  },
                        { last: index ==scope.row.setps.length-1},
                      ]"
                      > 
                      <div class="status statusName" v-if="item.status.find(f=>f.value==item.reviewStatus)">
                        {{ item.status.find(f=>f.value==item.reviewStatus).name }}
                      </div>
                      <div class="dept deptName" v-if="item.dept" >{{ item.dept }}</div>
                    </div>
                    <base-steps :steps="scope.row.setps.length" :active='setActive(scope.row.setps, scope.row.reviewList)'>
                    <!-- <base-steps :steps="scope.row.setps.length" :active='setActive(scope.row.setps, 2)'> -->
                    </base-steps>
                  </div>
                </div>
              </div>
            </template>
          </paged-list>
        </el-tab-pane>
        <el-tab-pane label="用户提现审核" name="2" v-if="areaManager == 'areaManager' || sysCOO == 'sysCOO' || sysCFO == 'sysCFO'">
          <!-- 提现审核 @需要改 数据不全-->
          <el-form class="params-wrap select" size="small" :inline="true" :model="queryParamsB">
            <el-form-item>
              <el-select v-model="queryParamsB.status" @change="refreshB(),requireUserCashAll()" filterable placeholder="请选择执行状态" clearable>
                <el-option v-for="(item,index) in userCashStatusOptions" :key="index" :label="item.label" :value="item.status"></el-option>
              </el-select>
            </el-form-item>    
            <el-form-item>
              <el-date-picker style="width:230px; margin-left: 20px;" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParamsB'),refreshB()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input  style="width:230px; margin-left: 20px;" v-model="queryParamsB.loginName" placeholder="请输入用户昵称"  clearable ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button  style="margin-left: 20px;" type="success" @click="refreshB">查询</el-button>
            </el-form-item>
          </el-form>

          <div class="money-box">
            <div class="money-info">
              <div class="money-num">¥{{userCashMoneyInfo.num|money}}</div>
              <div class="money-from">累计成功提现金额</div>
            </div>
            <div class="money-info">
              <div class="money-num">¥{{userCashMoneyInfo.sucNum|money}}</div>
              <div class="money-from">提现待审核金额</div>
            </div>
          </div>
          <!-- 列表 -->
          <div v-if="userCashTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
          <paged-list v-model="queryParamsB" :data="userCashList" :total="userCashTotal" @refresh="refreshB" :span='12'>
            <template slot-scope="scope">
              <div class="list" @click="userLoad(scope.row.id)">
                <div class="list-top">
                  <div class="top_head cfx">
                    <div class="top-name" v-if="scope.row.extend">
                      <span>{{scope.row.loginName}}</span>
                      <div class="top-lable">提现人员</div>
                    </div>
                    <div class="fr">
                      <div v-if="scope.row.status">
                        <span class="aduitStatus tinctblue" v-if="scope.row.status==4">待财务审核</span>
                        <span class="aduitStatus tinctgreen" v-else-if="scope.row.status==5">已打款</span>
                        <span class="aduitStatus tinctred" v-else-if="scope.row.status==-1">审核未通过</span>
                        <span class="aduitStatus tinctred" v-else>状态出错</span>
                      </div>
                      <div class="top-lable fr">提现状态</div>
                    </div>
                    <div class="top-name" v-if="scope.row.amount">
                      <span>¥{{scope.row.amount|money}}</span>
                      <div class="top-lable">提现金额</div>
                    </div>
                  </div>
                  <div class="top_foot">
                    <div class="cont">
                      <p><span>提现编号</span>{{scope.row.tradeNo}}</p>
                      <p><span>申请时间</span>{{scope.row.addTime}}</p>
                      <p><span>提现账户类型</span>{{scope.row.type == 1?'微信':'支付宝'}}</p>
                      <p><span>账户余额</span>{{scope.row.leftBalance|money}}</p>
                    </div>
                    <div class="cont">
                      <p v-if="scope.row.type == 2"><span>真实姓名</span>{{scope.row.name}}</p>
                      <p><span>学校名称</span></p>
                      <p><span>学号/老师号</span></p>
                    </div>
                  </div>
                </div>
                <div class="list-bottom" >
                  <div class="bottom_head">
                    <span>审核情况</span>
                  </div>
                  <div class="step" v-if="scope.row.status">
                    <div class="steps_item on"> 
                      <div class="dept deptName" style="margin-left:40px" >待审核</div>
                    </div>
                    <div class="steps_item" style="left:100%" :class="[
                      { fail: scope.row.status === -1 },
                      { finish: scope.row.status === 5 },
                    ]"> 
                      <div class="dept deptName" style="margin-left:-40px" v-if="scope.row.status === -1">未通过</div>
                      <div class="dept deptName" style="margin-left:-40px" v-if="scope.row.status === 5">已打款</div>
                    </div>
                    <base-steps :steps="2" :active="scope.row.status==4?1:2"></base-steps>
                  </div>
                </div>
              </div>
            </template>
          </paged-list>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!--工资 详情+审核 -->
    <el-drawer append-to-body title="提现审核详情" :visible.sync="drawer" size="35%" v-if="details" >
      <div class="company">
        <div class="company-info">
         <p class="companyTitle">基础信息</p>
          <div class="top_head cfx">
            <div class="top-name" v-if="details.extend">
              <span>{{details.extend.username}}</span>
              <div class="top-lable">提现人员</div>
            </div>
            <div class="fr">
              <div>
                <span class="aduitStatus tinctblue" v-if="details.status==2">等待区域经理审核</span>
                <span class="aduitStatus tinctblue" v-else-if="details.status==3">等待运营总监审核</span>
                <span class="aduitStatus tinctblue" v-else-if="details.status==4">待财务审核</span>
                <span class="aduitStatus tinctgreen" v-else-if="details.status==5">已打款</span>
                <span class="aduitStatus tinctred" v-else-if="details.status== -1">审核未通过</span>
                <span class="aduitStatus tinctred" v-else>状态出错</span>
              </div>
              <div class="top-lable fr">提现状态</div>
            </div>
            <div class="top-name" v-if="details.amount">
              <span>¥{{details.amount.toFixed(2)/100}}</span>
              <div class="top-lable">提现金额</div>
            </div>
          </div>
          <div class="top_foot" v-if="details.company">
            <div class="area">
              <p> <span class="time">提现编号</span><span class="con">{{details.tradeNo?details.tradeNo:'-'}}</span> </p>
              <p> <span class="time">提现账户类型</span><span class="con">{{details.extend.applyName?details.extend.applyName:'-'}}</span> </p>
              <!-- <p> <span class="time">学校名称</span><span class="con">{{details.company.extend.enterpriseTyeName?details.company.extend.enterpriseTyeName:'-'}}</span> </p> -->
              <p> <span class="time">学校名称</span><span class="con">{{details.company.extend.enterpriseTyeName?details.company.extend.enterpriseTyeName:'-'}}</span> </p>
            </div>
            <div class="area" v-if="details.extend">
              <p> <span class="time">申请时间</span><span class="con">{{details.submitTime?details.submitTime:'-'}}</span> </p>
              <p v-if="details.balance"> <span class="time">账户余额</span><span class="con">{{details.balance.toFixed(2)?details.balance.toFixed(2):'-'}}</span> </p>
              <p> <span class="time">校园CEO</span><span class="con">{{details.company.extend.shopTypeName?details.company.extend.shopTypeName:'-'}}</span> </p>
            </div>
          </div>
        </div>
        <div class="company-id">
          <p class="companyTitle cfx">{{details.startDate}} - {{ details.endDate }}
            <el-link :href="BaseUrl + `net/admin/cash/noLogin/exportWithSWId?withdrawId=${details.id}&isMall=${details.isMall}&mallWid=${details.mallWid}`" class="button_download fr">
              <el-button type="warning" size="small" icon="el-icon-position">导出明细</el-button>
            </el-link>
          </p>
          <div class="uploadBigBox">
            <div class="top-name">
              <span>¥{{ details.total?details.total:0 }}</span>
              <div class="top-lable">总收入</div>
            </div>
            <div class="top-name">
              <span>¥{{ details.historyOut?details.historyOut:0 }}</span>
              <div class="top-lable">历史提现</div>
            </div>
            <div class="top-name">
              <span>¥{{ details.balance?details.balance:0 }}</span>
              <div class="top-lable">余额</div>
            </div>
          </div>
        </div>
        <div class="company-aduit">
          <p class="companyTitle">审核情况</p>
          <div class="step" v-if="details.setps">
            <div class="steps_item" v-for="(item,index) in details.setps" :key="index"
              :style="{ left: 100 / (details.setps.length-1) * index +  '%' }"
              :class="[
                { fail: item.status.find(f=>f.class=='fail').value === item.reviewStatus },
                { on: item.status.find(f=>f.class=='on').value === item.reviewStatus },
                { finish: item.status.find(f=>f.class=='finish').value === item.reviewStatus },
                { first: index == 0  },
                { last: index == details.setps.length-1},
              ]"
              >
              <div class="status statusName" v-if="item.status.find(f=>f.value==item.reviewStatus)">
                {{ item.status.find(f=>f.value==item.reviewStatus).name }}
              </div>
              <div class="dept deptName" v-if="item.dept" >{{ item.dept }}</div>
              <div class="name doubleName" v-if="item.name">{{ item.name }}</div>
              <div class="time" v-if="item.reason">
                未通过原因：{{ item.reason }}
              </div>
            </div>
            <base-steps :steps="details.setps.length" :active='setActive(details.setps, details.reviewList)'></base-steps>
          </div>
        </div>
        <div class="aduitBox" v-if="details.status== 2 && areaManager=='areaManager'">
          <p class="companyTitle" >区域经理审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  label-width="110px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status" @change="statusChange">
                <el-radio :label="3">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" v-if="ruleForm.status==-1">
              <el-input type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="aduitBox" v-if="details.status== 3 && sysCOO=='sysCOO'">
          <p class="companyTitle" >运营总监审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  label-width="110px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status" @change="statusChange">
                <el-radio :label="4">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" v-if="ruleForm.status==-1">
              <el-input type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="aduitBox" v-if="details.status== 4 && sysCFO=='sysCFO'">
          <p class="companyTitle" >财务审核</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  label-width="110px" >
            <el-form-item prop="status" label="审核状态：">
              <el-radio-group v-model="ruleForm.status" @change="statusChange">
                <el-radio :label="5">审核通过</el-radio>
                <el-radio :label="-1">审核不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="reason" label="不通过原因：" v-if="ruleForm.status==-1">
              <el-input type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_true" @click="cancel">取消</el-button>
              <el-button type="success" class="btn_true" @click="aduitResult(details.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>
    <!--用户 详情+审核 -->
    <el-drawer append-to-body title="提现详情" :visible.sync="aVisible" size="35%" v-if="userDetails" >
      <div class="company">
        <div class="company-info">
         <p class="companyTitle">基础信息</p>
          <div class="top_head cfx">
            <div class="top-name" v-if="userDetails.withdraw">
              <span>{{userDetails.withdraw.loginName}}</span>
              <div class="top-lable">提现人员</div>
            </div>
            <div class="fr">
              <div v-if="userDetails.withdraw">
                <span class="aduitStatus tinctblue" v-if="userDetails.withdraw.status==4">待财务审核</span>
                <span class="aduitStatus tinctgreen" v-else-if="userDetails.withdraw.status==5">已打款</span>
                <span class="aduitStatus tinctred" v-else-if="userDetails.withdraw.status==-1">审核未通过</span>
                <span class="aduitStatus tinctred" v-else>状态出错</span>
              </div>
              <div class="top-lable fr">提现状态</div>
            </div>
            <div class="top-name" v-if="userDetails.withdraw">
              <span>¥{{userDetails.withdraw.amount.toFixed(2)/100}}</span>
              <div class="top-lable">提现金额</div>
            </div>
          </div>
        </div>
        <div class="company-id">
          <div class="top_foot" v-if="userDetails.withdraw">
            <div class="cont">
              <p><span>提现编号</span>{{userDetails.withdraw.tradeNo}}</p>
              <p><span>申请时间</span>{{userDetails.withdraw.addTime}}</p>
              <p><span>提现账户类型</span>{{userDetails.withdraw.type == 1?'微信':'支付宝'}}</p>
              <p><span>账户余额</span>{{userDetails.withdraw.leftBalance|money}}</p>
              <p v-if="userDetails.withdraw.type == 2"><span>真实姓名</span>{{userDetails.withdraw.name}}</p>
              <p v-if="userDetails.withdraw.extend"><span>学校名称</span>{{userDetails.withdraw.extend.schoolName}}</p>
              <p><span>学号/老师号</span>{{userDetails.withdraw.account}}</p>
            </div>
          </div>
        </div>
        <!-- 近三个月流水 -->
        <div class="company-record">
          <p class="companyTitle">近三个月账单流水</p>
          <div class="water-wrapper">
            <!-- 当前月份 -->
            <div class="month-bill">
              <div class="bill-h">
                <span class="time">{{userDetails.f_month}}</span>
                <div class="bill-total">
                  <span>支出：-￥{{userDetails.f_exp|money}}</span>
                  <span>收入：+￥{{userDetails.f_inc|money}}</span>
                </div>
              </div>
              <div class="bill-cont">
                <div class="bill-item" v-for="(item,index) in userDetails.first" :key="index">
                  <div class="item-name" v-if="item.type == 1"><span>订单编号<i class="green">（红包）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 2"><span>订单编号<i class="red">（提现）</i></span><span class="red">-￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 3"><span>订单编号<i class="green">（提现失败，余额返还）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-type"><span>{{item.tradeNo}}</span></div>
                  <div class="item-time"><span>{{item.time}}</span><span>余额：{{item.currBalance|money}}</span></div>
                </div>
              </div>
            </div>
            <!-- 上个月份 -->
            <div class="month-bill">
              <div class="bill-h">
                <span class="time">{{userDetails.s_month}}</span>
                <div class="bill-total">
                  <span>支出：-￥{{userDetails.s_exp|money}}</span>
                  <span>收入：+￥{{userDetails.s_inc|money}}</span>
                </div>
              </div>
              <div class="bill-cont">
                <div class="bill-item" v-for="(item,index) in userDetails.second" :key="index">
                  <div class="item-name" v-if="item.type == 1"><span>订单编号<i class="green">（红包）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 2"><span>订单编号<i class="red">（提现）</i></span><span class="red">-￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 3"><span>订单编号<i class="green">（提现失败，余额返还）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-type"><span>{{item.tradeNo}}</span></div>
                  <div class="item-time"><span>{{item.time}}</span><span>余额：{{item.currBalance|money}}</span></div>
                </div>
              </div>
            </div>
            <!-- 上上个月份 -->
            <div class="month-bill">
              <div class="bill-h">
                <span class="time">{{userDetails.t_month}}</span>
                <div class="bill-total">
                  <span>支出：-￥{{userDetails.t_exp|money}}</span>
                  <span>收入：+￥{{userDetails.t_inc|money}}</span>
                </div>
              </div>
              <div class="bill-cont">
                <div class="bill-item" v-for="(item,index) in userDetails.third" :key="index">
                  <div class="item-name" v-if="item.type == 1"><span>订单编号<i class="green">（红包）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 2"><span>订单编号<i class="red">（提现）</i></span><span class="red">-￥{{item.thisBalance|money}}</span></div>
                  <div class="item-name" v-if="item.type == 3"><span>订单编号<i class="green">（提现失败，余额返还）</i></span><span class="green">+￥{{item.thisBalance|money}}</span></div>
                  <div class="item-type"><span>{{item.tradeNo}}</span></div>
                  <div class="item-time"><span>{{item.time}}</span><span>余额：{{item.currBalance|money}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="company-aduit">
          <p class="companyTitle">审核情况</p>
          <div class="step" v-if="userDetails.withdraw">
            <div class="steps_item on"> 
              <div class="dept deptName" style="margin-left:40px">待审核</div>
            </div>
            <div class="steps_item" style="left:100%" :class="[
              { fail: userDetails.withdraw.status === -1 },
             { finish: userDetails.withdraw.status === 5 },
            ]"> 
              <div class="dept deptName" style="margin-left:-40px" v-if="userDetails.withdraw.status === -1">未通过</div>
              <div class="dept deptName" style="margin-left:-40px" v-if="userDetails.withdraw.status === 5">已打款</div>
            </div>
            <base-steps :steps="2" :active="userDetails.withdraw.status==4?1:2"></base-steps>
          </div>
        </div>
        <div v-if="userDetails.withdraw">
          <div class="aduitBox" v-if="sysCFO == 'sysCFO' && userDetails.withdraw.status == 4">
            <p class="companyTitle" >财务审核</p>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  label-width="110px" >
              <el-form-item prop="status" label="审核状态：">
                <el-radio-group v-model="ruleForm.status" @change="statusChange">
                  <el-radio :label="3">审核通过</el-radio>
                  <el-radio :label="-1">审核不通过</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="reason" label="不通过原因：" v-if="ruleForm.status==-1">
                <el-input type="text" placeholder="请输入原因"  v-model="ruleForm.reason" ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="btn_true" @click="cancel">取消</el-button>
                <el-button type="success" class="btn_true" @click="financerResult('ruleForm',userDetails.withdraw.id)">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        
      </div>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import BaseSteps from "@/components/common/BaseSteps";
import pageMixin from "@/mixins/pageMixin";
import { BaseUrl } from "@/common/config";
import datePicker from "@/mixins/datePicker";
export default {
  name:'cashCheck',
  components: {
    PagedList,
    BaseSteps,
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      aVisible: false,
      BaseUrl,
      activeTab:'1',
      cashAll: {
        succ: 0,
        wait: 0,
      },
      valueA: null,
      valueB:null,
      ceo:'',
      areaManager:'',
      sysCOO:'',
      sysCFO: '',
      role:'',
      ruleForm: {
        status: 1,
        reason: ''
      },
      rules:{
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        reason: [{ required: true, message: "请输入不通过原因", trigger: "blur" }]
      },
      reviewers:[],
      active: 1,
      details:{ },
      userDetails:{},
      exportsWith:{},
      drawer: false,
      queryParams: {
        pageNum:10,
        pageSize: 1,
        start: '',
        end: '',
        status: null,
        userType:null,
        shopName: '',
      },
      // 用户提现 查询
      queryParamsB: {
        flag:2,
        pageNum:10,
        pageSize: 1,
        s1: '',
        s2: '',
        status: null,
        loginName: '',
      },
      advStatusOptions: [
       { status: null, label: "全部状态" },
       { status: -1, label: "审核不通过" },
       { status: 2, label: "待区域经理审核" },
       { status: 3, label: "待运营总监审核" },
       { status: 4, label: "待财务审核" },
       { status: 5, label: "已打款" },
      ],
      userOptions: [
        { userType: 0, label: "全部" },
        { userType: 1, label: "CEO" },
        { userType: 2, label: "团队成员" },
      ],


      // 用户提现 状态
      userCashStatusOptions: [
        {status:-1,label:'未通过'},
        {status:4,label:'待财务审核'},
        {status:5,label:'已打款'},
      ],

      pageApi: 'settleShopWithdrawGetVerifyList',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('cashCheck',["cashCheckList","cashCheckTotal",'userCashList','userCashTotal','userCashInfo','userCashMoneyInfo']),
    
  },
  filters:{
    money(val) {
      return (val/100).toFixed(2)
    }
  },
  methods: {
    ...mapActions('cashCheck',["settleShopWithdrawGetVerifyList",
    "settleShopWithdrawGetSum",
    "settleShopWithdrawGetDetail",
    "netAdminCashNoLoginExportWithSWId",
    "settleShopWithdrawVerify",
    'getCashMoneyDetail',
    'getUserCashCheckList',
    'getUserCashInfo',
    'checkUserCash'
    ]),
    ...mapActions('enterAduit',["getLoginUser"]),
    async handleClick(tab, event) {
      // console.log(tab)
    },
    async load(id) {
      this.details = await this.settleShopWithdrawGetDetail({id})
      this.drawer = true
      let a = this.details.reviewList[0] ? this.details.reviewList[0].status : null
      let b = this.details.reviewList[1] ? this.details.reviewList[1].status : null
      let c = this.details.reviewList[2] ? this.details.reviewList[2].status : null
      let reasonA = this.details.reviewList[0] ? this.details.reviewList[0].reason : null
      let reasonB = this.details.reviewList[1] ? this.details.reviewList[1].reason : null
      let reasonC = this.details.reviewList[2] ? this.details.reviewList[2].reason : null
      if(this.details.level == 3){
        this.details.setps = [
          {
            dept: '区域经理', reviewStatus:a, reason:reasonA, status: [
              { name: '待审核', value: 2, class:'on' },
              { name: '审核通过', value: 3, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
          {
            dept: '运营总监', reviewStatus:b, reason:reasonB,status: [
              { name: '待审核', value: 3, class:'on' },
              { name: '审核通过', value: 4, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
          {
            dept: '财务', reviewStatus:c, reason:reasonC,status: [
              { name: '待审核', value: 4, class:'on' },
              { name: '审核通过', value: 5, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
        ]
      }else if(this.details.level == 2){
        this.details.setps = [
          {
            dept: '区域经理', reviewStatus:a, reason:reasonA, status: [
              { name: '待审核', value: 2, class:'on' },
              { name: '审核通过', value: 3, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
          {
            dept: '财务', reviewStatus:c, reason:reasonC,status: [
              { name: '待审核', value: 4, class:'on' },
              { name: '审核通过', value: 5, class:'finish' },
              { name: '未通过', value: -1, class:'fail' },
            ]
          },
        ]
      }
      
    },
    // 用户提现详情
    async userLoad(id) {
      this.aVisible = true
      this.userDetails = await this.getUserCashInfo({id})
    },
    // 取消按钮
    cancel() {
      this.drawer = false
    },
    // 审核按钮
    statusChange(){
      this.ruleForm.reason = ''
    },
    aduitResult(id) {
      // console.log(res)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.id = id
          console.log(this.ruleForm)
          const res = await this.settleShopWithdrawVerify(this.ruleForm)
          this.refresh()
          this.drawer = false
        } else {
          return false;
        }
      });

    },
    setActive(setps,view){
      if(setps[view.length-1]) {
        let active = view.length
        if(setps[view.length-1].status.find(f=>f.class=='finish').value === setps[view.length-1].reviewStatus){
          if(active==3) {
            active ++
            active=3
          }
        }
        return active
      }
    },
    // 用户提现 数据统计
    async requireCashAll(){
    //
    },
    async requireUserCashAll() {
      await this.getCashMoneyDetail(this.queryParamsB)
      
    },
    async requireRoles(){
      let res = await this.getLoginUser()
      let arr = res.roles
      arr.forEach(item=>{
        if(item == 'areaManager') { this.areaManager = item }
        if(item == 'sysCOO') { this.sysCOO = item }
        if(item == 'sysCFO') { this.sysCFO = item }
      })
    },
    // 用户提现列表 查询
    async refreshB(){ //列表查询
      try {
        await this.getUserCashCheckList({...this.queryParamsB})
        
      }catch(e){
      }finally {
      }
    },
    // 财务审核
    financerResult(formName,id) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            this.ruleForm.id = id
            let res = await this.checkUserCash(this.ruleForm)
            if (res == null) {
              this.$message.success('财务审核成功')
              this.aVisible = false
            }
          } else {
            return false;
          }
        });
    }
  },
  async created() {
    const res = await this.getLoginUser()
    const arr = res.roles
    arr.forEach(item=>{
      if( item == 'xtgly') {
        this.role = item
      }
      if(item == 'ceo') {
        this.ceo = item
      }
      if(item == 'areaManager') {
        this.areaManager = item
      }
      if(item == 'sysCOO') {
        this.sysCOO = item
      }
      if(item == 'sysCFO') {
        this.sysCFO = item
      }
    })  
  },
  async mounted() {
    this.refresh()
    this.refreshB()
    this.requireCashAll()
    this.requireRoles()
  },
  activated(){
    this.refresh()
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .params-wrap { padding: 0 20px;
    .el-form-item { margin: 0; }
  }
  .money-box { width: 100%; padding: 20px 10px 0;
    .money-info { margin: 0 10px; width: 48.7%; background: #F5F7F9; display: inline-block; text-align: center;
      .money-num { font-size: 20px; color: #28B892; margin-top: 15px; }
      .money-from { color: #889098; margin-bottom: 10px; }
    }
  }
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .tabs{ width: 100%; background:#fff;padding: 10px 20px 0 20px;
      /deep/.el-tabs__item { font-size: 18px; font-weight: bold; }
    }

      .list{ height: 348px; background: #FFFFFF; border-radius: 4px; 
            .list-top{ border-bottom: 1px solid $c-border;
              .top_head{ margin-bottom: 20px;
                .top-name { display: inline-block; margin-right: 20px; }
                .top-lable { color: #889098; }
                span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;}
              }
              .top_con{margin-bottom: 20px;
                span{ display: inline-block; width: 100px; height: 30px; padding-left:10px;  margin-right: 20px;
                  img{vertical-align: middle;margin-left: 3px;}
                }
                .yesCon{ background: #FFFFFF;border-radius: 4px;border: 1px solid #28B892; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400;color: #28B892; line-height: 30px; }
                .noCon{ background: #FFEDED; border-radius: 4px; border: 1px solid #FF5353; line-height: 30px;
                font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #FF5353;}
              }
              .top_foot{ margin-bottom:20px;
                .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:20px ;
                  .time{ display: inline-block; color: #889098; margin-bottom:20px;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
                  .textWidth{width: 100px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
                  .con{ display: inline-block; }
                }
                .cont {
                  width: 100%;
                  display: flex;
                  >p {
                    font-size: 14px;
                    color: #222222;
                    margin-right: 20px;
                    >span {
                      margin-right: 10px;
                      color: #889098;
                    }
                  }
                }
              }

            }
            .list-bottom{ 
              .bottom_head{ padding-top: 12px; margin-bottom: 20px;
                span{ color: #889098;line-height: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC; }
              }
              .base-steps {
                position: absolute;
                top: 40px;
              }
            }
      }

  }
}
.step{ position: relative; height:100px; text-align:center;
  .steps_item{ position: absolute; top:10px; padding-top:60px; min-width:60px; transform: translateX(-50%);
    .statusName{ position:absolute; left:0; top:0; min-width: 50px; }
    .deptName{ height:40px; }
    &.first {
      left: 0 !important;
      text-align: left;
        transform: translateX(0);
    }
    &.last {
      left: auto !important;
      right: 0;
      transform: translateX(0);
    }
    &.fail {
      color: $c-danger;
    }
    &.finish {
      color: $c-success;
    }
    &.on{
      color: $c-primary!important;
    }
  }
}
/deep/#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;}
.company{ padding: 20px;
  .companyTitle{ height: 18px; font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: $c-2; line-height: 18px; margin-bottom: 20px; }
  .company-info{ border-bottom:1px solid $c-border ; margin-bottom: 20px;
    .top_head{ 
      .top-name { display: inline-block; margin-right: 20px; }
      .top-lable { color: #889098; }
      span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;}
    }
    .top_foot{ margin-bottom:20px;
      .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:40px ; margin-top: 20px;
        p{ margin-bottom: 20px; }
        .time{ display: inline-block; color: #889098;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
        .detail-area { display: inline-block; width: 330px; line-height: 25px; }
        .con{ display: inline-block;}
        
        .textWidth{width: 360px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
        .address{ width:480px; }
      }
    }
  }
  .company-id{ margin-bottom: 20px; border-bottom:1px solid $c-border;
    .uploadBigBox{ padding-bottom: 10px; text-align: center;
      .top-name { display: inline-block; margin-right: 40px; }
      .top-lable { color: #889098; }
      span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: #28B892;line-height: 20px;}
    }
    .top_foot{ margin-bottom:20px;
      .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:20px ;
        .time{ display: inline-block; color: #889098; margin-bottom:20px;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
        .textWidth{width: 100px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
        .con{ display: inline-block; }
      }
      .cont {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        >p {
          width: 50%;
          font-size: 14px;
          color: #222222;
          >span {
            margin-right: 40px;
            color: #889098;
          }
        }
      }
    }
  }
  .company-record{margin-bottom: 20px; border-bottom:1px solid $c-border;
    .water-wrapper {
      width: 100%;
      padding: 20px;
      height: 472px;
      background-color: #F5F7F9;
      border-radius: 4px;
      overflow: scroll;
      .month-bill{
        .bill-h{
          display: flex;
          justify-content: space-between;
          .time{
            color: #222222;
            font-size: 16px;
            font-weight: 600;
          }
          .bill-total{
            font-size: 14px;
            color: #a0a0a0;;
            span{margin-left: 20px;}
          }
        }
        .bill-cont {
          border-radius: 4px;
          margin: 20px 0;
          padding: 20px;
          background-color: $c-white;
          .bill-item{
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #EAEFF3;
            .item-name {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 16px;
                font-weight: 600;
                &.red {
                  color: $c-danger;
                }
                &.green {
                  color: $c-success;
                }
                i {
                  &.red {
                    color: $c-danger;
                  }
                  &.green {
                    color: $c-success;
                  }
                }
              }
            }
            .item-type,.item-time {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 14px;
                color: #A0A0A0;
              }
            }
          }
        }
      }
    }
  }
  .company-aduit{ 
  // .company-aduit{ height: 235px;
    .step{ position: relative; height:100px; text-align:center;
      .steps_item{ position: absolute; top:10px; padding-top:60px; min-width:60px;
        .statusName{ position:absolute; left:0; top:0; }
        .deptName{ height:40px; }
        &.first {
          left: 0 !important;
          text-align: left;
            transform: translateX(0);
        }
        &.last {
          left: auto !important;
          right: 0;
          transform: translateX(0);
        }
        &.fail {
          color: $c-danger;
        }
        &.finish {
          color: $c-success;
        }
        &.on{
          color: $c-primary!important;
        }
      }
    }
    .base-steps {
      position: absolute;
      top: 40px;
    }
  }
  .aduitBox{ width: 100%; height: 272px; background: #F5F7F9; border-radius: 6px; padding: 20px;

  }
}

.totalNull{ width: 100%; height: 400px;
}
</style>
