<template>
  <div  class="wrap">
    <!-- 校友圈 -->
    <div class="box-con">
      <el-form class="select params-wrap" size="small" :inline="true" :model="queryParams" >
            <el-form-item v-if="!url">
              <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
            </el-form-item> 
          <el-form-item >
             <el-input placeholder="请输入动态关键词" clearable v-model="queryParams.title" style="width:300px" >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="refresh">查询</el-button>
          </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="schoolFellowTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="schoolFellowList" :total="schoolFellowTotal" @refresh="refresh" :span="12">
         <template slot-scope="scope">
          <div class="list " @click="detail(scope.row.id)">
            <div class="top cfx">
              <div class="new-title fl">
                <p class="digestWidth" :title="scope.row.digest">{{ scope.row.digest?scope.row.digest : '-' }}</p>
                <div class="txt">动态内容</div>
              </div>
              <div class="name fr">
                <p>{{scope.row.extend.userName?scope.row.extend.userName:'-'}}</p>
                <div class="txt">发布人</div>
              </div>
            </div>
            <div class="content cfx ">
              <el-row :gutter="20">
                <el-col :span="12" >
                  <div class="time"><span>发布时间</span><span class="con">{{scope.row.addTime?scope.row.addTime:'-'}}</span></div>
                </el-col>
                <el-col :span="12" >
                  <div class="time"><span>学校名称</span><span class="con">{{scope.row.extend.schoolName?scope.row.extend.schoolName:'-'}}</span></div>
                </el-col>
                <el-col :span="12" style="margin-top:20px;">
                  <div class="time"><span>点赞人数</span><span class="con">{{scope.row.thumbNum?scope.row.thumbNum:'0'}}</span></div>
                </el-col>
                <el-col :span="12" style="margin-top:20px;">
                  <div class="time"><span>评论人数</span><span class="con">{{scope.row.commentNum?scope.row.commentNum:'0'}}</span></div>
                </el-col>
              </el-row>
            </div>
          </div>
        </template>
        <template slot="control">
          <el-button type="primary" icon="el-icon-edit" circle  @click="drawer = true"></el-button>
          <!-- <el-button type="danger" icon="el-icon-delete" circle > </el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
        <div class="drawer ">
          <div class="title cfx">
              <div class="new-title fl">
                <p>{{details.addTime?details.addTime:'-'}}</p>
                <div class="txt">发布时间</div>
              </div>
              <div class="name fl">
                <p>{{details.thumbNum?details.thumbNum:'0'}}</p>
                <div class="txt">点赞人数</div>
              </div>
              <div class="name fl">
                <p>{{details.commentNum?details.commentNum:'0'}}</p>
                <div class="txt">评论人数</div>
              </div>
          </div>
          <div class="content cfx " v-if="details.user">
            <div class="puplish">
              <p class="puplish-name">发布人</p>
              <div class="puplish-con cfx">
                <div class="img fl">
                  <img :src="details.user.headImg" alt="">
                </div>
                <div class="name fl">
                  <div class="p">
                    {{details.user.extend.userName?details.user.extend.userName:'-'}}
                    <img :src="details.user.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
                  </div>
                  <div class="txt">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}|{{details.user.fade?details.user.fade:'-'}}</div>
                </div>
                <div class="name fl">
                  <div class="p">{{details.user.extend.mobile?details.user.extend.mobile:'-'}}</div>
                  <div class="txt">手机号码</div>
                </div>
                <div class="name fl">
                  <div class="p">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}</div>
                  <div class="txt">学校名称</div>
                </div>
              </div>
              <div class="status-con">
                <p>动态内容</p>
                <div class="text">{{details.digest?details.digest:'-'}}</div>
                <el-row :gutter="20">
                  <el-col :span="8" v-for="(item,index) in details.imgList" :key="index">
                    <div class="img">
                      <img :src="item" alt="" v-viewer>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
export default {
  name:'schoolfellow',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      drawer:false,
      queryParams:{
        status:'',
        title:''
      },
      details: {},

      pageApi: "getSchoolFellowList",
      loadApi: "socialDetail",
    };
  },
  computed: {
    ...mapState("schoolFellow", ["schoolFellowList", "schoolFellowTotal"]),
  },
  methods: {
    ...mapActions("schoolFellow", [
      "getSchoolFellowList",
      "socialDetail"
    ]),
    async detail(newsId) {
      this.details = await this.socialDetail({newsId})
      console.log(this.details)
      this.drawer = true
    },
    handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
      },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year {
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
  }
  .list{ 
    .top{ border-bottom: 1px solid $c-border; padding-bottom: 20px;
      .new-title{width: 450px; height: 48px; 
        p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9; }
      }
      .name{
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
    }
    .content{ margin-top: 20px; 
        .time{
          span{font-size: 14px; color: $c-9; }
          .con{font-size: 14px; color: $c-2; font-weight: 400; margin-left: 10px;}
        }
    }
  }
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/ .el-drawer__header{ margin-bottom: 0px;}
  .drawer{ padding: 0px 20px;
    .title{height: 84px;  background: #F5F7F9; margin-top: 20px; padding: 10px 1px 1px 20px;
      .new-title{ width: 240px;
        p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{  width: 100px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
    }
    .content{ margin: 20px 0px;
      .puplish{
        .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
        .puplish-con{
          .img{width:44px; height: 44px; overflow: hidden; border-radius: 50%; margin-right: 10px;
            img{width:44px; height: 44px;}
          }
          .name{  margin-right: 16px;
            .p{font-size: 16px;  color: $c-2; font-weight: bold; 
              img{margin: 6px 0px 0px 5px;}
            }
            .txt{font-size: 12px; color: $c-9;}
          }
        }
        .status-con{ margin-top: 20px;
          p{color: $c-2; font-weight:bold; margin-bottom: 10px;}
          .text{font-size: 14px; font-weight:400;}
          .img{height: 150px; margin-top: 10px; 
            img{width: 150px; height: 150px;}
          }
        }
      }
    }  
  } 
}
</style>