<template>
<!-- 任务分配 @需要写 -->
  <div class="wrap">
    sdf
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'taskAllocation',
  components: {
    PagedList
  },
  mixins: [pageMixin],
  data() {
    return{
      queryParams:{},
      activeName:'1',
      statusOptions:[],
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .tabs{ width: 100%; min-height: 100%; background:#fff;padding: 20px; padding-top: 0;
  }
  .big-box{ width: 100%; height: 100%; 
  }
}
.setting{ margin-right: 20px; }
.list{
  .listtit{ color: #222; line-height: 50px;}
  .listcon{
    .el-input:nth-child(n){ width: 120px; margin-right:10px ;}
    .el-input:nth-child(2n){ width: 200px;}
  }
}

</style>
