<template>
  <div  class="wrap">
    <!-- 校园新闻 -->
    <div class="box-con">
      <el-form class="select params-wrap" size="small" :inline="true" :model="queryParams" label-width="100px">
          <el-form-item v-if="!url">
            <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
          </el-form-item> 
          <el-form-item label="新闻状态:" >
            <el-select v-model="queryParams.status" @change="refresh" clearable filterable placeholder="新闻状态" style="width:200px;margin-right:20px">
              <el-option label="全部" value="" ></el-option>
              <el-option label="发布中" :value="'0'"  ></el-option>
              <el-option label="已下架" :value="'1'" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间：" class="ml20">
            <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'s1','s2','queryParams'),refresh()" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
          </el-form-item>
          <el-form-item >
             <el-input placeholder="请输入新闻标题关键字" clearable v-model="queryParams.title" style="width:300px" >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="refresh">搜索</el-button>
          </el-form-item>
          <el-form-item>
             <el-button  @click="gotoAddForm"  size="small" icon="el-icon-plus" style="color:#28B892; border-color:#28B892;" v-if="OA.includes('yygl:xyxw:add')">新增</el-button>
          </el-form-item>
           <el-form-item>
            <el-button @click="isEditor=!isEditor"  v-if="OA.includes('yygl:xyxw:del')"  :type="isEditor ? 'success' : ''" >{{isEditor ? '完成' : '删除'}}</el-button>
          </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="schoolNewsTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="schoolNewsList" @refresh='refresh' :total="schoolNewsTotal"  :span="12" :isEditor="isEditor">
         <template slot-scope="scope">
          <div class="list " @click="fetchSchoolNewsLoad(scope.row.id)">
            <div class="top cfx">
              <div class="new-title fl">
                <p>{{ scope.row.title?scope.row.title:'-' }}</p>
                <div class="txt">新闻标题</div>
              </div>
              <div class="name fl">
                <p>{{ scope.row.views?scope.row.views:'-' }}</p>
                <div class="txt">浏览量</div>
              </div>
              <div class="status fr cfx">
                <p v-if="scope.row.status ==0">发布中</p>
                <p v-if="scope.row.status ==1" class="gray" >已下架</p>
                <p v-if="scope.row.status ==2" class="tinctred" >已删除</p>
                <div class="txt fr">状态</div>
              </div>
            </div>
            <div class="content cfx " v-if="scope.row.extend">
              <el-row :gutter="20" >
                  <el-col :span="12" >
                        <div class="time"><span>发布人</span><span class="con">{{scope.row.extend.userName?scope.row.extend.userName:'-'}}</span></div>
                  </el-col>
                  <el-col :span="12" >
                        <div class="time"><span>手机号码</span><span class="con">{{scope.row.extend.mobile?scope.row.extend.mobile:'-'}}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;">
                        <div class="time"><span>发布时间</span><span class="con">{{scope.row.addTime?scope.row.addTime:'-'}}</span></div>
                  </el-col>
                  <el-col :span="12" style="margin-top:20px;">
                        <div class="time" v-if="scope.row.extend.schoolName"><span>学校名称</span><span class="con">{{scope.row.extend.schoolName?scope.row.extend.schoolName:'-'}}</span></div>
                        <div class="time" v-if="scope.row.extend.zoneName"><span>区域名称</span><span class="con">{{scope.row.extend.zoneName?scope.row.extend.zoneName:'-'}}</span></div>
                  </el-col>
              </el-row>
            </div>
          </div>
        </template>
        <template v-slot:control="{row}">          
          <el-button v-if="OA.includes('yygl:xyxw:del')" type="danger"  @click="newsDel(row.id)" icon="el-icon-delete" circle></el-button>
          <!-- <el-button type="danger"  @click="reportDelete(row.id)" icon="el-icon-delete" circle></el-button> -->
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      size="35%"
      >
        <div class="drawer ">
          <div class="title cfx">
              <div class="new-title fl">
                <p>{{details.addTime}}</p>
                <div class="txt">发布时间</div>
              </div>
              <div class="name fl">
                <p>{{details.views}}</p>
                <div class="txt">浏览量</div>
              </div>
              <div class="status fr cfx">
                <p v-if="details.status ==0">发布中</p>
                <p v-if="details.status ==1" class="gray">已下架</p>
                <p v-if="details.status ==2" class="tinctred">已删除</p>
                <div class="txt fr">状态</div>
              </div>
          </div>
          <div class="content cfx ">
            <div class="puplish">
              <p class="puplish-name">发布人</p>
              <div class="puplish-con cfx" v-if="details.extend">
                <div class="img fl">
                  <img src="../../assets/images/he/text/3.jpg" alt="">
                </div>
                <div class="name fl">
                  <p>{{details.extend.userName?details.extend.userName:'-'}} 
                    <img v-if="details.sex == 1" src="../../assets/images/he/operate/man.svg" alt="">
                    <img v-if="details.sex == 0" src="../../assets/images/he/operate/woman.svg" alt="">
                  </p>
                  <div class="txt">{{details.extend.schoolName?details.extend.schoolName:details.extend.zoneName}}</div>
                </div>
                <div class="name fl">
                  <p>{{details.extend.mobile}}</p>
                  <div class="txt">手机号码</div>
                </div>
                <div class="name fl" v-if="details.extend.schoolName">
                  <p>{{details.extend.schoolName}}</p>
                  <div class="txt">学校名称</div>
                </div>
                <div class="name fl" v-if="details.extend.zoneName">
                  <p>{{details.extend.zoneName}}</p>
                  <div class="txt">区域名称</div>
                </div>
              </div>
              <div class="status-con">
                <p>新闻内容</p>
                <div class="title-img cfx">
                    <div class="new-img">
                        <img :src="item" v-for="item in imgList" :key="item" alt="" v-viewer>
                    </div>
                    <div class="new-title">{{details.title}}</div>
                </div>
                
                <div class="text" v-html="details.content">{{details.content}}</div>
                <div class="btn">
                  <el-button type="success" v-if="details.status ==1" @click="gotoAdd(details.id)">重新发布</el-button>
                  <el-button type="success" v-if="details.status ==0" @click="gotoEdit">下架</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import PagedList from "@/components/common/PagedList";
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import datePicker from "@/mixins/datePicker";

export default {
  name:'noticeManagement',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin,datePicker],
  data() {
    return {
      drawer:false,
      queryParams:{
        s1:'',
        s2:'',
        status:'',
        content:'',
        title:''
      },
      form:{},
      details:{},
      imgList:[],
      pageApi: "getSchoolNewsList",
      loadApi: "getSchoolNewsLoad",
      insertApi: "getSchoolNewsAdd",
      editorApi: "getSchoolNewsEdit",
      isEditor: false
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("schoolNews", ["schoolNewsList", "schoolNewsTotal"]),
  },
  
  methods: {
    ...mapActions("schoolNews", [
      "getSchoolNewsList",
      "getSchoolNewsLoad",
      "getSchoolNewsAdd",
      "getSchoolNewsEdit",
      "getSchoolNewsDelete",
      "getSchoolNewsDeleteLogic"
    ]),
    // 详情
    async fetchSchoolNewsLoad(id){
      this.drawer = true
      const res = await this.getSchoolNewsLoad({id})
      this.details = res
      // 无图
      if (res.pics == '') {
        this.details.pics = ''
      }else{
        this.imgList = this.details.pics.split(',')
      }



      this.refresh();
      console.log(res,'详情');
    },
    // 修改Edit
    async fetchSchoolNewsEdit(){
      const res = await this.getSchoolNewsEdit(this.details)
    },
    
     // 逻辑删除
    async newsDel(id){
      await this.getSchoolNewsDeleteLogic({id, status:2})
      this.isEditor = false
      this.refresh()
    },

    //重新发布
    gotoAdd(id){
      this.drawer = false
      this.$router.push({
        path:'schoolNewsAdd',
        query:{
          id:id
        }
      })
    },
    //下架
    gotoEdit(){
      this.details.status = 1
      this.drawer = false
      this.getSchoolNewsEdit(this.details)
      // 成功提示
      this.$message.success('下架成功')
      this.refresh();
    },
    //去添加页
    gotoAddForm(){
      this.$router.push('schoolNewsAdd')
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    this.url = window.location.href.includes('ceo.') || window.location.href.includes('20030')
     this.refresh();
  },
  activated(){
    this.refresh();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Regular, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year {
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
  }
  .list{ 
    .top{ border-bottom: 1px solid $c-border; padding-bottom: 20px;
      .new-title{width: 350px; height: 48px; 
        p{font-size: 20px;  color: $c-2; font-weight: bold;  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{ margin-left: 20px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .status{
         p{font-size: 20px;  color: $c-primary; font-weight: bold;}
         .gray{color: $c-9;}
        .txt{font-size: 14px; color: $c-9;}
      }
    }
    .content{ margin-top: 20px; 
        .time{
          span{font-size: 14px; color: $c-9; }
          .con{font-size: 14px; color: $c-2; margin-left: 10px; font-weight: 400;}
        }
    }
  }
  // 详情
  /deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
  /deep/ .el-drawer__header{ margin-bottom: 0px;}
  .drawer{ padding: 0px 20px;
    .title{height: 84px;  background: #F5F7F9; margin-top: 20px; padding: 10px 20px 1px 20px;
      .new-title{ width: 220px;
        p{font-size: 20px;  color: $c-2; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .name{  width: 120px;
        p{font-size: 20px;  color: $c-2; font-weight: bold;}
        .txt{font-size: 14px; color: $c-9;}
      }
      .status{ 
        p{font-size: 20px;  color:$c-primary; font-weight: bold;}
        .gray{color: $c-9;}
        .txt{font-size: 14px; color: $c-9;}
      }
    }
    .content{ margin: 20px 0px;
      .puplish{
        .puplish-name{color: $c-2; margin-bottom: 10px; font-weight:bold;}
        .puplish-con{ 
          .img{width:44px; height: 44px; overflow: hidden; border-radius: 50%; margin-right: 10px;
            img{width:44px; height: 44px;}
          }
          .name{   width: 140px;
            p{font-size: 16px;  color: $c-2; font-weight: bold;
              img{margin: 6px 0px 0px 5px;}
            }
            .txt{font-size: 12px; color: $c-9;
              .line{margin: 0px 5px;}
            }
          }
        }
        .status-con{ margin-top: 20px;
          p{color: &c-2; font-weight:bold; margin-bottom: 10px; font-weight: bold;}
          .title-img{margin-bottom: 20px;
            .new-img{width: 100%; height: 100px;  margin-right: 20px;display:flex;justify-content: space-around;
              img{width: 150px; height: 100px;}
            }
            .new-title{font-size: 16px; font-weight:bold; margin-bottom: 10px;width: 250px; }
          }
          .text{font-size: 14px; font-weight:400;}
          .btn{ margin: 40px auto; width: 100px; 
            .el-button{width: 100px;}
          }
        }
      }
    }  
  } 
}
</style>