/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postDealOrder = function(params) {
  return axios({
    url: BaseUrl + "net/admin/order/list",
    method: "post",
    data: params
  });
};
 
// outTradeNo = orderNum money
// 支付宝退款
export const payAlipayRefund = function(params) {
  return axios({
    url: BaseUrl + "pay/alipay/refund",
    method: "post",
    data: params
  });
};

// 微信退款
export const payWxpayRefund = function(params) {
  return axios({
    url: BaseUrl + "pay/wxpay/refund",
    method: "post",
    data: params
  });
};

// 余额退款
export const payYepayRefund = function(params) {
  return axios({
    url: BaseUrl + "pay/yepay/refund",
    method: "post",
    data: params
  });
};

// 顶部汇总
export const getOrderSummary = function (params) {
  return axios({
      url: BaseUrl + 'net/admin/order/getOperateSum',
      method: 'post',
      data: params
  })
}

export default {
  postDealOrder,
  payAlipayRefund,
  payWxpayRefund,
  payYepayRefund,
  getOrderSummary
};