<template>
  <!-- 分销任务分工 -->
<div  class="wrap">
    <div class="box-con">
      <!-- 入驻管理 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams" label-width="100px">
        <el-form-item >
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="分销类型：">
          <el-select v-model="queryParams.status" @change="resetPageAndRefresh()" clearable filterable placeholder="请选择执行状态">
            <el-option v-for="item in taskStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="商铺类型：">
          <el-button  @click="queryParams.shopType=104003002,queryParams.mallType='',resetPageAndRefresh()">通信大厅</el-button>
          <el-button  @click="queryParams.shopType=104003001,queryParams.mallType='',resetPageAndRefresh()">网络大厅</el-button>
          <el-select  v-model="queryParams.mallType"  @change="queryParams.shopType = queryParams.mallType,resetPageAndRefresh()" clearable filterable placeholder="商城" style="width:100px;margin-left:10px">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-input placeholder="请输入公司名称" size="small" clearable v-model="queryParams.companyName" style="width:200px;margin-left:20px" >
          </el-input>
          <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
        </el-form-item >
      </el-form>
    </div>
    <!-- 列表 -->
    <div v-if="settlList.length==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
    <paged-list v-model="queryParams"  @refresh="refresh" :span='12'>
      <template slot-scope="scope">
        <div class="item">
          <div class="task-item">
            <span class="name">{{scope.row.name}}</span>
            <span class="tip">成员姓名</span>
          </div>
        </div>
      </template>
    </paged-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";

export default {
  name:'tasksmarket',
  mixins:[pageMixin],
  components:{
    AreaTree,
    PagedList
  },
  data() {
    return {
      queryParams: {
        status:null,
        companyName:'',
        shopType:null

      },
      taskStatusOptions:[
        {
          value:0,
          label:'未加入'
        },
        {
          value:1,
          label:'已加入'
        },
        {
          value:2,
          label:'不加入'
        },
      ],
      positonList:[],
      settlList:[
        
      ],

      pageApi:''
    }
  },
  computed:{
    ...mapState(["OA"]),
  },
  methods: {
    ...mapActions('enterAduit',['dictGetDictFPid']),
    // 地区查询
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
  },
  async mounted() {
    this.positonList = await this.dictGetDictFPid({pid:104003003})
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .btnBox{display: inline-block; margin-right: 10px; width: 100%;padding: 0 20px;}
    .list{ height: 348px; background: #FFFFFF; border-radius: 4px; 
      .list-top{ border-bottom: 1px solid $c-border;
        .top_head{ margin-bottom: 20px;
          span{ font-size: 20px; height: 20px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #222222;line-height: 20px;}
        }
        .top_con{margin-bottom: 20px;
          span{ display: inline-block; width: 100px; height: 30px; padding-left:10px;  margin-right: 20px;
            img{vertical-align: middle;margin-left: 3px;}
          }
          .yesCon{ background: #FFFFFF;border-radius: 4px;border: 1px solid #28B892; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400;color: #28B892; line-height: 30px; }
          .noCon{ background: #FFEDED; border-radius: 4px; border: 1px solid #FF5353; line-height: 30px;
          font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #FF5353;}
        }
        .top_foot{ margin-bottom:20px;
          .area{ display: inline-block; height: 14px; font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #222222;line-height: 14px; margin-right:20px ;
            .time{ display: inline-block; color: #889098; margin-bottom:20px;margin-right: 10px; font-family: PingFangSC-Regular, PingFang SC;}
            .textWidth{width: 100px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
            .con{ display: inline-block; }
          }
        }

      }
      .list-bottom{ 
        .bottom_head{ padding-top: 12px; margin-bottom: 20px;
          span{ color: #889098;line-height: 14px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC; }
        }
        .base-steps {
          position: absolute;
          top: 40px;
        }
      }
    }
  }
}
</style>