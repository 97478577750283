/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/task/StudyTask";

const state = {
  storeList:[],
  storeTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

// actions
const actions = {
  async sectionList({ commit, state }, params) {
    let result = await api.sectionList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async sectionAdd({ commit, state }, params) {
    return await api.sectionAdd(params);
  },
  async sectionEdit({ commit, state }, params) {
    return await api.sectionEdit(params);
  },
  async sectionLoad({ commit, state }, params) {
    return await api.sectionLoad(params);
  },
  async sectionDelete({ commit, state }, params) {
    return await api.sectionDelete(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
