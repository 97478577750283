/*
 * @Auhtor: 文锋
 * @Date: 2021-05-12 17:06:46
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 09:31:10
 * @FilePath: \operate\src\api\basic\ceocheck.js
 */
import { BaseUrl } from "@/common/config";
import axios from "axios";

// ceo 审核列表
export const getPagesList = function (params) {
 return axios({
     url: BaseUrl + 'members/ceoApply/getPageList',
     method: 'post',
     data: params
 })   
}
// 审核详情
export const getCheckInfoById = function (params) {
    return axios({
        url: BaseUrl + 'members/ceoApply/load',
        method: 'post',
        data: params
    })
}

// ceo 编辑审核
export const editCeoCheck = function (params) {
    return axios({
        url: BaseUrl + 'members/ceoApply/check',
        method: 'post',
        data: params
    })
}

export default {
    getPagesList,
    editCeoCheck,
    getCheckInfoById
}