<template>
  <!-- 分销池 -->
  <div  class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams" label-width="100px">
        <el-form-item v-if="areaManager == 'areaManager' || sysCOO == 'sysCOO'">
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="分销类型：">
          <el-select v-model="queryParams.status" @change="resetPageAndRefresh()" clearable filterable placeholder="请选择执行状态">
            <el-option v-for="item in taskStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="商铺类型：">
          <el-button class="w110" :class="activeNum == 1?'all':''"  @click="activeNum =1,queryParams.shopType=null,queryParams.mallType='',resetPageAndRefresh()">全部</el-button>
          <el-button :class="activeNum == 2?'all':''" @click="activeNum =2,queryParams.shopType=107001001,queryParams.mallType='',resetPageAndRefresh()">通信大厅</el-button>
          <el-button :class="activeNum == 3?'all':''" @click="activeNum =3,queryParams.shopType=107001002,queryParams.mallType='',resetPageAndRefresh()">网络大厅</el-button>
          <el-select  v-model="queryParams.mallType"  @change="queryParams.shopType = queryParams.mallType,resetPageAndRefresh()" clearable filterable placeholder="商城" style="width:100px;margin-left:10px">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-input placeholder="请输入成员名称" size="small" clearable v-model="queryParams.companyName" style="width:200px;margin-left:20px" >
          </el-input>
          <el-button size="small" type="success" @click="refresh" style="margin-left:20px;">查询</el-button>
        </el-form-item >
      </el-form>
      <!-- 列表 -->
      <div v-if="marketTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-if="marketTotal>0" v-model="queryParams" :data="marketList" :total="marketTotal"  @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="market" @click="aVisible = true,formData = {},getDetailInfo(scope.row.id)">
            <div class="market-h">
              <div class="market-t w50">
                <span class="tip ellipsis">{{scope.row.goodsName}}</span>
                <span class="mt">商品名称</span>
              </div>
              <div class="market-t w20">
                <span class="tip">¥{{scope.row.goodsCommission|money}}</span>
                <span class="mt">佣金</span>
              </div>
              <div class="market-t w20">
                <span class="tip">{{scope.row.demand}}</span>
                <span class="mt">可分销数（笔）</span>
              </div>
              <div class="market-t">
                <span class="tip primary" v-if="scope.row.stateName == '未加入'" >{{scope.row.stateName}}</span>
                <span class="tip success" v-if="scope.row.stateName == '已加入'" >{{scope.row.stateName}}</span>
                <span class="tip danger" v-if="scope.row.stateName == '不加入'">{{scope.row.stateName}}</span>
                <span class="mt">分销状态</span>
              </div>
            </div>
            <div class="cont">
              <div class="fl">
                <p><span>申请时间：</span>{{scope.row.applyTime}}</p>
                <p><span>申请店铺：</span>{{scope.row.shopName}}</p>
                <p><span>经营范围：</span>{{scope.row.shopAddr}}</p>
              </div>
              <div class="fr">
                <p><span>店铺类型：</span>{{scope.row.extend.shopTypeName}}</p>
                <p><span>商品金额：</span>¥{{scope.row.goodsPrice|money}}</p>
              </div>
            </div>
          </div>
        </template>
      </paged-list>
      <!-- 详情 -->
      <el-drawer class="detail" title="详情" :visible.sync="aVisible" direction="rtl" size="750px" :destroy-on-close="true" :show-close="true" :wrapperClosable="true">
        <div class="user" v-if="marketInfo.state != 0">
          <div class="userInfo">
            <div class="info">
              <div class="info-t">{{successInfo.extend.userName || failInfo.extend.userName}}</div>
              <span>申请人</span>
            </div>
            <div class="info">
              <div class="info-t">{{successInfo.extend.mobile || failInfo.extend.mobile}}</div>
              <span>手机号码</span>
            </div>
            <div class="info">
              <div class="info-t">{{marketInfo.applyTime}}</div>
              <span>申请时间</span>
            </div>
            <div class="info">
              <div class="info-t" v-if="marketInfo.state == 1">已加入</div>
              <div class="info-t" v-if="marketInfo.state == 2">不加入</div>
              <span>状态</span>
            </div>
          </div>
          <!-- 分销佣金 -->
          <div class="task-money" v-if="marketInfo.state == 1">
            <p><span>分销总金额</span>￥{{successInfo.commission}}</p>
            <div class="money">
              <div class="money-info"><span>一级佣金</span>￥{{successInfo.commission1|money}}</div>
              <div class="money-info"><span>二级佣金</span>￥{{successInfo.commission2|money}}</div>
              <div class="money-info"><span>三级佣金</span>￥{{successInfo.commission3|money}}</div>
            </div>
          </div>
          <!-- 拒绝原因 -->
          <div class="reason" v-if="marketInfo.state == 2"><span>不加入原因</span>{{failInfo.refuseReason}}</div>
        </div>
        <div class="shopInfo">
          <p>店铺信息</p>
          <div class="shop">
            <img :src="marketInfo.shopLogo" alt="">
            <div class="continfo">
              <div class="cont">
                <div class="info">
                  <div class="info-t">{{marketInfo.shopName}}</div>
                  <span>店铺名称</span>
                </div>
                <div class="info">
                  <div class="info-t" v-if="marketInfo.extend">{{marketInfo.extend.shopTypeName}}</div>
                  <span>店铺类型</span>
                </div>
              </div>
              <div class="address"><span>经营范围</span>{{marketInfo.shopAddr}}</div>
            </div>
          </div>
        </div>
        <div class="goodsInfo">
          <p>商品详情</p>
          <div class="goodsName">{{marketInfo.goodsName}}</div>
          <div class="money">
            <div class="money-info"><span>商品金额</span>￥{{marketInfo.goodsPrice|money}}</div>
            <div class="money-info"><span>佣金</span>￥{{marketInfo.goodsCommission|money}}</div>
            <div class="money-info"><span>可分销数</span>{{marketInfo.demand}}</div>
          </div>
          <div class="money-line"><span>商品链接</span><a :href="marketInfo.goodsLink" target="_blank" rel="noopener noreferrer">{{marketInfo.goodsLink}}</a></div>
          <div class="imgItem">
            <viewer v-if="marketInfo.notePic" :images="marketInfo.notePic.map(item => item.url)">
              <span v-for="(item,index) in marketInfo.notePic" :key="index">
                  <el-image style="width: 207px; height: 207px;border-radius:4px;margin:10px" :src="item.url"></el-image>
              </span>
            </viewer>
          </div>
        </div>
        <!-- 任务处理 -->
        <div class="task" v-if="marketInfo.state == 0">
          <p>任务处理</p>
          <el-form :model="formData" ref="form" :rules="formRules"  label-width="180px"  >
            <el-form-item label="是否加入任务清单：" >
              <el-radio-group v-model="taskStatus" @change="formData = {}">
                <el-radio :label="0" >加入</el-radio>
                <el-radio :label="1">不加入</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="分销总金额：">
              <span>{{marketInfo.goodsCommission|money}}</span>
            </el-form-item>
             <el-form-item label="不加入原因：" v-if="taskStatus == 1" prop="refuseReason">
              <el-input v-model="formData.refuseReason" placeholder="请输入拒绝加入原因"  clearable></el-input>
            </el-form-item>
            <div v-else>
              <el-form-item label="一级佣金：" prop="goodsCommission1">
                <el-input v-model="formData.goodsCommission1" placeholder="请输入金额"  clearable ></el-input>
              </el-form-item>
              <el-form-item label="二级佣金：" prop="goodsCommission2">
                <el-input v-model="formData.goodsCommission2" placeholder="请输入金额"  clearable ></el-input>
              </el-form-item>
              <el-form-item label="三级佣金：" prop="goodsCommission3">
                <el-input v-model="formData.goodsCommission3" placeholder="请输入金额"  clearable ></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="edit">
                <el-button class="btn" @click="aVisible = false">取消</el-button>
                <el-button class="btn submit" @click="taskStatus == 0?addSubmit('form'):cancelSubmit('form')">立即创建</el-button>
              </div>
            </el-form-item>
          </el-form>
          
        </div>
      </el-drawer>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";

export default {
  name:'channelmarket',
  mixins:[pageMixin],
  components:{
    AreaTree,
    PagedList
  },
  data() {
    return {
      activeNum:1,
      // 角色
      ceo:'',
      areaManager:'',
      sysCOO:'',
      role:'',

      aVisible: false,
      formData:{},
      taskStatus:0,
      // 已加入分销
      successInfo:{
        extend:{}
      },
      // 不加入分销
      failInfo:{
         extend:{}
      },
      // 查询
      queryParams: {
        status:null,
        companyName:'',
        shopType:null,
        pageNum:1,
        pageSize:10,
      },
      taskStatusOptions:[
        {
          value:0,
          label:'未加入'
        },
        {
          value:1,
          label:'已加入'
        },
        {
          value:2,
          label:'不加入'
        },
      ],
      positonList:[],
      formRules:{
        refuseReason:[{ required: true, message: '请输入不加入原因', trigger: 'blur' }],
        goodsCommission1:[{ required: true, message: '请输入一级佣金', trigger: 'blur' },
        
        ],
        goodsCommission2:[{ required: true, message: '请输入二级佣金', trigger: 'blur' },
        ],
        goodsCommission3:[{ required: true, message: '请输入三级佣金', trigger: 'blur' },
        ],
      },

      pageApi:'getChannelMarketList'
    }
  },
  computed:{
    ...mapState(["OA"]),
    ...mapState('channelMarket',['marketList','marketTotal','marketInfo'])
  },
  filters:{
    money(val) {
      return (val/100).toFixed(2)
    }
  },
  methods: {
    ...mapActions('enterAduit',['dictGetDictFPid','getLoginUser']),
    ...mapActions('channelMarket',['getChannelMarketList','getChannelInfo','successTask','failTask','joinTask','refuseTask']),
    // 分销详情
    async getDetailInfo(id) {
      await this.getChannelInfo({id})
      // 已加入
      if(this.marketInfo.state == 1) this.successInfo = await this.successTask({sellId:id})
      console.log('join',this.successInfo)
      if(this.marketInfo.state == 2) this.failInfo =  await this.failTask({sellId:id})
      console.log('nojoin',this.failInfo)
    },
    // 加入
    addSubmit(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          // 计算佣金 金额  必须小于  总佣金值
          this.formData.id = this.marketInfo.id
          this.formData.goodsCommission1 = parseFloat(this.formData.goodsCommission1*100)
          this.formData.goodsCommission2 = parseFloat(this.formData.goodsCommission2*100)
          this.formData.goodsCommission3 = parseFloat(this.formData.goodsCommission3*100)
          let money = parseFloat(this.formData.goodsCommission1 + this.formData.goodsCommission2 + this.formData.goodsCommission3)
          if (money <= parseFloat(this.marketInfo.goodsCommission)) {
            let res = await this.joinTask(this.formData)
            if (res == null) {
              this.$message.success('加入分销成功')
              this.aVisible = false
              this.refresh()
            }
          }else{
            this.$message.warning('一、二、三级佣金不能超过总佣金额度')
            this.formData = {}
            return false;
          }
        } else {
          return false;
        }
      });
    },
    // 不加入
    cancelSubmit(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.formData.id = this.marketInfo.id
          let res = await this.refuseTask(this.formData)
          if (res == null) {
            this.$message.success('拒绝加入分销成功')
            this.aVisible = false
            this.refresh()
          }
        } else {
          return false;
        }
      });
    },
    // 地区查询
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
  },
  async created() {
    const res = await this.getLoginUser()
    const arr = res.roles
    arr.forEach(item=>{
      if( item == 'xtgly') {
        this.role = item
      }
      if(item == 'ceo') {
        this.ceo = item
      }
      if(item == 'areaManager') {
        this.areaManager = item
      }
      if(item == 'sysCOO') {
        this.sysCOO = item
      }
    })  
  },
  async mounted() {
    let res = await this.dictGetDictFPid({pid:104003003})
    this.positonList = res.filter(item=>item.id != 107001001 || item.id != 107001002)
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
*{font-family: PingFangSC-Semibold, PingFang SC;}
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .market {
      .market-h {
        display: flex;
        .market-t {
          display: flex;
          flex-direction: column;
          span {
            color: #889098;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            &.tip{
              font-size: 20px;
              font-weight: 600;
              color: #222222;
              line-height: 20px;
            }
            &.mt {
              margin: 14px 0 40px;
            }
          }
          
        }
        .w50 {width: 50%;}
        .w20 {width: 20%;}
      }
      .cont {
        display: flex;
        .fl,.fr {
          width: 50%;
          p {
            font-size: 14px;color:#222222;
            span {font-size: 14px;color: #889098;}
          }
        }
      }
    }
    .detail {
      .user{
        display: flex;
        flex-direction: column;
        margin: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 4px;
        background-color: #F5F7F9;
        .userInfo{
          display: flex;
          justify-content: space-between;
          .info {
            display: flex;
            flex-direction: column;
            .info-t {
              font-size: 20px;
              font-weight: 600;
              color: #222222;
              line-height: 36px;
            }
            span{font-size: 14px;color: #979797;}
          }
        }
        .task-money {
          >p{
            color: $c-2;
            span{font-size: 14px;color: #979797;margin-right: 10px;}
          }
          .money {
            margin-left: 20px;
            display: flex;
            .money-info {
              width: 30%;
              color: $c-2;
              span {font-size: 14px;color: #979797;margin-right: 10px;}
            }
          }
        }
        .reason {
          color: #222222;
          span{font-size: 14px;color: #979797;margin-right: 20px;}
        }
      }
      .shopInfo {
        margin: 40px 20px 20px 20px;
        >p{font-size: 16px;color: #222222;font-weight: 600;}
        .shop {
          margin-top: 20px;
          border-bottom: 1px solid #EAEFF3;
          display: flex;
          >img{width: 44px;height: 44px;border-radius: 4px;}
          .continfo {
            margin-left: 20px;
            flex: 1;
            display: flex;
            flex-direction: column;
            .cont {
              display: flex;
              .info {
                width: 50%;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                .info-t {
                  font-size: 20px;
                  font-weight: 600;
                  color: #222222;
                  line-height: 20px;
                }
                span{font-size: 14px;color: #979797;}
              }
            }
            .address {
              margin-bottom: 20px;
              span {font-size: 14px;color: #979797;margin-right: 10px;}
            }
          }
        }
      }
      .goodsInfo {
        margin: 40px 20px 20px 20px;
        >p{margin-bottom: 10px;}
        >p,.goodsName{font-size: 16px;color: #222222;font-weight: 600;}
        .money {
          margin: 10px auto;
          display: flex;
          .money-info {
            width: 30%;
            color: $c-danger;
            span {font-size: 14px;color: #979797;margin-right: 10px;}
          }
        }
        .money-line {
          span {font-size: 14px;color: #979797;margin-right: 10px;}
        }
      }
      .task {
        margin: 40px 20px 20px 20px;
        padding: 10px;
        background: #F5F7F9;
        border-radius: 6px;
        >p{font-size: 16px;color: #222222;font-weight: 600;}
        .edit {
          .btn {
            width: 140px;
            margin-right: 20px;
            &.submit {
              color:$c-white;
              background-color:$c-success ;
            }
          }
        }
        /deep/.el-form-item__label{font-size: 14px;color: #979797;}
      }
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w110 {
  width: 110px;
}
.all {
  color: $c-success;
  background-color: rgba(40, 184, 146, 0.1);
  border: 1px solid $c-success;
}
.primary {
  color: #4A90E2!important;
}
.success {
  color: #28B892!important;
}
.danger {
  color: #979797!important;
}
/deep/ #el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/ .el-drawer__header{ margin-bottom: 0px;}
</style>