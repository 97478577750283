<template>
<!-- 地图组件封装 -->
  <div class="amap-page-container">
    <el-row :gutter="20">
      <el-col :span="10">
        <div class="hd">热门搜索：</div>
        <span
          class="mapItem"
          v-for="(item,index) in mapSearchData"
          :key="index"
          @click="mapSearch(item)"
        >{{item}}</span>
      </el-col>
      <el-col :span="8">
        <div class="hd">请输入搜索内容：</div>
        <el-amap-search-box
          class="search-box"
          :value="address"
          :search-option="searchOption"
          :on-search-result="onSearchResult"
        ></el-amap-search-box>
      </el-col>
      <el-col :span="6" style="position: relative;">
        <div class="hd">坐标获取结果：</div>
        <div>经度：{{mapCenter[0]}}</div>
        <div>纬度：{{mapCenter[1]}}</div>
        <el-button
          type="primary"
          @click="submitLnglat()"
          style="position: absolute; right: 0; top: 30px;"
        >确 定</el-button>
      </el-col>
    </el-row>
    <el-amap vid="amapDemo" :center="mapCenter" :zoom="12" :events="events" :style="ch">
      <el-amap-marker :position="mapCenter" :visible="marker.visible" :draggable="marker.draggable"></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
export default {
  data() {
    return {
      address:'',
      marker: {
        visible: true,
        draggable: false,
      },
      searchOption: {
        city: "合肥",
      },
      mapCenter: [0, 0],
      mapSearchData: [
        "北京",
        "上海",
        "广州",
        "深圳",
        "成都",
        "沈阳",
        "苏州",
        "郑州",
        "青岛",
        "天津",
        "重庆",
        "武汉",
        "杭州",
        "西安",
        "南京",
        "大连",
        "长沙",
        "合肥",
      ],
      events: {
        click: (e) => {
          this.mapCenter = [e.lnglat.lng, e.lnglat.lat];
        },
      },
      ch: {
        height: document.documentElement.clientHeight - 280 + "px",
      },
    };
  },
  props: {
    lng:{
      type: Number,
      default: 0
    },
    lat:{
      type: Number,
      default: 0
    }
  },
  methods: {
    mapSearch(name) {
      let that = this;
      AMap.service(["AMap.Geocoder"], function () {
        var geocoder = new AMap.Geocoder({
          radius: 1000,
        });
        geocoder.getLocation(name, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            let lng = result.geocodes[0].location.getLng().toFixed(6);
            let lat = result.geocodes[0].location.getLat().toFixed(6);
            that.mapCenter = [lng, lat];
            that.gAddress(that.mapCenter);
            
          }
        });
      });
    },
    onSearchResult(pois) {
      let latSum = 0;
      let lngSum = 0;
      if (pois.length > 0) {
        pois.forEach((poi) => {
          let { lng, lat } = poi;
          lngSum += lng;
          latSum += lat;
        });
        let center = {
          lng: (lngSum / pois.length).toFixed(6),
          lat: (latSum / pois.length).toFixed(6),
        };
        this.mapCenter = [center.lng, center.lat];
        this.gAddress(center);
      }
    },
    async gAddress(points) {
        await new Promise((r,j)=>{
          let geocoder = new AMap.Geocoder({ radius: 1000 });
          geocoder.getAddress(points, (status, result) => {
              if (status === "complete" && result.regeocode) {
                let s_addr =
                  result.regeocode.addressComponent.province +
                  "-" +
                  result.regeocode.addressComponent.city +
                  "-" +
                  result.regeocode.addressComponent.district;
                let addreesd = result.regeocode.formattedAddress; // 全部地址名称
                let houzhi = addreesd.substring(s_addr.length); // 获取具体街道
                // console.log(s_addr);
                // console.log(houzhi);
                // console.log(addreesd);
                this.address = s_addr + "-" + houzhi;
                r(this.address)
              }
            });
        })
      },  
    async submitLnglat() {
      let o = this.mapCenter
      await this.gAddress(this.mapCenter)
      o.push(this.address)
      this.$emit("finish", o);
    },
  },
  mounted() {
    if (this.lat && this.lng) {
      this.mapCenter = [this.lng, this.lat];
    } else {
      let self = this;
      AMap.service(["AMap.CitySearch"], function () {
        var citysearch = new AMap.CitySearch();
        citysearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            if (result && result.city && result.bounds) {
              var cityinfo = result.city;
              var citybounds = result.bounds;
							console.log(citybounds)
              var lat = ((citybounds.oc.lat + citybounds.xc.lat) / 2).toFixed(
                6
              );
              var lng = ((citybounds.oc.lng + citybounds.xc.lng) / 2).toFixed(
                6
              );
              self.lng = lng;
              self.lat = lat;
							self.mapCenter = [self.lng, self.lat];
              console.log(self.mapCenter)
              
            }
          } else {
            self.lng = 117.277272;
            self.lat = 31.871093;
						self.mapCenter = [117.277272, 31.871093];
          }
        });
      });
    }


  },
};
</script>
<style scoped>
.amap-page-container .hd {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.mapItem {
  color: #0082e6;
  background: #e8f5ff;
  border: 1px solid #bfe4ff;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  cursor: pointer;
}
.el-vue-search-box-container {
  width: 100% !important;
}
</style>