<!--
 * @Auhtor: 文锋
 * @Date: 2021-05-12 16:33:50
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-13 14:28:50
 * @FilePath: \operate\src\components\basic\CeoCheck.vue
-->
<template lang="">
    <!-- Ceo审核 -->
    <div>
        <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
            <el-form-item>
                <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
            </el-form-item>
            <el-form-item>
                <el-select clearable filterable v-model="queryParams.status" placeholder="处理状态" @change="resetPageAndRefresh">
                <el-option label="待处理" value="0">审核中</el-option>
                <el-option label="已处理" value="1">通过</el-option>
                <el-option label="已处理" value="-1">未通过</el-option>
                </el-select>
            </el-form-item>
        </el-form>
         <!-- 列表 -->
        <div class="content-wrap">
            <div v-if="checkTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
            <paged-list v-if="checkTotal>0" v-model="queryParams" :data="checkList" :total="checkTotal" @refresh="refresh" :span='12'>
                <template slot-scope="scope">
                    <div class="flex col" @click="drawer = true,checkDetail(scope.row.id)">
                      <div class="flex border-line">
                        <div class="left">
                          <div class="name mr50">
                            <p>{{scope.row.userName}}</p>
                            <p>申请人</p>
                          </div>
                          <div class="name">
                            <p>{{scope.row.extend.schoolName}}</p>
                            <p>申请学校</p>
                          </div>
                        </div>
                        <div class="right space-between">
                          <div class="name">
                            <p>{{scope.row.mobile}}</p>
                            <p>联系方式</p>
                          </div>
                          <div class="name">
                            <p class="wraning" v-if="scope.row.status == 0">待审核</p>
                            <p class="success" v-if="scope.row.status == 1">审核通过</p>
                            <p class="danger" v-if="scope.row.status == -1">审核未通过</p>
                            <p>处理状态</p>
                          </div>
                        </div>
                      </div>
                      <div><span class="desc">申请时间:</span>{{scope.row.applyTime}}</div>
                      <div class="text-ellipsis"><span class="desc">申请描述:</span>{{scope.row.description}}</div>
                    </div>
                </template>
            </paged-list>
        </div>
        <!-- 详情 -->
        <el-drawer
        append-to-body
        title="详情"
        :visible.sync="drawer"
        size="800px"
        v-if="detail"
        >
        <div class="checkStatus">
          <div class="flex">
            <div class="left">
              <div class="flex col name mr20">
                <p>{{detail.userName}}</p>
                <p>申请人</p>
              </div>
              <div class="name flex col">
                <p>{{detail.extend.schoolName}}</p>
                <p>学校</p>
              </div>
            </div>
            <div class="right space-between">
              <div class="name flex col">
                <p>{{detail.mobile}}</p>
                <p>联系方式</p>
              </div>
              <div class="name flex col">
                <p class="wraning" v-if="detail.status == 0">待审核</p>
                <p class="success" v-if="detail.status == 1">通过</p>
                <p class="danger" v-if="detail.status == -1">不通过</p>
                <p>处理状态</p>
              </div>
            </div>
          </div>
          <p v-if="detail.status !== 0"><span class="checktime2">审核时间</span>{{detail.checkTime}}</p>
        </div>
        <div>
          <p class="time">申请时间</p>
          <p class="desc2">{{detail.applyTime}}</p>
        </div>
        <div>
          <p class="time">申请描述</p>
          <p class="desc2">{{detail.description}}</p>
        </div>
        <div class="checkForm" v-if="detail.status == 0">
          <p class="tips">审核</p>
          <el-form :model="checkForm" label-width="90px">
            <el-form-item label="审核状态">
              <el-radio-group v-model="checkForm.status" @change="checkForm.reason = ''">
                <el-radio label="1">通过</el-radio>
                <el-radio label="-1">不通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="不通过原因" v-if="checkForm.status == -1">
              <el-input v-model="checkForm.reason" placeholder="请输入原因"  ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button class="btn" @click="drawer = false">取消</el-button>
              <el-button class="btn sumbit"  @click="handleCheck(detail.id)">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AreaTree from "@/components/common/AreaTree";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";

export default {
  name: "ceoCheck",
  components: {
    PagedList,
    AreaTree,
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        province: "",
        city: "",
        schoolId: "",
        status: "",
        pageNum: 1,
        pageSize: 10,
      },
      checkForm: {
        id: "",
        status: "1",
        reason: "",
      },
      detail: null,
      drawer: false,
      pageApi: "getPagesList",
    };
  },
  computed: {
    ...mapState("ceocheck", ["checkList", "checkTotal"]),
  },
  methods: {
    ...mapActions("ceocheck", [
      "getPagesList",
      "getCheckInfoById",
      "editCheckInfo",
    ]),
    handleQuery(data) {
      this.clearArea(false);
      data.forEach((ele) => {
        if ((ele + "").length === 9) {
          this.queryParams.province = ele;
        }
        if ((ele + "").length === 12) {
          this.queryParams.city = ele;
        }
        if ((ele + "").length === 15) {
          this.queryParams.area = ele;
        }
        if ((ele + "").length < 9) {
          this.queryParams.schoolId = ele;
        }
      });
      this.refresh();
    },
    clearArea(isRefresh) {
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if (isRefresh) this.refresh();
    },
    async checkDetail(id) {
      this.detail = await this.getCheckInfoById({ id });
    },
    async handleCheck(id) {
      this.checkForm.id = id;
      let res = await this.editCheckInfo(this.checkForm);
      this.$message.success("数据保存成功！");
      this.drawer = false;
      this.refresh();
    },
  },
  async mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.flex {
  display: flex;
  .left,
  .right {
    display: flex;
    width: 50%;
  }
}
.col {
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}
.mr50 {
  margin-right: 50px;
}
.mr20 {
  margin-right: 20px;
}
.border-line {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeff3;
}
.name {
  font-family: PingFangSC-Semibold, PingFang SC;
  p:first-child {
    font-weight: 600;
    font-size: 20px;
    color: #222;
    &.wraning {
      color: $c-primary;
    }
    &.success {
      color: $c-success;
    }
    &.danger {
      color: $c-danger;
    }
  }
  p:last-child {
    font-size: 14px;
    color: #889098;
  }
}
.desc {
  margin-right: 20px;
  font-size: 14px;
  color: #889098;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkStatus {
  margin: 20px;
  padding: 20px;
  background: #f5f7f9;
  border-radius: 4px;
}

/deep/#el-drawer__title {
  background: $c-title;
  padding: 10px;
  color: $c-white;
}
/deep/.el-drawer__header {
  margin-bottom: 0px;
}
/deep/.el-form-item__label {
  color: #afafaf;
}
.time {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.desc2 {
  margin-left: 20px;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.checktime2 {
  color: #889098;
  margin-right: 20px;
}
.checkForm {
  margin: 20px;
  padding: 20px;
  background: #f5f7f9;
  border-radius: 6px;
  .tips {
    font-size: 16px;
    font-weight: 600;
  }
  .btn {
    width: 140px;
    height: 44px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #eaeff3;
    &.sumbit {
      color: #fff;
      background-color: #28b892;
    }
  }
}
</style>