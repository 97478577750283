import { BaseUrl } from "@/common/config";
import axios from "axios";

//通用考核权重 详情 
export const getAllByRegionInfo = function (params) {
    return axios({
        url: BaseUrl + 'score-set/getAllByRegionId',
        method: 'post',
        data: params
    })
} 

//通用考核权重 设置
export const editAllByRegionInfo = function (params) {
    return axios({
        url: BaseUrl + 'score-set/save?regionId=0',
        method: 'post',
        data: params
    })
} 


export default {
    getAllByRegionInfo,
    editAllByRegionInfo
}