<template>
<!-- 新增分成管理 @需要写 -->
  <div>
    <img style="width:100%" @click="add" src="../../assets/images/fcadd.png" alt="">
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods:{
     add() {
       this.$router.push('/divide')
     }
    },
    mounted() {
      
    }
  }
</script>

<style lang="scss" scoped>

</style>