<template>
  <!-- 编辑提现配置 @需要改,没有进行数据渲染 -->
  <div class="wrap">
    <div class="box-con">
      <el-tabs v-model="active" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="编辑提现配置" name="1"></el-tab-pane>
      </el-tabs>

      <el-form class="form-task" size="small" :model="addForm" v-if="addForm.type==105001">
        <div class="form-title">提现配置</div>
        <div v-for="(item1,index1) in commonList" :key="index1">
          <div v-for="(item2,index2) in item1.taskNumArr" :key="index2" class="for-task">
            <el-form-item>
              <div class="cash-box">
                <span>提现金额：&nbsp;</span>
                <el-input class="cash-input" placeholder="请输入金额"></el-input>
                <span class="to">-</span>
                <el-input class="cash-input" placeholder="请输入金额"></el-input>
              </div>&nbsp;
              <img src="@/assets/images/operate/add.svg" alt class="add-icon" v-if="index2 == 0" @click="addCurrent(index1,item1.options.length)" />
              <img src="@/assets/images/operate/delete.svg" alt class="add-icon" v-if="index2 > 0" @click="deleteCurrent(index1,index2)" />
              <div>
                <div class="power-box">
                  <span>审核级别：&nbsp;</span>
                  <el-select class="form-select" v-model="addForm2.select[index1].t2Id">
                    <el-option v-for="(item2,index2) in commonListOpera.options" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                  </el-select>
                </div>
                <div class="power-box">
                  <span>一级审核：&nbsp;</span>
                  <el-select class="form-select" v-model="addForm2.select[index1].t2Id">
                    <el-option v-for="(item2,index2) in commonListOpera.options" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                  </el-select>
                </div>
                <div class="power-box">
                  <span>三级审核：&nbsp;</span>
                  <el-select class="form-select" v-model="addForm2.select[index1].t2Id">
                    <el-option v-for="(item2,index2) in commonListOpera.options" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                  </el-select>
                </div>
                <div class="power-box">
                  <span>二级审核：&nbsp;</span>
                  <el-select class="form-select" v-model="addForm2.select[index1].t2Id">
                    <el-option v-for="(item2,index2) in commonListOpera.options" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                  </el-select>
                </div>
                <div class="power-box">
                  <span>最终审核：&nbsp;</span>
                  <el-input class="form-select" placeholder="请输入金额"></el-input>
                </div>
              </div>
              
            </el-form-item>
          </div>
        </div>
        <div class="form-title">配置描述</div>
        <div>
          <textarea class="textarea" placeholder="请输入配置描述"> </textarea> 
        </div>
        
        <el-form-item style="text-align: center;">
          <el-button size="small" class="sub-btn" @click="resetForm()">取消</el-button>
          <el-button size="small" class="sub-btn" type="primary" @click="taskSubmit()">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MemberTask from "@/components/task/myTask/MemberTask";
import DirectorTask from "@/components/task/myTask/DirectorTask";
import { mapState, mapActions } from "vuex";
export default {
  name:'allocationsAdd',
  components: {
    MemberTask,
    DirectorTask
  },
  data() {
    var dateNew = new Date();
    var queryYear = dateNew.getFullYear()-0
    return {
      arrGe: [105001003001,105001001002,105001003002,105001003003,105001003004,105001003005,105001003006,105001004001,105001004002,105001004003],
      arrJia: [105001002001,105001002002,105001002003,105001002004,105001002005,105001002006,105001002007],
      taskCommon: {},
      getAwardTask: {},
      editor: false,
      yearOptions: [],
      addForm: {
        type:105001,
        years:2021,
        month:"上半年",
        options: [
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
        ],
      },
      editForm: {
        type:105001,
        years:2021,
        month:"上半年",
        options: [
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
        ],
      },
      addForm2: {
        t1Id: null,
        select:[
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
        ]
      },
      form: {
        type: 105001,
        years: queryYear,
        month: '上半年',
        generals:[{t1Id:null, typeId:null, target:null}],
        operations:[]
      },
      statusOptions: [],
      active:'1',
      nameRoomArr: [''],

      commonList: [
        { name:'通用任务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'推广任务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'对接应用', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'用户服务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'用户运营', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
      ],
      commonListOpera: { name:'用户运营', value:'', taskNumArr:[''], options:[
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
      ]},
    };
  },
  computed: {
  },
  methods: {
    ...mapActions("directorTask", ["directorAdd", "directorEdit", "taskList", "taskTree"]),
    async handleClick(tab, event) {
      // console.log(tab)
    },
    commonBtn(){
      this.addForm.type=105001
    },
    addCurrent(index1,length){
      console.log(this.commonList,this.addForm)
      if(this.commonList[index1].taskNumArr.length<length) {
        this.commonList[index1].taskNumArr.push('');
        if(this.addForm.options[index1].select.length<length){
          this.addForm.options[index1].select.push({t2Id:null, num:null})
        }
      }
    },
    deleteCurrent(index1,index2){
      this.commonList[index1].taskNumArr = this.commonList[index1].taskNumArr.filter((item, idx) => idx != index1);
      this.addForm.options[index1].select.splice(index2,1)
      // console.log(this.addForm.options[index1])
    },
    addOperate(length){
      console.log(this.commonListOpera,this.addForm2)
      if(this.commonListOpera.taskNumArr.length<length) {
        this.commonListOpera.taskNumArr.push('');
      }
    },
    deleteOperate(index){
      this.commonListOpera.taskNumArr = this.commonListOpera.taskNumArr.filter((item, idx) => idx != index);
      this.addForm2.select.splice(index,1)
    },
    
    async taskSubmit(){
      Object.assign(this.form ,this.addForm);
      delete this.form.options
      this.form.generals = []
      this.addForm.options.forEach((ele1,index1)=>{
        ele1.select.forEach((ele2, index2)=>{
          if(ele2.t2Id == 105001001001){ ele2.num*=100 }
          this.form.generals.push({t1Id: ele1.t1Id, typeId: ele2.t2Id, target: ele2.num })
        })
      })
      this.form.operations = []
      this.addForm2.select.forEach((ele1, index2)=>{
        this.form.operations.push({
          t1Id: this.commonListOpera.value, 
          typeId: ele1.t2Id,
          relTar: ele1.relTar,
          vieTar: ele1.vieTar,
          likTar: ele1.likTar,
          comTar: ele1.comTar,
          shaTar: ele1.shaTar
        })
      })
      let obj = {}
      Object.assign(obj, this.form)
      // console.log(obj)
      if(this.editor){
        obj.id = this.$route.params.id
        try{
          let res = this.directorEdit(obj)
          this.$message.success("修改成功")
        }catch(error){}
      }else{
        try{
          let res = this.directorAdd(obj)
          this.$message.success("添加成功")
        }catch(error){}
      }
      this.resetForm()
    },
    resetForm() {
      this.$router.push({path: '/myTask'})
      this.addForm = {
        type:105001,
        years:2021,
        month:"上半年",
        options: [
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
          {
            t1Id: null,
            select:[
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
              {t2Id:null, num:null},
            ]
          },
        ],
      },
      this.addForm2 = {
        t1Id: null,
        select:[
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
          {t2Id:null, relTar:null, vieTar:null, likTar:null, comTar:null, shaTar:null},
        ]
      },
      this.commonListOpera = { name:'用户运营', value:'', taskNumArr:[''], options:[
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
        { name:'', value:'' },
      ]},
      this.commonList = [
        { name:'通用任务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'推广任务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'对接应用', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'用户服务', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
        { name:'用户运营', value:'', taskNumArr:[''], options:[
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
          { name:'', value:'' },
        ]},
      ]
    },
    addAwardTaskOne(){
      if(this.nameRoomArr.length<2) {
        this.form.nameRoom = ''
        this.nameRoomArr.push('');
      }
    },
    deleteAwardTaskOne(index){this.nameRoomArr = this.nameRoomArr.filter((item, idx) => idx != index);},
    getyear(){
      this.yearOptions = []
      var date = new Date();
      let year = date.getFullYear()
      for(let i=year;i<year+3;i++){
        this.yearOptions.push({ name: i+'年', value: i })
      }
    },
    async getTask(){
      let res1 = await this.taskTree({code:"task_common"})
      this.taskCommon = res1
      res1.children.forEach((ele1,index1)=>{
        this.commonList[index1].name = ele1.name
        this.commonList[index1].value = ele1.id
        this.addForm.options[index1].t1Id = ele1.id
        ele1.children.forEach((ele2,index2)=>{
          this.commonList[index1].options[index2].name = ele2.name
          this.commonList[index1].options[index2].value = ele2.id
        })
      })
      // console.log(this.commonList)
      this.commonListOpera = this.commonList.pop()
      this.getAwardTask = await this.taskTree({code:"task_award"})
    },
    async editGet(){
      let res1 = await this.taskTree({code:"task_common"})
      res1.children.forEach((ele1,index1)=>{
        this.commonList[index1].name = ele1.name
        this.commonList[index1].value = ele1.id
        ele1.children.forEach((ele2,index2)=>{
          this.commonList[index1].options[index2].name = ele2.name
          this.commonList[index1].options[index2].value = ele2.id
        })
      })
      this.commonListOpera = this.commonList.pop()
      
      let res = this.$route.params
      console.log(res)
      this.addForm.type = res.type
      this.addForm.years = res.years-0
      this.addForm.month = res.month
      let t1IdArr = []
      if(res.generals && res.generals.length>0){
        res.generals.forEach((item,index)=>{
          t1IdArr.splice(0,0,item.t1Id)
        })
      }
      // console.log(res)
      t1IdArr = Array.from(new Set(t1IdArr))
      t1IdArr.forEach((item1,index1)=>{
        // this.commonList[index1].taskNumArr = []
        this.addForm.options[index1].t1Id = item1
        this.addForm.options[index1].select = []
        res.generals.forEach((item2,index2)=>{
          if(res.generals[index2].t1Id == item1){
            this.addForm.options[index1].select.splice(0,0,{t2Id: item2.typeId, num: item2.target})
            // this.commonList[index1].taskNumArr.push('')
            // this.commonList[index1].options.splice(0,0,{name: item2.extend.t2Name, value: item2.typeId})
          }
        })
        this.addForm.options[index1].select.forEach((item,index)=>{
          if(item.t2Id == 105001001001){
            item.num = item.num.toFixed(2) / 100
          }
        })
      })

      if(res.operations && res.operations.length>0){
        // this.commonListOpera.taskNumArr = []
        this.addForm2.select = []
        this.addForm2.t1Id = res.operations[0].t1Id
        // this.commonListOpera.options = []
        res.operations.forEach((item1,index1)=>{
          this.addForm2.select.splice(0,0,{
            t2Id: item1.typeId, relTar: item1.relTar, 
            vieTar: item1.vieTar, likTar: item1.likTar, 
            comTar: item1.comTar, shaTar: item1.shaTar 
          })
          // this.commonListOpera.options.splice(0,0,{name: item1.extend.t2Name, value: item1.typeId})
        })
        res.operations.forEach((item1,index1)=>{
          this.commonListOpera.taskNumArr.push('')
        })
      }
    },
  },
  watch: {
    // 方法1
    '$route' (to, from) { //监听路由是否变化
      if(this.$route.params.id){// 判断条件1  判断传递值的变化
        console.log("路由变化")
        this.editGet()
        this.editor = true
      }else{
        this.getTask()
        this.editor = false
      }
    }
  },
  created(){
    if(this.$route.params.id){ this.editGet() }
  },
  async mounted() {
    this.getyear()
    if(!this.$route.params.id){
      this.getTask()
      this.editor = false
      console.log("添加")
    }else{
      this.editor = true
    }
  },
};
</script>
<style lang="scss" scoped>
.wrap{ width: 100%; background: #fff; width: 100%; padding: 10px 20px 20px;
  .box-con{ width: 100%; min-height: 100%; background:#fff;
    .tabs{ width: 100%; background:#fff;padding-top: 0;
      /deep/.el-tabs__item { font-size: 18px; font-weight: bold; }
    }
    .form-task { margin-bottom: 30px;
      .form-title { font-size: 18px; font-weight: bold; height: 18px; line-height: 18px; margin-top: 20px; }
      .for-task { display: inline-block; margin: 20px 20px 0 0;
        .cash-box { display: inline-block;
          .cash-input { width: 150px; }
          .to { margin: 0px 10px; }
        }
        .power-box { margin: 20px 40px 0 0; display: inline-block;
          .form-select { margin-left:10px; width: 250px; }
        }
        .form-input { width: 200px; margin-left: 10px; }
        .add-icon { vertical-align: middle; cursor: pointer; }
      }
      .textarea { width: 100%; height: 5em; border: 1px solid #DCDFE6; margin-top: 20px; outline: none; padding: 5px 10px; border-radius: 4px; }
      .sub-btn { width: 160px; height: 40px; margin: 30px 50px 0 0; } 
    }
  }
}
</style>
