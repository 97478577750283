<template>
  <div>
    <!-- 部门管理 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.deptName" clearable placeholder="请输入部门名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.deptManager" clearable placeholder="请输入部门负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('xtgl:bmgl:add')">添加</el-button>
      </div>
      <div v-if="deptTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="deptTotal>0"
        :data="deptList"
        :total="deptTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="deptName" show-overflow-tooltip label="部门名称" width="250"></el-table-column>
        <el-table-column sortable="custom" prop="deptManager" show-overflow-tooltip label="部门负责人"></el-table-column>
        <el-table-column sortable="custom" prop="phone" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row)" v-if="OA.includes('xtgl:bmgl:editor')" icon="el-icon-edit" title="编辑"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" v-if="OA.includes('xtgl:bmgl:del')" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑部门' : '添加部门'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="部门名称：" prop="deptName">
          <el-input v-model="form.deptName" clearable></el-input>
        </el-form-item>
        <el-form-item label="部门负责人：" prop="deptManager">
          <el-input v-model="form.deptManager" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" clearable :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="form.remark" clearable :row="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="部门详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="部门名称：" prop="deptName">
          <span v-text="details.deptName"></span>
        </el-form-item>
        <el-form-item label="部门负责人：" prop="deptManager">
          <span v-text="details.deptManager"></span>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <span v-text="details.phone"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'noticeManagement',
  components: {
    PagedTable
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        deptName: null,
        deptManager: null,
      },

      form:{
        id:'',
        deptName:'',
        deptManager:'',
        phone:'',
        remark:'',
      },
      details:{
        deptName:'',
        deptManager:'',
        phone:'',
        remark:'',
        extend:{}
      },
      formRules:{
        deptName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        deptManager: [{ required: true, message: '请输入部门负责人', trigger: 'blur' }],
      },

      pageApi:'commonDeptist',
      insertApi:'commonDeptAdd',
      editorApi:'commonDeptEdit',
      deleteApi:'commonDeptDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemDept", ["deptList", "deptTotal"]),
  },
  methods: {
    ...mapActions("systemDept",["commonDeptist", "commonDeptAdd", "commonDeptEdit", "commonDeptDelete"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>