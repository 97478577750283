<template>
  <!-- 院系管理 -->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" :model="queryParams">
        <!-- <el-form-item class="area_img">
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item> -->
        <el-form-item>
          <el-cascader size="small" placeholder="请选择校区" v-model="value" :options="dataTreeB" accordion :props="{ checkStrictly: true, ...defaultPropsB }" @change="handleNodeClick" clearable ref="cascader"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-select size="small" clearable filterable v-model="queryParams.fadeName" placeholder="请选择学院名称" @change="resetPageAndRefresh">
            <el-option v-for="item in fadeOption" :key="item.id" :label="item.fadeName" :value="item.fadeName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="small" v-model="queryParams.major" clearable placeholder="请输入专业名称" @change="resetPageAndRefresh"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button size="small" icon="el-icon-plus" @click="addHandleForm()"  v-if="OA.includes('jcxx:yxgl:add')">新增</el-button>
          <el-button size="small"  v-if="OA.includes('jcxx:yxgl:showEdit')" @click="isEditor=!isEditor" :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
      </el-form>
      
      <div v-if="collegeTotal==0" class="totalNull"><img src="../../assets/images/nothing.svg" alt=""></div>
      <!-- 列表 -->
      <paged-list v-if="collegeTotal>0" v-model="queryParams" :data="collegeList" :isEditor="isEditor" :total="collegeTotal" :span='8' @refresh="refresh">
        <template slot-scope="scope">
          <div class="list" @click="requireDetails(scope.row)">
            <div class="con-head">
              <div class="area">
                <p class="con-txt">{{ scope.row.fadeName?scope.row.fadeName:'-' }}</p>
                <div class="con-school" v-if="scope.row.extend">{{scope.row.extend.schoolName}}-{{scope.row.distName}}</div>
              </div>
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">专业名称</span>
                  <p class="majorName">{{ scope.row.majorNames }}</p>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template slot="control" slot-scope="scope">
          <el-button type="primary" @click="handleForm(true, scope.row, afterHandleForm)" icon="el-icon-edit" title="编辑"  v-if="OA.includes('jcxx:yxgl:editor')" circle></el-button>
          <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
          <el-button type="danger" v-if="OA.includes('jcxx:yxgl:del')" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" circle></el-button>
        </template>
      </paged-list>
    </div>

    <!-- 编辑界面 -->
    <el-dialog @close="resetForm()" :title="isEditor ? '编辑学院信息' : '学院信息维护'" :visible.sync="aVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="院系名称：" prop="fadeName">
          <el-input placeholder="请输入院系" v-model="form.fadeName" class="w350"></el-input>
        </el-form-item>
        <el-form-item label="专业名称：" prop="" class="majors">
          <div v-for="(item,index) in form.majorList" :key="index">
            <el-select style="width:310px" v-model="form.majorList[index].name" filterable placeholder="请选择或搜索专业">
              <el-option v-for="item in majorOptions" :key="item.id" :label="item.name" :value="item.id+''">
              </el-option>
            </el-select>
            <img src="@/assets/images/operate/add.svg" alt class="btn" v-if="index == 0" @click="addMajor" />
            <img src="@/assets/images/operate/delete.svg" alt class="btn" v-if="index > 0" @click="removeMajor(index)" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm()">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi), resetForm()">保存</el-button>
      </div>
    </el-dialog>

    <!-- 添加界面 -->
    <el-dialog @close="resetAddForm()" title="学院信息维护" :visible.sync="addVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="addFormFileds" :model="addForm" :rules="addFormRules" size="small">
        <el-form-item label="校区选择：" prop="schoolId">
          <el-select style="width:330px" v-model="addForm.distId" filterable placeholder="请选择校区专业">
            <el-option v-for="item in campusOption" :key="item.id" :label="item.distName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-for="(i,dex) in addForm.fadeList" :key="i.id">
          <el-form-item label="院系名称：" prop="fade" class="majors">
            <el-input style="width:330px" placeholder="请输入院系名称" v-model="addForm.fadeList[dex].fade"></el-input>
            <img src="@/assets/images/operate/add.svg" alt class="btn" v-if="dex == 0" @click="addFade" />
            <img src="@/assets/images/operate/delete.svg" alt class="btn" v-if="dex > 0" @click="removeFade(dex)" />
          </el-form-item>
          <el-form-item label="专业名称：" prop="name" class="majors">
            <div v-for="(item,index) in addForm.fadeList[dex].majorList" :key="index">
              <el-select style="width:290px" v-model="addForm.fadeList[dex].majorList[index].name" filterable placeholder="请选择或搜索专业">
                <el-option v-for="item in majorOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <img src="@/assets/images/operate/add.svg" alt class="btn" v-if="index == 0" @click="addMajorB(dex)" />
              <img src="@/assets/images/operate/delete.svg" alt class="btn" v-if="index > 0" @click="removeMajorB(dex,index)" />
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetAddForm()">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="submitAddForm()">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-drawer title="院系详情" :visible.sync="drawer" size="36.5%">
      <div class="drawer">
        <div class="big-title">{{ collegeDetials.fadeName }}</div>
        <p class="dist-title" v-if="collegeDetials.extend">{{collegeDetials.extend.schoolName}}-{{collegeDetials.distName}}</p>
        <div class="gray-title">专业名称</div>
        <div class="content-normal">{{ collegeDetials.majorNames }}</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import datePicker from "@/mixins/datePicker";
import { mapState, mapActions } from "vuex";
import { log, mergeObject } from '@/common/utils'
/* eslint-disable no-unused-vars */
export default {
  name:'collegeManege',
  components: {
    PagedList,
    AreaTree,
  },
  mixins: [pageMixin, datePicker],
  data() {
    var validateFade = (rule, value, callback) => {
      let fadeLists = this.addForm.fadeList
      if (!fadeLists[fadeLists.length-1].fade) {
        callback(new Error('请输入院系名称'));
      } else {
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      let fadeLists = this.addForm.fadeList
      if (!fadeLists[fadeLists.length-1].majorList[fadeLists[fadeLists.length-1].majorList.length-1].name) {
        callback(new Error('请选择专业'));
      } else {
        callback();
      }
    };
    return {
      value: null,
      fadeOption: [],
      dataTreeB: [],
      defaultPropsB: {
        children: "children",
        label: "name",
        value: "id",
      },
      /** 角色权限 */
      addVisible: false,
      form: {
        id: null,
        distId: '',
        fadeName: "",
        majorList: [],
      },
      collegeDetials: {},
      majorOptions:[],
      drawer: false,
      formRules:{
        fadeName: [{ required: true, message: '请输入院系', trigger: 'blur' }],
      },
      queryParams: {
        province: null,
        city: null,
        schoolId: null,
        distId: '',
        fade: '',
        major: ''
      },
      campusOption: [],
      addForm: {
        distId: null,
        fadeList: [
          {
            fade: '',
            majorList: [
              {name: ''}
            ],
          }
        ]
      },
      addFormRules: {
        distId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        fade: [{ required: true, validator: validateFade, trigger: 'blur' }],
        name: [{ required: true, validator: validateName, trigger: 'blur' }],
      },

      pageApi:'schoolFadeGetPageList',
      editorApi: 'membersMajorEdit',
      deleteApi: 'membersMajorDeleteFade',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicCollege", ["collegeList", "collegeTotal"]),
  },
  methods: {
    ...mapActions("commonOptions", ["getSchoolCombo"]),
    ...mapActions("basicCollege", ["schoolFadeGetPageList","membersSchoolDistAppUserDists", "commonDictList", "membersMajorAdd", "membersMajorSelectAll", "membersMajorEdit","membersMajorDeleteFade", "membersSchoolFadeSelectAll"]),
    ...mapActions("basicApartment", [ "membersSchoolGetSchoolDistTree" ]),
    async requireDetails(row) {
      this.collegeDetials = row
      this.drawer = true
      console.log(this.collegeDetials)
    },
    // 添加
    async addHandleForm() {
      this.addVisible = true,
      this.majorRequire()
    },
    resetAddForm(){
      this.addForm = {
        distId: null,
        fadeList: [ { fade: '', majorList: [ {name: null} ], } ]
      },
      this.$refs.addFormFileds.resetFields()
      this.addVisible = false
    },
    submitAddForm() {
      this.$refs.addFormFileds.validate(async (valid)=>{
        if(valid){
          try{
            this.addForm.fadeList.forEach((element,index) => {
              element.majorList.forEach((ele, dex) => {
                this.addForm.fadeList[index].majorList[dex].name = ele.name+''
              });
            });
            let res = await this.membersMajorAdd(this.addForm)
            if(!!res){ this.$message.success("添加成功") }
          }catch(error) {}finally{
            this.resetAddForm()
            this.refresh();
          }
        }
      })
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    async afterHandleForm(row){
      this.majorRequire()
      let res = await this.membersMajorSelectAll({schoolId:row.schoolId,fadeId: row.id})
      this.form.distId = row.schoolId
      this.form.fadeName = row.fadeName
      res.forEach(element => {
        this.form.majorList.push({ name: element.majorId+'' })
      });
    },
    addMajor() {
      this.form.majorList = this.form.majorList.filter(item=>item);
      if(!!this.form.majorList[this.form.majorList.length-1].name){
        this.form.majorList.push({name: null});
      }
    },
    // 删除关联标签
    removeMajor(index) {
      this.form.majorList = this.form.majorList.filter((item, idx) => idx != index);
    },
    addFade() {
      this.addForm.fadeList = this.addForm.fadeList.filter(item=>item);
      if(!!this.addForm.fadeList[this.addForm.fadeList.length-1].majorList[this.addForm.fadeList[this.addForm.fadeList.length-1].majorList.length-1].name){
        this.addForm.fadeList.push({fade: '', majorList: []});
        this.addForm.fadeList[this.addForm.fadeList.length-1].majorList.push({name: null});
      }else{ this.$message.warning('请将信息填写完整,再添加') }
    },
    // 删除关联标签
    removeFade(index) {
      this.addForm.fadeList = this.addForm.fadeList.filter((item, idx) => idx != index);
    },
    addMajorB(dex) {
      this.addForm.fadeList[dex].majorList = this.addForm.fadeList[dex].majorList.filter(item=>item);
      if(!!this.addForm.fadeList[dex].majorList[this.addForm.fadeList[dex].majorList.length-1].name){
        this.addForm.fadeList[dex].majorList.push({name: null});
      }else{ this.$message.warning('请将信息填写完整,再添加') }
    },
    // 删除关联标签
    removeMajorB(dex,index) {
      this.addForm.fadeList[dex].majorList = this.addForm.fadeList[dex].majorList.filter((item, idx) => idx != index);
    },
    async majorRequire() {
      let res = await this.commonDictList({pid: 104006, name:'', pageNum: 1,pageSize: 1000})
      this.majorOptions = res.list
    },
    resetForm(){
      this.$refs.formFileds.resetFields()
      this.form.majorList = []
      this.aVisible = false
    },
    // 获取侧边栏区域树
    async areaTree(){
      this.dataTreeB = await this.membersSchoolGetSchoolDistTree({code: "area_cn"})
    },
    // 获取侧边栏区域树
    async fadeRequire(){
      this.fadeOption = await this.membersSchoolFadeSelectAll({distId: this.queryParams.distId})
    },
    handleNodeClick(data) {
      this.queryParams.province = null
      this.queryParams.city = null
      this.queryParams.schoolId = null
      this.queryParams.distId = ''
      // if(data.length===4){ this.queryParams.distId = data[data.length-1] 
      // }else{ this.$message.warning("选择校区才能看到院系哦!")
      // }
      if(data.length===1){ this.queryParams.province = data[data.length-1] }
      if(data.length===2){ this.queryParams.city = data[data.length-1] }
      if(data.length===3){ this.queryParams.schoolId = data[data.length-1] }
      if(data.length===4){ this.queryParams.distId = data[data.length-1] }
      this.fadeRequire()
      this.refresh()
      this.$refs.cascader.dropDownVisible = false;  
    },
  },
  async mounted() {
    this.areaTree()
    this.campusOption = await this.membersSchoolDistAppUserDists({})
    this.refresh ()
  },
};
</script>
<style lang="scss" scoped>  
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{ padding-bottom:15px; border-bottom: 1px solid #E5E5E5;
        .area{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px; line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
        }
          .con-school { color: #999; font-size: 14px; margin-top: 10px;}
      }
      .con-con{
        .time-fl{ display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .majorName { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 370px; }
        }
      }
    }
  }
  .drawer { padding: 20px;  
    .big-title { font-size: 20px; font-weight: bold; line-height: 20px;}
    .dist-title { font-size: 14px; color: #afafaf;border-bottom: 1px $c-border solid;padding-bottom: 10px;}
    .gray-title { color: $c-9; font-size: 16px; line-height: 16px; padding: 20px 0; }
  }
}
.majors {
  img { vertical-align: middle; margin-left: 20px; cursor: pointer; }
}
.w350{ width: 350px !important; }
.w175{ width: 175px !important; }

.dialog-footer { margin-right: 100px;
  .el-button { width: 140px; margin-right: 20px;}
}
</style>
