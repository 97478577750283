/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/finance/allocations";

const state = {
  allocationsList:[],
  allocationsTotal: 0,
};

// actions
const actions = {
  // 列表
  async settleWithdrawSelectAll({ commit, state }, params) {
    return await api.settleWithdrawSelectAll(params);
  },
  async settleWithdrawUpd({ commit, state }, params) {
    return await api.settleWithdrawUpd(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveList(state, { list, total }) {
    state.allocationsList = list;
    state.allocationsTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
